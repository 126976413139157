/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_ResultStatus = require("../remotemessage/ResultStatus");
var remotemessage_Message = require("../remotemessage/Message");
var base_Signature = require("../base/Signature");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var SignatureResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = SignatureResponseMessage;
  this.setMethod(remotemessage_Method["REQUEST_SIGNATURE_RESPONSE"]);
  this.status = undefined;
  this.reason = undefined;
  this.signature = undefined;
};

SignatureResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
SignatureResponseMessage.prototype.constructor = SignatureResponseMessage;

/**
* Set the field value
* @memberof remotemessage.SignatureResponseMessage
* @param {remotemessage.ResultStatus} status 
*/
SignatureResponseMessage.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* @memberof remotemessage.SignatureResponseMessage
* @return {remotemessage.ResultStatus} 
*/
SignatureResponseMessage.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* @memberof remotemessage.SignatureResponseMessage
* @param {String} reason 
*/
SignatureResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotemessage.SignatureResponseMessage
* @return {String} 
*/
SignatureResponseMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* @memberof remotemessage.SignatureResponseMessage
* @param {base.Signature} signature 
*/
SignatureResponseMessage.prototype.setSignature = function(signature) {
  this.signature = signature;
};

/**
* Get the field value
* @memberof remotemessage.SignatureResponseMessage
* @return {base.Signature} 
*/
SignatureResponseMessage.prototype.getSignature = function() {
  return this.signature;
};

SignatureResponseMessage._meta_ =  {fields:  {}};
SignatureResponseMessage._meta_._class_ =  SignatureResponseMessage;
SignatureResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
SignatureResponseMessage._meta_.fields["status"] = {};
SignatureResponseMessage._meta_.fields["status"].type = remotemessage_ResultStatus;
SignatureResponseMessage._meta_.fields["reason"] = {};
SignatureResponseMessage._meta_.fields["reason"].type = String;
SignatureResponseMessage._meta_.fields["signature"] = {};
SignatureResponseMessage._meta_.fields["signature"].type = base_Signature;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = SignatureResponseMessage;
}

