/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var CardEntryType = {
  SWIPED : "SWIPED",
  KEYED : "KEYED",
  VOICE : "VOICE",
  VAULTED : "VAULTED",
  OFFLINE_SWIPED : "OFFLINE_SWIPED",
  OFFLINE_KEYED : "OFFLINE_KEYED",
  EMV_CONTACT : "EMV_CONTACT",
  EMV_CONTACTLESS : "EMV_CONTACTLESS",
  MSD_CONTACTLESS : "MSD_CONTACTLESS",
  PINPAD_MANUAL_ENTRY : "PINPAD_MANUAL_ENTRY",
  QR_CODE : "QR_CODE",
  SCANNED : "SCANNED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardEntryType;
}

