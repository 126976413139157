/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Payment = require("../payments/Payment");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");
var order_Order = require("../order/Order");
var payments_Refund = require("../payments/Refund");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var PrintRefundPaymentReceiptResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = PrintRefundPaymentReceiptResponse;
  this.payment = undefined;
  this.refund = undefined;
  this.order = undefined;
};

PrintRefundPaymentReceiptResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
PrintRefundPaymentReceiptResponse.prototype.constructor = PrintRefundPaymentReceiptResponse;

/**
* Set the field value
* @memberof remotepay.PrintRefundPaymentReceiptResponse
* @param {payments.Payment} payment 
*/
PrintRefundPaymentReceiptResponse.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotepay.PrintRefundPaymentReceiptResponse
* @return {payments.Payment} 
*/
PrintRefundPaymentReceiptResponse.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotepay.PrintRefundPaymentReceiptResponse
* @param {payments.Refund} refund 
*/
PrintRefundPaymentReceiptResponse.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* @memberof remotepay.PrintRefundPaymentReceiptResponse
* @return {payments.Refund} 
*/
PrintRefundPaymentReceiptResponse.prototype.getRefund = function() {
  return this.refund;
};

/**
* Set the field value
* @memberof remotepay.PrintRefundPaymentReceiptResponse
* @param {order.Order} order 
*/
PrintRefundPaymentReceiptResponse.prototype.setOrder = function(order) {
  this.order = order;
};

/**
* Get the field value
* @memberof remotepay.PrintRefundPaymentReceiptResponse
* @return {order.Order} 
*/
PrintRefundPaymentReceiptResponse.prototype.getOrder = function() {
  return this.order;
};

PrintRefundPaymentReceiptResponse._meta_ =  {fields:  {}};
PrintRefundPaymentReceiptResponse._meta_._class_ =  PrintRefundPaymentReceiptResponse;
PrintRefundPaymentReceiptResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
PrintRefundPaymentReceiptResponse._meta_.fields["payment"] = {};
PrintRefundPaymentReceiptResponse._meta_.fields["payment"].type = payments_Payment;
PrintRefundPaymentReceiptResponse._meta_.fields["refund"] = {};
PrintRefundPaymentReceiptResponse._meta_.fields["refund"].type = payments_Refund;
PrintRefundPaymentReceiptResponse._meta_.fields["order"] = {};
PrintRefundPaymentReceiptResponse._meta_.fields["order"].type = order_Order;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintRefundPaymentReceiptResponse;
}

