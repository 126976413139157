import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  legendText: {
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 18,
    color: "#89909B",
    opacity: 0.8,
  },
  btn: {
    marginTop: 20,
    marginBottom: 20,
    width: 350,
    height: 50,
    borderRadius: 12,
    alignSelf: "center",
  },
  disabledBtn: {
    backgroundColor: "#89909b",
  },
  btnText: {
    color: "#FFF",
    fontSize: 18,
  },
  loginLaterBtn: {
    width: 300,
    fontSize: 18,
    marginBottom: 20,
    alignSelf: "center",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  textUnderline: {
    textDecorationLine: "underline",
  },
  inputEmail__container: {
    marginHorizontal: 16,
  },
  inputEmail__label: {
    fontSize: 14,
    textTransform: "uppercase",
    color: "#89909b",
  },
  inputEmail__input: {
    borderColor: "#89909b",
    borderWidth: 1,
    marginTop: 10,
    backgroundColor: "#FFF",
    height: 40,
    fontSize: 18,
    borderRadius: 10,
    padding: 10,
  },
  inputEmail__invalid: {
    color: "#F00",
    fontSize: 16,
    marginTop: 5,
  },
  verificationCode__success: {
    textAlign: "center",
    color: "#0f6657",
    fontSize: 16,
  },
  verificationCode__title: {
    textAlign: "center",
    fontSize: 30,
  },
  verificationCode__inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  verificationCode__inputSubContainer: {
    width: "50%",
  },
  verificationCode__input: {
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  verificationCode__cell: {
    width: 40,
    height: 40,
    lineHeight: 38,
    fontSize: 24,
    borderWidth: 2,
    borderColor: "#00000030",
    textAlign: "center",
  },
  verificationCode__focusCell: {
    borderColor: "#000",
  },
  verificationCode__invalidInput: {
    color: "#DC3545",
    fontSize: 15,
    marginTop: 10,
    paddingLeft: 20,
    textAlign: "center",
  },
});
