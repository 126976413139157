/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof order
*/
var OrderTaxRate = function() {
  this._class_ = OrderTaxRate;
  this.id = undefined;
  this.name = undefined;
  this.amount = undefined;
};


/**
* Set the field value
* @memberof order.OrderTaxRate
* @param {String} id 
*/
OrderTaxRate.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* @memberof order.OrderTaxRate
* @return {String} 
*/
OrderTaxRate.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof order.OrderTaxRate
* @param {String} name 
*/
OrderTaxRate.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* @memberof order.OrderTaxRate
* @return {String} 
*/
OrderTaxRate.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* @memberof order.OrderTaxRate
* @param {Number} amount must be a long integer
*/
OrderTaxRate.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* @memberof order.OrderTaxRate
* @return {Number} must be a long integer
*/
OrderTaxRate.prototype.getAmount = function() {
  return this.amount;
};

/**
* @memberof order.OrderTaxRate
* @private
*/
OrderTaxRate.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

OrderTaxRate.prototype.toString = function() {
  return JSON.stringify(this);
};

OrderTaxRate._meta_ =  {fields:  {}};
OrderTaxRate._meta_._class_ =  OrderTaxRate;
OrderTaxRate._meta_.fields["id"] = {};
OrderTaxRate._meta_.fields["id"].type = String;
OrderTaxRate._meta_.fields["name"] = {};
OrderTaxRate._meta_.fields["name"].type = String;
OrderTaxRate._meta_.fields["amount"] = {};
OrderTaxRate._meta_.fields["amount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OrderTaxRate;
}

