/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_CardType = require("../payments/CardType");
var payments_FraudResponseAction = require("../payments/FraudResponseAction");
var payments_FraudResponseCodeType = require("../payments/FraudResponseCodeType");

/**
* @constructor
* @memberof payments
*/
var PaymentResponseFraudSetting = function() {
  this._class_ = PaymentResponseFraudSetting;
  this.id = undefined;
  this.cardType = undefined;
  this.responseCode = undefined;
  this.responseCodeType = undefined;
  this.action = undefined;
  this.settingName = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
  this.deletedTime = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof payments.PaymentResponseFraudSetting
* @param {String} id 
*/
PaymentResponseFraudSetting.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof payments.PaymentResponseFraudSetting
* @return {String} 
*/
PaymentResponseFraudSetting.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof payments.PaymentResponseFraudSetting
* @param {payments.CardType} cardType 
*/
PaymentResponseFraudSetting.prototype.setCardType = function(cardType) {
  this.cardType = cardType;
};

/**
* Get the field value
* @memberof payments.PaymentResponseFraudSetting
* @return {payments.CardType} 
*/
PaymentResponseFraudSetting.prototype.getCardType = function() {
  return this.cardType;
};

/**
* Set the field value
* @memberof payments.PaymentResponseFraudSetting
* @param {String} responseCode 
*/
PaymentResponseFraudSetting.prototype.setResponseCode = function(responseCode) {
  this.responseCode = responseCode;
};

/**
* Get the field value
* @memberof payments.PaymentResponseFraudSetting
* @return {String} 
*/
PaymentResponseFraudSetting.prototype.getResponseCode = function() {
  return this.responseCode;
};

/**
* Set the field value
* Type of response code: avs or cvv
*
* @memberof payments.PaymentResponseFraudSetting
* @param {payments.FraudResponseCodeType} responseCodeType 
*/
PaymentResponseFraudSetting.prototype.setResponseCodeType = function(responseCodeType) {
  this.responseCodeType = responseCodeType;
};

/**
* Get the field value
* Type of response code: avs or cvv
* @memberof payments.PaymentResponseFraudSetting
* @return {payments.FraudResponseCodeType} 
*/
PaymentResponseFraudSetting.prototype.getResponseCodeType = function() {
  return this.responseCodeType;
};

/**
* Set the field value
* Type of fraud response action: whether to void transaction, prompt user whether he/she wants to void it or ignore - do nothing
*
* @memberof payments.PaymentResponseFraudSetting
* @param {payments.FraudResponseAction} action 
*/
PaymentResponseFraudSetting.prototype.setAction = function(action) {
  this.action = action;
};

/**
* Get the field value
* Type of fraud response action: whether to void transaction, prompt user whether he/she wants to void it or ignore - do nothing
* @memberof payments.PaymentResponseFraudSetting
* @return {payments.FraudResponseAction} 
*/
PaymentResponseFraudSetting.prototype.getAction = function() {
  return this.action;
};

/**
* Set the field value
* @memberof payments.PaymentResponseFraudSetting
* @param {String} settingName 
*/
PaymentResponseFraudSetting.prototype.setSettingName = function(settingName) {
  this.settingName = settingName;
};

/**
* Get the field value
* @memberof payments.PaymentResponseFraudSetting
* @return {String} 
*/
PaymentResponseFraudSetting.prototype.getSettingName = function() {
  return this.settingName;
};

/**
* Set the field value
* @memberof payments.PaymentResponseFraudSetting
* @param {Number} createdTime must be a long integer
*/
PaymentResponseFraudSetting.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* @memberof payments.PaymentResponseFraudSetting
* @return {Number} must be a long integer
*/
PaymentResponseFraudSetting.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* @memberof payments.PaymentResponseFraudSetting
* @param {Number} modifiedTime must be a long integer
*/
PaymentResponseFraudSetting.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* @memberof payments.PaymentResponseFraudSetting
* @return {Number} must be a long integer
*/
PaymentResponseFraudSetting.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* @memberof payments.PaymentResponseFraudSetting
* @param {Number} deletedTime must be a long integer
*/
PaymentResponseFraudSetting.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* @memberof payments.PaymentResponseFraudSetting
* @return {Number} must be a long integer
*/
PaymentResponseFraudSetting.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* @memberof payments.PaymentResponseFraudSetting
* @private
*/
PaymentResponseFraudSetting.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PaymentResponseFraudSetting.prototype.toString = function() {
  return JSON.stringify(this);
};

PaymentResponseFraudSetting._meta_ =  {fields:  {}};
PaymentResponseFraudSetting._meta_._class_ =  PaymentResponseFraudSetting;
PaymentResponseFraudSetting._meta_.fields["id"] = {};
PaymentResponseFraudSetting._meta_.fields["id"].type = String;
PaymentResponseFraudSetting._meta_.fields["cardType"] = {};
PaymentResponseFraudSetting._meta_.fields["cardType"].type = payments_CardType;
PaymentResponseFraudSetting._meta_.fields["responseCode"] = {};
PaymentResponseFraudSetting._meta_.fields["responseCode"].type = String;
PaymentResponseFraudSetting._meta_.fields["responseCodeType"] = {};
PaymentResponseFraudSetting._meta_.fields["responseCodeType"].type = payments_FraudResponseCodeType;
PaymentResponseFraudSetting._meta_.fields["action"] = {};
PaymentResponseFraudSetting._meta_.fields["action"].type = payments_FraudResponseAction;
PaymentResponseFraudSetting._meta_.fields["settingName"] = {};
PaymentResponseFraudSetting._meta_.fields["settingName"].type = String;
PaymentResponseFraudSetting._meta_.fields["createdTime"] = {};
PaymentResponseFraudSetting._meta_.fields["createdTime"].type = Number;
PaymentResponseFraudSetting._meta_.fields["modifiedTime"] = {};
PaymentResponseFraudSetting._meta_.fields["modifiedTime"].type = Number;
PaymentResponseFraudSetting._meta_.fields["deletedTime"] = {};
PaymentResponseFraudSetting._meta_.fields["deletedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PaymentResponseFraudSetting;
}

