/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_RefundPaymentResponse = require("../remotepay/RefundPaymentResponse");

/**
* @constructor
* @augments remotepay.RefundPaymentResponse
* @memberof remotepay
*/
var VoidPaymentRefundResponse = function() {
  remotepay_RefundPaymentResponse.call(this);
  this._superClass_ = remotepay_RefundPaymentResponse;
  this._class_ = VoidPaymentRefundResponse;
  this.refundId = undefined;
};

VoidPaymentRefundResponse.prototype = Object.create(remotepay_RefundPaymentResponse.prototype);
VoidPaymentRefundResponse.prototype.constructor = VoidPaymentRefundResponse;

/**
* Set the field value
* The id of the refund voided
*
* @memberof remotepay.VoidPaymentRefundResponse
* @param {String} refundId 
*/
VoidPaymentRefundResponse.prototype.setRefundId = function(refundId) {
  this.refundId = refundId;
};

/**
* Get the field value
* The id of the refund voided
* @memberof remotepay.VoidPaymentRefundResponse
* @return {String} 
*/
VoidPaymentRefundResponse.prototype.getRefundId = function() {
  return this.refundId;
};

VoidPaymentRefundResponse._meta_ =  {fields:  {}};
VoidPaymentRefundResponse._meta_._class_ =  VoidPaymentRefundResponse;
VoidPaymentRefundResponse._meta_._superMeta_ = remotepay_RefundPaymentResponse._meta_;
VoidPaymentRefundResponse._meta_.fields["refundId"] = {};
VoidPaymentRefundResponse._meta_.fields["refundId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VoidPaymentRefundResponse;
}

