/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_DataEntryLocation = require("../payments/DataEntryLocation");
var merchant_TipSuggestion = require("../merchant/TipSuggestion");
var remotepay_BaseTransactionRequest = require("../remotepay/BaseTransactionRequest");

/**
* @constructor
* @augments remotepay.BaseTransactionRequest
* @memberof remotepay
*/
var TransactionRequest = function() {
  remotepay_BaseTransactionRequest.call(this);
  this._superClass_ = remotepay_BaseTransactionRequest;
  this._class_ = TransactionRequest;
  this.signatureThreshold = undefined;
  this.signatureEntryLocation = undefined;
  this.autoAcceptSignature = undefined;
  this.allowOfflinePayment = undefined;
  this.forceOfflinePayment = undefined;
  this.disableCashback = undefined;
  this.approveOfflinePaymentWithoutPrompt = undefined;
  this.taxAmount = undefined;
  this.tippableAmount = undefined;
  this.tipSuggestions = undefined;
};

TransactionRequest.prototype = Object.create(remotepay_BaseTransactionRequest.prototype);
TransactionRequest.prototype.constructor = TransactionRequest;

/**
* Set the field value
* If the amount is equal to or greater than this amount, then a signature should be obtained
*
* @memberof remotepay.TransactionRequest
* @param {Number} signatureThreshold must be a long integer
*/
TransactionRequest.prototype.setSignatureThreshold = function(signatureThreshold) {
  this.signatureThreshold = signatureThreshold;
};

/**
* Get the field value
* If the amount is equal to or greater than this amount, then a signature should be obtained
* @memberof remotepay.TransactionRequest
* @return {Number} must be a long integer
*/
TransactionRequest.prototype.getSignatureThreshold = function() {
  return this.signatureThreshold;
};

/**
* Set the field value
* Where the signature should be obtained from (paper, screen...etc)
*
* @memberof remotepay.TransactionRequest
* @param {payments.DataEntryLocation|Null} signatureEntryLocation 
*/
TransactionRequest.prototype.setSignatureEntryLocation = function(signatureEntryLocation) {
  this.signatureEntryLocation = signatureEntryLocation;
};

/**
* Get the field value
* Where the signature should be obtained from (paper, screen...etc)
* @memberof remotepay.TransactionRequest
* @return {payments.DataEntryLocation|Null} 
*/
TransactionRequest.prototype.getSignatureEntryLocation = function() {
  return this.signatureEntryLocation;
};

/**
* Set the field value
* Do not show/send signature verification challenges
*
* @memberof remotepay.TransactionRequest
* @param {Boolean|Null} autoAcceptSignature 
*/
TransactionRequest.prototype.setAutoAcceptSignature = function(autoAcceptSignature) {
  this.autoAcceptSignature = autoAcceptSignature;
};

/**
* Get the field value
* Do not show/send signature verification challenges
* @memberof remotepay.TransactionRequest
* @return {Boolean|Null} 
*/
TransactionRequest.prototype.getAutoAcceptSignature = function() {
  return this.autoAcceptSignature;
};

/**
* Set the field value
* If true then offline payments can be accepted
*
* @memberof remotepay.TransactionRequest
* @param {Boolean|Null} allowOfflinePayment 
*/
TransactionRequest.prototype.setAllowOfflinePayment = function(allowOfflinePayment) {
  this.allowOfflinePayment = allowOfflinePayment;
};

/**
* Get the field value
* If true then offline payments can be accepted
* @memberof remotepay.TransactionRequest
* @return {Boolean|Null} 
*/
TransactionRequest.prototype.getAllowOfflinePayment = function() {
  return this.allowOfflinePayment;
};

/**
* Set the field value
* If true then the payment will be taken offline
*
* @memberof remotepay.TransactionRequest
* @param {Boolean|Null} forceOfflinePayment 
*/
TransactionRequest.prototype.setForceOfflinePayment = function(forceOfflinePayment) {
  this.forceOfflinePayment = forceOfflinePayment;
};

/**
* Get the field value
* If true then the payment will be taken offline
* @memberof remotepay.TransactionRequest
* @return {Boolean|Null} 
*/
TransactionRequest.prototype.getForceOfflinePayment = function() {
  return this.forceOfflinePayment;
};

/**
* Set the field value
* If true, do not allow cash back
*
* @memberof remotepay.TransactionRequest
* @param {Boolean|Null} disableCashback 
*/
TransactionRequest.prototype.setDisableCashback = function(disableCashback) {
  this.disableCashback = disableCashback;
};

/**
* Get the field value
* If true, do not allow cash back
* @memberof remotepay.TransactionRequest
* @return {Boolean|Null} 
*/
TransactionRequest.prototype.getDisableCashback = function() {
  return this.disableCashback;
};

/**
* Set the field value
* If true then offline payments will be approved without a prompt
*
* @memberof remotepay.TransactionRequest
* @param {Boolean|Null} approveOfflinePaymentWithoutPrompt 
*/
TransactionRequest.prototype.setApproveOfflinePaymentWithoutPrompt = function(approveOfflinePaymentWithoutPrompt) {
  this.approveOfflinePaymentWithoutPrompt = approveOfflinePaymentWithoutPrompt;
};

/**
* Get the field value
* If true then offline payments will be approved without a prompt
* @memberof remotepay.TransactionRequest
* @return {Boolean|Null} 
*/
TransactionRequest.prototype.getApproveOfflinePaymentWithoutPrompt = function() {
  return this.approveOfflinePaymentWithoutPrompt;
};

/**
* Set the field value
* Amount paid in taxes
*
* @memberof remotepay.TransactionRequest
* @param {Number} taxAmount must be a long integer
*/
TransactionRequest.prototype.setTaxAmount = function(taxAmount) {
  this.taxAmount = taxAmount;
};

/**
* Get the field value
* Amount paid in taxes
* @memberof remotepay.TransactionRequest
* @return {Number} must be a long integer
*/
TransactionRequest.prototype.getTaxAmount = function() {
  return this.taxAmount;
};

/**
* Set the field value
* The total amount used when calculating tips
*
* @memberof remotepay.TransactionRequest
* @param {Number} tippableAmount must be a long integer
*/
TransactionRequest.prototype.setTippableAmount = function(tippableAmount) {
  this.tippableAmount = tippableAmount;
};

/**
* Get the field value
* The total amount used when calculating tips
* @memberof remotepay.TransactionRequest
* @return {Number} must be a long integer
*/
TransactionRequest.prototype.getTippableAmount = function() {
  return this.tippableAmount;
};

/**
* Set the field value
* Custom Tip Suggestions that can be set per transaction
*
* @memberof remotepay.TransactionRequest
* @param {Array.<merchant.TipSuggestion>} tipSuggestions An array of 
*/
TransactionRequest.prototype.setTipSuggestions = function(tipSuggestions) {
  this.tipSuggestions = tipSuggestions;
};

/**
* Get the field value
* Custom Tip Suggestions that can be set per transaction
* @memberof remotepay.TransactionRequest
* @return {Array.<merchant.TipSuggestion>} An array of 
*/
TransactionRequest.prototype.getTipSuggestions = function() {
  return this.tipSuggestions;
};

TransactionRequest._meta_ =  {fields:  {}};
TransactionRequest._meta_._class_ =  TransactionRequest;
TransactionRequest._meta_._superMeta_ = remotepay_BaseTransactionRequest._meta_;
TransactionRequest._meta_.fields["signatureThreshold"] = {};
TransactionRequest._meta_.fields["signatureThreshold"].type = Number;
TransactionRequest._meta_.fields["signatureEntryLocation"] = {};
TransactionRequest._meta_.fields["signatureEntryLocation"].type = payments_DataEntryLocation;
TransactionRequest._meta_.fields["autoAcceptSignature"] = {};
TransactionRequest._meta_.fields["autoAcceptSignature"].type = Boolean;
TransactionRequest._meta_.fields["allowOfflinePayment"] = {};
TransactionRequest._meta_.fields["allowOfflinePayment"].type = Boolean;
TransactionRequest._meta_.fields["forceOfflinePayment"] = {};
TransactionRequest._meta_.fields["forceOfflinePayment"].type = Boolean;
TransactionRequest._meta_.fields["disableCashback"] = {};
TransactionRequest._meta_.fields["disableCashback"].type = Boolean;
TransactionRequest._meta_.fields["approveOfflinePaymentWithoutPrompt"] = {};
TransactionRequest._meta_.fields["approveOfflinePaymentWithoutPrompt"].type = Boolean;
TransactionRequest._meta_.fields["taxAmount"] = {};
TransactionRequest._meta_.fields["taxAmount"].type = Number;
TransactionRequest._meta_.fields["tippableAmount"] = {};
TransactionRequest._meta_.fields["tippableAmount"].type = Number;
TransactionRequest._meta_.fields["tipSuggestions"] = {};
TransactionRequest._meta_.fields["tipSuggestions"].type = Array;
TransactionRequest._meta_.fields["tipSuggestions"].elementType = merchant_TipSuggestion;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TransactionRequest;
}

