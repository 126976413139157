/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");

/**
* @constructor
* @memberof remotemessage
*/
var Message = function() {
  this._class_ = Message;
  this.method = undefined;
  this.messageId = undefined;
  this.sourceMessageId = undefined;
  this.version = 0;
};


/**
* Set the field value
* The list of message types
*
* @memberof remotemessage.Message
* @param {remotemessage.Method} method 
*/
Message.prototype.setMethod = function(method) {
  this.method = method;
};

/**
* Get the field value
* The list of message types
* @memberof remotemessage.Message
* @return {remotemessage.Method} 
*/
Message.prototype.getMethod = function() {
  return this.method;
};

/**
* Set the field value
* The business message id for this message.  This can be used to set the 'sourceMessageId' for meesages that are 'caused' by this message.  This may be null.
*
* @memberof remotemessage.Message
* @param {String} messageId 
*/
Message.prototype.setMessageId = function(messageId) {
  this.messageId = messageId;
};

/**
* Get the field value
* The business message id for this message.  This can be used to set the 'sourceMessageId' for meesages that are 'caused' by this message.  This may be null.
* @memberof remotemessage.Message
* @return {String} 
*/
Message.prototype.getMessageId = function() {
  return this.messageId;
};

/**
* Set the field value
* The remote message id that 'caused' this message to be sent.  This may not be set.
*
* @memberof remotemessage.Message
* @param {String} sourceMessageId 
*/
Message.prototype.setSourceMessageId = function(sourceMessageId) {
  this.sourceMessageId = sourceMessageId;
};

/**
* Get the field value
* The remote message id that 'caused' this message to be sent.  This may not be set.
* @memberof remotemessage.Message
* @return {String} 
*/
Message.prototype.getSourceMessageId = function() {
  return this.sourceMessageId;
};

/**
* Set the field value
* The version of this message
*
* @memberof remotemessage.Message
* @param {Number|Null} version must be an integer, 
*/
Message.prototype.setVersion = function(version) {
  this.version = version;
};

/**
* Get the field value
* The version of this message
* @memberof remotemessage.Message
* @return {Number|Null} must be an integer, 
*/
Message.prototype.getVersion = function() {
  return this.version;
};

/**
* @memberof remotemessage.Message
* @private
*/
Message.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Message.prototype.toString = function() {
  return JSON.stringify(this);
};

Message._meta_ =  {fields:  {}};
Message._meta_._class_ =  Message;
Message._meta_.fields["method"] = {};
Message._meta_.fields["method"].type = remotemessage_Method;
Message._meta_.fields["messageId"] = {};
Message._meta_.fields["messageId"].type = String;
Message._meta_.fields["sourceMessageId"] = {};
Message._meta_.fields["sourceMessageId"].type = String;
Message._meta_.fields["version"] = {};
Message._meta_.fields["version"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Message;
}

