/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var Currency = {
  USD : "USD",
  GBP : "GBP",
  EUR : "EUR"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Currency;
}

