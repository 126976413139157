/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_TransactionType = require("../remotepay/TransactionType");
var remotepay_BaseTransactionRequest = require("../remotepay/BaseTransactionRequest");

/**
* @constructor
* @augments remotepay.BaseTransactionRequest
* @memberof remotepay
*/
var ManualRefundRequest = function() {
  remotepay_BaseTransactionRequest.call(this);
  this._superClass_ = remotepay_BaseTransactionRequest;
  this._class_ = ManualRefundRequest;
  this.type = remotepay_TransactionType["CREDIT"];
};

ManualRefundRequest.prototype = Object.create(remotepay_BaseTransactionRequest.prototype);
ManualRefundRequest.prototype.constructor = ManualRefundRequest;

ManualRefundRequest._meta_ =  {fields:  {}};
ManualRefundRequest._meta_._class_ =  ManualRefundRequest;
ManualRefundRequest._meta_._superMeta_ = remotepay_BaseTransactionRequest._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ManualRefundRequest;
}

