/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var printer_PrintCategory = require("../printer/PrintCategory");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var GetPrintersRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = GetPrintersRequestMessage;
  this.setMethod(remotemessage_Method["GET_PRINTERS_REQUEST"]);
  this.category = undefined;
};

GetPrintersRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
GetPrintersRequestMessage.prototype.constructor = GetPrintersRequestMessage;

/**
* Set the field value
* Category of printers to retrieve
*
* @memberof remotemessage.GetPrintersRequestMessage
* @param {printer.PrintCategory} category 
*/
GetPrintersRequestMessage.prototype.setCategory = function(category) {
  this.category = category;
};

/**
* Get the field value
* Category of printers to retrieve
* @memberof remotemessage.GetPrintersRequestMessage
* @return {printer.PrintCategory} 
*/
GetPrintersRequestMessage.prototype.getCategory = function() {
  return this.category;
};

GetPrintersRequestMessage._meta_ =  {fields:  {}};
GetPrintersRequestMessage._meta_._class_ =  GetPrintersRequestMessage;
GetPrintersRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
GetPrintersRequestMessage._meta_.fields["category"] = {};
GetPrintersRequestMessage._meta_.fields["category"].type = printer_PrintCategory;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = GetPrintersRequestMessage;
}

