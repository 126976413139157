import React from "react";
import { Dimensions, Text, View, TouchableOpacity } from "react-native";
import CarouselItem from "./CarouselItem";
import ItemCarouselProps from "../types/components/ItemsCarouselProps";
import { styles } from "../styles/components/itemsCarousel";
import { DraggableScrollView } from "./DraggableScrollView";

export default ({
  title,
  items,
  seeMoreText,
  onClickItem,
  isFiltered,
  onAllClick,
  itemsSize,
  textColor,
}: ItemCarouselProps): JSX.Element => {
  const { width } = Dimensions.get("window");

  const renderItem = (item) => {
    if (onClickItem) {
      return (
        <TouchableOpacity
          onPress={() => {
            onClickItem(item.id);
          }}
        >
          <CarouselItem
            id={item.id}
            description={item.description}
            image={item.image}
            textColor={item.textColor}
            isSelected={item.isSelected}
            size={itemsSize}
          />
        </TouchableOpacity>
      );
    } else {
      return (
        <CarouselItem
          id={item.id}
          description={item.description}
          image={item.image}
          textColor={item.textColor}
          isSelected={item.isSelected}
          size={itemsSize}
        />
      );
    }
  };

  return (
    <>
      <View style={styles.header}>
        <Text style={[styles.header__title, textColor && { color: textColor }]}>
          {title}
        </Text>
        {isFiltered && onAllClick && (
          <Text style={styles.header__seeFull} onPress={() => onAllClick()}>
            {seeMoreText}
          </Text>
        )}
      </View>
      <DraggableScrollView horizontal showsHorizontalScrollIndicator={false}>
        {items.map((item, index) => (
          <View
            key={item.id}
            style={[index > 0 && { marginLeft: width * 0.05 }]}
          >
            {renderItem(item)}
          </View>
        ))}
      </DraggableScrollView> 
    </>
  );
};
