/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var LogLevelEnum = {
  VERBOSE : "VERBOSE",
  DEBUG : "DEBUG",
  INFO : "INFO",
  WARN : "WARN",
  ERROR : "ERROR"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = LogLevelEnum;
}

