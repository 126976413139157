import React from "react";
import {
  FlatList,
  ListRenderItemInfo,
  TouchableOpacity,
  Linking,
  ImageBackground,
  View,
  Text,
} from "react-native";
import InstagramFeedProps, {
  InstagramPost,
} from "../types/components/InstagramFeedProps";
import useMediumScreen from "../hooks/useMediumScreen";
import { styles } from "../styles/components/instagramFeed";

export default ({ data, limit }: InstagramFeedProps): JSX.Element => {
  const isMediumScreen = useMediumScreen();

  const filterData = React.useCallback(() => {
    const result = data.filter((post) => post.mediaType !== "CAROUSEL_ALBUM");
    // Slice if limit greater than 0
    if (Number.isInteger(limit) && limit > 0) return result.slice(0, limit);
    return result;
  }, [data, limit]);

  const renderItem = ({
    item,
  }: ListRenderItemInfo<InstagramPost>): JSX.Element => {
    const uri = item.mediaType === "IMAGE" ? item.mediaUrl : item.thumbnailUrl;
    return (
      <TouchableOpacity
        style={
          isMediumScreen ? styles.itemMediumContainer : styles.itemContainer
        }
        onPress={() => {
          Linking.openURL(item.permalink);
        }}
      >
        <ImageBackground source={{ uri }} style={styles.image}>
          <View style={styles.imageContainer}>
            <View style={styles.imageTextContainer}>
              <Text
                numberOfLines={5}
                ellipsizeMode="tail"
                style={styles.imageText}
              >
                {item.caption}
              </Text>
            </View>
          </View>
        </ImageBackground>
      </TouchableOpacity>
    );
  };

  return (
    <FlatList
      data={filterData()}
      renderItem={renderItem}
      keyExtractor={(item) => item.id.toString()}
      numColumns={isMediumScreen ? 4 : 2}
    />
  );
};
