/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var VasResponseFormat = {
  NDEF : "NDEF",
  STRING : "STRING",
  ENCRYPTED : "ENCRYPTED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasResponseFormat;
}

