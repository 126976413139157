/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Payment = require("../payments/Payment");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var PaymentPrintMerchantCopyMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = PaymentPrintMerchantCopyMessage;
  this.setMethod(remotemessage_Method["PRINT_PAYMENT_MERCHANT_COPY"]);
  this.payment = undefined;
};

PaymentPrintMerchantCopyMessage.prototype = Object.create(remotemessage_Message.prototype);
PaymentPrintMerchantCopyMessage.prototype.constructor = PaymentPrintMerchantCopyMessage;

/**
* Set the field value
* @memberof remotemessage.PaymentPrintMerchantCopyMessage
* @param {payments.Payment} payment 
*/
PaymentPrintMerchantCopyMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotemessage.PaymentPrintMerchantCopyMessage
* @return {payments.Payment} 
*/
PaymentPrintMerchantCopyMessage.prototype.getPayment = function() {
  return this.payment;
};

PaymentPrintMerchantCopyMessage._meta_ =  {fields:  {}};
PaymentPrintMerchantCopyMessage._meta_._class_ =  PaymentPrintMerchantCopyMessage;
PaymentPrintMerchantCopyMessage._meta_._superMeta_ = remotemessage_Message._meta_;
PaymentPrintMerchantCopyMessage._meta_.fields["payment"] = {};
PaymentPrintMerchantCopyMessage._meta_.fields["payment"].type = payments_Payment;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PaymentPrintMerchantCopyMessage;
}

