/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof inventory
*/
var MenuModifierGroup = function() {
  this._class_ = MenuModifierGroup;
  this.id = undefined;
  this.modifierGroup = undefined;
  this.merchantRef = undefined;
  this.name = undefined;
  this.description = undefined;
  this.minRequired = undefined;
  this.maxAllowed = undefined;
  this.imageFilename = undefined;
  this.enabled = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
  this.deletedTime = undefined;
};


/**
* Set the field value
* Modifier group id
*
* @memberof inventory.MenuModifierGroup
* @param {String} id 
*/
MenuModifierGroup.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Modifier group id
* @memberof inventory.MenuModifierGroup
* @return {String} 
*/
MenuModifierGroup.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Reference to the modifier group
*
* @memberof inventory.MenuModifierGroup
* @param {base.Reference} modifierGroup 
*/
MenuModifierGroup.prototype.setModifierGroup = function(modifierGroup) {
  this.modifierGroup = modifierGroup;
};

/**
* Get the field value
* Reference to the modifier group
* @memberof inventory.MenuModifierGroup
* @return {base.Reference} 
*/
MenuModifierGroup.prototype.getModifierGroup = function() {
  return this.modifierGroup;
};

/**
* Set the field value
* Reference to the merchant that created the item
*
* @memberof inventory.MenuModifierGroup
* @param {base.Reference} merchantRef 
*/
MenuModifierGroup.prototype.setMerchantRef = function(merchantRef) {
  this.merchantRef = merchantRef;
};

/**
* Get the field value
* Reference to the merchant that created the item
* @memberof inventory.MenuModifierGroup
* @return {base.Reference} 
*/
MenuModifierGroup.prototype.getMerchantRef = function() {
  return this.merchantRef;
};

/**
* Set the field value
* Customer facing modifier group name
*
* @memberof inventory.MenuModifierGroup
* @param {String} name 
*/
MenuModifierGroup.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Customer facing modifier group name
* @memberof inventory.MenuModifierGroup
* @return {String} 
*/
MenuModifierGroup.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* Modifier group description
*
* @memberof inventory.MenuModifierGroup
* @param {String} description 
*/
MenuModifierGroup.prototype.setDescription = function(description) {
  this.description = description;
};

/**
* Get the field value
* Modifier group description
* @memberof inventory.MenuModifierGroup
* @return {String} 
*/
MenuModifierGroup.prototype.getDescription = function() {
  return this.description;
};

/**
* Set the field value
* The minimum amount of modifiers from this group that must be applied to associated lineItems
*
* @memberof inventory.MenuModifierGroup
* @param {Null|Number} minRequired must be an integer
*/
MenuModifierGroup.prototype.setMinRequired = function(minRequired) {
  this.minRequired = minRequired;
};

/**
* Get the field value
* The minimum amount of modifiers from this group that must be applied to associated lineItems
* @memberof inventory.MenuModifierGroup
* @return {Null|Number} must be an integer
*/
MenuModifierGroup.prototype.getMinRequired = function() {
  return this.minRequired;
};

/**
* Set the field value
* The maximum amount of modifiers from this group that can be applied to associated lineItems
*
* @memberof inventory.MenuModifierGroup
* @param {Null|Number} maxAllowed must be an integer
*/
MenuModifierGroup.prototype.setMaxAllowed = function(maxAllowed) {
  this.maxAllowed = maxAllowed;
};

/**
* Get the field value
* The maximum amount of modifiers from this group that can be applied to associated lineItems
* @memberof inventory.MenuModifierGroup
* @return {Null|Number} must be an integer
*/
MenuModifierGroup.prototype.getMaxAllowed = function() {
  return this.maxAllowed;
};

/**
* Set the field value
* Modifier group image file path
*
* @memberof inventory.MenuModifierGroup
* @param {String} imageFilename 
*/
MenuModifierGroup.prototype.setImageFilename = function(imageFilename) {
  this.imageFilename = imageFilename;
};

/**
* Get the field value
* Modifier group image file path
* @memberof inventory.MenuModifierGroup
* @return {String} 
*/
MenuModifierGroup.prototype.getImageFilename = function() {
  return this.imageFilename;
};

/**
* Set the field value
* Modifier group enabled for menu
*
* @memberof inventory.MenuModifierGroup
* @param {Boolean} enabled 
*/
MenuModifierGroup.prototype.setEnabled = function(enabled) {
  this.enabled = enabled;
};

/**
* Get the field value
* Modifier group enabled for menu
* @memberof inventory.MenuModifierGroup
* @return {Boolean} 
*/
MenuModifierGroup.prototype.getEnabled = function() {
  return this.enabled;
};

/**
* Set the field value
* Timestamp when the menu modifier group was created
*
* @memberof inventory.MenuModifierGroup
* @param {Number} createdTime must be a long integer
*/
MenuModifierGroup.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Timestamp when the menu modifier group was created
* @memberof inventory.MenuModifierGroup
* @return {Number} must be a long integer
*/
MenuModifierGroup.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* Timestamp when the menu modifier group was last modified
*
* @memberof inventory.MenuModifierGroup
* @param {Number} modifiedTime must be a long integer
*/
MenuModifierGroup.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* Timestamp when the menu modifier group was last modified
* @memberof inventory.MenuModifierGroup
* @return {Number} must be a long integer
*/
MenuModifierGroup.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* Timestamp when menu modifier group was last deleted
*
* @memberof inventory.MenuModifierGroup
* @param {Number} deletedTime must be a long integer
*/
MenuModifierGroup.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* Timestamp when menu modifier group was last deleted
* @memberof inventory.MenuModifierGroup
* @return {Number} must be a long integer
*/
MenuModifierGroup.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* @memberof inventory.MenuModifierGroup
* @private
*/
MenuModifierGroup.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

MenuModifierGroup.prototype.toString = function() {
  return JSON.stringify(this);
};

MenuModifierGroup._meta_ =  {fields:  {}};
MenuModifierGroup._meta_._class_ =  MenuModifierGroup;
MenuModifierGroup._meta_.fields["id"] = {};
MenuModifierGroup._meta_.fields["id"].type = String;
MenuModifierGroup._meta_.fields["modifierGroup"] = {};
MenuModifierGroup._meta_.fields["modifierGroup"].type = base_Reference;
MenuModifierGroup._meta_.fields["merchantRef"] = {};
MenuModifierGroup._meta_.fields["merchantRef"].type = base_Reference;
MenuModifierGroup._meta_.fields["name"] = {};
MenuModifierGroup._meta_.fields["name"].type = String;
MenuModifierGroup._meta_.fields["description"] = {};
MenuModifierGroup._meta_.fields["description"].type = String;
MenuModifierGroup._meta_.fields["minRequired"] = {};
MenuModifierGroup._meta_.fields["minRequired"].type = Number;
MenuModifierGroup._meta_.fields["maxAllowed"] = {};
MenuModifierGroup._meta_.fields["maxAllowed"].type = Number;
MenuModifierGroup._meta_.fields["imageFilename"] = {};
MenuModifierGroup._meta_.fields["imageFilename"].type = String;
MenuModifierGroup._meta_.fields["enabled"] = {};
MenuModifierGroup._meta_.fields["enabled"].type = Boolean;
MenuModifierGroup._meta_.fields["createdTime"] = {};
MenuModifierGroup._meta_.fields["createdTime"].type = Number;
MenuModifierGroup._meta_.fields["modifiedTime"] = {};
MenuModifierGroup._meta_.fields["modifiedTime"].type = Number;
MenuModifierGroup._meta_.fields["deletedTime"] = {};
MenuModifierGroup._meta_.fields["deletedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = MenuModifierGroup;
}

