/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/** Groups together a set of line items to display under a heading on receipts. */
/**
* @constructor
* @memberof order
*/
var PrintGroup = function() {
  this._class_ = PrintGroup;
  this.id = undefined;
  this.name = undefined;
  this.sortOrder = undefined;
  this.fired = false;
};


/**
* Set the field value
* Unique identifier.
*
* @memberof order.PrintGroup
* @param {String} id 
*/
PrintGroup.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier.
* @memberof order.PrintGroup
* @return {String} 
*/
PrintGroup.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* The print group heading that will be displayed on receipts.
*
* @memberof order.PrintGroup
* @param {String} name 
*/
PrintGroup.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* The print group heading that will be displayed on receipts.
* @memberof order.PrintGroup
* @return {String} 
*/
PrintGroup.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* The order in which this print group is displayed relative to other print groups on the same receipt. Print groups with identical sort orders will be ordered by name.
*
* @memberof order.PrintGroup
* @param {Null|Number} sortOrder must be an integer
*/
PrintGroup.prototype.setSortOrder = function(sortOrder) {
  this.sortOrder = sortOrder;
};

/**
* Get the field value
* The order in which this print group is displayed relative to other print groups on the same receipt. Print groups with identical sort orders will be ordered by name.
* @memberof order.PrintGroup
* @return {Null|Number} must be an integer
*/
PrintGroup.prototype.getSortOrder = function() {
  return this.sortOrder;
};

/**
* Set the field value
* True if this print group has been sent to an order printer.
*
* @memberof order.PrintGroup
* @param {Boolean} fired 
*/
PrintGroup.prototype.setFired = function(fired) {
  this.fired = fired;
};

/**
* Get the field value
* True if this print group has been sent to an order printer.
* @memberof order.PrintGroup
* @return {Boolean} 
*/
PrintGroup.prototype.getFired = function() {
  return this.fired;
};

/**
* @memberof order.PrintGroup
* @private
*/
PrintGroup.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PrintGroup.prototype.toString = function() {
  return JSON.stringify(this);
};

PrintGroup._meta_ =  {fields:  {}};
PrintGroup._meta_._class_ =  PrintGroup;
PrintGroup._meta_.fields["id"] = {};
PrintGroup._meta_.fields["id"].type = String;
PrintGroup._meta_.fields["name"] = {};
PrintGroup._meta_.fields["name"].type = String;
PrintGroup._meta_.fields["sortOrder"] = {};
PrintGroup._meta_.fields["sortOrder"].type = Number;
PrintGroup._meta_.fields["fired"] = {};
PrintGroup._meta_.fields["fired"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintGroup;
}

