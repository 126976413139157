/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_VasState = require("../remotemessage/VasState");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var VasStateMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = VasStateMessage;
  this.setMethod(remotemessage_Method["VAS_STATE"]);
  this.oldState = undefined;
  this.newState = undefined;
};

VasStateMessage.prototype = Object.create(remotemessage_Message.prototype);
VasStateMessage.prototype.constructor = VasStateMessage;

/**
* Set the field value
* The previous state of the vas system
*
* @memberof remotemessage.VasStateMessage
* @param {remotemessage.VasState} oldState 
*/
VasStateMessage.prototype.setOldState = function(oldState) {
  this.oldState = oldState;
};

/**
* Get the field value
* The previous state of the vas system
* @memberof remotemessage.VasStateMessage
* @return {remotemessage.VasState} 
*/
VasStateMessage.prototype.getOldState = function() {
  return this.oldState;
};

/**
* Set the field value
* The current state of the vas system
*
* @memberof remotemessage.VasStateMessage
* @param {remotemessage.VasState} newState 
*/
VasStateMessage.prototype.setNewState = function(newState) {
  this.newState = newState;
};

/**
* Get the field value
* The current state of the vas system
* @memberof remotemessage.VasStateMessage
* @return {remotemessage.VasState} 
*/
VasStateMessage.prototype.getNewState = function() {
  return this.newState;
};

VasStateMessage._meta_ =  {fields:  {}};
VasStateMessage._meta_._class_ =  VasStateMessage;
VasStateMessage._meta_._superMeta_ = remotemessage_Message._meta_;
VasStateMessage._meta_.fields["oldState"] = {};
VasStateMessage._meta_.fields["oldState"].type = remotemessage_VasState;
VasStateMessage._meta_.fields["newState"] = {};
VasStateMessage._meta_.fields["newState"].type = remotemessage_VasState;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasStateMessage;
}

