/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var order_LineItem = require("../order/LineItem");

/**
* @constructor
* @memberof order
*/
var CreateLineItemsRequest = function() {
  this._class_ = CreateLineItemsRequest;
  this.items = undefined;
};


/**
* Set the field value
* List of LineItem objects to create
*
* @memberof order.CreateLineItemsRequest
* @param {Array.<order.LineItem>} items An array of 
*/
CreateLineItemsRequest.prototype.setItems = function(items) {
  this.items = items;
};

/**
* Get the field value
* List of LineItem objects to create
* @memberof order.CreateLineItemsRequest
* @return {Array.<order.LineItem>} An array of 
*/
CreateLineItemsRequest.prototype.getItems = function() {
  return this.items;
};

/**
* @memberof order.CreateLineItemsRequest
* @private
*/
CreateLineItemsRequest.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

CreateLineItemsRequest.prototype.toString = function() {
  return JSON.stringify(this);
};

CreateLineItemsRequest._meta_ =  {fields:  {}};
CreateLineItemsRequest._meta_._class_ =  CreateLineItemsRequest;
CreateLineItemsRequest._meta_.fields["items"] = {};
CreateLineItemsRequest._meta_.fields["items"].type = Array;
CreateLineItemsRequest._meta_.fields["items"].elementType = order_LineItem;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CreateLineItemsRequest;
}

