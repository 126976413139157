/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Payment = require("../payments/Payment");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var DeclinePaymentPrintMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = DeclinePaymentPrintMessage;
  this.setMethod(remotemessage_Method["PRINT_PAYMENT_DECLINE"]);
  this.payment = undefined;
  this.reason = undefined;
};

DeclinePaymentPrintMessage.prototype = Object.create(remotemessage_Message.prototype);
DeclinePaymentPrintMessage.prototype.constructor = DeclinePaymentPrintMessage;

/**
* Set the field value
* @memberof remotemessage.DeclinePaymentPrintMessage
* @param {payments.Payment} payment 
*/
DeclinePaymentPrintMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotemessage.DeclinePaymentPrintMessage
* @return {payments.Payment} 
*/
DeclinePaymentPrintMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotemessage.DeclinePaymentPrintMessage
* @param {String} reason 
*/
DeclinePaymentPrintMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotemessage.DeclinePaymentPrintMessage
* @return {String} 
*/
DeclinePaymentPrintMessage.prototype.getReason = function() {
  return this.reason;
};

DeclinePaymentPrintMessage._meta_ =  {fields:  {}};
DeclinePaymentPrintMessage._meta_._class_ =  DeclinePaymentPrintMessage;
DeclinePaymentPrintMessage._meta_._superMeta_ = remotemessage_Message._meta_;
DeclinePaymentPrintMessage._meta_.fields["payment"] = {};
DeclinePaymentPrintMessage._meta_.fields["payment"].type = payments_Payment;
DeclinePaymentPrintMessage._meta_.fields["reason"] = {};
DeclinePaymentPrintMessage._meta_.fields["reason"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeclinePaymentPrintMessage;
}

