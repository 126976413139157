import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Card } from "@rneui/themed";
import CardWithButtonsProps from "../types/components/CardWithButtonsProps";
import { styles } from "../styles/components/cardWithButtons";

export default ({ title, children, operations}: CardWithButtonsProps):JSX.Element => {
    return (
        <Card containerStyle={styles.container}>
                <View style={styles.cardUpSide}>
                    <Text style={styles.title}>{title}</Text>
                    <View>
                        {children}
                    </View>
                </View>
                <View style={operations.length > 1 ? 
                    styles.cardDownSide :
                    styles.cardDownSideOneOperation}>
                    {operations.map((operation, index) => (
                        <TouchableOpacity 
                        key={index} 
                        style={[styles.operationButton, {backgroundColor: operation.color}]}
                        onPress={() => operation.onClick()} 
                        >
                            <Text style={styles.operationButtonText}>
                                {operation.description}
                            </Text>
                        </TouchableOpacity>
                    ))}
                </View>
        </Card>
    )
}