/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof remotepay
*/
var DisplayReceiptOptionsRequest = function() {
  this._class_ = DisplayReceiptOptionsRequest;
  this.orderId = undefined;
  this.paymentId = undefined;
  this.refundId = undefined;
  this.creditId = undefined;
  this.disablePrinting = false;
};


/**
* Set the field value
* Unique identifier of a Clover order
*
* @memberof remotepay.DisplayReceiptOptionsRequest
* @param {String} orderId 
*/
DisplayReceiptOptionsRequest.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier of a Clover order
* @memberof remotepay.DisplayReceiptOptionsRequest
* @return {String} 
*/
DisplayReceiptOptionsRequest.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* Unique identifier of the payment associated with an order
*
* @memberof remotepay.DisplayReceiptOptionsRequest
* @param {String} paymentId 
*/
DisplayReceiptOptionsRequest.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier of the payment associated with an order
* @memberof remotepay.DisplayReceiptOptionsRequest
* @return {String} 
*/
DisplayReceiptOptionsRequest.prototype.getPaymentId = function() {
  return this.paymentId;
};

/**
* Set the field value
* Unique identifier of the refund associated with an order
*
* @memberof remotepay.DisplayReceiptOptionsRequest
* @param {String} refundId 
*/
DisplayReceiptOptionsRequest.prototype.setRefundId = function(refundId) {
  this.refundId = refundId;
};

/**
* Get the field value
* Unique identifier of the refund associated with an order
* @memberof remotepay.DisplayReceiptOptionsRequest
* @return {String} 
*/
DisplayReceiptOptionsRequest.prototype.getRefundId = function() {
  return this.refundId;
};

/**
* Set the field value
* Unique identifier of a credit (manual refund) given to a customer
*
* @memberof remotepay.DisplayReceiptOptionsRequest
* @param {String} creditId 
*/
DisplayReceiptOptionsRequest.prototype.setCreditId = function(creditId) {
  this.creditId = creditId;
};

/**
* Get the field value
* Unique identifier of a credit (manual refund) given to a customer
* @memberof remotepay.DisplayReceiptOptionsRequest
* @return {String} 
*/
DisplayReceiptOptionsRequest.prototype.getCreditId = function() {
  return this.creditId;
};

/**
* Set the field value
* @memberof remotepay.DisplayReceiptOptionsRequest
* @param {Boolean} disablePrinting 
*/
DisplayReceiptOptionsRequest.prototype.setDisablePrinting = function(disablePrinting) {
  this.disablePrinting = disablePrinting;
};

/**
* Get the field value
* @memberof remotepay.DisplayReceiptOptionsRequest
* @return {Boolean} 
*/
DisplayReceiptOptionsRequest.prototype.getDisablePrinting = function() {
  return this.disablePrinting;
};

/**
* @memberof remotepay.DisplayReceiptOptionsRequest
* @private
*/
DisplayReceiptOptionsRequest.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DisplayReceiptOptionsRequest.prototype.toString = function() {
  return JSON.stringify(this);
};

DisplayReceiptOptionsRequest._meta_ =  {fields:  {}};
DisplayReceiptOptionsRequest._meta_._class_ =  DisplayReceiptOptionsRequest;
DisplayReceiptOptionsRequest._meta_.fields["orderId"] = {};
DisplayReceiptOptionsRequest._meta_.fields["orderId"].type = String;
DisplayReceiptOptionsRequest._meta_.fields["paymentId"] = {};
DisplayReceiptOptionsRequest._meta_.fields["paymentId"].type = String;
DisplayReceiptOptionsRequest._meta_.fields["refundId"] = {};
DisplayReceiptOptionsRequest._meta_.fields["refundId"].type = String;
DisplayReceiptOptionsRequest._meta_.fields["creditId"] = {};
DisplayReceiptOptionsRequest._meta_.fields["creditId"].type = String;
DisplayReceiptOptionsRequest._meta_.fields["disablePrinting"] = {};
DisplayReceiptOptionsRequest._meta_.fields["disablePrinting"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DisplayReceiptOptionsRequest;
}

