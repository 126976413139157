/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var VasState = {
  START_REQUESTED : "START_REQUESTED",
  STARTED : "STARTED",
  TIMED_OUT : "TIMED_OUT",
  STOP_REQUESTED : "STOP_REQUESTED",
  STOPPED : "STOPPED",
  FAILED : "FAILED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasState;
}

