/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var inventory_Item = require("../inventory/Item");
var inventory_Tag = require("../inventory/Tag");

/**
* @constructor
* @memberof inventory
*/
var TagItem = function() {
  this._class_ = TagItem;
  this.tag = undefined;
  this.item = undefined;
};


/**
* Set the field value
* @memberof inventory.TagItem
* @param {inventory.Tag} tag 
*/
TagItem.prototype.setTag = function(tag) {
  this.tag = tag;
};

/**
* Get the field value
* @memberof inventory.TagItem
* @return {inventory.Tag} 
*/
TagItem.prototype.getTag = function() {
  return this.tag;
};

/**
* Set the field value
* @memberof inventory.TagItem
* @param {inventory.Item} item 
*/
TagItem.prototype.setItem = function(item) {
  this.item = item;
};

/**
* Get the field value
* @memberof inventory.TagItem
* @return {inventory.Item} 
*/
TagItem.prototype.getItem = function() {
  return this.item;
};

/**
* @memberof inventory.TagItem
* @private
*/
TagItem.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

TagItem.prototype.toString = function() {
  return JSON.stringify(this);
};

TagItem._meta_ =  {fields:  {}};
TagItem._meta_._class_ =  TagItem;
TagItem._meta_.fields["tag"] = {};
TagItem._meta_.fields["tag"].type = inventory_Tag;
TagItem._meta_.fields["item"] = {};
TagItem._meta_.fields["item"].type = inventory_Item;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TagItem;
}

