/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var PairingRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = PairingRequestMessage;
  this.setMethod(remotemessage_Method["PAIRING_REQUEST"]);
  this.name = undefined;
  this.serialNumber = undefined;
  this.applicationName = undefined;
  this.authenticationToken = undefined;
};

PairingRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
PairingRequestMessage.prototype.constructor = PairingRequestMessage;

/**
* Set the field value
* The peripheral sends a pairing request containing the peripheral name
*
* @memberof remotemessage.PairingRequestMessage
* @param {String} name 
*/
PairingRequestMessage.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* The peripheral sends a pairing request containing the peripheral name
* @memberof remotemessage.PairingRequestMessage
* @return {String} 
*/
PairingRequestMessage.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* The peripheral serial number
*
* @memberof remotemessage.PairingRequestMessage
* @param {String} serialNumber 
*/
PairingRequestMessage.prototype.setSerialNumber = function(serialNumber) {
  this.serialNumber = serialNumber;
};

/**
* Get the field value
* The peripheral serial number
* @memberof remotemessage.PairingRequestMessage
* @return {String} 
*/
PairingRequestMessage.prototype.getSerialNumber = function() {
  return this.serialNumber;
};

/**
* Set the field value
* The name of the application the peripheral is associated with. This is the "resourceDescriptor" in the handshake
*
* @memberof remotemessage.PairingRequestMessage
* @param {String} applicationName 
*/
PairingRequestMessage.prototype.setApplicationName = function(applicationName) {
  this.applicationName = applicationName;
};

/**
* Get the field value
* The name of the application the peripheral is associated with. This is the "resourceDescriptor" in the handshake
* @memberof remotemessage.PairingRequestMessage
* @return {String} 
*/
PairingRequestMessage.prototype.getApplicationName = function() {
  return this.applicationName;
};

/**
* Set the field value
* The token, recognized by the specific device.
*
* @memberof remotemessage.PairingRequestMessage
* @param {String|Null} authenticationToken 
*/
PairingRequestMessage.prototype.setAuthenticationToken = function(authenticationToken) {
  this.authenticationToken = authenticationToken;
};

/**
* Get the field value
* The token, recognized by the specific device.
* @memberof remotemessage.PairingRequestMessage
* @return {String|Null} 
*/
PairingRequestMessage.prototype.getAuthenticationToken = function() {
  return this.authenticationToken;
};

PairingRequestMessage._meta_ =  {fields:  {}};
PairingRequestMessage._meta_._class_ =  PairingRequestMessage;
PairingRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
PairingRequestMessage._meta_.fields["name"] = {};
PairingRequestMessage._meta_.fields["name"].type = String;
PairingRequestMessage._meta_.fields["serialNumber"] = {};
PairingRequestMessage._meta_.fields["serialNumber"].type = String;
PairingRequestMessage._meta_.fields["applicationName"] = {};
PairingRequestMessage._meta_.fields["applicationName"].type = String;
PairingRequestMessage._meta_.fields["authenticationToken"] = {};
PairingRequestMessage._meta_.fields["authenticationToken"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PairingRequestMessage;
}

