/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var inventory_ModifierGroup = require("../inventory/ModifierGroup");
var inventory_Item = require("../inventory/Item");

/**
* @constructor
* @memberof inventory
*/
var ItemModifierGroup = function() {
  this._class_ = ItemModifierGroup;
  this.item = undefined;
  this.modifierGroup = undefined;
};


/**
* Set the field value
* @memberof inventory.ItemModifierGroup
* @param {inventory.Item} item 
*/
ItemModifierGroup.prototype.setItem = function(item) {
  this.item = item;
};

/**
* Get the field value
* @memberof inventory.ItemModifierGroup
* @return {inventory.Item} 
*/
ItemModifierGroup.prototype.getItem = function() {
  return this.item;
};

/**
* Set the field value
* @memberof inventory.ItemModifierGroup
* @param {inventory.ModifierGroup} modifierGroup 
*/
ItemModifierGroup.prototype.setModifierGroup = function(modifierGroup) {
  this.modifierGroup = modifierGroup;
};

/**
* Get the field value
* @memberof inventory.ItemModifierGroup
* @return {inventory.ModifierGroup} 
*/
ItemModifierGroup.prototype.getModifierGroup = function() {
  return this.modifierGroup;
};

/**
* @memberof inventory.ItemModifierGroup
* @private
*/
ItemModifierGroup.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

ItemModifierGroup.prototype.toString = function() {
  return JSON.stringify(this);
};

ItemModifierGroup._meta_ =  {fields:  {}};
ItemModifierGroup._meta_._class_ =  ItemModifierGroup;
ItemModifierGroup._meta_.fields["item"] = {};
ItemModifierGroup._meta_.fields["item"].type = inventory_Item;
ItemModifierGroup._meta_.fields["modifierGroup"] = {};
ItemModifierGroup._meta_.fields["modifierGroup"].type = inventory_ModifierGroup;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ItemModifierGroup;
}

