/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var order_DisplayLineItem = require("../order/DisplayLineItem");

/**
* @constructor
* @memberof remotemessage
*/
var AddLineItemAction = function() {
  this._class_ = AddLineItemAction;
  this.lineItem = undefined;
  this.taxable = undefined;
};


/**
* Set the field value
* @memberof remotemessage.AddLineItemAction
* @param {order.DisplayLineItem} lineItem 
*/
AddLineItemAction.prototype.setLineItem = function(lineItem) {
  this.lineItem = lineItem;
};

/**
* Get the field value
* @memberof remotemessage.AddLineItemAction
* @return {order.DisplayLineItem} 
*/
AddLineItemAction.prototype.getLineItem = function() {
  return this.lineItem;
};

/**
* Set the field value
* @memberof remotemessage.AddLineItemAction
* @param {Boolean} taxable 
*/
AddLineItemAction.prototype.setTaxable = function(taxable) {
  this.taxable = taxable;
};

/**
* Get the field value
* @memberof remotemessage.AddLineItemAction
* @return {Boolean} 
*/
AddLineItemAction.prototype.getTaxable = function() {
  return this.taxable;
};

/**
* @memberof remotemessage.AddLineItemAction
* @private
*/
AddLineItemAction.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

AddLineItemAction.prototype.toString = function() {
  return JSON.stringify(this);
};

AddLineItemAction._meta_ =  {fields:  {}};
AddLineItemAction._meta_._class_ =  AddLineItemAction;
AddLineItemAction._meta_.fields["lineItem"] = {};
AddLineItemAction._meta_.fields["lineItem"].type = order_DisplayLineItem;
AddLineItemAction._meta_.fields["taxable"] = {};
AddLineItemAction._meta_.fields["taxable"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AddLineItemAction;
}

