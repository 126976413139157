/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof inventory
* @enum {string}
*/
var PriceType = {
  FIXED : "FIXED",
  VARIABLE : "VARIABLE",
  PER_UNIT : "PER_UNIT"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PriceType;
}

