import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    width: 100,
    marginBottom: 20,
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 14,
    margin: "auto",
  },
  itemNoSelected: {
    opacity: 0.5,
  },
  description: {
    textAlign: "center",
    marginTop: 5,
    fontSize: 12,
    letterSpacing: 1.25,
    color: "#FFF",
  },
});
