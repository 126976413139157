/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Credit = require("../payments/Credit");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var CreditPrintMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = CreditPrintMessage;
  this.setMethod(remotemessage_Method["PRINT_CREDIT"]);
  this.credit = undefined;
};

CreditPrintMessage.prototype = Object.create(remotemessage_Message.prototype);
CreditPrintMessage.prototype.constructor = CreditPrintMessage;

/**
* Set the field value
* @memberof remotemessage.CreditPrintMessage
* @param {payments.Credit} credit 
*/
CreditPrintMessage.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* @memberof remotemessage.CreditPrintMessage
* @return {payments.Credit} 
*/
CreditPrintMessage.prototype.getCredit = function() {
  return this.credit;
};

CreditPrintMessage._meta_ =  {fields:  {}};
CreditPrintMessage._meta_._class_ =  CreditPrintMessage;
CreditPrintMessage._meta_._superMeta_ = remotemessage_Message._meta_;
CreditPrintMessage._meta_.fields["credit"] = {};
CreditPrintMessage._meta_.fields["credit"].type = payments_Credit;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CreditPrintMessage;
}

