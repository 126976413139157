/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Payment = require("../payments/Payment");
var order_Order = require("../order/Order");
var payments_Refund = require("../payments/Refund");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var RefundPaymentPrintMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = RefundPaymentPrintMessage;
  this.setMethod(remotemessage_Method["REFUND_PRINT_PAYMENT"]);
  this.payment = undefined;
  this.order = undefined;
  this.refund = undefined;
};

RefundPaymentPrintMessage.prototype = Object.create(remotemessage_Message.prototype);
RefundPaymentPrintMessage.prototype.constructor = RefundPaymentPrintMessage;

/**
* Set the field value
* @memberof remotemessage.RefundPaymentPrintMessage
* @param {payments.Payment} payment 
*/
RefundPaymentPrintMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotemessage.RefundPaymentPrintMessage
* @return {payments.Payment} 
*/
RefundPaymentPrintMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotemessage.RefundPaymentPrintMessage
* @param {order.Order} order 
*/
RefundPaymentPrintMessage.prototype.setOrder = function(order) {
  this.order = order;
};

/**
* Get the field value
* @memberof remotemessage.RefundPaymentPrintMessage
* @return {order.Order} 
*/
RefundPaymentPrintMessage.prototype.getOrder = function() {
  return this.order;
};

/**
* Set the field value
* @memberof remotemessage.RefundPaymentPrintMessage
* @param {payments.Refund} refund 
*/
RefundPaymentPrintMessage.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* @memberof remotemessage.RefundPaymentPrintMessage
* @return {payments.Refund} 
*/
RefundPaymentPrintMessage.prototype.getRefund = function() {
  return this.refund;
};

RefundPaymentPrintMessage._meta_ =  {fields:  {}};
RefundPaymentPrintMessage._meta_._class_ =  RefundPaymentPrintMessage;
RefundPaymentPrintMessage._meta_._superMeta_ = remotemessage_Message._meta_;
RefundPaymentPrintMessage._meta_.fields["payment"] = {};
RefundPaymentPrintMessage._meta_.fields["payment"].type = payments_Payment;
RefundPaymentPrintMessage._meta_.fields["order"] = {};
RefundPaymentPrintMessage._meta_.fields["order"].type = order_Order;
RefundPaymentPrintMessage._meta_.fields["refund"] = {};
RefundPaymentPrintMessage._meta_.fields["refund"].type = payments_Refund;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RefundPaymentPrintMessage;
}

