/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof printer
* @enum {string}
*/
var PrintJobStatus = {
  IN_QUEUE : "IN_QUEUE",
  PRINTING : "PRINTING",
  DONE : "DONE",
  ERROR : "ERROR",
  UNKNOWN : "UNKNOWN",
  NOT_FOUND : "NOT_FOUND"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintJobStatus;
}

