/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseResponse = require("../remotepay/BaseResponse");
var payments_Credit = require("../payments/Credit");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var ManualRefundResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = ManualRefundResponse;
  this.credit = undefined;
};

ManualRefundResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
ManualRefundResponse.prototype.constructor = ManualRefundResponse;

/**
* Set the field value
* The credit that resulted from the request
*
* @memberof remotepay.ManualRefundResponse
* @param {payments.Credit} credit 
*/
ManualRefundResponse.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* The credit that resulted from the request
* @memberof remotepay.ManualRefundResponse
* @return {payments.Credit} 
*/
ManualRefundResponse.prototype.getCredit = function() {
  return this.credit;
};

ManualRefundResponse._meta_ =  {fields:  {}};
ManualRefundResponse._meta_._class_ =  ManualRefundResponse;
ManualRefundResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
ManualRefundResponse._meta_.fields["credit"] = {};
ManualRefundResponse._meta_.fields["credit"].type = payments_Credit;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ManualRefundResponse;
}

