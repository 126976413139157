/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var printer_PrintJobStatus = require("../printer/PrintJobStatus");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/** contains the status of the requested print job */
/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var PrintJobStatusResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = PrintJobStatusResponse;
  this.status = undefined;
  this.printRequestId = undefined;
};

PrintJobStatusResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
PrintJobStatusResponse.prototype.constructor = PrintJobStatusResponse;

/**
* Set the field value
* The current status of the requested print job
*
* @memberof remotepay.PrintJobStatusResponse
* @param {printer.PrintJobStatus} status 
*/
PrintJobStatusResponse.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* The current status of the requested print job
* @memberof remotepay.PrintJobStatusResponse
* @return {printer.PrintJobStatus} 
*/
PrintJobStatusResponse.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* The ID of the print job
*
* @memberof remotepay.PrintJobStatusResponse
* @param {String} printRequestId 
*/
PrintJobStatusResponse.prototype.setPrintRequestId = function(printRequestId) {
  this.printRequestId = printRequestId;
};

/**
* Get the field value
* The ID of the print job
* @memberof remotepay.PrintJobStatusResponse
* @return {String} 
*/
PrintJobStatusResponse.prototype.getPrintRequestId = function() {
  return this.printRequestId;
};

PrintJobStatusResponse._meta_ =  {fields:  {}};
PrintJobStatusResponse._meta_._class_ =  PrintJobStatusResponse;
PrintJobStatusResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
PrintJobStatusResponse._meta_.fields["status"] = {};
PrintJobStatusResponse._meta_.fields["status"].type = printer_PrintJobStatus;
PrintJobStatusResponse._meta_.fields["printRequestId"] = {};
PrintJobStatusResponse._meta_.fields["printRequestId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintJobStatusResponse;
}

