/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Point = require("../base/Point");

/**
* @constructor
* @memberof base
*/
var Points = function() {
  this._class_ = Points;
  this.points = undefined;
};


/**
* Set the field value
* @memberof base.Points
* @param {Array.<base.Point>} points An array of 
*/
Points.prototype.setPoints = function(points) {
  this.points = points;
};

/**
* Get the field value
* @memberof base.Points
* @return {Array.<base.Point>} An array of 
*/
Points.prototype.getPoints = function() {
  return this.points;
};

/**
* @memberof base.Points
* @private
*/
Points.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Points.prototype.toString = function() {
  return JSON.stringify(this);
};

Points._meta_ =  {fields:  {}};
Points._meta_._class_ =  Points;
Points._meta_.fields["points"] = {};
Points._meta_.fields["points"].type = Array;
Points._meta_.fields["points"].elementType = base_Point;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Points;
}

