/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var RefundRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = RefundRequestMessage;
  this.setMethod(remotemessage_Method["REFUND_REQUEST"]);
  this.fullRefund = undefined;
  this.amount = undefined;
  this.orderId = undefined;
  this.paymentId = undefined;
  this.disableCloverPrinting = false;
  this.disableReceiptSelection = false;
  this.extras = undefined;
};

RefundRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
RefundRequestMessage.prototype.constructor = RefundRequestMessage;

/**
* Set the field value
* If true, then it is a full refund.  The amount will be ignored.
*
* @memberof remotemessage.RefundRequestMessage
* @param {Boolean} fullRefund 
*/
RefundRequestMessage.prototype.setFullRefund = function(fullRefund) {
  this.fullRefund = fullRefund;
};

/**
* Get the field value
* If true, then it is a full refund.  The amount will be ignored.
* @memberof remotemessage.RefundRequestMessage
* @return {Boolean} 
*/
RefundRequestMessage.prototype.getFullRefund = function() {
  return this.fullRefund;
};

/**
* Set the field value
* Amount of refund.  Used in partial refunds.
*
* @memberof remotemessage.RefundRequestMessage
* @param {Number} amount must be a long integer
*/
RefundRequestMessage.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Amount of refund.  Used in partial refunds.
* @memberof remotemessage.RefundRequestMessage
* @return {Number} must be a long integer
*/
RefundRequestMessage.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* Unique identifier for a order
*
* @memberof remotemessage.RefundRequestMessage
* @param {String} orderId 
*/
RefundRequestMessage.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier for a order
* @memberof remotemessage.RefundRequestMessage
* @return {String} 
*/
RefundRequestMessage.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* Unique identifier for a payment
*
* @memberof remotemessage.RefundRequestMessage
* @param {String} paymentId 
*/
RefundRequestMessage.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier for a payment
* @memberof remotemessage.RefundRequestMessage
* @return {String} 
*/
RefundRequestMessage.prototype.getPaymentId = function() {
  return this.paymentId;
};

/**
* Set the field value
* @memberof remotemessage.RefundRequestMessage
* @param {Boolean} disableCloverPrinting 
*/
RefundRequestMessage.prototype.setDisableCloverPrinting = function(disableCloverPrinting) {
  this.disableCloverPrinting = disableCloverPrinting;
};

/**
* Get the field value
* @memberof remotemessage.RefundRequestMessage
* @return {Boolean} 
*/
RefundRequestMessage.prototype.getDisableCloverPrinting = function() {
  return this.disableCloverPrinting;
};

/**
* Set the field value
* @memberof remotemessage.RefundRequestMessage
* @param {Boolean} disableReceiptSelection 
*/
RefundRequestMessage.prototype.setDisableReceiptSelection = function(disableReceiptSelection) {
  this.disableReceiptSelection = disableReceiptSelection;
};

/**
* Get the field value
* @memberof remotemessage.RefundRequestMessage
* @return {Boolean} 
*/
RefundRequestMessage.prototype.getDisableReceiptSelection = function() {
  return this.disableReceiptSelection;
};

/**
* Set the field value
* Extra data used by external systems.
*
* @memberof remotemessage.RefundRequestMessage
* @param {Object.<String,String>} extras A map of <String> to <>
*/
RefundRequestMessage.prototype.setExtras = function(extras) {
  this.extras = extras;
};

/**
* Get the field value
* Extra data used by external systems.
* @memberof remotemessage.RefundRequestMessage
* @return {Object.<String,String>} A map of <String> to <>
*/
RefundRequestMessage.prototype.getExtras = function() {
  return this.extras;
};

RefundRequestMessage._meta_ =  {fields:  {}};
RefundRequestMessage._meta_._class_ =  RefundRequestMessage;
RefundRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
RefundRequestMessage._meta_.fields["fullRefund"] = {};
RefundRequestMessage._meta_.fields["fullRefund"].type = Boolean;
RefundRequestMessage._meta_.fields["amount"] = {};
RefundRequestMessage._meta_.fields["amount"].type = Number;
RefundRequestMessage._meta_.fields["orderId"] = {};
RefundRequestMessage._meta_.fields["orderId"].type = String;
RefundRequestMessage._meta_.fields["paymentId"] = {};
RefundRequestMessage._meta_.fields["paymentId"].type = String;
RefundRequestMessage._meta_.fields["disableCloverPrinting"] = {};
RefundRequestMessage._meta_.fields["disableCloverPrinting"].type = Boolean;
RefundRequestMessage._meta_.fields["disableReceiptSelection"] = {};
RefundRequestMessage._meta_.fields["disableReceiptSelection"].type = Boolean;
RefundRequestMessage._meta_.fields["extras"] = {};
RefundRequestMessage._meta_.fields["extras"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RefundRequestMessage;
}

