/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof onlineorder
* @enum {string}
*/
var ProviderId = {
  GOOGLE : "GOOGLE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ProviderId;
}

