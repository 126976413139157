/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_VasServiceProvider = require("../payments/VasServiceProvider");

/**
* @constructor
* @memberof payments
*/
var VasConfig = function() {
  this._class_ = VasConfig;
  this.vasProviders = undefined;
};


/**
* Set the field value
* List of vas service providers who are interested in receiving vas data
*
* @memberof payments.VasConfig
* @param {Array.<payments.VasServiceProvider>} vasProviders An array of 
*/
VasConfig.prototype.setVasProviders = function(vasProviders) {
  this.vasProviders = vasProviders;
};

/**
* Get the field value
* List of vas service providers who are interested in receiving vas data
* @memberof payments.VasConfig
* @return {Array.<payments.VasServiceProvider>} An array of 
*/
VasConfig.prototype.getVasProviders = function() {
  return this.vasProviders;
};

/**
* @memberof payments.VasConfig
* @private
*/
VasConfig.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

VasConfig.prototype.toString = function() {
  return JSON.stringify(this);
};

VasConfig._meta_ =  {fields:  {}};
VasConfig._meta_._class_ =  VasConfig;
VasConfig._meta_.fields["vasProviders"] = {};
VasConfig._meta_.fields["vasProviders"].type = Array;
VasConfig._meta_.fields["vasProviders"].elementType = payments_VasServiceProvider;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasConfig;
}

