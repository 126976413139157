/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var TipAdjustMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = TipAdjustMessage;
  this.setMethod(remotemessage_Method["TIP_ADJUST"]);
  this.tipAmount = undefined;
  this.orderId = undefined;
  this.paymentId = undefined;
};

TipAdjustMessage.prototype = Object.create(remotemessage_Message.prototype);
TipAdjustMessage.prototype.constructor = TipAdjustMessage;

/**
* Set the field value
* Amount paid in tips
*
* @memberof remotemessage.TipAdjustMessage
* @param {Number} tipAmount must be a long integer
*/
TipAdjustMessage.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Amount paid in tips
* @memberof remotemessage.TipAdjustMessage
* @return {Number} must be a long integer
*/
TipAdjustMessage.prototype.getTipAmount = function() {
  return this.tipAmount;
};

/**
* Set the field value
* Unique identifier for a order
*
* @memberof remotemessage.TipAdjustMessage
* @param {String} orderId 
*/
TipAdjustMessage.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier for a order
* @memberof remotemessage.TipAdjustMessage
* @return {String} 
*/
TipAdjustMessage.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* Unique identifier for a payment
*
* @memberof remotemessage.TipAdjustMessage
* @param {String} paymentId 
*/
TipAdjustMessage.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier for a payment
* @memberof remotemessage.TipAdjustMessage
* @return {String} 
*/
TipAdjustMessage.prototype.getPaymentId = function() {
  return this.paymentId;
};

TipAdjustMessage._meta_ =  {fields:  {}};
TipAdjustMessage._meta_._class_ =  TipAdjustMessage;
TipAdjustMessage._meta_._superMeta_ = remotemessage_Message._meta_;
TipAdjustMessage._meta_.fields["tipAmount"] = {};
TipAdjustMessage._meta_.fields["tipAmount"].type = Number;
TipAdjustMessage._meta_.fields["orderId"] = {};
TipAdjustMessage._meta_.fields["orderId"].type = String;
TipAdjustMessage._meta_.fields["paymentId"] = {};
TipAdjustMessage._meta_.fields["paymentId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipAdjustMessage;
}

