/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var hours_HourRange = require("../hours/HourRange");
var hours_Reference = require("../hours/Reference");

/**
* @constructor
* @memberof hours
*/
var HoursSet = function() {
  this._class_ = HoursSet;
  this.id = undefined;
  this.name = undefined;
  this.reference = undefined;
  this.sunday = undefined;
  this.monday = undefined;
  this.tuesday = undefined;
  this.wednesday = undefined;
  this.thursday = undefined;
  this.friday = undefined;
  this.saturday = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof hours.HoursSet
* @param {String} id 
*/
HoursSet.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof hours.HoursSet
* @return {String} 
*/
HoursSet.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Friendly name to describe the hours
*
* @memberof hours.HoursSet
* @param {String} name 
*/
HoursSet.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Friendly name to describe the hours
* @memberof hours.HoursSet
* @return {String} 
*/
HoursSet.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* @memberof hours.HoursSet
* @param {Null|hours.Reference} reference 
*/
HoursSet.prototype.setReference = function(reference) {
  this.reference = reference;
};

/**
* Get the field value
* @memberof hours.HoursSet
* @return {Null|hours.Reference} 
*/
HoursSet.prototype.getReference = function() {
  return this.reference;
};

/**
* Set the field value
* @memberof hours.HoursSet
* @param {Array.<hours.HourRange>} sunday An array of 
*/
HoursSet.prototype.setSunday = function(sunday) {
  this.sunday = sunday;
};

/**
* Get the field value
* @memberof hours.HoursSet
* @return {Array.<hours.HourRange>} An array of 
*/
HoursSet.prototype.getSunday = function() {
  return this.sunday;
};

/**
* Set the field value
* @memberof hours.HoursSet
* @param {Array.<hours.HourRange>} monday An array of 
*/
HoursSet.prototype.setMonday = function(monday) {
  this.monday = monday;
};

/**
* Get the field value
* @memberof hours.HoursSet
* @return {Array.<hours.HourRange>} An array of 
*/
HoursSet.prototype.getMonday = function() {
  return this.monday;
};

/**
* Set the field value
* @memberof hours.HoursSet
* @param {Array.<hours.HourRange>} tuesday An array of 
*/
HoursSet.prototype.setTuesday = function(tuesday) {
  this.tuesday = tuesday;
};

/**
* Get the field value
* @memberof hours.HoursSet
* @return {Array.<hours.HourRange>} An array of 
*/
HoursSet.prototype.getTuesday = function() {
  return this.tuesday;
};

/**
* Set the field value
* @memberof hours.HoursSet
* @param {Array.<hours.HourRange>} wednesday An array of 
*/
HoursSet.prototype.setWednesday = function(wednesday) {
  this.wednesday = wednesday;
};

/**
* Get the field value
* @memberof hours.HoursSet
* @return {Array.<hours.HourRange>} An array of 
*/
HoursSet.prototype.getWednesday = function() {
  return this.wednesday;
};

/**
* Set the field value
* @memberof hours.HoursSet
* @param {Array.<hours.HourRange>} thursday An array of 
*/
HoursSet.prototype.setThursday = function(thursday) {
  this.thursday = thursday;
};

/**
* Get the field value
* @memberof hours.HoursSet
* @return {Array.<hours.HourRange>} An array of 
*/
HoursSet.prototype.getThursday = function() {
  return this.thursday;
};

/**
* Set the field value
* @memberof hours.HoursSet
* @param {Array.<hours.HourRange>} friday An array of 
*/
HoursSet.prototype.setFriday = function(friday) {
  this.friday = friday;
};

/**
* Get the field value
* @memberof hours.HoursSet
* @return {Array.<hours.HourRange>} An array of 
*/
HoursSet.prototype.getFriday = function() {
  return this.friday;
};

/**
* Set the field value
* @memberof hours.HoursSet
* @param {Array.<hours.HourRange>} saturday An array of 
*/
HoursSet.prototype.setSaturday = function(saturday) {
  this.saturday = saturday;
};

/**
* Get the field value
* @memberof hours.HoursSet
* @return {Array.<hours.HourRange>} An array of 
*/
HoursSet.prototype.getSaturday = function() {
  return this.saturday;
};

/**
* @memberof hours.HoursSet
* @private
*/
HoursSet.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

HoursSet.prototype.toString = function() {
  return JSON.stringify(this);
};

HoursSet._meta_ =  {fields:  {}};
HoursSet._meta_._class_ =  HoursSet;
HoursSet._meta_.fields["id"] = {};
HoursSet._meta_.fields["id"].type = String;
HoursSet._meta_.fields["name"] = {};
HoursSet._meta_.fields["name"].type = String;
HoursSet._meta_.fields["reference"] = {};
HoursSet._meta_.fields["reference"].type = hours_Reference;
HoursSet._meta_.fields["sunday"] = {};
HoursSet._meta_.fields["sunday"].type = Array;
HoursSet._meta_.fields["sunday"].elementType = hours_HourRange;
HoursSet._meta_.fields["monday"] = {};
HoursSet._meta_.fields["monday"].type = Array;
HoursSet._meta_.fields["monday"].elementType = hours_HourRange;
HoursSet._meta_.fields["tuesday"] = {};
HoursSet._meta_.fields["tuesday"].type = Array;
HoursSet._meta_.fields["tuesday"].elementType = hours_HourRange;
HoursSet._meta_.fields["wednesday"] = {};
HoursSet._meta_.fields["wednesday"].type = Array;
HoursSet._meta_.fields["wednesday"].elementType = hours_HourRange;
HoursSet._meta_.fields["thursday"] = {};
HoursSet._meta_.fields["thursday"].type = Array;
HoursSet._meta_.fields["thursday"].elementType = hours_HourRange;
HoursSet._meta_.fields["friday"] = {};
HoursSet._meta_.fields["friday"].type = Array;
HoursSet._meta_.fields["friday"].elementType = hours_HourRange;
HoursSet._meta_.fields["saturday"] = {};
HoursSet._meta_.fields["saturday"].type = Array;
HoursSet._meta_.fields["saturday"].elementType = hours_HourRange;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = HoursSet;
}

