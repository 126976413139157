/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof inventory
*/
var Option = function() {
  this._class_ = Option;
  this.id = undefined;
  this.name = undefined;
  this.attribute = undefined;
  this.items = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof inventory.Option
* @param {String} id 
*/
Option.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof inventory.Option
* @return {String} 
*/
Option.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Name of the option
*
* @memberof inventory.Option
* @param {String} name 
*/
Option.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Name of the option
* @memberof inventory.Option
* @return {String} 
*/
Option.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* @memberof inventory.Option
* @param {base.Reference} attribute 
*/
Option.prototype.setAttribute = function(attribute) {
  this.attribute = attribute;
};

/**
* Get the field value
* @memberof inventory.Option
* @return {base.Reference} 
*/
Option.prototype.getAttribute = function() {
  return this.attribute;
};

/**
* Set the field value
* Items associated with this option
*
* @memberof inventory.Option
* @param {Array.<base.Reference>} items An array of 
*/
Option.prototype.setItems = function(items) {
  this.items = items;
};

/**
* Get the field value
* Items associated with this option
* @memberof inventory.Option
* @return {Array.<base.Reference>} An array of 
*/
Option.prototype.getItems = function() {
  return this.items;
};

/**
* @memberof inventory.Option
* @private
*/
Option.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Option.prototype.toString = function() {
  return JSON.stringify(this);
};

Option._meta_ =  {fields:  {}};
Option._meta_._class_ =  Option;
Option._meta_.fields["id"] = {};
Option._meta_.fields["id"].type = String;
Option._meta_.fields["name"] = {};
Option._meta_.fields["name"].type = String;
Option._meta_.fields["attribute"] = {};
Option._meta_.fields["attribute"].type = base_Reference;
Option._meta_.fields["items"] = {};
Option._meta_.fields["items"].type = Array;
Option._meta_.fields["items"].elementType = base_Reference;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Option;
}

