/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_DeviceInfo = require("../remotepay/DeviceInfo");

/**
* @constructor
* @memberof remotepay
*/
var MerchantInfo = function() {
  this._class_ = MerchantInfo;
  this.merchantID = undefined;
  this.merchantMID = undefined;
  this.merchantName = undefined;
  this.supportsAuths = true;
  this.supportsPreAuths = true;
  this.supportsSales = true;
  this.supportsVaultCards = true;
  this.supportsManualRefunds = true;
  this.supportsTipAdjust = true;
  this.deviceInfo = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof remotepay.MerchantInfo
* @param {String} merchantID 
*/
MerchantInfo.prototype.setMerchantID = function(merchantID) {
  this.merchantID = merchantID;
};

/**
* Get the field value
* Unique identifier
* @memberof remotepay.MerchantInfo
* @return {String} 
*/
MerchantInfo.prototype.getMerchantID = function() {
  return this.merchantID;
};

/**
* Set the field value
* @memberof remotepay.MerchantInfo
* @param {String} merchantMID 
*/
MerchantInfo.prototype.setMerchantMID = function(merchantMID) {
  this.merchantMID = merchantMID;
};

/**
* Get the field value
* @memberof remotepay.MerchantInfo
* @return {String} 
*/
MerchantInfo.prototype.getMerchantMID = function() {
  return this.merchantMID;
};

/**
* Set the field value
* Name of the merchant
*
* @memberof remotepay.MerchantInfo
* @param {String} merchantName 
*/
MerchantInfo.prototype.setMerchantName = function(merchantName) {
  this.merchantName = merchantName;
};

/**
* Get the field value
* Name of the merchant
* @memberof remotepay.MerchantInfo
* @return {String} 
*/
MerchantInfo.prototype.getMerchantName = function() {
  return this.merchantName;
};

/**
* Set the field value
* @memberof remotepay.MerchantInfo
* @param {Boolean} supportsAuths 
*/
MerchantInfo.prototype.setSupportsAuths = function(supportsAuths) {
  this.supportsAuths = supportsAuths;
};

/**
* Get the field value
* @memberof remotepay.MerchantInfo
* @return {Boolean} 
*/
MerchantInfo.prototype.getSupportsAuths = function() {
  return this.supportsAuths;
};

/**
* Set the field value
* @memberof remotepay.MerchantInfo
* @param {Boolean} supportsPreAuths 
*/
MerchantInfo.prototype.setSupportsPreAuths = function(supportsPreAuths) {
  this.supportsPreAuths = supportsPreAuths;
};

/**
* Get the field value
* @memberof remotepay.MerchantInfo
* @return {Boolean} 
*/
MerchantInfo.prototype.getSupportsPreAuths = function() {
  return this.supportsPreAuths;
};

/**
* Set the field value
* @memberof remotepay.MerchantInfo
* @param {Boolean} supportsSales 
*/
MerchantInfo.prototype.setSupportsSales = function(supportsSales) {
  this.supportsSales = supportsSales;
};

/**
* Get the field value
* @memberof remotepay.MerchantInfo
* @return {Boolean} 
*/
MerchantInfo.prototype.getSupportsSales = function() {
  return this.supportsSales;
};

/**
* Set the field value
* @memberof remotepay.MerchantInfo
* @param {Boolean} supportsVaultCards 
*/
MerchantInfo.prototype.setSupportsVaultCards = function(supportsVaultCards) {
  this.supportsVaultCards = supportsVaultCards;
};

/**
* Get the field value
* @memberof remotepay.MerchantInfo
* @return {Boolean} 
*/
MerchantInfo.prototype.getSupportsVaultCards = function() {
  return this.supportsVaultCards;
};

/**
* Set the field value
* @memberof remotepay.MerchantInfo
* @param {Boolean} supportsManualRefunds 
*/
MerchantInfo.prototype.setSupportsManualRefunds = function(supportsManualRefunds) {
  this.supportsManualRefunds = supportsManualRefunds;
};

/**
* Get the field value
* @memberof remotepay.MerchantInfo
* @return {Boolean} 
*/
MerchantInfo.prototype.getSupportsManualRefunds = function() {
  return this.supportsManualRefunds;
};

/**
* Set the field value
* @memberof remotepay.MerchantInfo
* @param {Boolean} supportsTipAdjust 
*/
MerchantInfo.prototype.setSupportsTipAdjust = function(supportsTipAdjust) {
  this.supportsTipAdjust = supportsTipAdjust;
};

/**
* Get the field value
* @memberof remotepay.MerchantInfo
* @return {Boolean} 
*/
MerchantInfo.prototype.getSupportsTipAdjust = function() {
  return this.supportsTipAdjust;
};

/**
* Set the field value
* @memberof remotepay.MerchantInfo
* @param {remotepay.DeviceInfo} deviceInfo 
*/
MerchantInfo.prototype.setDeviceInfo = function(deviceInfo) {
  this.deviceInfo = deviceInfo;
};

/**
* Get the field value
* @memberof remotepay.MerchantInfo
* @return {remotepay.DeviceInfo} 
*/
MerchantInfo.prototype.getDeviceInfo = function() {
  return this.deviceInfo;
};

/**
* @memberof remotepay.MerchantInfo
* @private
*/
MerchantInfo.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

MerchantInfo.prototype.toString = function() {
  return JSON.stringify(this);
};

MerchantInfo._meta_ =  {fields:  {}};
MerchantInfo._meta_._class_ =  MerchantInfo;
MerchantInfo._meta_.fields["merchantID"] = {};
MerchantInfo._meta_.fields["merchantID"].type = String;
MerchantInfo._meta_.fields["merchantMID"] = {};
MerchantInfo._meta_.fields["merchantMID"].type = String;
MerchantInfo._meta_.fields["merchantName"] = {};
MerchantInfo._meta_.fields["merchantName"].type = String;
MerchantInfo._meta_.fields["supportsAuths"] = {};
MerchantInfo._meta_.fields["supportsAuths"].type = Boolean;
MerchantInfo._meta_.fields["supportsPreAuths"] = {};
MerchantInfo._meta_.fields["supportsPreAuths"].type = Boolean;
MerchantInfo._meta_.fields["supportsSales"] = {};
MerchantInfo._meta_.fields["supportsSales"].type = Boolean;
MerchantInfo._meta_.fields["supportsVaultCards"] = {};
MerchantInfo._meta_.fields["supportsVaultCards"].type = Boolean;
MerchantInfo._meta_.fields["supportsManualRefunds"] = {};
MerchantInfo._meta_.fields["supportsManualRefunds"].type = Boolean;
MerchantInfo._meta_.fields["supportsTipAdjust"] = {};
MerchantInfo._meta_.fields["supportsTipAdjust"].type = Boolean;
MerchantInfo._meta_.fields["deviceInfo"] = {};
MerchantInfo._meta_.fields["deviceInfo"].type = remotepay_DeviceInfo;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = MerchantInfo;
}

