/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_CardType = require("../payments/CardType");
var payments_CardTransactionState = require("../payments/CardTransactionState");
var payments_CardEntryType = require("../payments/CardEntryType");
var payments_VaultedCard = require("../payments/VaultedCard");
var payments_CardTransactionType = require("../payments/CardTransactionType");
var payments_GatewayTxState = require("../payments/GatewayTxState");
var payments_AVSResult = require("../payments/AVSResult");

/**
* @constructor
* @memberof payments
*/
var CardTransaction = function() {
  this._class_ = CardTransaction;
  this.cardType = undefined;
  this.entryType = undefined;
  this.first6 = undefined;
  this.last4 = undefined;
  this.type = undefined;
  this.authCode = undefined;
  this.referenceId = undefined;
  this.transactionNo = undefined;
  this.state = undefined;
  this.extra = undefined;
  this.begBalance = undefined;
  this.endBalance = undefined;
  this.avsResult = undefined;
  this.cardholderName = undefined;
  this.token = undefined;
  this.vaultedCard = undefined;
  this.gatewayTxState = undefined;
  this.currency = undefined;
  this.captured = undefined;
};


/**
* Set the field value
* @memberof payments.CardTransaction
* @param {payments.CardType} cardType 
*/
CardTransaction.prototype.setCardType = function(cardType) {
  this.cardType = cardType;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {payments.CardType} 
*/
CardTransaction.prototype.getCardType = function() {
  return this.cardType;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {payments.CardEntryType} entryType 
*/
CardTransaction.prototype.setEntryType = function(entryType) {
  this.entryType = entryType;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {payments.CardEntryType} 
*/
CardTransaction.prototype.getEntryType = function() {
  return this.entryType;
};

/**
* Set the field value
* The first four digits of the card number
*
* @memberof payments.CardTransaction
* @param {String} first6 
*/
CardTransaction.prototype.setFirst6 = function(first6) {
  this.first6 = first6;
};

/**
* Get the field value
* The first four digits of the card number
* @memberof payments.CardTransaction
* @return {String} 
*/
CardTransaction.prototype.getFirst6 = function() {
  return this.first6;
};

/**
* Set the field value
* The last four digits of the card number
*
* @memberof payments.CardTransaction
* @param {String} last4 
*/
CardTransaction.prototype.setLast4 = function(last4) {
  this.last4 = last4;
};

/**
* Get the field value
* The last four digits of the card number
* @memberof payments.CardTransaction
* @return {String} 
*/
CardTransaction.prototype.getLast4 = function() {
  return this.last4;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {payments.CardTransactionType} type 
*/
CardTransaction.prototype.setType = function(type) {
  this.type = type;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {payments.CardTransactionType} 
*/
CardTransaction.prototype.getType = function() {
  return this.type;
};

/**
* Set the field value
* Authorization code (if successful)
*
* @memberof payments.CardTransaction
* @param {String} authCode 
*/
CardTransaction.prototype.setAuthCode = function(authCode) {
  this.authCode = authCode;
};

/**
* Get the field value
* Authorization code (if successful)
* @memberof payments.CardTransaction
* @return {String} 
*/
CardTransaction.prototype.getAuthCode = function() {
  return this.authCode;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {String} referenceId 
*/
CardTransaction.prototype.setReferenceId = function(referenceId) {
  this.referenceId = referenceId;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {String} 
*/
CardTransaction.prototype.getReferenceId = function() {
  return this.referenceId;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {String} transactionNo 
*/
CardTransaction.prototype.setTransactionNo = function(transactionNo) {
  this.transactionNo = transactionNo;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {String} 
*/
CardTransaction.prototype.getTransactionNo = function() {
  return this.transactionNo;
};

/**
* Set the field value
* A state inferred from transaction type and whether the gateway captured the transaction
*
* @memberof payments.CardTransaction
* @param {payments.CardTransactionState} state 
*/
CardTransaction.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* A state inferred from transaction type and whether the gateway captured the transaction
* @memberof payments.CardTransaction
* @return {payments.CardTransactionState} 
*/
CardTransaction.prototype.getState = function() {
  return this.state;
};

/**
* Set the field value
* Extra info to be stored as part of gateway/card transaction
*
* @memberof payments.CardTransaction
* @param {Object.<String,String>} extra A map of <String> to <>
*/
CardTransaction.prototype.setExtra = function(extra) {
  this.extra = extra;
};

/**
* Get the field value
* Extra info to be stored as part of gateway/card transaction
* @memberof payments.CardTransaction
* @return {Object.<String,String>} A map of <String> to <>
*/
CardTransaction.prototype.getExtra = function() {
  return this.extra;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {Number|Null} begBalance must be a long integer, 
*/
CardTransaction.prototype.setBegBalance = function(begBalance) {
  this.begBalance = begBalance;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {Number|Null} must be a long integer, 
*/
CardTransaction.prototype.getBegBalance = function() {
  return this.begBalance;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {Number|Null} endBalance must be a long integer, 
*/
CardTransaction.prototype.setEndBalance = function(endBalance) {
  this.endBalance = endBalance;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {Number|Null} must be a long integer, 
*/
CardTransaction.prototype.getEndBalance = function() {
  return this.endBalance;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {payments.AVSResult} avsResult 
*/
CardTransaction.prototype.setAvsResult = function(avsResult) {
  this.avsResult = avsResult;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {payments.AVSResult} 
*/
CardTransaction.prototype.getAvsResult = function() {
  return this.avsResult;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {String} cardholderName 
*/
CardTransaction.prototype.setCardholderName = function(cardholderName) {
  this.cardholderName = cardholderName;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {String} 
*/
CardTransaction.prototype.getCardholderName = function() {
  return this.cardholderName;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {String} token 
*/
CardTransaction.prototype.setToken = function(token) {
  this.token = token;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {String} 
*/
CardTransaction.prototype.getToken = function() {
  return this.token;
};

/**
* Set the field value
* Vaulted card which can be used for subsequent transactions
*
* @memberof payments.CardTransaction
* @param {Null|payments.VaultedCard} vaultedCard 
*/
CardTransaction.prototype.setVaultedCard = function(vaultedCard) {
  this.vaultedCard = vaultedCard;
};

/**
* Get the field value
* Vaulted card which can be used for subsequent transactions
* @memberof payments.CardTransaction
* @return {Null|payments.VaultedCard} 
*/
CardTransaction.prototype.getVaultedCard = function() {
  return this.vaultedCard;
};

/**
* Set the field value
* The state as currently recorded. This differs from the legacy 'state' field. The legacy 'state' field is calculated from the 'type' field and whether the transaction was captured.
*
* @memberof payments.CardTransaction
* @param {payments.GatewayTxState} gatewayTxState 
*/
CardTransaction.prototype.setGatewayTxState = function(gatewayTxState) {
  this.gatewayTxState = gatewayTxState;
};

/**
* Get the field value
* The state as currently recorded. This differs from the legacy 'state' field. The legacy 'state' field is calculated from the 'type' field and whether the transaction was captured.
* @memberof payments.CardTransaction
* @return {payments.GatewayTxState} 
*/
CardTransaction.prototype.getGatewayTxState = function() {
  return this.gatewayTxState;
};

/**
* Set the field value
* Currency code
*
* @memberof payments.CardTransaction
* @param {String} currency 
*/
CardTransaction.prototype.setCurrency = function(currency) {
  this.currency = currency;
};

/**
* Get the field value
* Currency code
* @memberof payments.CardTransaction
* @return {String} 
*/
CardTransaction.prototype.getCurrency = function() {
  return this.currency;
};

/**
* Set the field value
* @memberof payments.CardTransaction
* @param {Boolean} captured 
*/
CardTransaction.prototype.setCaptured = function(captured) {
  this.captured = captured;
};

/**
* Get the field value
* @memberof payments.CardTransaction
* @return {Boolean} 
*/
CardTransaction.prototype.getCaptured = function() {
  return this.captured;
};

/**
* @memberof payments.CardTransaction
* @private
*/
CardTransaction.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

CardTransaction.prototype.toString = function() {
  return JSON.stringify(this);
};

CardTransaction._meta_ =  {fields:  {}};
CardTransaction._meta_._class_ =  CardTransaction;
CardTransaction._meta_.fields["cardType"] = {};
CardTransaction._meta_.fields["cardType"].type = payments_CardType;
CardTransaction._meta_.fields["entryType"] = {};
CardTransaction._meta_.fields["entryType"].type = payments_CardEntryType;
CardTransaction._meta_.fields["first6"] = {};
CardTransaction._meta_.fields["first6"].type = String;
CardTransaction._meta_.fields["last4"] = {};
CardTransaction._meta_.fields["last4"].type = String;
CardTransaction._meta_.fields["type"] = {};
CardTransaction._meta_.fields["type"].type = payments_CardTransactionType;
CardTransaction._meta_.fields["authCode"] = {};
CardTransaction._meta_.fields["authCode"].type = String;
CardTransaction._meta_.fields["referenceId"] = {};
CardTransaction._meta_.fields["referenceId"].type = String;
CardTransaction._meta_.fields["transactionNo"] = {};
CardTransaction._meta_.fields["transactionNo"].type = String;
CardTransaction._meta_.fields["state"] = {};
CardTransaction._meta_.fields["state"].type = payments_CardTransactionState;
CardTransaction._meta_.fields["extra"] = {};
CardTransaction._meta_.fields["extra"].type = Object;
CardTransaction._meta_.fields["begBalance"] = {};
CardTransaction._meta_.fields["begBalance"].type = Number;
CardTransaction._meta_.fields["endBalance"] = {};
CardTransaction._meta_.fields["endBalance"].type = Number;
CardTransaction._meta_.fields["avsResult"] = {};
CardTransaction._meta_.fields["avsResult"].type = payments_AVSResult;
CardTransaction._meta_.fields["cardholderName"] = {};
CardTransaction._meta_.fields["cardholderName"].type = String;
CardTransaction._meta_.fields["token"] = {};
CardTransaction._meta_.fields["token"].type = String;
CardTransaction._meta_.fields["vaultedCard"] = {};
CardTransaction._meta_.fields["vaultedCard"].type = payments_VaultedCard;
CardTransaction._meta_.fields["gatewayTxState"] = {};
CardTransaction._meta_.fields["gatewayTxState"].type = payments_GatewayTxState;
CardTransaction._meta_.fields["currency"] = {};
CardTransaction._meta_.fields["currency"].type = String;
CardTransaction._meta_.fields["captured"] = {};
CardTransaction._meta_.fields["captured"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardTransaction;
}

