/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
Symbols beginning with USER_ or REJECT_ are user-initiated.  Others are client- or server-initiated.
* @memberof order
* @enum {string}
*/
var VoidReason = {
  USER_CANCEL : "USER_CANCEL",
  TRANSPORT_ERROR : "TRANSPORT_ERROR",
  REJECT_SIGNATURE : "REJECT_SIGNATURE",
  REJECT_PARTIAL_AUTH : "REJECT_PARTIAL_AUTH",
  NOT_APPROVED : "NOT_APPROVED",
  FAILED : "FAILED",
  AUTH_CLOSED_NEW_CARD : "AUTH_CLOSED_NEW_CARD",
  DEVELOPER_PAY_PARTIAL_AUTH : "DEVELOPER_PAY_PARTIAL_AUTH",
  REJECT_DUPLICATE : "REJECT_DUPLICATE",
  REJECT_OFFLINE : "REJECT_OFFLINE",
  GIFTCARD_LOAD_FAILED : "GIFTCARD_LOAD_FAILED",
  USER_GIFTCARD_LOAD_CANCEL : "USER_GIFTCARD_LOAD_CANCEL",
  DEVELOPER_PAY_TIP_ADJUST_FAILED : "DEVELOPER_PAY_TIP_ADJUST_FAILED",
  USER_CUSTOMER_CANCEL : "USER_CUSTOMER_CANCEL",
  FRAUD : "FRAUD"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VoidReason;
}

