/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var ActivityRequest = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = ActivityRequest;
  this.setMethod(remotemessage_Method["ACTIVITY_REQUEST"]);
  this.action = undefined;
  this.payload = undefined;
  this.nonBlocking = undefined;
  this.forceLaunch = undefined;
};

ActivityRequest.prototype = Object.create(remotemessage_Message.prototype);
ActivityRequest.prototype.constructor = ActivityRequest;

/**
* Set the field value
* The name of the action for this activity
*
* @memberof remotemessage.ActivityRequest
* @param {String} action 
*/
ActivityRequest.prototype.setAction = function(action) {
  this.action = action;
};

/**
* Get the field value
* The name of the action for this activity
* @memberof remotemessage.ActivityRequest
* @return {String} 
*/
ActivityRequest.prototype.getAction = function() {
  return this.action;
};

/**
* Set the field value
* Data for this activity
*
* @memberof remotemessage.ActivityRequest
* @param {String} payload 
*/
ActivityRequest.prototype.setPayload = function(payload) {
  this.payload = payload;
};

/**
* Get the field value
* Data for this activity
* @memberof remotemessage.ActivityRequest
* @return {String} 
*/
ActivityRequest.prototype.getPayload = function() {
  return this.payload;
};

/**
* Set the field value
* If true, then the activity can be stopped in regular execution.
*
* @memberof remotemessage.ActivityRequest
* @param {Boolean} nonBlocking 
*/
ActivityRequest.prototype.setNonBlocking = function(nonBlocking) {
  this.nonBlocking = nonBlocking;
};

/**
* Get the field value
* If true, then the activity can be stopped in regular execution.
* @memberof remotemessage.ActivityRequest
* @return {Boolean} 
*/
ActivityRequest.prototype.getNonBlocking = function() {
  return this.nonBlocking;
};

/**
* Set the field value
* If true, then the activity will cancel other activity and run.  This includes payment activity.
*
* @memberof remotemessage.ActivityRequest
* @param {Boolean} forceLaunch 
*/
ActivityRequest.prototype.setForceLaunch = function(forceLaunch) {
  this.forceLaunch = forceLaunch;
};

/**
* Get the field value
* If true, then the activity will cancel other activity and run.  This includes payment activity.
* @memberof remotemessage.ActivityRequest
* @return {Boolean} 
*/
ActivityRequest.prototype.getForceLaunch = function() {
  return this.forceLaunch;
};

ActivityRequest._meta_ =  {fields:  {}};
ActivityRequest._meta_._class_ =  ActivityRequest;
ActivityRequest._meta_._superMeta_ = remotemessage_Message._meta_;
ActivityRequest._meta_.fields["action"] = {};
ActivityRequest._meta_.fields["action"].type = String;
ActivityRequest._meta_.fields["payload"] = {};
ActivityRequest._meta_.fields["payload"].type = String;
ActivityRequest._meta_.fields["nonBlocking"] = {};
ActivityRequest._meta_.fields["nonBlocking"].type = Boolean;
ActivityRequest._meta_.fields["forceLaunch"] = {};
ActivityRequest._meta_.fields["forceLaunch"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ActivityRequest;
}

