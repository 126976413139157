/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof order
* @enum {string}
*/
var HoursAvailable = {
  ALL : "ALL",
  BUSINESS : "BUSINESS",
  CUSTOM : "CUSTOM"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = HoursAvailable;
}

