import React from "react";
import { Image, Text, View } from "react-native";
import CarouselItemProps from "../types/components/CarouselItemProps";
import { styles } from "../styles/components/carouselItem";

export default ({
  image,
  description,
  textColor,
  isSelected,
  size
}: CarouselItemProps): JSX.Element => {
  const itemNotSelected = typeof isSelected !== 'undefined' && !isSelected;

  return (
    <View style={[styles.container, itemNotSelected && styles.itemNoSelected]}>
      <Image
        source={{ uri: image || "https://via.placeholder.com/100/100" }}
        style={[styles.image, size]}
      />
      {description && (
          <Text style={[styles.description, { color: textColor }]}>
            {description}
          </Text>
      )}

    </View>
  );
};

export const CarouselItemPlaceHolder = (): JSX.Element => (
  <View style={styles.container}>
    <Image source={{ uri: "" }} style={styles.image} />
    <Text style={styles.description}></Text>
  </View>
);
