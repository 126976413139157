/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_ExternalDeviceState = require("../remotepay/ExternalDeviceState");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");
var remotepay_ExternalDeviceStateData = require("../remotepay/ExternalDeviceStateData");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var RetrieveDeviceStatusResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = RetrieveDeviceStatusResponse;
  this.state = undefined;
  this.data = undefined;
};

RetrieveDeviceStatusResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
RetrieveDeviceStatusResponse.prototype.constructor = RetrieveDeviceStatusResponse;

/**
* Set the field value
* High level state of the connected device.
*
* @memberof remotepay.RetrieveDeviceStatusResponse
* @param {remotepay.ExternalDeviceState} state 
*/
RetrieveDeviceStatusResponse.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* High level state of the connected device.
* @memberof remotepay.RetrieveDeviceStatusResponse
* @return {remotepay.ExternalDeviceState} 
*/
RetrieveDeviceStatusResponse.prototype.getState = function() {
  return this.state;
};

/**
* Set the field value
* Additional data elements supplementing the state of the connected device.
*
* @memberof remotepay.RetrieveDeviceStatusResponse
* @param {remotepay.ExternalDeviceStateData} data 
*/
RetrieveDeviceStatusResponse.prototype.setData = function(data) {
  this.data = data;
};

/**
* Get the field value
* Additional data elements supplementing the state of the connected device.
* @memberof remotepay.RetrieveDeviceStatusResponse
* @return {remotepay.ExternalDeviceStateData} 
*/
RetrieveDeviceStatusResponse.prototype.getData = function() {
  return this.data;
};

RetrieveDeviceStatusResponse._meta_ =  {fields:  {}};
RetrieveDeviceStatusResponse._meta_._class_ =  RetrieveDeviceStatusResponse;
RetrieveDeviceStatusResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
RetrieveDeviceStatusResponse._meta_.fields["state"] = {};
RetrieveDeviceStatusResponse._meta_.fields["state"].type = remotepay_ExternalDeviceState;
RetrieveDeviceStatusResponse._meta_.fields["data"] = {};
RetrieveDeviceStatusResponse._meta_.fields["data"].type = remotepay_ExternalDeviceStateData;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RetrieveDeviceStatusResponse;
}

