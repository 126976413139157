/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var CardTransactionState = {
  PENDING : "PENDING",
  CLOSED : "CLOSED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardTransactionState;
}

