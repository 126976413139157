/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_QueryStatus = require("../remotemessage/QueryStatus");

/**
* @constructor
* @memberof remotemessage
*/
var QueryStatusEnum = function() {
  this._class_ = QueryStatusEnum;
  this.queryStatus = undefined;
};


/**
* Set the field value
* @memberof remotemessage.QueryStatusEnum
* @param {remotemessage.QueryStatus} queryStatus 
*/
QueryStatusEnum.prototype.setQueryStatus = function(queryStatus) {
  this.queryStatus = queryStatus;
};

/**
* Get the field value
* @memberof remotemessage.QueryStatusEnum
* @return {remotemessage.QueryStatus} 
*/
QueryStatusEnum.prototype.getQueryStatus = function() {
  return this.queryStatus;
};

/**
* @memberof remotemessage.QueryStatusEnum
* @private
*/
QueryStatusEnum.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

QueryStatusEnum.prototype.toString = function() {
  return JSON.stringify(this);
};

QueryStatusEnum._meta_ =  {fields:  {}};
QueryStatusEnum._meta_._class_ =  QueryStatusEnum;
QueryStatusEnum._meta_.fields["queryStatus"] = {};
QueryStatusEnum._meta_.fields["queryStatus"].type = remotemessage_QueryStatus;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = QueryStatusEnum;
}

