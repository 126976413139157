/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof device
*/
var BundleItem = function() {
  this._class_ = BundleItem;
  this.equipmentCode = undefined;
  this.equipmentCodeDesc = undefined;
  this.quantity = undefined;
  this.version = undefined;
};


/**
* Set the field value
* @memberof device.BundleItem
* @param {String} equipmentCode 
*/
BundleItem.prototype.setEquipmentCode = function(equipmentCode) {
  this.equipmentCode = equipmentCode;
};

/**
* Get the field value
* @memberof device.BundleItem
* @return {String} 
*/
BundleItem.prototype.getEquipmentCode = function() {
  return this.equipmentCode;
};

/**
* Set the field value
* @memberof device.BundleItem
* @param {String} equipmentCodeDesc 
*/
BundleItem.prototype.setEquipmentCodeDesc = function(equipmentCodeDesc) {
  this.equipmentCodeDesc = equipmentCodeDesc;
};

/**
* Get the field value
* @memberof device.BundleItem
* @return {String} 
*/
BundleItem.prototype.getEquipmentCodeDesc = function() {
  return this.equipmentCodeDesc;
};

/**
* Set the field value
* @memberof device.BundleItem
* @param {Number} quantity must be an integer
*/
BundleItem.prototype.setQuantity = function(quantity) {
  this.quantity = quantity;
};

/**
* Get the field value
* @memberof device.BundleItem
* @return {Number} must be an integer
*/
BundleItem.prototype.getQuantity = function() {
  return this.quantity;
};

/**
* Set the field value
* @memberof device.BundleItem
* @param {Number} version must be an integer
*/
BundleItem.prototype.setVersion = function(version) {
  this.version = version;
};

/**
* Get the field value
* @memberof device.BundleItem
* @return {Number} must be an integer
*/
BundleItem.prototype.getVersion = function() {
  return this.version;
};

/**
* @memberof device.BundleItem
* @private
*/
BundleItem.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

BundleItem.prototype.toString = function() {
  return JSON.stringify(this);
};

BundleItem._meta_ =  {fields:  {}};
BundleItem._meta_._class_ =  BundleItem;
BundleItem._meta_.fields["equipmentCode"] = {};
BundleItem._meta_.fields["equipmentCode"].type = String;
BundleItem._meta_.fields["equipmentCodeDesc"] = {};
BundleItem._meta_.fields["equipmentCodeDesc"].type = String;
BundleItem._meta_.fields["quantity"] = {};
BundleItem._meta_.fields["quantity"].type = Number;
BundleItem._meta_.fields["version"] = {};
BundleItem._meta_.fields["version"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = BundleItem;
}

