/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var order_PrintState = require("../order/PrintState");
var base_Reference = require("../base/Reference");
var printer_PrintCategory = require("../printer/PrintCategory");

/**
* @constructor
* @memberof order
*/
var PrintOrder = function() {
  this._class_ = PrintOrder;
  this.id = undefined;
  this.merchantRef = undefined;
  this.orderRef = undefined;
  this.deviceRef = undefined;
  this.category = undefined;
  this.state = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
  this.deletedTime = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof order.PrintOrder
* @param {String} id 
*/
PrintOrder.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof order.PrintOrder
* @return {String} 
*/
PrintOrder.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof order.PrintOrder
* @param {base.Reference} merchantRef 
*/
PrintOrder.prototype.setMerchantRef = function(merchantRef) {
  this.merchantRef = merchantRef;
};

/**
* Get the field value
* @memberof order.PrintOrder
* @return {base.Reference} 
*/
PrintOrder.prototype.getMerchantRef = function() {
  return this.merchantRef;
};

/**
* Set the field value
* Reference to the order to be printed
*
* @memberof order.PrintOrder
* @param {base.Reference} orderRef 
*/
PrintOrder.prototype.setOrderRef = function(orderRef) {
  this.orderRef = orderRef;
};

/**
* Get the field value
* Reference to the order to be printed
* @memberof order.PrintOrder
* @return {base.Reference} 
*/
PrintOrder.prototype.getOrderRef = function() {
  return this.orderRef;
};

/**
* Set the field value
* The printing device. A 128-bit UUID, not a normal base-13 Clover ID.
*
* @memberof order.PrintOrder
* @param {base.Reference} deviceRef 
*/
PrintOrder.prototype.setDeviceRef = function(deviceRef) {
  this.deviceRef = deviceRef;
};

/**
* Get the field value
* The printing device. A 128-bit UUID, not a normal base-13 Clover ID.
* @memberof order.PrintOrder
* @return {base.Reference} 
*/
PrintOrder.prototype.getDeviceRef = function() {
  return this.deviceRef;
};

/**
* Set the field value
* The print category
*
* @memberof order.PrintOrder
* @param {printer.PrintCategory} category 
*/
PrintOrder.prototype.setCategory = function(category) {
  this.category = category;
};

/**
* Get the field value
* The print category
* @memberof order.PrintOrder
* @return {printer.PrintCategory} 
*/
PrintOrder.prototype.getCategory = function() {
  return this.category;
};

/**
* Set the field value
* print event state
*
* @memberof order.PrintOrder
* @param {Null|order.PrintState} state 
*/
PrintOrder.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* print event state
* @memberof order.PrintOrder
* @return {Null|order.PrintState} 
*/
PrintOrder.prototype.getState = function() {
  return this.state;
};

/**
* Set the field value
* Timestamp when the print event was created
*
* @memberof order.PrintOrder
* @param {Number} createdTime must be a long integer
*/
PrintOrder.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Timestamp when the print event was created
* @memberof order.PrintOrder
* @return {Number} must be a long integer
*/
PrintOrder.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* Timestamp when the print event was last modified
*
* @memberof order.PrintOrder
* @param {Number} modifiedTime must be a long integer
*/
PrintOrder.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* Timestamp when the print event was last modified
* @memberof order.PrintOrder
* @return {Number} must be a long integer
*/
PrintOrder.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* Timestamp when the print event was last deleted
*
* @memberof order.PrintOrder
* @param {Number} deletedTime must be a long integer
*/
PrintOrder.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* Timestamp when the print event was last deleted
* @memberof order.PrintOrder
* @return {Number} must be a long integer
*/
PrintOrder.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* @memberof order.PrintOrder
* @private
*/
PrintOrder.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PrintOrder.prototype.toString = function() {
  return JSON.stringify(this);
};

PrintOrder._meta_ =  {fields:  {}};
PrintOrder._meta_._class_ =  PrintOrder;
PrintOrder._meta_.fields["id"] = {};
PrintOrder._meta_.fields["id"].type = String;
PrintOrder._meta_.fields["merchantRef"] = {};
PrintOrder._meta_.fields["merchantRef"].type = base_Reference;
PrintOrder._meta_.fields["orderRef"] = {};
PrintOrder._meta_.fields["orderRef"].type = base_Reference;
PrintOrder._meta_.fields["deviceRef"] = {};
PrintOrder._meta_.fields["deviceRef"].type = base_Reference;
PrintOrder._meta_.fields["category"] = {};
PrintOrder._meta_.fields["category"].type = printer_PrintCategory;
PrintOrder._meta_.fields["state"] = {};
PrintOrder._meta_.fields["state"].type = order_PrintState;
PrintOrder._meta_.fields["createdTime"] = {};
PrintOrder._meta_.fields["createdTime"].type = Number;
PrintOrder._meta_.fields["modifiedTime"] = {};
PrintOrder._meta_.fields["modifiedTime"].type = Number;
PrintOrder._meta_.fields["deletedTime"] = {};
PrintOrder._meta_.fields["deletedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintOrder;
}

