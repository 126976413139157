/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof payments
*/
var BatchTotalType = function() {
  this._class_ = BatchTotalType;
  this.count = undefined;
  this.total = undefined;
};


/**
* Set the field value
* Total count of types
*
* @memberof payments.BatchTotalType
* @param {Number} count must be a long integer
*/
BatchTotalType.prototype.setCount = function(count) {
  this.count = count;
};

/**
* Get the field value
* Total count of types
* @memberof payments.BatchTotalType
* @return {Number} must be a long integer
*/
BatchTotalType.prototype.getCount = function() {
  return this.count;
};

/**
* Set the field value
* Total amount for type
*
* @memberof payments.BatchTotalType
* @param {Number} total must be a long integer
*/
BatchTotalType.prototype.setTotal = function(total) {
  this.total = total;
};

/**
* Get the field value
* Total amount for type
* @memberof payments.BatchTotalType
* @return {Number} must be a long integer
*/
BatchTotalType.prototype.getTotal = function() {
  return this.total;
};

/**
* @memberof payments.BatchTotalType
* @private
*/
BatchTotalType.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

BatchTotalType.prototype.toString = function() {
  return JSON.stringify(this);
};

BatchTotalType._meta_ =  {fields:  {}};
BatchTotalType._meta_._class_ =  BatchTotalType;
BatchTotalType._meta_.fields["count"] = {};
BatchTotalType._meta_.fields["count"].type = Number;
BatchTotalType._meta_.fields["total"] = {};
BatchTotalType._meta_.fields["total"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = BatchTotalType;
}

