/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var VasMode = {
  PAY_ONLY : "PAY_ONLY",
  VAS_ONLY : "VAS_ONLY",
  VAS_OR_PAYMENT : "VAS_OR_PAYMENT",
  VAS_AND_PAYMENT : "VAS_AND_PAYMENT"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasMode;
}

