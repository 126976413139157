/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/** Request to get the status of a print job. */
/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var PrintJobStatusRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = PrintJobStatusRequestMessage;
  this.setMethod(remotemessage_Method["PRINT_JOB_STATUS_REQUEST"]);
  this.externalPrintJobId = undefined;
};

PrintJobStatusRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
PrintJobStatusRequestMessage.prototype.constructor = PrintJobStatusRequestMessage;

/**
* Set the field value
* External print job ID to retrieve status for
*
* @memberof remotemessage.PrintJobStatusRequestMessage
* @param {String} externalPrintJobId 
*/
PrintJobStatusRequestMessage.prototype.setExternalPrintJobId = function(externalPrintJobId) {
  this.externalPrintJobId = externalPrintJobId;
};

/**
* Get the field value
* External print job ID to retrieve status for
* @memberof remotemessage.PrintJobStatusRequestMessage
* @return {String} 
*/
PrintJobStatusRequestMessage.prototype.getExternalPrintJobId = function() {
  return this.externalPrintJobId;
};

PrintJobStatusRequestMessage._meta_ =  {fields:  {}};
PrintJobStatusRequestMessage._meta_._class_ =  PrintJobStatusRequestMessage;
PrintJobStatusRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
PrintJobStatusRequestMessage._meta_.fields["externalPrintJobId"] = {};
PrintJobStatusRequestMessage._meta_.fields["externalPrintJobId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintJobStatusRequestMessage;
}

