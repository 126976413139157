import { StyleSheet, Platform } from "react-native";

export const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10, 
    marginBottom: 15, 
    flexDirection: "row"
  },
  exitButton: {
    justifyContent: "center", 
    marginBottom: 5, 
    marginRight: Platform.OS === "web" ? 15 : 25, 
  },
  appleButtonContainer: {
    width: 350,
    height: 68,
    marginBottom: 20,
    alignSelf: 'center',
  },
  legendText: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 18,
    fontWeight: "700",
    color: "#001639"
  },
  titleDivider: {
    borderBottomWidth: 3
  },
  termsAndConditions: {
    alignSelf: "center",
    textAlign: "center",
    color: "#89909b",
  },
  termsAndConditionsText: {
    color: "#89909b",
    fontSize: 16,
    fontWeight: "400",
  },
});
