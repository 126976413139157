/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var remotemessage_LogLevelEnum = require("../remotemessage/LogLevelEnum");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var LogMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = LogMessage;
  this.setMethod(remotemessage_Method["LOG_MESSAGE"]);
  this.logLevel = undefined;
  this.messages = undefined;
};

LogMessage.prototype = Object.create(remotemessage_Message.prototype);
LogMessage.prototype.constructor = LogMessage;

/**
* Set the field value
* @memberof remotemessage.LogMessage
* @param {remotemessage.LogLevelEnum} logLevel 
*/
LogMessage.prototype.setLogLevel = function(logLevel) {
  this.logLevel = logLevel;
};

/**
* Get the field value
* @memberof remotemessage.LogMessage
* @return {remotemessage.LogLevelEnum} 
*/
LogMessage.prototype.getLogLevel = function() {
  return this.logLevel;
};

/**
* Set the field value
* @memberof remotemessage.LogMessage
* @param {Object.<String,String>} messages A map of <String> to <>
*/
LogMessage.prototype.setMessages = function(messages) {
  this.messages = messages;
};

/**
* Get the field value
* @memberof remotemessage.LogMessage
* @return {Object.<String,String>} A map of <String> to <>
*/
LogMessage.prototype.getMessages = function() {
  return this.messages;
};

LogMessage._meta_ =  {fields:  {}};
LogMessage._meta_._class_ =  LogMessage;
LogMessage._meta_._superMeta_ = remotemessage_Message._meta_;
LogMessage._meta_.fields["logLevel"] = {};
LogMessage._meta_.fields["logLevel"].type = remotemessage_LogLevelEnum;
LogMessage._meta_.fields["messages"] = {};
LogMessage._meta_.fields["messages"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = LogMessage;
}

