/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/** Increments a previously made pre auth */
/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var IncrementPreAuthRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = IncrementPreAuthRequest;
  this.amount = undefined;
  this.paymentId = undefined;
};

IncrementPreAuthRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
IncrementPreAuthRequest.prototype.constructor = IncrementPreAuthRequest;

/**
* Set the field value
* The amount by which to increment the pre-auth.
*
* @memberof remotepay.IncrementPreAuthRequest
* @param {Number} amount must be a long integer
*/
IncrementPreAuthRequest.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* The amount by which to increment the pre-auth.
* @memberof remotepay.IncrementPreAuthRequest
* @return {Number} must be a long integer
*/
IncrementPreAuthRequest.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* The preauth to be incremented. This id should be pulled from the Payment.paymentId field in the PreAuthResponse.
*
* @memberof remotepay.IncrementPreAuthRequest
* @param {String} paymentId 
*/
IncrementPreAuthRequest.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* The preauth to be incremented. This id should be pulled from the Payment.paymentId field in the PreAuthResponse.
* @memberof remotepay.IncrementPreAuthRequest
* @return {String} 
*/
IncrementPreAuthRequest.prototype.getPaymentId = function() {
  return this.paymentId;
};

IncrementPreAuthRequest._meta_ =  {fields:  {}};
IncrementPreAuthRequest._meta_._class_ =  IncrementPreAuthRequest;
IncrementPreAuthRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
IncrementPreAuthRequest._meta_.fields["amount"] = {};
IncrementPreAuthRequest._meta_.fields["amount"].type = Number;
IncrementPreAuthRequest._meta_.fields["paymentId"] = {};
IncrementPreAuthRequest._meta_.fields["paymentId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = IncrementPreAuthRequest;
}

