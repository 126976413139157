/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_CardType = require("../payments/CardType");
var pay_TransactionData = require("../pay/TransactionData");
var payments_CardEntryType = require("../payments/CardEntryType");
var pay_CardFunction = require("../pay/CardFunction");

/**
* @constructor
* @memberof pay
*/
var PaymentRequestCardDetails = function() {
  this._class_ = PaymentRequestCardDetails;
  this.track1 = undefined;
  this.track2 = undefined;
  this.track3 = undefined;
  this.encrypted = undefined;
  this.maskedTrack1 = undefined;
  this.maskedTrack2 = undefined;
  this.maskedTrack3 = undefined;
  this.uniqueToken = undefined;
  this.pan = undefined;
  this.firstName = undefined;
  this.lastName = undefined;
  this.countryCode = undefined;
  this.exp = undefined;
  this.streetAddress = undefined;
  this.zip = undefined;
  this.cvv = undefined;
  this.last4 = undefined;
  this.first4 = undefined;
  this.dukptSerial = undefined;
  this.swipeStatus = undefined;
  this.fingerprint = undefined;
  this.deviceSerial = undefined;
  this.manualEntered = undefined;
  this.async = undefined;
  this.isFallback = undefined;
  this.isAuth = undefined;
  this.isPrepaid = undefined;
  this.cardFunction = undefined;
  this.qrCode = undefined;
  this.cardType = undefined;
  this.entryType = undefined;
  this.transactionNo = undefined;
  this.cardholderName = undefined;
  this.plainCardData = undefined;
  this.transactionData = undefined;
};


/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} track1 
*/
PaymentRequestCardDetails.prototype.setTrack1 = function(track1) {
  this.track1 = track1;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getTrack1 = function() {
  return this.track1;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} track2 
*/
PaymentRequestCardDetails.prototype.setTrack2 = function(track2) {
  this.track2 = track2;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getTrack2 = function() {
  return this.track2;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} track3 
*/
PaymentRequestCardDetails.prototype.setTrack3 = function(track3) {
  this.track3 = track3;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getTrack3 = function() {
  return this.track3;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {Boolean} encrypted 
*/
PaymentRequestCardDetails.prototype.setEncrypted = function(encrypted) {
  this.encrypted = encrypted;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {Boolean} 
*/
PaymentRequestCardDetails.prototype.getEncrypted = function() {
  return this.encrypted;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} maskedTrack1 
*/
PaymentRequestCardDetails.prototype.setMaskedTrack1 = function(maskedTrack1) {
  this.maskedTrack1 = maskedTrack1;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getMaskedTrack1 = function() {
  return this.maskedTrack1;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} maskedTrack2 
*/
PaymentRequestCardDetails.prototype.setMaskedTrack2 = function(maskedTrack2) {
  this.maskedTrack2 = maskedTrack2;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getMaskedTrack2 = function() {
  return this.maskedTrack2;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} maskedTrack3 
*/
PaymentRequestCardDetails.prototype.setMaskedTrack3 = function(maskedTrack3) {
  this.maskedTrack3 = maskedTrack3;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getMaskedTrack3 = function() {
  return this.maskedTrack3;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} uniqueToken 
*/
PaymentRequestCardDetails.prototype.setUniqueToken = function(uniqueToken) {
  this.uniqueToken = uniqueToken;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getUniqueToken = function() {
  return this.uniqueToken;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} pan 
*/
PaymentRequestCardDetails.prototype.setPan = function(pan) {
  this.pan = pan;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getPan = function() {
  return this.pan;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} firstName 
*/
PaymentRequestCardDetails.prototype.setFirstName = function(firstName) {
  this.firstName = firstName;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getFirstName = function() {
  return this.firstName;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} lastName 
*/
PaymentRequestCardDetails.prototype.setLastName = function(lastName) {
  this.lastName = lastName;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getLastName = function() {
  return this.lastName;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} countryCode 
*/
PaymentRequestCardDetails.prototype.setCountryCode = function(countryCode) {
  this.countryCode = countryCode;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getCountryCode = function() {
  return this.countryCode;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} exp 
*/
PaymentRequestCardDetails.prototype.setExp = function(exp) {
  this.exp = exp;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getExp = function() {
  return this.exp;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} streetAddress 
*/
PaymentRequestCardDetails.prototype.setStreetAddress = function(streetAddress) {
  this.streetAddress = streetAddress;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getStreetAddress = function() {
  return this.streetAddress;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} zip 
*/
PaymentRequestCardDetails.prototype.setZip = function(zip) {
  this.zip = zip;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getZip = function() {
  return this.zip;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} cvv 
*/
PaymentRequestCardDetails.prototype.setCvv = function(cvv) {
  this.cvv = cvv;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getCvv = function() {
  return this.cvv;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} last4 
*/
PaymentRequestCardDetails.prototype.setLast4 = function(last4) {
  this.last4 = last4;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getLast4 = function() {
  return this.last4;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} first4 
*/
PaymentRequestCardDetails.prototype.setFirst4 = function(first4) {
  this.first4 = first4;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getFirst4 = function() {
  return this.first4;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} dukptSerial 
*/
PaymentRequestCardDetails.prototype.setDukptSerial = function(dukptSerial) {
  this.dukptSerial = dukptSerial;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getDukptSerial = function() {
  return this.dukptSerial;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} swipeStatus 
*/
PaymentRequestCardDetails.prototype.setSwipeStatus = function(swipeStatus) {
  this.swipeStatus = swipeStatus;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getSwipeStatus = function() {
  return this.swipeStatus;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} fingerprint 
*/
PaymentRequestCardDetails.prototype.setFingerprint = function(fingerprint) {
  this.fingerprint = fingerprint;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getFingerprint = function() {
  return this.fingerprint;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} deviceSerial 
*/
PaymentRequestCardDetails.prototype.setDeviceSerial = function(deviceSerial) {
  this.deviceSerial = deviceSerial;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getDeviceSerial = function() {
  return this.deviceSerial;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {Boolean} manualEntered 
*/
PaymentRequestCardDetails.prototype.setManualEntered = function(manualEntered) {
  this.manualEntered = manualEntered;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {Boolean} 
*/
PaymentRequestCardDetails.prototype.getManualEntered = function() {
  return this.manualEntered;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {Boolean} async 
*/
PaymentRequestCardDetails.prototype.setAsync = function(async) {
  this.async = async;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {Boolean} 
*/
PaymentRequestCardDetails.prototype.getAsync = function() {
  return this.async;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {Boolean} isFallback 
*/
PaymentRequestCardDetails.prototype.setIsFallback = function(isFallback) {
  this.isFallback = isFallback;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {Boolean} 
*/
PaymentRequestCardDetails.prototype.getIsFallback = function() {
  return this.isFallback;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {Boolean} isAuth 
*/
PaymentRequestCardDetails.prototype.setIsAuth = function(isAuth) {
  this.isAuth = isAuth;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {Boolean} 
*/
PaymentRequestCardDetails.prototype.getIsAuth = function() {
  return this.isAuth;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {Boolean} isPrepaid 
*/
PaymentRequestCardDetails.prototype.setIsPrepaid = function(isPrepaid) {
  this.isPrepaid = isPrepaid;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {Boolean} 
*/
PaymentRequestCardDetails.prototype.getIsPrepaid = function() {
  return this.isPrepaid;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {pay.CardFunction} cardFunction 
*/
PaymentRequestCardDetails.prototype.setCardFunction = function(cardFunction) {
  this.cardFunction = cardFunction;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {pay.CardFunction} 
*/
PaymentRequestCardDetails.prototype.getCardFunction = function() {
  return this.cardFunction;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {Boolean} qrCode 
*/
PaymentRequestCardDetails.prototype.setQrCode = function(qrCode) {
  this.qrCode = qrCode;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {Boolean} 
*/
PaymentRequestCardDetails.prototype.getQrCode = function() {
  return this.qrCode;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {payments.CardType} cardType 
*/
PaymentRequestCardDetails.prototype.setCardType = function(cardType) {
  this.cardType = cardType;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {payments.CardType} 
*/
PaymentRequestCardDetails.prototype.getCardType = function() {
  return this.cardType;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {payments.CardEntryType} entryType 
*/
PaymentRequestCardDetails.prototype.setEntryType = function(entryType) {
  this.entryType = entryType;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {payments.CardEntryType} 
*/
PaymentRequestCardDetails.prototype.getEntryType = function() {
  return this.entryType;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} transactionNo 
*/
PaymentRequestCardDetails.prototype.setTransactionNo = function(transactionNo) {
  this.transactionNo = transactionNo;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getTransactionNo = function() {
  return this.transactionNo;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} cardholderName 
*/
PaymentRequestCardDetails.prototype.setCardholderName = function(cardholderName) {
  this.cardholderName = cardholderName;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getCardholderName = function() {
  return this.cardholderName;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {String} plainCardData 
*/
PaymentRequestCardDetails.prototype.setPlainCardData = function(plainCardData) {
  this.plainCardData = plainCardData;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {String} 
*/
PaymentRequestCardDetails.prototype.getPlainCardData = function() {
  return this.plainCardData;
};

/**
* Set the field value
* @memberof pay.PaymentRequestCardDetails
* @param {pay.TransactionData} transactionData 
*/
PaymentRequestCardDetails.prototype.setTransactionData = function(transactionData) {
  this.transactionData = transactionData;
};

/**
* Get the field value
* @memberof pay.PaymentRequestCardDetails
* @return {pay.TransactionData} 
*/
PaymentRequestCardDetails.prototype.getTransactionData = function() {
  return this.transactionData;
};

/**
* @memberof pay.PaymentRequestCardDetails
* @private
*/
PaymentRequestCardDetails.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PaymentRequestCardDetails.prototype.toString = function() {
  return JSON.stringify(this);
};

PaymentRequestCardDetails._meta_ =  {fields:  {}};
PaymentRequestCardDetails._meta_._class_ =  PaymentRequestCardDetails;
PaymentRequestCardDetails._meta_.fields["track1"] = {};
PaymentRequestCardDetails._meta_.fields["track1"].type = String;
PaymentRequestCardDetails._meta_.fields["track2"] = {};
PaymentRequestCardDetails._meta_.fields["track2"].type = String;
PaymentRequestCardDetails._meta_.fields["track3"] = {};
PaymentRequestCardDetails._meta_.fields["track3"].type = String;
PaymentRequestCardDetails._meta_.fields["encrypted"] = {};
PaymentRequestCardDetails._meta_.fields["encrypted"].type = Boolean;
PaymentRequestCardDetails._meta_.fields["maskedTrack1"] = {};
PaymentRequestCardDetails._meta_.fields["maskedTrack1"].type = String;
PaymentRequestCardDetails._meta_.fields["maskedTrack2"] = {};
PaymentRequestCardDetails._meta_.fields["maskedTrack2"].type = String;
PaymentRequestCardDetails._meta_.fields["maskedTrack3"] = {};
PaymentRequestCardDetails._meta_.fields["maskedTrack3"].type = String;
PaymentRequestCardDetails._meta_.fields["uniqueToken"] = {};
PaymentRequestCardDetails._meta_.fields["uniqueToken"].type = String;
PaymentRequestCardDetails._meta_.fields["pan"] = {};
PaymentRequestCardDetails._meta_.fields["pan"].type = String;
PaymentRequestCardDetails._meta_.fields["firstName"] = {};
PaymentRequestCardDetails._meta_.fields["firstName"].type = String;
PaymentRequestCardDetails._meta_.fields["lastName"] = {};
PaymentRequestCardDetails._meta_.fields["lastName"].type = String;
PaymentRequestCardDetails._meta_.fields["countryCode"] = {};
PaymentRequestCardDetails._meta_.fields["countryCode"].type = String;
PaymentRequestCardDetails._meta_.fields["exp"] = {};
PaymentRequestCardDetails._meta_.fields["exp"].type = String;
PaymentRequestCardDetails._meta_.fields["streetAddress"] = {};
PaymentRequestCardDetails._meta_.fields["streetAddress"].type = String;
PaymentRequestCardDetails._meta_.fields["zip"] = {};
PaymentRequestCardDetails._meta_.fields["zip"].type = String;
PaymentRequestCardDetails._meta_.fields["cvv"] = {};
PaymentRequestCardDetails._meta_.fields["cvv"].type = String;
PaymentRequestCardDetails._meta_.fields["last4"] = {};
PaymentRequestCardDetails._meta_.fields["last4"].type = String;
PaymentRequestCardDetails._meta_.fields["first4"] = {};
PaymentRequestCardDetails._meta_.fields["first4"].type = String;
PaymentRequestCardDetails._meta_.fields["dukptSerial"] = {};
PaymentRequestCardDetails._meta_.fields["dukptSerial"].type = String;
PaymentRequestCardDetails._meta_.fields["swipeStatus"] = {};
PaymentRequestCardDetails._meta_.fields["swipeStatus"].type = String;
PaymentRequestCardDetails._meta_.fields["fingerprint"] = {};
PaymentRequestCardDetails._meta_.fields["fingerprint"].type = String;
PaymentRequestCardDetails._meta_.fields["deviceSerial"] = {};
PaymentRequestCardDetails._meta_.fields["deviceSerial"].type = String;
PaymentRequestCardDetails._meta_.fields["manualEntered"] = {};
PaymentRequestCardDetails._meta_.fields["manualEntered"].type = Boolean;
PaymentRequestCardDetails._meta_.fields["async"] = {};
PaymentRequestCardDetails._meta_.fields["async"].type = Boolean;
PaymentRequestCardDetails._meta_.fields["isFallback"] = {};
PaymentRequestCardDetails._meta_.fields["isFallback"].type = Boolean;
PaymentRequestCardDetails._meta_.fields["isAuth"] = {};
PaymentRequestCardDetails._meta_.fields["isAuth"].type = Boolean;
PaymentRequestCardDetails._meta_.fields["isPrepaid"] = {};
PaymentRequestCardDetails._meta_.fields["isPrepaid"].type = Boolean;
PaymentRequestCardDetails._meta_.fields["cardFunction"] = {};
PaymentRequestCardDetails._meta_.fields["cardFunction"].type = pay_CardFunction;
PaymentRequestCardDetails._meta_.fields["qrCode"] = {};
PaymentRequestCardDetails._meta_.fields["qrCode"].type = Boolean;
PaymentRequestCardDetails._meta_.fields["cardType"] = {};
PaymentRequestCardDetails._meta_.fields["cardType"].type = payments_CardType;
PaymentRequestCardDetails._meta_.fields["entryType"] = {};
PaymentRequestCardDetails._meta_.fields["entryType"].type = payments_CardEntryType;
PaymentRequestCardDetails._meta_.fields["transactionNo"] = {};
PaymentRequestCardDetails._meta_.fields["transactionNo"].type = String;
PaymentRequestCardDetails._meta_.fields["cardholderName"] = {};
PaymentRequestCardDetails._meta_.fields["cardholderName"].type = String;
PaymentRequestCardDetails._meta_.fields["plainCardData"] = {};
PaymentRequestCardDetails._meta_.fields["plainCardData"].type = String;
PaymentRequestCardDetails._meta_.fields["transactionData"] = {};
PaymentRequestCardDetails._meta_.fields["transactionData"].type = pay_TransactionData;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PaymentRequestCardDetails;
}

