/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof inventory
*/
var Tag = function() {
  this._class_ = Tag;
  this.id = undefined;
  this.name = "";
  this.showInReporting = false;
  this.items = undefined;
  this.printers = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof inventory.Tag
* @param {String} id 
*/
Tag.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof inventory.Tag
* @return {String} 
*/
Tag.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Tag name
*
* @memberof inventory.Tag
* @param {String|Null} name 
*/
Tag.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Tag name
* @memberof inventory.Tag
* @return {String|Null} 
*/
Tag.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* True if tag is a summary label in the reporting app
*
* @memberof inventory.Tag
* @param {Boolean|Null} showInReporting 
*/
Tag.prototype.setShowInReporting = function(showInReporting) {
  this.showInReporting = showInReporting;
};

/**
* Get the field value
* True if tag is a summary label in the reporting app
* @memberof inventory.Tag
* @return {Boolean|Null} 
*/
Tag.prototype.getShowInReporting = function() {
  return this.showInReporting;
};

/**
* Set the field value
* Items associated with this tag
*
* @memberof inventory.Tag
* @param {Null|Array.<base.Reference>} items An array of 
*/
Tag.prototype.setItems = function(items) {
  this.items = items;
};

/**
* Get the field value
* Items associated with this tag
* @memberof inventory.Tag
* @return {Null|Array.<base.Reference>} An array of 
*/
Tag.prototype.getItems = function() {
  return this.items;
};

/**
* Set the field value
* Printers associated with this tag
*
* @memberof inventory.Tag
* @param {Null|Array.<base.Reference>} printers An array of 
*/
Tag.prototype.setPrinters = function(printers) {
  this.printers = printers;
};

/**
* Get the field value
* Printers associated with this tag
* @memberof inventory.Tag
* @return {Null|Array.<base.Reference>} An array of 
*/
Tag.prototype.getPrinters = function() {
  return this.printers;
};

/**
* @memberof inventory.Tag
* @private
*/
Tag.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Tag.prototype.toString = function() {
  return JSON.stringify(this);
};

Tag._meta_ =  {fields:  {}};
Tag._meta_._class_ =  Tag;
Tag._meta_.fields["id"] = {};
Tag._meta_.fields["id"].type = String;
Tag._meta_.fields["name"] = {};
Tag._meta_.fields["name"].type = String;
Tag._meta_.fields["showInReporting"] = {};
Tag._meta_.fields["showInReporting"].type = Boolean;
Tag._meta_.fields["items"] = {};
Tag._meta_.fields["items"].type = Array;
Tag._meta_.fields["items"].elementType = base_Reference;
Tag._meta_.fields["printers"] = {};
Tag._meta_.fields["printers"].type = Array;
Tag._meta_.fields["printers"].elementType = base_Reference;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Tag;
}

