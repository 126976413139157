/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof customers
* @enum {string}
*/
var TokenType = {
  MULTIPAY : "MULTIPAY",
  FINANCIAL : "FINANCIAL",
  CTOKEN : "CTOKEN"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TokenType;
}

