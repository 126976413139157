/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var AcknowledgementMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = AcknowledgementMessage;
  this.setMethod(remotemessage_Method["ACK"]);
  this.sourceMethod = undefined;
};

AcknowledgementMessage.prototype = Object.create(remotemessage_Message.prototype);
AcknowledgementMessage.prototype.constructor = AcknowledgementMessage;

/**
* Set the field value
* The remote message method type that is being acknowledged
*
* @memberof remotemessage.AcknowledgementMessage
* @param {remotemessage.Method} sourceMethod 
*/
AcknowledgementMessage.prototype.setSourceMethod = function(sourceMethod) {
  this.sourceMethod = sourceMethod;
};

/**
* Get the field value
* The remote message method type that is being acknowledged
* @memberof remotemessage.AcknowledgementMessage
* @return {remotemessage.Method} 
*/
AcknowledgementMessage.prototype.getSourceMethod = function() {
  return this.sourceMethod;
};

AcknowledgementMessage._meta_ =  {fields:  {}};
AcknowledgementMessage._meta_._class_ =  AcknowledgementMessage;
AcknowledgementMessage._meta_._superMeta_ = remotemessage_Message._meta_;
AcknowledgementMessage._meta_.fields["sourceMethod"] = {};
AcknowledgementMessage._meta_.fields["sourceMethod"].type = remotemessage_Method;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AcknowledgementMessage;
}

