/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof remotepay
*/
var DataProviderConfig = function() {
  this._class_ = DataProviderConfig;
  this.type = undefined;
  this.configuration = undefined;
};


/**
* Set the field value
* The string type of the loyalty data of interest.
*
* @memberof remotepay.DataProviderConfig
* @param {String} type 
*/
DataProviderConfig.prototype.setType = function(type) {
  this.type = type;
};

/**
* Get the field value
* The string type of the loyalty data of interest.
* @memberof remotepay.DataProviderConfig
* @return {String} 
*/
DataProviderConfig.prototype.getType = function() {
  return this.type;
};

/**
* Set the field value
* Configuration for the data type.
*
* @memberof remotepay.DataProviderConfig
* @param {Object.<String,String>} configuration A map of <String> to <>
*/
DataProviderConfig.prototype.setConfiguration = function(configuration) {
  this.configuration = configuration;
};

/**
* Get the field value
* Configuration for the data type.
* @memberof remotepay.DataProviderConfig
* @return {Object.<String,String>} A map of <String> to <>
*/
DataProviderConfig.prototype.getConfiguration = function() {
  return this.configuration;
};

/**
* @memberof remotepay.DataProviderConfig
* @private
*/
DataProviderConfig.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DataProviderConfig.prototype.toString = function() {
  return JSON.stringify(this);
};

DataProviderConfig._meta_ =  {fields:  {}};
DataProviderConfig._meta_._class_ =  DataProviderConfig;
DataProviderConfig._meta_.fields["type"] = {};
DataProviderConfig._meta_.fields["type"].type = String;
DataProviderConfig._meta_.fields["configuration"] = {};
DataProviderConfig._meta_.fields["configuration"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DataProviderConfig;
}

