/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof order.operation
*/
var OrderDeletedOperation = function() {
  this._class_ = OrderDeletedOperation;
  this.id = undefined;
};


/**
* Set the field value
* @memberof order.operation.OrderDeletedOperation
* @param {String} id 
*/
OrderDeletedOperation.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* @memberof order.operation.OrderDeletedOperation
* @return {String} 
*/
OrderDeletedOperation.prototype.getId = function() {
  return this.id;
};

/**
* @memberof order.operation.OrderDeletedOperation
* @private
*/
OrderDeletedOperation.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

OrderDeletedOperation.prototype.toString = function() {
  return JSON.stringify(this);
};

OrderDeletedOperation._meta_ =  {fields:  {}};
OrderDeletedOperation._meta_._class_ =  OrderDeletedOperation;
OrderDeletedOperation._meta_.fields["id"] = {};
OrderDeletedOperation._meta_.fields["id"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OrderDeletedOperation;
}

