/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var inventory_Item = require("../inventory/Item");

/**
* @constructor
* @memberof inventory
*/
var InventoryItems = function() {
  this._class_ = InventoryItems;
  this.items = undefined;
};


/**
* Set the field value
* Array of inventory items
*
* @memberof inventory.InventoryItems
* @param {Array.<inventory.Item>} items An array of 
*/
InventoryItems.prototype.setItems = function(items) {
  this.items = items;
};

/**
* Get the field value
* Array of inventory items
* @memberof inventory.InventoryItems
* @return {Array.<inventory.Item>} An array of 
*/
InventoryItems.prototype.getItems = function() {
  return this.items;
};

/**
* @memberof inventory.InventoryItems
* @private
*/
InventoryItems.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

InventoryItems.prototype.toString = function() {
  return JSON.stringify(this);
};

InventoryItems._meta_ =  {fields:  {}};
InventoryItems._meta_._class_ =  InventoryItems;
InventoryItems._meta_.fields["items"] = {};
InventoryItems._meta_.fields["items"].type = Array;
InventoryItems._meta_.fields["items"].elementType = inventory_Item;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = InventoryItems;
}

