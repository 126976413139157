/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var VoidCreditMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = VoidCreditMessage;
  this.setMethod(remotemessage_Method["VOID_CREDIT"]);
  this.orderId = undefined;
  this.creditId = undefined;
  this.disableCloverPrinting = false;
  this.disableReceiptSelection = false;
};

VoidCreditMessage.prototype = Object.create(remotemessage_Message.prototype);
VoidCreditMessage.prototype.constructor = VoidCreditMessage;

/**
* Set the field value
* @memberof remotemessage.VoidCreditMessage
* @param {String} orderId 
*/
VoidCreditMessage.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditMessage
* @return {String} 
*/
VoidCreditMessage.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* @memberof remotemessage.VoidCreditMessage
* @param {String} creditId 
*/
VoidCreditMessage.prototype.setCreditId = function(creditId) {
  this.creditId = creditId;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditMessage
* @return {String} 
*/
VoidCreditMessage.prototype.getCreditId = function() {
  return this.creditId;
};

/**
* Set the field value
* @memberof remotemessage.VoidCreditMessage
* @param {Boolean} disableCloverPrinting 
*/
VoidCreditMessage.prototype.setDisableCloverPrinting = function(disableCloverPrinting) {
  this.disableCloverPrinting = disableCloverPrinting;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditMessage
* @return {Boolean} 
*/
VoidCreditMessage.prototype.getDisableCloverPrinting = function() {
  return this.disableCloverPrinting;
};

/**
* Set the field value
* @memberof remotemessage.VoidCreditMessage
* @param {Boolean} disableReceiptSelection 
*/
VoidCreditMessage.prototype.setDisableReceiptSelection = function(disableReceiptSelection) {
  this.disableReceiptSelection = disableReceiptSelection;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditMessage
* @return {Boolean} 
*/
VoidCreditMessage.prototype.getDisableReceiptSelection = function() {
  return this.disableReceiptSelection;
};

VoidCreditMessage._meta_ =  {fields:  {}};
VoidCreditMessage._meta_._class_ =  VoidCreditMessage;
VoidCreditMessage._meta_._superMeta_ = remotemessage_Message._meta_;
VoidCreditMessage._meta_.fields["orderId"] = {};
VoidCreditMessage._meta_.fields["orderId"].type = String;
VoidCreditMessage._meta_.fields["creditId"] = {};
VoidCreditMessage._meta_.fields["creditId"].type = String;
VoidCreditMessage._meta_.fields["disableCloverPrinting"] = {};
VoidCreditMessage._meta_.fields["disableCloverPrinting"].type = Boolean;
VoidCreditMessage._meta_.fields["disableReceiptSelection"] = {};
VoidCreditMessage._meta_.fields["disableReceiptSelection"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VoidCreditMessage;
}

