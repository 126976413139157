import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import AdvertisingBannerListProps from "../types/components/AdvertisingBannerListProps";
import AdvertisingBanner from "./AdvertisingBanner";
import { styles } from "../styles/components/advertisingBannerList";

export default ({
    title,
    colorTitle,
    items,
    onClickMore,
    seeMoreText
  }: AdvertisingBannerListProps): JSX.Element => {

    return (
      <>
        <View style={styles.headerContainer}>
          <Text style={[styles.title, {color: colorTitle}]}>{title}</Text>
        </View>
        {items.map((item) => (
          <AdvertisingBanner
            id={item.id}
            key={item.id}
            image={item.image}
            onPress={item.onPress}
            title={item.title}
            logo={item.logo}
            price={item.price}
            description={item.description}
            color={item.color}
            colorTitle={item.colorTitle}
            isForTotemDisplay={item.isForTotemDisplay}
          />
        ))}
        {onClickMore && (
          <View style={styles.footerContainer}>
            <TouchableOpacity onPress={onClickMore}>
              <Text style={styles.titleSeeMoreResults}>{seeMoreText}</Text>
            </TouchableOpacity>
          </View>
        )}
      </>
    );
  };