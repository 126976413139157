/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_VasDataTypeType = require("../payments/VasDataTypeType");

/** Service types supported by the vas provider. */
/**
* @constructor
* @memberof payments
*/
var VasDataType = function() {
  this._class_ = VasDataType;
  this.dataType = undefined;
};


/**
* Set the field value
* @memberof payments.VasDataType
* @param {payments.VasDataTypeType} dataType 
*/
VasDataType.prototype.setDataType = function(dataType) {
  this.dataType = dataType;
};

/**
* Get the field value
* @memberof payments.VasDataType
* @return {payments.VasDataTypeType} 
*/
VasDataType.prototype.getDataType = function() {
  return this.dataType;
};

/**
* @memberof payments.VasDataType
* @private
*/
VasDataType.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

VasDataType.prototype.toString = function() {
  return JSON.stringify(this);
};

VasDataType._meta_ =  {fields:  {}};
VasDataType._meta_._class_ =  VasDataType;
VasDataType._meta_.fields["dataType"] = {};
VasDataType._meta_.fields["dataType"].type = payments_VasDataTypeType;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasDataType;
}

