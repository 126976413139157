/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var CvmResult = {
  NO_CVM_REQUIRED : "NO_CVM_REQUIRED",
  SIGNATURE : "SIGNATURE",
  SIGNATURE_VERIFIED : "SIGNATURE_VERIFIED",
  SIGNATURE_ON_PAPER : "SIGNATURE_ON_PAPER",
  SIGNATURE_REJECTED : "SIGNATURE_REJECTED",
  PIN : "PIN",
  ONLINE_PIN : "ONLINE_PIN",
  SIGNATURE_AND_PIN : "SIGNATURE_AND_PIN",
  CVM_FAILED : "CVM_FAILED",
  DEVICE : "DEVICE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CvmResult;
}

