import React from "react";
import { Platform } from "react-native";
import axios from "axios";
import Constants from "expo-constants";
import * as WebBrowser from "expo-web-browser";
import * as Facebook from "expo-auth-session/providers/facebook";
//import * as FacebookSignIn from "expo-facebook";

WebBrowser.maybeCompleteAuthSession();

type FacebookAuthRequestConfig = Partial<Facebook.FacebookAuthRequestConfig>;

type FacebookUserData = {
  email: string;
  name: string;
};

const useFacebookAuth = (
  facebookAuthRequestConfig: FacebookAuthRequestConfig
) => {
  const [facebookSignInAuthResult, setFacebookSignInAuthResult] =
    React.useState<any>();
  const [facebookUserData, setFacebookUserData] =
    React.useState<FacebookUserData>();
  const [, response, promptAsync] = Facebook.useAuthRequest(
    facebookAuthRequestConfig
  );

  React.useEffect(() => {
    const getUserData = async () => {
      let accessToken;
      if (
        facebookSignInAuthResult &&
        facebookSignInAuthResult.type === "success" &&
        facebookSignInAuthResult.token
      ) {
        accessToken = facebookSignInAuthResult.token;
      } else if (
        response &&
        response.type === "success" &&
        response.authentication?.accessToken
      ) {
        setFacebookSignInAuthResult(response);
        accessToken = response.authentication.accessToken;
      }
      if (!accessToken) return;
      try {
        const { data } = await axios.get(
          `https://graph.facebook.com/me?fields=id,email,name&access_token=${accessToken}`
        );
        setFacebookUserData({
          email: data.email,
          name: data.name,
        });
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, [facebookSignInAuthResult, response]);

  const facebookPromptAsync = async () => {
    if (Platform.OS === "android" && Constants.appOwnership === "standalone") {
      setFacebookSignInAuthResult(null
        //await FacebookSignIn.logInWithReadPermissionsAsync()
      );
    } else {
      promptAsync();
    }
  };

  return { facebookUserData, facebookSignInAuthResult, facebookPromptAsync };
};

export {
  useFacebookAuth as default,
  FacebookAuthRequestConfig,
  FacebookUserData,
};
