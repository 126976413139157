/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var SignatureRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = SignatureRequestMessage;
  this.setMethod(remotemessage_Method["REQUEST_SIGNATURE"]);
  this.acknowledgementMessage = undefined;
};

SignatureRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
SignatureRequestMessage.prototype.constructor = SignatureRequestMessage;

/**
* Set the field value
* @memberof remotemessage.SignatureRequestMessage
* @param {String} acknowledgementMessage 
*/
SignatureRequestMessage.prototype.setAcknowledgementMessage = function(acknowledgementMessage) {
  this.acknowledgementMessage = acknowledgementMessage;
};

/**
* Get the field value
* @memberof remotemessage.SignatureRequestMessage
* @return {String} 
*/
SignatureRequestMessage.prototype.getAcknowledgementMessage = function() {
  return this.acknowledgementMessage;
};

SignatureRequestMessage._meta_ =  {fields:  {}};
SignatureRequestMessage._meta_._class_ =  SignatureRequestMessage;
SignatureRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
SignatureRequestMessage._meta_.fields["acknowledgementMessage"] = {};
SignatureRequestMessage._meta_.fields["acknowledgementMessage"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = SignatureRequestMessage;
}

