/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var inventory_TaxRate = require("../inventory/TaxRate");
var inventory_Item = require("../inventory/Item");

/**
* @constructor
* @memberof inventory
*/
var TaxRateItem = function() {
  this._class_ = TaxRateItem;
  this.taxRate = undefined;
  this.item = undefined;
};


/**
* Set the field value
* @memberof inventory.TaxRateItem
* @param {inventory.TaxRate} taxRate 
*/
TaxRateItem.prototype.setTaxRate = function(taxRate) {
  this.taxRate = taxRate;
};

/**
* Get the field value
* @memberof inventory.TaxRateItem
* @return {inventory.TaxRate} 
*/
TaxRateItem.prototype.getTaxRate = function() {
  return this.taxRate;
};

/**
* Set the field value
* @memberof inventory.TaxRateItem
* @param {inventory.Item} item 
*/
TaxRateItem.prototype.setItem = function(item) {
  this.item = item;
};

/**
* Get the field value
* @memberof inventory.TaxRateItem
* @return {inventory.Item} 
*/
TaxRateItem.prototype.getItem = function() {
  return this.item;
};

/**
* @memberof inventory.TaxRateItem
* @private
*/
TaxRateItem.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

TaxRateItem.prototype.toString = function() {
  return JSON.stringify(this);
};

TaxRateItem._meta_ =  {fields:  {}};
TaxRateItem._meta_._class_ =  TaxRateItem;
TaxRateItem._meta_.fields["taxRate"] = {};
TaxRateItem._meta_.fields["taxRate"].type = inventory_TaxRate;
TaxRateItem._meta_.fields["item"] = {};
TaxRateItem._meta_.fields["item"].type = inventory_Item;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TaxRateItem;
}

