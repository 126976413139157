/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof order
* @enum {string}
*/
var PaymentState = {
  OPEN : "OPEN",
  PAID : "PAID",
  REFUNDED : "REFUNDED",
  CREDITED : "CREDITED",
  PARTIALLY_PAID : "PARTIALLY_PAID",
  PARTIALLY_REFUNDED : "PARTIALLY_REFUNDED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PaymentState;
}

