/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var order_VoidReason = require("../order/VoidReason");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Payment = require("../payments/Payment");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var PaymentRejectedMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = PaymentRejectedMessage;
  this.setMethod(remotemessage_Method["PAYMENT_REJECTED"]);
  this.payment = undefined;
  this.reason = undefined;
};

PaymentRejectedMessage.prototype = Object.create(remotemessage_Message.prototype);
PaymentRejectedMessage.prototype.constructor = PaymentRejectedMessage;

/**
* Set the field value
* A payment
*
* @memberof remotemessage.PaymentRejectedMessage
* @param {payments.Payment} payment 
*/
PaymentRejectedMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* A payment
* @memberof remotemessage.PaymentRejectedMessage
* @return {payments.Payment} 
*/
PaymentRejectedMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* The reason the payment is being rejected
*
* @memberof remotemessage.PaymentRejectedMessage
* @param {order.VoidReason} reason 
*/
PaymentRejectedMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* The reason the payment is being rejected
* @memberof remotemessage.PaymentRejectedMessage
* @return {order.VoidReason} 
*/
PaymentRejectedMessage.prototype.getReason = function() {
  return this.reason;
};

PaymentRejectedMessage._meta_ =  {fields:  {}};
PaymentRejectedMessage._meta_._class_ =  PaymentRejectedMessage;
PaymentRejectedMessage._meta_._superMeta_ = remotemessage_Message._meta_;
PaymentRejectedMessage._meta_.fields["payment"] = {};
PaymentRejectedMessage._meta_.fields["payment"].type = payments_Payment;
PaymentRejectedMessage._meta_.fields["reason"] = {};
PaymentRejectedMessage._meta_.fields["reason"].type = order_VoidReason;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PaymentRejectedMessage;
}

