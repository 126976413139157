/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseResponse = require("../remotepay/BaseResponse");
var base_Signature = require("../base/Signature");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var SignatureResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = SignatureResponse;
  this.signature = undefined;
};

SignatureResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
SignatureResponse.prototype.constructor = SignatureResponse;

/**
* Set the field value
* @memberof remotepay.SignatureResponse
* @param {base.Signature} signature 
*/
SignatureResponse.prototype.setSignature = function(signature) {
  this.signature = signature;
};

/**
* Get the field value
* @memberof remotepay.SignatureResponse
* @return {base.Signature} 
*/
SignatureResponse.prototype.getSignature = function() {
  return this.signature;
};

SignatureResponse._meta_ =  {fields:  {}};
SignatureResponse._meta_._class_ =  SignatureResponse;
SignatureResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
SignatureResponse._meta_.fields["signature"] = {};
SignatureResponse._meta_.fields["signature"].type = base_Signature;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = SignatureResponse;
}

