/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_CardTransaction = require("../payments/CardTransaction");
var payments_Result = require("../payments/Result");
var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof payments
*/
var IncrementalAuthorization = function() {
  this._class_ = IncrementalAuthorization;
  this.id = undefined;
  this.merchantId = undefined;
  this.paymentId = undefined;
  this.gatewayTxId = undefined;
  this.amount = undefined;
  this.cardTransaction = undefined;
  this.result = undefined;
  this.createdTime = undefined;
  this.employee = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof payments.IncrementalAuthorization
* @param {String} id 
*/
IncrementalAuthorization.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof payments.IncrementalAuthorization
* @return {String} 
*/
IncrementalAuthorization.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof payments.IncrementalAuthorization
* @param {Number} merchantId must be a long integer
*/
IncrementalAuthorization.prototype.setMerchantId = function(merchantId) {
  this.merchantId = merchantId;
};

/**
* Get the field value
* @memberof payments.IncrementalAuthorization
* @return {Number} must be a long integer
*/
IncrementalAuthorization.prototype.getMerchantId = function() {
  return this.merchantId;
};

/**
* Set the field value
* @memberof payments.IncrementalAuthorization
* @param {Number} paymentId must be a long integer
*/
IncrementalAuthorization.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* @memberof payments.IncrementalAuthorization
* @return {Number} must be a long integer
*/
IncrementalAuthorization.prototype.getPaymentId = function() {
  return this.paymentId;
};

/**
* Set the field value
* @memberof payments.IncrementalAuthorization
* @param {Number} gatewayTxId must be a long integer
*/
IncrementalAuthorization.prototype.setGatewayTxId = function(gatewayTxId) {
  this.gatewayTxId = gatewayTxId;
};

/**
* Get the field value
* @memberof payments.IncrementalAuthorization
* @return {Number} must be a long integer
*/
IncrementalAuthorization.prototype.getGatewayTxId = function() {
  return this.gatewayTxId;
};

/**
* Set the field value
* Amount of the incremental authorization
*
* @memberof payments.IncrementalAuthorization
* @param {Number} amount must be a long integer
*/
IncrementalAuthorization.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Amount of the incremental authorization
* @memberof payments.IncrementalAuthorization
* @return {Number} must be a long integer
*/
IncrementalAuthorization.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* @memberof payments.IncrementalAuthorization
* @param {payments.CardTransaction} cardTransaction 
*/
IncrementalAuthorization.prototype.setCardTransaction = function(cardTransaction) {
  this.cardTransaction = cardTransaction;
};

/**
* Get the field value
* @memberof payments.IncrementalAuthorization
* @return {payments.CardTransaction} 
*/
IncrementalAuthorization.prototype.getCardTransaction = function() {
  return this.cardTransaction;
};

/**
* Set the field value
* Result of the incremental auth
*
* @memberof payments.IncrementalAuthorization
* @param {payments.Result} result 
*/
IncrementalAuthorization.prototype.setResult = function(result) {
  this.result = result;
};

/**
* Get the field value
* Result of the incremental auth
* @memberof payments.IncrementalAuthorization
* @return {payments.Result} 
*/
IncrementalAuthorization.prototype.getResult = function() {
  return this.result;
};

/**
* Set the field value
* Created time on the server
*
* @memberof payments.IncrementalAuthorization
* @param {Number} createdTime must be a long integer
*/
IncrementalAuthorization.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Created time on the server
* @memberof payments.IncrementalAuthorization
* @return {Number} must be a long integer
*/
IncrementalAuthorization.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* The employee who processed the incremental Auth
*
* @memberof payments.IncrementalAuthorization
* @param {base.Reference} employee 
*/
IncrementalAuthorization.prototype.setEmployee = function(employee) {
  this.employee = employee;
};

/**
* Get the field value
* The employee who processed the incremental Auth
* @memberof payments.IncrementalAuthorization
* @return {base.Reference} 
*/
IncrementalAuthorization.prototype.getEmployee = function() {
  return this.employee;
};

/**
* @memberof payments.IncrementalAuthorization
* @private
*/
IncrementalAuthorization.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

IncrementalAuthorization.prototype.toString = function() {
  return JSON.stringify(this);
};

IncrementalAuthorization._meta_ =  {fields:  {}};
IncrementalAuthorization._meta_._class_ =  IncrementalAuthorization;
IncrementalAuthorization._meta_.fields["id"] = {};
IncrementalAuthorization._meta_.fields["id"].type = String;
IncrementalAuthorization._meta_.fields["merchantId"] = {};
IncrementalAuthorization._meta_.fields["merchantId"].type = Number;
IncrementalAuthorization._meta_.fields["paymentId"] = {};
IncrementalAuthorization._meta_.fields["paymentId"].type = Number;
IncrementalAuthorization._meta_.fields["gatewayTxId"] = {};
IncrementalAuthorization._meta_.fields["gatewayTxId"].type = Number;
IncrementalAuthorization._meta_.fields["amount"] = {};
IncrementalAuthorization._meta_.fields["amount"].type = Number;
IncrementalAuthorization._meta_.fields["cardTransaction"] = {};
IncrementalAuthorization._meta_.fields["cardTransaction"].type = payments_CardTransaction;
IncrementalAuthorization._meta_.fields["result"] = {};
IncrementalAuthorization._meta_.fields["result"].type = payments_Result;
IncrementalAuthorization._meta_.fields["createdTime"] = {};
IncrementalAuthorization._meta_.fields["createdTime"].type = Number;
IncrementalAuthorization._meta_.fields["employee"] = {};
IncrementalAuthorization._meta_.fields["employee"].type = base_Reference;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = IncrementalAuthorization;
}

