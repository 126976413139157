/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var CheckBalanceRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = CheckBalanceRequest;
  this.cardEntryMethods = undefined;
};

CheckBalanceRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
CheckBalanceRequest.prototype.constructor = CheckBalanceRequest;

/**
* Set the field value
* @memberof remotepay.CheckBalanceRequest
* @param {Null|Number} cardEntryMethods must be an integer
*/
CheckBalanceRequest.prototype.setCardEntryMethods = function(cardEntryMethods) {
  this.cardEntryMethods = cardEntryMethods;
};

/**
* Get the field value
* @memberof remotepay.CheckBalanceRequest
* @return {Null|Number} must be an integer
*/
CheckBalanceRequest.prototype.getCardEntryMethods = function() {
  return this.cardEntryMethods;
};

CheckBalanceRequest._meta_ =  {fields:  {}};
CheckBalanceRequest._meta_._class_ =  CheckBalanceRequest;
CheckBalanceRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
CheckBalanceRequest._meta_.fields["cardEntryMethods"] = {};
CheckBalanceRequest._meta_.fields["cardEntryMethods"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CheckBalanceRequest;
}

