/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var DiscoveryRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = DiscoveryRequestMessage;
  this.setMethod(remotemessage_Method["DISCOVERY_REQUEST"]);
  this.supportsOrderModification = undefined;
};

DiscoveryRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
DiscoveryRequestMessage.prototype.constructor = DiscoveryRequestMessage;

/**
* Set the field value
* If true then order modification operations are supported.
*
* @memberof remotemessage.DiscoveryRequestMessage
* @param {Boolean|Null} supportsOrderModification 
*/
DiscoveryRequestMessage.prototype.setSupportsOrderModification = function(supportsOrderModification) {
  this.supportsOrderModification = supportsOrderModification;
};

/**
* Get the field value
* If true then order modification operations are supported.
* @memberof remotemessage.DiscoveryRequestMessage
* @return {Boolean|Null} 
*/
DiscoveryRequestMessage.prototype.getSupportsOrderModification = function() {
  return this.supportsOrderModification;
};

DiscoveryRequestMessage._meta_ =  {fields:  {}};
DiscoveryRequestMessage._meta_._class_ =  DiscoveryRequestMessage;
DiscoveryRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
DiscoveryRequestMessage._meta_.fields["supportsOrderModification"] = {};
DiscoveryRequestMessage._meta_.fields["supportsOrderModification"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DiscoveryRequestMessage;
}

