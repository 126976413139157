/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var BatchType = {
  MANUAL_CLOSE : "MANUAL_CLOSE",
  AUTO_CLOSE : "AUTO_CLOSE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = BatchType;
}

