/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof remotepay
*/
var VoidCreditRequest = function() {
  this._class_ = VoidCreditRequest;
  this.requestId = undefined;
  this.orderId = undefined;
  this.creditId = undefined;
  this.extras = undefined;
};


/**
* Set the field value
* Identifier for the request
*
* @memberof remotepay.VoidCreditRequest
* @param {String} requestId 
*/
VoidCreditRequest.prototype.setRequestId = function(requestId) {
  this.requestId = requestId;
};

/**
* Get the field value
* Identifier for the request
* @memberof remotepay.VoidCreditRequest
* @return {String} 
*/
VoidCreditRequest.prototype.getRequestId = function() {
  return this.requestId;
};

/**
* Set the field value
* Unique identifier
*
* @memberof remotepay.VoidCreditRequest
* @param {String} orderId 
*/
VoidCreditRequest.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier
* @memberof remotepay.VoidCreditRequest
* @return {String} 
*/
VoidCreditRequest.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* Unique identifier
*
* @memberof remotepay.VoidCreditRequest
* @param {String} creditId 
*/
VoidCreditRequest.prototype.setCreditId = function(creditId) {
  this.creditId = creditId;
};

/**
* Get the field value
* Unique identifier
* @memberof remotepay.VoidCreditRequest
* @return {String} 
*/
VoidCreditRequest.prototype.getCreditId = function() {
  return this.creditId;
};

/**
* Set the field value
* Extra pass-through data used by external systems.
*
* @memberof remotepay.VoidCreditRequest
* @param {Object.<String,String>} extras A map of <String> to <>
*/
VoidCreditRequest.prototype.setExtras = function(extras) {
  this.extras = extras;
};

/**
* Get the field value
* Extra pass-through data used by external systems.
* @memberof remotepay.VoidCreditRequest
* @return {Object.<String,String>} A map of <String> to <>
*/
VoidCreditRequest.prototype.getExtras = function() {
  return this.extras;
};

/**
* @memberof remotepay.VoidCreditRequest
* @private
*/
VoidCreditRequest.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

VoidCreditRequest.prototype.toString = function() {
  return JSON.stringify(this);
};

VoidCreditRequest._meta_ =  {fields:  {}};
VoidCreditRequest._meta_._class_ =  VoidCreditRequest;
VoidCreditRequest._meta_.fields["requestId"] = {};
VoidCreditRequest._meta_.fields["requestId"].type = String;
VoidCreditRequest._meta_.fields["orderId"] = {};
VoidCreditRequest._meta_.fields["orderId"].type = String;
VoidCreditRequest._meta_.fields["creditId"] = {};
VoidCreditRequest._meta_.fields["creditId"].type = String;
VoidCreditRequest._meta_.fields["extras"] = {};
VoidCreditRequest._meta_.fields["extras"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VoidCreditRequest;
}

