import React from "react";
import { Image, View, Text, TouchableOpacity, ImageBackground } from "react-native";
import AdvertisingBannerProps from "../types/components/AdvertisingBannerProps";
import { styles } from "../styles/components/advertisingBanner";

export default ({
  image,
  color,
  title,
  logo,
  colorTitle,
  price,
  description,
  onPress,
  customCarouselStyles,
  isForTotemDisplay
}: AdvertisingBannerProps): JSX.Element => {
  const dimensionsForTotem = { height: 161, width: 658 };
  const logoDimensionsForTotem = { height: 40, width: 38 };
  const textDescriptionSizeForTotem = { fontSize: 16, lineHeight: 20 };
  const priceSizeForTotem = { fontSize: 16 };
  const lowerBarHeightForTotem = { height: 36 };
  const internalLogoContainerStyleForTotem = { bottom: 30, marginBotton: 35, marginLeft: 20 };
  const clickableContentTotemMargins = { marginTop: 10, marginBottom: 5}
  const hasTitle = title && title.length > 0 ? true : false;
  const hasDescription = description && description.length > 0 ? true : false;
  const hasPrice = price && price > 0 ? true : false;
  const hasLogo = logo && logo.length > 0 ? true : false;

  const renderContent = (): JSX.Element => {
    return (
      <>
        <ImageBackground source={{ uri: image }} style={[styles.imageBackgroundDimensions, hasTitle && { height: 100 }, isForTotemDisplay && dimensionsForTotem]} resizeMode="stretch">
          <View style={styles.internalSectionContainer}>
            {hasLogo ? (
              <View style={[styles.internalLogoContainer, isForTotemDisplay && internalLogoContainerStyleForTotem]}>
                <Image source={{ uri: logo }} style={[styles.logo, isForTotemDisplay && logoDimensionsForTotem]} />
              </View>
            ) : null}
          </View>
        </ImageBackground>
        <View style={[styles.lowerDataBar, isForTotemDisplay && lowerBarHeightForTotem]}>
              <View style={styles.lowerDataBarTextContainer}>
                {hasTitle ? (
                  <Text style={[styles.bannerTitle, isForTotemDisplay && {
                    fontSize: 16,
                    fontWeight: "500",
                    lineHeight: 20,
                    letterSpacing: 0.25,
                  }]} numberOfLines={1}>{title}</Text>
                ) : null}
                {hasDescription ? (
                   <Text style={[styles.bannerDescription, !hasTitle && { marginTop: !hasPrice && description.length <= 49 ? 10 : !hasPrice && description.length >= 50 ? 2 : 5 } , isForTotemDisplay && textDescriptionSizeForTotem]} numberOfLines={2} ellipsizeMode="tail">{description}</Text>
                ) : null} 
              </View>
              {hasPrice ? (
                <Text style={[styles.bannerPrice, !hasTitle && { marginTop: 10} , {color: colorTitle}, isForTotemDisplay && {...priceSizeForTotem, fontWeight: "700", lineHeight: 20, letterSpacing: 0.25, alignSelf: "center", marginTop: 7, marginBottom: 5}]}>{`$${price}`}</Text>
              ) : null}
            </View>
      </>
    );
  };

  const clickableContent = (): JSX.Element => {
    return (
      <TouchableOpacity onPress={onPress}>
        <View style={[styles.clikableContainer, isForTotemDisplay && clickableContentTotemMargins]}>{renderContent()}</View>
      </TouchableOpacity>
    );
  };

  const nonClikableContent = (): JSX.Element => {
    return <View style={[styles.container, 
      customCarouselStyles && {
        marginTop: customCarouselStyles.marginTop,
        height: customCarouselStyles.height,
        width: customCarouselStyles.width,
      }]}>{renderContent()}</View>;
  };

  if (onPress) return clickableContent();
  return nonClikableContent();
};
