import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor: "#F3F3F3",
    borderRadius: 12,
    borderColor: "#89909b"
  },
  cardUpSide: {
    alignSelf: "center",
  },
  cardDownSide: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  cardDownSideOneOperation: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    flex:1,
    marginTop: 10,
    textAlign: "left",
    fontSize: 18,
    fontWeight: "500"
  },
  operationButtonText: {
    color: "#FFF",
    fontSize: 12,
    fontWeight: "500"
  },
  operationButton: {
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 6,
    marginTop: 15,
    marginBottom: 5,
    marginHorizontal: "1%",
    alignSelf: "flex-start",
    textAlign: "center",
    minWidth: "48%"
  }
});
