/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var TipMode = {
  TIP_PROVIDED : "TIP_PROVIDED",
  ON_SCREEN_BEFORE_PAYMENT : "ON_SCREEN_BEFORE_PAYMENT",
  ON_SCREEN_AFTER_PAYMENT : "ON_SCREEN_AFTER_PAYMENT",
  ON_PAPER : "ON_PAPER",
  NO_TIP : "NO_TIP"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipMode;
}

