/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof onlineorder
*/
var OnlineOrderCustomer = function() {
  this._class_ = OnlineOrderCustomer;
  this.id = undefined;
  this.merchantRef = undefined;
  this.customerEmailAddress = undefined;
  this.customerDisplayName = undefined;
  this.customerFirstName = undefined;
  this.customerLastName = undefined;
  this.customerPhoneNumber = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
};


/**
* Set the field value
* online order customer id
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {String} id 
*/
OnlineOrderCustomer.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* online order customer id
* @memberof onlineorder.OnlineOrderCustomer
* @return {String} 
*/
OnlineOrderCustomer.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Reference to the merchant that created the order
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {base.Reference} merchantRef 
*/
OnlineOrderCustomer.prototype.setMerchantRef = function(merchantRef) {
  this.merchantRef = merchantRef;
};

/**
* Get the field value
* Reference to the merchant that created the order
* @memberof onlineorder.OnlineOrderCustomer
* @return {base.Reference} 
*/
OnlineOrderCustomer.prototype.getMerchantRef = function() {
  return this.merchantRef;
};

/**
* Set the field value
* Online Order customer email address
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {String} customerEmailAddress 
*/
OnlineOrderCustomer.prototype.setCustomerEmailAddress = function(customerEmailAddress) {
  this.customerEmailAddress = customerEmailAddress;
};

/**
* Get the field value
* Online Order customer email address
* @memberof onlineorder.OnlineOrderCustomer
* @return {String} 
*/
OnlineOrderCustomer.prototype.getCustomerEmailAddress = function() {
  return this.customerEmailAddress;
};

/**
* Set the field value
* Online Order customer display name
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {String} customerDisplayName 
*/
OnlineOrderCustomer.prototype.setCustomerDisplayName = function(customerDisplayName) {
  this.customerDisplayName = customerDisplayName;
};

/**
* Get the field value
* Online Order customer display name
* @memberof onlineorder.OnlineOrderCustomer
* @return {String} 
*/
OnlineOrderCustomer.prototype.getCustomerDisplayName = function() {
  return this.customerDisplayName;
};

/**
* Set the field value
* Online Order customer first name
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {String} customerFirstName 
*/
OnlineOrderCustomer.prototype.setCustomerFirstName = function(customerFirstName) {
  this.customerFirstName = customerFirstName;
};

/**
* Get the field value
* Online Order customer first name
* @memberof onlineorder.OnlineOrderCustomer
* @return {String} 
*/
OnlineOrderCustomer.prototype.getCustomerFirstName = function() {
  return this.customerFirstName;
};

/**
* Set the field value
* Online Order customer last name
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {String} customerLastName 
*/
OnlineOrderCustomer.prototype.setCustomerLastName = function(customerLastName) {
  this.customerLastName = customerLastName;
};

/**
* Get the field value
* Online Order customer last name
* @memberof onlineorder.OnlineOrderCustomer
* @return {String} 
*/
OnlineOrderCustomer.prototype.getCustomerLastName = function() {
  return this.customerLastName;
};

/**
* Set the field value
* Online Order customer phone number
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {String} customerPhoneNumber 
*/
OnlineOrderCustomer.prototype.setCustomerPhoneNumber = function(customerPhoneNumber) {
  this.customerPhoneNumber = customerPhoneNumber;
};

/**
* Get the field value
* Online Order customer phone number
* @memberof onlineorder.OnlineOrderCustomer
* @return {String} 
*/
OnlineOrderCustomer.prototype.getCustomerPhoneNumber = function() {
  return this.customerPhoneNumber;
};

/**
* Set the field value
* Timestamp when the online ordering item was created
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {Number} createdTime must be a long integer
*/
OnlineOrderCustomer.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Timestamp when the online ordering item was created
* @memberof onlineorder.OnlineOrderCustomer
* @return {Number} must be a long integer
*/
OnlineOrderCustomer.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* Timestamp when the online ordering item was last modified
*
* @memberof onlineorder.OnlineOrderCustomer
* @param {Number} modifiedTime must be a long integer
*/
OnlineOrderCustomer.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* Timestamp when the online ordering item was last modified
* @memberof onlineorder.OnlineOrderCustomer
* @return {Number} must be a long integer
*/
OnlineOrderCustomer.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* @memberof onlineorder.OnlineOrderCustomer
* @private
*/
OnlineOrderCustomer.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

OnlineOrderCustomer.prototype.toString = function() {
  return JSON.stringify(this);
};

OnlineOrderCustomer._meta_ =  {fields:  {}};
OnlineOrderCustomer._meta_._class_ =  OnlineOrderCustomer;
OnlineOrderCustomer._meta_.fields["id"] = {};
OnlineOrderCustomer._meta_.fields["id"].type = String;
OnlineOrderCustomer._meta_.fields["merchantRef"] = {};
OnlineOrderCustomer._meta_.fields["merchantRef"].type = base_Reference;
OnlineOrderCustomer._meta_.fields["customerEmailAddress"] = {};
OnlineOrderCustomer._meta_.fields["customerEmailAddress"].type = String;
OnlineOrderCustomer._meta_.fields["customerDisplayName"] = {};
OnlineOrderCustomer._meta_.fields["customerDisplayName"].type = String;
OnlineOrderCustomer._meta_.fields["customerFirstName"] = {};
OnlineOrderCustomer._meta_.fields["customerFirstName"].type = String;
OnlineOrderCustomer._meta_.fields["customerLastName"] = {};
OnlineOrderCustomer._meta_.fields["customerLastName"].type = String;
OnlineOrderCustomer._meta_.fields["customerPhoneNumber"] = {};
OnlineOrderCustomer._meta_.fields["customerPhoneNumber"].type = String;
OnlineOrderCustomer._meta_.fields["createdTime"] = {};
OnlineOrderCustomer._meta_.fields["createdTime"].type = Number;
OnlineOrderCustomer._meta_.fields["modifiedTime"] = {};
OnlineOrderCustomer._meta_.fields["modifiedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OnlineOrderCustomer;
}

