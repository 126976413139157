/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var FraudResponseAction = {
  VOID : "VOID",
  PROMPT : "PROMPT",
  IGNORE : "IGNORE",
  DECLINE : "DECLINE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = FraudResponseAction;
}

