/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Payment = require("../payments/Payment");
var order_Order = require("../order/Order");

/**
* @constructor
* @memberof remotepay
*/
var PrintPaymentReceiptMessage = function() {
  this._class_ = PrintPaymentReceiptMessage;
  this.payment = undefined;
  this.order = undefined;
};


/**
* Set the field value
* @memberof remotepay.PrintPaymentReceiptMessage
* @param {payments.Payment} payment 
*/
PrintPaymentReceiptMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotepay.PrintPaymentReceiptMessage
* @return {payments.Payment} 
*/
PrintPaymentReceiptMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotepay.PrintPaymentReceiptMessage
* @param {order.Order} order 
*/
PrintPaymentReceiptMessage.prototype.setOrder = function(order) {
  this.order = order;
};

/**
* Get the field value
* @memberof remotepay.PrintPaymentReceiptMessage
* @return {order.Order} 
*/
PrintPaymentReceiptMessage.prototype.getOrder = function() {
  return this.order;
};

/**
* @memberof remotepay.PrintPaymentReceiptMessage
* @private
*/
PrintPaymentReceiptMessage.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PrintPaymentReceiptMessage.prototype.toString = function() {
  return JSON.stringify(this);
};

PrintPaymentReceiptMessage._meta_ =  {fields:  {}};
PrintPaymentReceiptMessage._meta_._class_ =  PrintPaymentReceiptMessage;
PrintPaymentReceiptMessage._meta_.fields["payment"] = {};
PrintPaymentReceiptMessage._meta_.fields["payment"].type = payments_Payment;
PrintPaymentReceiptMessage._meta_.fields["order"] = {};
PrintPaymentReceiptMessage._meta_.fields["order"].type = order_Order;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintPaymentReceiptMessage;
}

