/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof device
* @enum {string}
*/
var DeviceProvisionState = {
  NOT_ASSOCIATED : "NOT_ASSOCIATED",
  DISASSOCIATED : "DISASSOCIATED",
  ASSOCIATED : "ASSOCIATED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeviceProvisionState;
}

