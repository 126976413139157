import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 10,
    shadowOpacity: 0.15,
    shadowOffset: {
      height: 2, 
      width: 1
    }
  },
  clikableContainer: {
    height: 150,
    width: 340,
    borderRadius: 10,
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 10,
    shadowOpacity: 0.15,
    shadowOffset: {
      height: 2, 
      width: 1
    }
  },
  imageBackgroundDimensions: {
    overflow: 'hidden',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    height: 115,
    width: 340,
  },
  internalSectionContainer: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    bottom: 0,
    flex: 1,
    width: '100%',
  },
  internalLogoContainer: {
    marginBottom: 5,
    marginLeft: 10,
    position: 'absolute',
    flexDirection: 'column',
    bottom: 5,
  },
  logo: {
    borderRadius: 5,
    height: 39,
    width: 39,
  },
  lowerDataBar: {
    flexDirection: 'row',
    marginLeft: 10,
    marginRight: 15,
  },
  lowerDataBarTextContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  bannerTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  bannerDescription: {
    fontWeight: '500',
    fontSize: 12,
    alignSelf: "flex-start",
    lineHeight: 15
  },
  bannerPrice: {
    alignSelf: "flex-end",
    marginTop: 5,
    marginBottom: 10,
    fontSize: 16,
    fontWeight: '700',
    fontStyle: "normal",
    lineHeight: 15
  },
  imageBanner: {
    height: 130,
    width: 340,
    borderRadius: 15,
  },
  benefitContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    position: "absolute",
  },
  benefit: {
    position: "absolute",
    top: 15,
    left: 10,
  },
  benefitTitle: {
    fontSize: 16,
    textTransform: "uppercase",
    width: 130,
    textAlign: "center",
  },
  benefitDescription: {
    marginTop: 2,
    color: "#FFF",
    fontSize: 12,
    width: 130,
    textAlign: "center",
  }
});
