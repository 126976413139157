/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/** Request to get the status of a print job. */
/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var PrintJobStatusRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = PrintJobStatusRequest;
  this.printRequestId = undefined;
};

PrintJobStatusRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
PrintJobStatusRequest.prototype.constructor = PrintJobStatusRequest;

/**
* Set the field value
* The ID of the print job
*
* @memberof remotepay.PrintJobStatusRequest
* @param {String} printRequestId 
*/
PrintJobStatusRequest.prototype.setPrintRequestId = function(printRequestId) {
  this.printRequestId = printRequestId;
};

/**
* Get the field value
* The ID of the print job
* @memberof remotepay.PrintJobStatusRequest
* @return {String} 
*/
PrintJobStatusRequest.prototype.getPrintRequestId = function() {
  return this.printRequestId;
};

PrintJobStatusRequest._meta_ =  {fields:  {}};
PrintJobStatusRequest._meta_._class_ =  PrintJobStatusRequest;
PrintJobStatusRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
PrintJobStatusRequest._meta_.fields["printRequestId"] = {};
PrintJobStatusRequest._meta_.fields["printRequestId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintJobStatusRequest;
}

