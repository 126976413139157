/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof order
* @enum {string}
*/
var ClientEventType = {
  DELETED_LINE_ITEM : "DELETED_LINE_ITEM",
  MOVED_TABLE : "MOVED_TABLE",
  LEFT_TABLE : "LEFT_TABLE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ClientEventType;
}

