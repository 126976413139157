/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**  Used to notify clients of pay display of a configuration change.
 * This is conceptually abstract to allow for various configuration types. */
/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var ConfigurationChangeMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = ConfigurationChangeMessage;
  this.setMethod(remotemessage_Method["CONFIGURATION_CHANGE"]);
  this.configurationType = undefined;
  this.configuration = undefined;
};

ConfigurationChangeMessage.prototype = Object.create(remotemessage_Message.prototype);
ConfigurationChangeMessage.prototype.constructor = ConfigurationChangeMessage;

/**
* Set the field value
* The type of configuration that is upcoming.  One of
 * com.clover.remote.message.ConfigurationChangeMessage#USB
 * com.clover.remote.message.ConfigurationChangeMessage#NETWORK_PAY_DISPLAY
 * com.clover.remote.message.ConfigurationChangeMessage#CLOUD_PAY_DISPLAY
*
* @memberof remotemessage.ConfigurationChangeMessage
* @param {String} configurationType 
*/
ConfigurationChangeMessage.prototype.setConfigurationType = function(configurationType) {
  this.configurationType = configurationType;
};

/**
* Get the field value
* The type of configuration that is upcoming.  One of
 * com.clover.remote.message.ConfigurationChangeMessage#USB
 * com.clover.remote.message.ConfigurationChangeMessage#NETWORK_PAY_DISPLAY
 * com.clover.remote.message.ConfigurationChangeMessage#CLOUD_PAY_DISPLAY
* @memberof remotemessage.ConfigurationChangeMessage
* @return {String} 
*/
ConfigurationChangeMessage.prototype.getConfigurationType = function() {
  return this.configurationType;
};

/**
* Set the field value
* Typically a json serialized string that represents the change to the configuration.
 * This is not typed, or constrained, so it could contain any string value, but the
 * intention is to include a serialized representation of the new configuration.
*
* @memberof remotemessage.ConfigurationChangeMessage
* @param {String} configuration 
*/
ConfigurationChangeMessage.prototype.setConfiguration = function(configuration) {
  this.configuration = configuration;
};

/**
* Get the field value
* Typically a json serialized string that represents the change to the configuration.
 * This is not typed, or constrained, so it could contain any string value, but the
 * intention is to include a serialized representation of the new configuration.
* @memberof remotemessage.ConfigurationChangeMessage
* @return {String} 
*/
ConfigurationChangeMessage.prototype.getConfiguration = function() {
  return this.configuration;
};

ConfigurationChangeMessage._meta_ =  {fields:  {}};
ConfigurationChangeMessage._meta_._class_ =  ConfigurationChangeMessage;
ConfigurationChangeMessage._meta_._superMeta_ = remotemessage_Message._meta_;
ConfigurationChangeMessage._meta_.fields["configurationType"] = {};
ConfigurationChangeMessage._meta_.fields["configurationType"].type = String;
ConfigurationChangeMessage._meta_.fields["configuration"] = {};
ConfigurationChangeMessage._meta_.fields["configuration"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ConfigurationChangeMessage;
}

