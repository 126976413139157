/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var employees_EmployeeCardStatus = require("../employees/EmployeeCardStatus");
var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof employees
*/
var EmployeeCard = function() {
  this._class_ = EmployeeCard;
  this.id = undefined;
  this.merchant = undefined;
  this.employee = undefined;
  this.updater = undefined;
  this.number = undefined;
  this.status = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof employees.EmployeeCard
* @param {String} id 
*/
EmployeeCard.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof employees.EmployeeCard
* @return {String} 
*/
EmployeeCard.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* The merchant the card belongs to
*
* @memberof employees.EmployeeCard
* @param {base.Reference} merchant 
*/
EmployeeCard.prototype.setMerchant = function(merchant) {
  this.merchant = merchant;
};

/**
* Get the field value
* The merchant the card belongs to
* @memberof employees.EmployeeCard
* @return {base.Reference} 
*/
EmployeeCard.prototype.getMerchant = function() {
  return this.merchant;
};

/**
* Set the field value
* The employee who the card is currently assigned to
*
* @memberof employees.EmployeeCard
* @param {base.Reference} employee 
*/
EmployeeCard.prototype.setEmployee = function(employee) {
  this.employee = employee;
};

/**
* Get the field value
* The employee who the card is currently assigned to
* @memberof employees.EmployeeCard
* @return {base.Reference} 
*/
EmployeeCard.prototype.getEmployee = function() {
  return this.employee;
};

/**
* Set the field value
* The employee who last updated the card's state
*
* @memberof employees.EmployeeCard
* @param {base.Reference} updater 
*/
EmployeeCard.prototype.setUpdater = function(updater) {
  this.updater = updater;
};

/**
* Get the field value
* The employee who last updated the card's state
* @memberof employees.EmployeeCard
* @return {base.Reference} 
*/
EmployeeCard.prototype.getUpdater = function() {
  return this.updater;
};

/**
* Set the field value
* Unique number on the employee card.
*
* @memberof employees.EmployeeCard
* @param {String} number 
*/
EmployeeCard.prototype.setNumber = function(number) {
  this.number = number;
};

/**
* Get the field value
* Unique number on the employee card.
* @memberof employees.EmployeeCard
* @return {String} 
*/
EmployeeCard.prototype.getNumber = function() {
  return this.number;
};

/**
* Set the field value
* Current status of the employee card
*
* @memberof employees.EmployeeCard
* @param {employees.EmployeeCardStatus} status 
*/
EmployeeCard.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* Current status of the employee card
* @memberof employees.EmployeeCard
* @return {employees.EmployeeCardStatus} 
*/
EmployeeCard.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* Timestamp of when this employee card was created
*
* @memberof employees.EmployeeCard
* @param {Number} createdTime must be a long integer
*/
EmployeeCard.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Timestamp of when this employee card was created
* @memberof employees.EmployeeCard
* @return {Number} must be a long integer
*/
EmployeeCard.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* Timestamp of when this employee card was last updated
*
* @memberof employees.EmployeeCard
* @param {Number} modifiedTime must be a long integer
*/
EmployeeCard.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* Timestamp of when this employee card was last updated
* @memberof employees.EmployeeCard
* @return {Number} must be a long integer
*/
EmployeeCard.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* @memberof employees.EmployeeCard
* @private
*/
EmployeeCard.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

EmployeeCard.prototype.toString = function() {
  return JSON.stringify(this);
};

EmployeeCard._meta_ =  {fields:  {}};
EmployeeCard._meta_._class_ =  EmployeeCard;
EmployeeCard._meta_.fields["id"] = {};
EmployeeCard._meta_.fields["id"].type = String;
EmployeeCard._meta_.fields["merchant"] = {};
EmployeeCard._meta_.fields["merchant"].type = base_Reference;
EmployeeCard._meta_.fields["employee"] = {};
EmployeeCard._meta_.fields["employee"].type = base_Reference;
EmployeeCard._meta_.fields["updater"] = {};
EmployeeCard._meta_.fields["updater"].type = base_Reference;
EmployeeCard._meta_.fields["number"] = {};
EmployeeCard._meta_.fields["number"].type = String;
EmployeeCard._meta_.fields["status"] = {};
EmployeeCard._meta_.fields["status"].type = employees_EmployeeCardStatus;
EmployeeCard._meta_.fields["createdTime"] = {};
EmployeeCard._meta_.fields["createdTime"].type = Number;
EmployeeCard._meta_.fields["modifiedTime"] = {};
EmployeeCard._meta_.fields["modifiedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = EmployeeCard;
}

