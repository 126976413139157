import React from "react";
import { Image } from "react-native";
import { Button } from "@rneui/themed";
import { createIconSetFromIcoMoon, Ionicons } from "@expo/vector-icons";
import ButtonWithIconProps from "../types/components/ButtonWithIconProps";
import { styles } from "../styles/components/buttonWithIcon";
import selection from "../assets/icomoon/selection.json";
import GoogleColorIcon from '../assets/images/google-color-icon.svg';
import font from "../assets/icomoon/rn-components.ttf";

export default ({
  icon,
  text,
  backgroundColor,
  colorText,
  venueIcon,
  usingDiferentIcon,
  onPress,
}: ButtonWithIconProps): JSX.Element => {
  const Icon = createIconSetFromIcoMoon(selection, "rn-components", font);

  const determineShownIcon = () => {
    if (icon && usingDiferentIcon) {
      if (icon === "user") {
        return <Ionicons name="person-circle-outline" size={32} style={[styles.icon, {  color:"#FFF", fontSize: 30 }]} />
      } else if (icon === "google") {
        return <Image style={{ height: 30, width: 30, backgroundColor: "transparent",  marginRight: 15, position: "absolute", left: 20 }} source={GoogleColorIcon}/>
      }
    } else if (icon && !usingDiferentIcon) {
      return <Icon name={icon} style={[styles.icon, { color: colorText }]} />;
    } else {
      return <><Image style={{ height: 35, width: 35, backgroundColor: "transparent",  marginRight: 15, position: "absolute", left: 20 }} source={{ uri: venueIcon }}/></>
    }
  }

  return (
    <Button
      title={text}
      type="clear"
      buttonStyle={[styles.btn, { backgroundColor }]}
      titleStyle={[styles.btnText, { color: colorText }]}
      onPress={onPress}
      icon={determineShownIcon()}
    />
  );
};
