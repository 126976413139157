/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var GatewayTxState = {
  INITIATED : "INITIATED",
  INITIATED_ON_AUTH : "INITIATED_ON_AUTH",
  ACKNOWLEDGED : "ACKNOWLEDGED",
  CONNECT_FAILED : "CONNECT_FAILED",
  TIMEOUT : "TIMEOUT",
  FAILED : "FAILED",
  REVERSE_INITIATED : "REVERSE_INITIATED",
  REVERSE_INITIATED_ON_AUTH : "REVERSE_INITIATED_ON_AUTH",
  REVERSED : "REVERSED",
  REVERSAL_FAILED : "REVERSAL_FAILED",
  EXTERNAL : "EXTERNAL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = GatewayTxState;
}

