/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var inventory_MenuModifier = require("../inventory/MenuModifier");
var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof inventory
*/
var Modifier = function() {
  this._class_ = Modifier;
  this.id = undefined;
  this.name = undefined;
  this.alternateName = undefined;
  this.price = 0;
  this.modifierGroup = undefined;
  this.menuModifier = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof inventory.Modifier
* @param {String} id 
*/
Modifier.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof inventory.Modifier
* @return {String} 
*/
Modifier.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Name of the modifier
*
* @memberof inventory.Modifier
* @param {String} name 
*/
Modifier.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Name of the modifier
* @memberof inventory.Modifier
* @return {String} 
*/
Modifier.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* Alternate name of the modifier
*
* @memberof inventory.Modifier
* @param {Null|String} alternateName 
*/
Modifier.prototype.setAlternateName = function(alternateName) {
  this.alternateName = alternateName;
};

/**
* Get the field value
* Alternate name of the modifier
* @memberof inventory.Modifier
* @return {Null|String} 
*/
Modifier.prototype.getAlternateName = function() {
  return this.alternateName;
};

/**
* Set the field value
* Additional cost when used
*
* @memberof inventory.Modifier
* @param {Null|Number} price must be a long integer
*/
Modifier.prototype.setPrice = function(price) {
  this.price = price;
};

/**
* Get the field value
* Additional cost when used
* @memberof inventory.Modifier
* @return {Null|Number} must be a long integer
*/
Modifier.prototype.getPrice = function() {
  return this.price;
};

/**
* Set the field value
* @memberof inventory.Modifier
* @param {Null|base.Reference} modifierGroup 
*/
Modifier.prototype.setModifierGroup = function(modifierGroup) {
  this.modifierGroup = modifierGroup;
};

/**
* Get the field value
* @memberof inventory.Modifier
* @return {Null|base.Reference} 
*/
Modifier.prototype.getModifierGroup = function() {
  return this.modifierGroup;
};

/**
* Set the field value
* Menu Modifier attribute that can be expanded to menu specific attributes
*
* @memberof inventory.Modifier
* @param {Null|inventory.MenuModifier} menuModifier 
*/
Modifier.prototype.setMenuModifier = function(menuModifier) {
  this.menuModifier = menuModifier;
};

/**
* Get the field value
* Menu Modifier attribute that can be expanded to menu specific attributes
* @memberof inventory.Modifier
* @return {Null|inventory.MenuModifier} 
*/
Modifier.prototype.getMenuModifier = function() {
  return this.menuModifier;
};

/**
* @memberof inventory.Modifier
* @private
*/
Modifier.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Modifier.prototype.toString = function() {
  return JSON.stringify(this);
};

Modifier._meta_ =  {fields:  {}};
Modifier._meta_._class_ =  Modifier;
Modifier._meta_.fields["id"] = {};
Modifier._meta_.fields["id"].type = String;
Modifier._meta_.fields["name"] = {};
Modifier._meta_.fields["name"].type = String;
Modifier._meta_.fields["alternateName"] = {};
Modifier._meta_.fields["alternateName"].type = String;
Modifier._meta_.fields["price"] = {};
Modifier._meta_.fields["price"].type = Number;
Modifier._meta_.fields["modifierGroup"] = {};
Modifier._meta_.fields["modifierGroup"].type = base_Reference;
Modifier._meta_.fields["menuModifier"] = {};
Modifier._meta_.fields["menuModifier"].type = inventory_MenuModifier;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Modifier;
}

