/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var WelcomeMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = WelcomeMessage;
  this.setMethod(remotemessage_Method["SHOW_WELCOME_SCREEN"]);
};

WelcomeMessage.prototype = Object.create(remotemessage_Message.prototype);
WelcomeMessage.prototype.constructor = WelcomeMessage;

WelcomeMessage._meta_ =  {fields:  {}};
WelcomeMessage._meta_._class_ =  WelcomeMessage;
WelcomeMessage._meta_._superMeta_ = remotemessage_Message._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = WelcomeMessage;
}

