/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var order_Modification = require("../order/Modification");
var inventory_TaxRate = require("../inventory/TaxRate");
var payments_LineItemPayment = require("../payments/LineItemPayment");
var order_Discount = require("../order/Discount");
var base_Reference = require("../base/Reference");
var payments_Refund = require("../payments/Refund");

/**
* @constructor
* @memberof order
*/
var LineItem = function() {
  this._class_ = LineItem;
  this.id = undefined;
  this.item = undefined;
  this.name = undefined;
  this.alternateName = undefined;
  this.price = undefined;
  this.priceWithModifiers = undefined;
  this.priceWithModifiersAndItemAndOrderDiscounts = undefined;
  this.unitQty = undefined;
  this.unitName = undefined;
  this.itemCode = undefined;
  this.note = undefined;
  this.printed = false;
  this.exchangedLineItem = undefined;
  this.binName = undefined;
  this.userData = undefined;
  this.createdTime = undefined;
  this.orderClientCreatedTime = undefined;
  this.discounts = undefined;
  this.orderLevelDiscounts = undefined;
  this.discountAmount = undefined;
  this.orderLevelDiscountAmount = undefined;
  this.exchanged = false;
  this.modifications = undefined;
  this.refunded = false;
  this.refund = undefined;
  this.isRevenue = false;
  this.taxRates = undefined;
  this.payments = undefined;
  this.revenueAmount = undefined;
  this.quantitySold = undefined;
  this.printGroup = undefined;
};


/**
* Set the field value
* Unique identifier.
*
* @memberof order.LineItem
* @param {String} id 
*/
LineItem.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier.
* @memberof order.LineItem
* @return {String} 
*/
LineItem.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* A reference to the inventory item that was used to create this line item. Note that since this a reference the item can be changed and deleted any time such that it no longer matches this line item.
*
* @memberof order.LineItem
* @param {Null|base.Reference} item 
*/
LineItem.prototype.setItem = function(item) {
  this.item = item;
};

/**
* Get the field value
* A reference to the inventory item that was used to create this line item. Note that since this a reference the item can be changed and deleted any time such that it no longer matches this line item.
* @memberof order.LineItem
* @return {Null|base.Reference} 
*/
LineItem.prototype.getItem = function() {
  return this.item;
};

/**
* Set the field value
* Line item name.
*
* @memberof order.LineItem
* @param {Null|String} name 
*/
LineItem.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Line item name.
* @memberof order.LineItem
* @return {Null|String} 
*/
LineItem.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* Alternate name of the line item.
*
* @memberof order.LineItem
* @param {Null|String} alternateName 
*/
LineItem.prototype.setAlternateName = function(alternateName) {
  this.alternateName = alternateName;
};

/**
* Get the field value
* Alternate name of the line item.
* @memberof order.LineItem
* @return {Null|String} 
*/
LineItem.prototype.getAlternateName = function() {
  return this.alternateName;
};

/**
* Set the field value
* Price of the item, typically in cents of merchant currency. When unit quantity is not null then this is the price for a single unit and is not the complete price.
*
* @memberof order.LineItem
* @param {Number} price must be a long integer
*/
LineItem.prototype.setPrice = function(price) {
  this.price = price;
};

/**
* Get the field value
* Price of the item, typically in cents of merchant currency. When unit quantity is not null then this is the price for a single unit and is not the complete price.
* @memberof order.LineItem
* @return {Number} must be a long integer
*/
LineItem.prototype.getPrice = function() {
  return this.price;
};

/**
* Set the field value
* Price of item after adding all modifications. This is used only for reporting.
*
* @memberof order.LineItem
* @param {Number} priceWithModifiers must be a long integer
*/
LineItem.prototype.setPriceWithModifiers = function(priceWithModifiers) {
  this.priceWithModifiers = priceWithModifiers;
};

/**
* Get the field value
* Price of item after adding all modifications. This is used only for reporting.
* @memberof order.LineItem
* @return {Number} must be a long integer
*/
LineItem.prototype.getPriceWithModifiers = function() {
  return this.priceWithModifiers;
};

/**
* Set the field value
* Price of item after adding all modifications and subtracting all line item and order level discounts. This is used only for reporting.
*
* @memberof order.LineItem
* @param {Number} priceWithModifiersAndItemAndOrderDiscounts must be a long integer
*/
LineItem.prototype.setPriceWithModifiersAndItemAndOrderDiscounts = function(priceWithModifiersAndItemAndOrderDiscounts) {
  this.priceWithModifiersAndItemAndOrderDiscounts = priceWithModifiersAndItemAndOrderDiscounts;
};

/**
* Get the field value
* Price of item after adding all modifications and subtracting all line item and order level discounts. This is used only for reporting.
* @memberof order.LineItem
* @return {Number} must be a long integer
*/
LineItem.prototype.getPriceWithModifiersAndItemAndOrderDiscounts = function() {
  return this.priceWithModifiersAndItemAndOrderDiscounts;
};

/**
* Set the field value
* Unit quantity if this line item is priced by quantity, or null if the item is not priced by quantity. The value is a fixed-point integer with scaling factor of 1000 (e.g. if charging by ounces then the value should be set to 2500 for 2.5 ounces). To compute the complete price perform the following calculation: PRICE * (UNIT QTY / 1000).
*
* @memberof order.LineItem
* @param {Null|Number} unitQty must be an integer
*/
LineItem.prototype.setUnitQty = function(unitQty) {
  this.unitQty = unitQty;
};

/**
* Get the field value
* Unit quantity if this line item is priced by quantity, or null if the item is not priced by quantity. The value is a fixed-point integer with scaling factor of 1000 (e.g. if charging by ounces then the value should be set to 2500 for 2.5 ounces). To compute the complete price perform the following calculation: PRICE * (UNIT QTY / 1000).
* @memberof order.LineItem
* @return {Null|Number} must be an integer
*/
LineItem.prototype.getUnitQty = function() {
  return this.unitQty;
};

/**
* Set the field value
* Unit name (e.g. oz, lb, etc) if priced by unit, otherwise null.
*
* @memberof order.LineItem
* @param {Null|String} unitName 
*/
LineItem.prototype.setUnitName = function(unitName) {
  this.unitName = unitName;
};

/**
* Get the field value
* Unit name (e.g. oz, lb, etc) if priced by unit, otherwise null.
* @memberof order.LineItem
* @return {Null|String} 
*/
LineItem.prototype.getUnitName = function() {
  return this.unitName;
};

/**
* Set the field value
* @memberof order.LineItem
* @param {Null|String} itemCode 
*/
LineItem.prototype.setItemCode = function(itemCode) {
  this.itemCode = itemCode;
};

/**
* Get the field value
* @memberof order.LineItem
* @return {Null|String} 
*/
LineItem.prototype.getItemCode = function() {
  return this.itemCode;
};

/**
* Set the field value
* @memberof order.LineItem
* @param {Null|String} note 
*/
LineItem.prototype.setNote = function(note) {
  this.note = note;
};

/**
* Get the field value
* @memberof order.LineItem
* @return {Null|String} 
*/
LineItem.prototype.getNote = function() {
  return this.note;
};

/**
* Set the field value
* True if this line item has been printed out on an order printer at least once already.
*
* @memberof order.LineItem
* @param {Boolean} printed 
*/
LineItem.prototype.setPrinted = function(printed) {
  this.printed = printed;
};

/**
* Get the field value
* True if this line item has been printed out on an order printer at least once already.
* @memberof order.LineItem
* @return {Boolean} 
*/
LineItem.prototype.getPrinted = function() {
  return this.printed;
};

/**
* Set the field value
* @memberof order.LineItem
* @param {Null|base.Reference} exchangedLineItem 
*/
LineItem.prototype.setExchangedLineItem = function(exchangedLineItem) {
  this.exchangedLineItem = exchangedLineItem;
};

/**
* Get the field value
* @memberof order.LineItem
* @return {Null|base.Reference} 
*/
LineItem.prototype.getExchangedLineItem = function() {
  return this.exchangedLineItem;
};

/**
* Set the field value
* @memberof order.LineItem
* @param {Null|String} binName 
*/
LineItem.prototype.setBinName = function(binName) {
  this.binName = binName;
};

/**
* Get the field value
* @memberof order.LineItem
* @return {Null|String} 
*/
LineItem.prototype.getBinName = function() {
  return this.binName;
};

/**
* Set the field value
* @memberof order.LineItem
* @param {Null|String} userData 
*/
LineItem.prototype.setUserData = function(userData) {
  this.userData = userData;
};

/**
* Get the field value
* @memberof order.LineItem
* @return {Null|String} 
*/
LineItem.prototype.getUserData = function() {
  return this.userData;
};

/**
* Set the field value
* @memberof order.LineItem
* @param {Number} createdTime must be a long integer
*/
LineItem.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* @memberof order.LineItem
* @return {Number} must be a long integer
*/
LineItem.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* @memberof order.LineItem
* @param {Number} orderClientCreatedTime must be a long integer
*/
LineItem.prototype.setOrderClientCreatedTime = function(orderClientCreatedTime) {
  this.orderClientCreatedTime = orderClientCreatedTime;
};

/**
* Get the field value
* @memberof order.LineItem
* @return {Number} must be a long integer
*/
LineItem.prototype.getOrderClientCreatedTime = function() {
  return this.orderClientCreatedTime;
};

/**
* Set the field value
* List of discounts applied to the line item. Each line item on an order may have zero or more percentage or amount discounts. Line item discounts are separate from order-level discounts (which are applied to the order subtotal).
*
* @memberof order.LineItem
* @param {Array.<order.Discount>} discounts An array of 
*/
LineItem.prototype.setDiscounts = function(discounts) {
  this.discounts = discounts;
};

/**
* Get the field value
* List of discounts applied to the line item. Each line item on an order may have zero or more percentage or amount discounts. Line item discounts are separate from order-level discounts (which are applied to the order subtotal).
* @memberof order.LineItem
* @return {Array.<order.Discount>} An array of 
*/
LineItem.prototype.getDiscounts = function() {
  return this.discounts;
};

/**
* Set the field value
* List of order level discounts attributed to this line item. Amounts are rounded to the nearest penny. This is for reporting purposes only. These rounding approximates are not used when calculating the transaction.
*
* @memberof order.LineItem
* @param {Array.<order.Discount>} orderLevelDiscounts An array of 
*/
LineItem.prototype.setOrderLevelDiscounts = function(orderLevelDiscounts) {
  this.orderLevelDiscounts = orderLevelDiscounts;
};

/**
* Get the field value
* List of order level discounts attributed to this line item. Amounts are rounded to the nearest penny. This is for reporting purposes only. These rounding approximates are not used when calculating the transaction.
* @memberof order.LineItem
* @return {Array.<order.Discount>} An array of 
*/
LineItem.prototype.getOrderLevelDiscounts = function() {
  return this.orderLevelDiscounts;
};

/**
* Set the field value
* Unnamed fixed discount amount in cents. Or, in reporting, this may be the calculated sum of all the line item discounts.
*
* @memberof order.LineItem
* @param {Null|Number} discountAmount must be a long integer
*/
LineItem.prototype.setDiscountAmount = function(discountAmount) {
  this.discountAmount = discountAmount;
};

/**
* Get the field value
* Unnamed fixed discount amount in cents. Or, in reporting, this may be the calculated sum of all the line item discounts.
* @memberof order.LineItem
* @return {Null|Number} must be a long integer
*/
LineItem.prototype.getDiscountAmount = function() {
  return this.discountAmount;
};

/**
* Set the field value
* The calculated sum of order level discounts attributed, approximately to the nearest cent, to this the line item. This is only used in reporting.
*
* @memberof order.LineItem
* @param {Number} orderLevelDiscountAmount must be a long integer
*/
LineItem.prototype.setOrderLevelDiscountAmount = function(orderLevelDiscountAmount) {
  this.orderLevelDiscountAmount = orderLevelDiscountAmount;
};

/**
* Get the field value
* The calculated sum of order level discounts attributed, approximately to the nearest cent, to this the line item. This is only used in reporting.
* @memberof order.LineItem
* @return {Number} must be a long integer
*/
LineItem.prototype.getOrderLevelDiscountAmount = function() {
  return this.orderLevelDiscountAmount;
};

/**
* Set the field value
* @memberof order.LineItem
* @param {Boolean} exchanged 
*/
LineItem.prototype.setExchanged = function(exchanged) {
  this.exchanged = exchanged;
};

/**
* Get the field value
* @memberof order.LineItem
* @return {Boolean} 
*/
LineItem.prototype.getExchanged = function() {
  return this.exchanged;
};

/**
* Set the field value
* List of references to modifications applied to this line item.
*
* @memberof order.LineItem
* @param {Array.<order.Modification>} modifications An array of 
*/
LineItem.prototype.setModifications = function(modifications) {
  this.modifications = modifications;
};

/**
* Get the field value
* List of references to modifications applied to this line item.
* @memberof order.LineItem
* @return {Array.<order.Modification>} An array of 
*/
LineItem.prototype.getModifications = function() {
  return this.modifications;
};

/**
* Set the field value
* True if this line item has been refunded
*
* @memberof order.LineItem
* @param {Boolean} refunded 
*/
LineItem.prototype.setRefunded = function(refunded) {
  this.refunded = refunded;
};

/**
* Get the field value
* True if this line item has been refunded
* @memberof order.LineItem
* @return {Boolean} 
*/
LineItem.prototype.getRefunded = function() {
  return this.refunded;
};

/**
* Set the field value
* direct item refund
*
* @memberof order.LineItem
* @param {payments.Refund} refund 
*/
LineItem.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* direct item refund
* @memberof order.LineItem
* @return {payments.Refund} 
*/
LineItem.prototype.getRefund = function() {
  return this.refund;
};

/**
* Set the field value
* True if this item should be counted as revenue, for example gift cards and donations would not.
*
* @memberof order.LineItem
* @param {Boolean} isRevenue 
*/
LineItem.prototype.setIsRevenue = function(isRevenue) {
  this.isRevenue = isRevenue;
};

/**
* Get the field value
* True if this item should be counted as revenue, for example gift cards and donations would not.
* @memberof order.LineItem
* @return {Boolean} 
*/
LineItem.prototype.getIsRevenue = function() {
  return this.isRevenue;
};

/**
* Set the field value
* Tax rates that apply to this line item
*
* @memberof order.LineItem
* @param {Array.<inventory.TaxRate>} taxRates An array of 
*/
LineItem.prototype.setTaxRates = function(taxRates) {
  this.taxRates = taxRates;
};

/**
* Get the field value
* Tax rates that apply to this line item
* @memberof order.LineItem
* @return {Array.<inventory.TaxRate>} An array of 
*/
LineItem.prototype.getTaxRates = function() {
  return this.taxRates;
};

/**
* Set the field value
* Payments that apply to this line item
*
* @memberof order.LineItem
* @param {Array.<payments.LineItemPayment>} payments An array of 
*/
LineItem.prototype.setPayments = function(payments) {
  this.payments = payments;
};

/**
* Get the field value
* Payments that apply to this line item
* @memberof order.LineItem
* @return {Array.<payments.LineItemPayment>} An array of 
*/
LineItem.prototype.getPayments = function() {
  return this.payments;
};

/**
* Set the field value
* Revenue collected for this. This field is only used in reporting.
*
* @memberof order.LineItem
* @param {Number} revenueAmount must be a long integer
*/
LineItem.prototype.setRevenueAmount = function(revenueAmount) {
  this.revenueAmount = revenueAmount;
};

/**
* Get the field value
* Revenue collected for this. This field is only used in reporting.
* @memberof order.LineItem
* @return {Number} must be a long integer
*/
LineItem.prototype.getRevenueAmount = function() {
  return this.revenueAmount;
};

/**
* Set the field value
* Quantity sold for this line item. This field is only used in reporting
*
* @memberof order.LineItem
* @param {Number} quantitySold must be a double
*/
LineItem.prototype.setQuantitySold = function(quantitySold) {
  this.quantitySold = quantitySold;
};

/**
* Get the field value
* Quantity sold for this line item. This field is only used in reporting
* @memberof order.LineItem
* @return {Number} must be a double
*/
LineItem.prototype.getQuantitySold = function() {
  return this.quantitySold;
};

/**
* Set the field value
* Optional reference to the print group that this line item belongs to.
*
* @memberof order.LineItem
* @param {Null|base.Reference} printGroup 
*/
LineItem.prototype.setPrintGroup = function(printGroup) {
  this.printGroup = printGroup;
};

/**
* Get the field value
* Optional reference to the print group that this line item belongs to.
* @memberof order.LineItem
* @return {Null|base.Reference} 
*/
LineItem.prototype.getPrintGroup = function() {
  return this.printGroup;
};

/**
* @memberof order.LineItem
* @private
*/
LineItem.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

LineItem.prototype.toString = function() {
  return JSON.stringify(this);
};

LineItem._meta_ =  {fields:  {}};
LineItem._meta_._class_ =  LineItem;
LineItem._meta_.fields["id"] = {};
LineItem._meta_.fields["id"].type = String;
LineItem._meta_.fields["item"] = {};
LineItem._meta_.fields["item"].type = base_Reference;
LineItem._meta_.fields["name"] = {};
LineItem._meta_.fields["name"].type = String;
LineItem._meta_.fields["alternateName"] = {};
LineItem._meta_.fields["alternateName"].type = String;
LineItem._meta_.fields["price"] = {};
LineItem._meta_.fields["price"].type = Number;
LineItem._meta_.fields["priceWithModifiers"] = {};
LineItem._meta_.fields["priceWithModifiers"].type = Number;
LineItem._meta_.fields["priceWithModifiersAndItemAndOrderDiscounts"] = {};
LineItem._meta_.fields["priceWithModifiersAndItemAndOrderDiscounts"].type = Number;
LineItem._meta_.fields["unitQty"] = {};
LineItem._meta_.fields["unitQty"].type = Number;
LineItem._meta_.fields["unitName"] = {};
LineItem._meta_.fields["unitName"].type = String;
LineItem._meta_.fields["itemCode"] = {};
LineItem._meta_.fields["itemCode"].type = String;
LineItem._meta_.fields["note"] = {};
LineItem._meta_.fields["note"].type = String;
LineItem._meta_.fields["printed"] = {};
LineItem._meta_.fields["printed"].type = Boolean;
LineItem._meta_.fields["exchangedLineItem"] = {};
LineItem._meta_.fields["exchangedLineItem"].type = base_Reference;
LineItem._meta_.fields["binName"] = {};
LineItem._meta_.fields["binName"].type = String;
LineItem._meta_.fields["userData"] = {};
LineItem._meta_.fields["userData"].type = String;
LineItem._meta_.fields["createdTime"] = {};
LineItem._meta_.fields["createdTime"].type = Number;
LineItem._meta_.fields["orderClientCreatedTime"] = {};
LineItem._meta_.fields["orderClientCreatedTime"].type = Number;
LineItem._meta_.fields["discounts"] = {};
LineItem._meta_.fields["discounts"].type = Array;
LineItem._meta_.fields["discounts"].elementType = order_Discount;
LineItem._meta_.fields["orderLevelDiscounts"] = {};
LineItem._meta_.fields["orderLevelDiscounts"].type = Array;
LineItem._meta_.fields["orderLevelDiscounts"].elementType = order_Discount;
LineItem._meta_.fields["discountAmount"] = {};
LineItem._meta_.fields["discountAmount"].type = Number;
LineItem._meta_.fields["orderLevelDiscountAmount"] = {};
LineItem._meta_.fields["orderLevelDiscountAmount"].type = Number;
LineItem._meta_.fields["exchanged"] = {};
LineItem._meta_.fields["exchanged"].type = Boolean;
LineItem._meta_.fields["modifications"] = {};
LineItem._meta_.fields["modifications"].type = Array;
LineItem._meta_.fields["modifications"].elementType = order_Modification;
LineItem._meta_.fields["refunded"] = {};
LineItem._meta_.fields["refunded"].type = Boolean;
LineItem._meta_.fields["refund"] = {};
LineItem._meta_.fields["refund"].type = payments_Refund;
LineItem._meta_.fields["isRevenue"] = {};
LineItem._meta_.fields["isRevenue"].type = Boolean;
LineItem._meta_.fields["taxRates"] = {};
LineItem._meta_.fields["taxRates"].type = Array;
LineItem._meta_.fields["taxRates"].elementType = inventory_TaxRate;
LineItem._meta_.fields["payments"] = {};
LineItem._meta_.fields["payments"].type = Array;
LineItem._meta_.fields["payments"].elementType = payments_LineItemPayment;
LineItem._meta_.fields["revenueAmount"] = {};
LineItem._meta_.fields["revenueAmount"].type = Number;
LineItem._meta_.fields["quantitySold"] = {};
LineItem._meta_.fields["quantitySold"].type = Number;
LineItem._meta_.fields["printGroup"] = {};
LineItem._meta_.fields["printGroup"].type = base_Reference;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = LineItem;
}

