/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var CashbackSelectedMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = CashbackSelectedMessage;
  this.setMethod(remotemessage_Method["CASHBACK_SELECTED"]);
  this.cashbackAmount = undefined;
};

CashbackSelectedMessage.prototype = Object.create(remotemessage_Message.prototype);
CashbackSelectedMessage.prototype.constructor = CashbackSelectedMessage;

/**
* Set the field value
* Amount of cash selected
*
* @memberof remotemessage.CashbackSelectedMessage
* @param {Number} cashbackAmount must be a long integer
*/
CashbackSelectedMessage.prototype.setCashbackAmount = function(cashbackAmount) {
  this.cashbackAmount = cashbackAmount;
};

/**
* Get the field value
* Amount of cash selected
* @memberof remotemessage.CashbackSelectedMessage
* @return {Number} must be a long integer
*/
CashbackSelectedMessage.prototype.getCashbackAmount = function() {
  return this.cashbackAmount;
};

CashbackSelectedMessage._meta_ =  {fields:  {}};
CashbackSelectedMessage._meta_._class_ =  CashbackSelectedMessage;
CashbackSelectedMessage._meta_._superMeta_ = remotemessage_Message._meta_;
CashbackSelectedMessage._meta_.fields["cashbackAmount"] = {};
CashbackSelectedMessage._meta_.fields["cashbackAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CashbackSelectedMessage;
}

