/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_KeyPress = require("../remotepay/KeyPress");

/**
* @constructor
* @memberof remotepay
*/
var InputOption = function() {
  this._class_ = InputOption;
  this.keyPress = undefined;
  this.description = undefined;
};


/**
* Set the field value
* The keypress this represents
*
* @memberof remotepay.InputOption
* @param {remotepay.KeyPress} keyPress 
*/
InputOption.prototype.setKeyPress = function(keyPress) {
  this.keyPress = keyPress;
};

/**
* Get the field value
* The keypress this represents
* @memberof remotepay.InputOption
* @return {remotepay.KeyPress} 
*/
InputOption.prototype.getKeyPress = function() {
  return this.keyPress;
};

/**
* Set the field value
* A description of what this input option represents
*
* @memberof remotepay.InputOption
* @param {String} description 
*/
InputOption.prototype.setDescription = function(description) {
  this.description = description;
};

/**
* Get the field value
* A description of what this input option represents
* @memberof remotepay.InputOption
* @return {String} 
*/
InputOption.prototype.getDescription = function() {
  return this.description;
};

/**
* @memberof remotepay.InputOption
* @private
*/
InputOption.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

InputOption.prototype.toString = function() {
  return JSON.stringify(this);
};

InputOption._meta_ =  {fields:  {}};
InputOption._meta_._class_ =  InputOption;
InputOption._meta_.fields["keyPress"] = {};
InputOption._meta_.fields["keyPress"].type = remotepay_KeyPress;
InputOption._meta_.fields["description"] = {};
InputOption._meta_.fields["description"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = InputOption;
}

