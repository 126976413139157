/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof base
* @enum {string}
*/
var ChallengeType = {
  DUPLICATE_CHALLENGE : "DUPLICATE_CHALLENGE",
  OFFLINE_CHALLENGE : "OFFLINE_CHALLENGE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ChallengeType;
}

