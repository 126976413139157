/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var CardlessPaymentDataType = {
  QR_CODE : "QR_CODE",
  BARCODE : "BARCODE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardlessPaymentDataType;
}

