/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Payment = require("../payments/Payment");

/**
* @constructor
* @memberof remotepay
*/
var PrintPaymentDeclineReceiptMessage = function() {
  this._class_ = PrintPaymentDeclineReceiptMessage;
  this.payment = undefined;
  this.reason = undefined;
};


/**
* Set the field value
* @memberof remotepay.PrintPaymentDeclineReceiptMessage
* @param {payments.Payment} payment 
*/
PrintPaymentDeclineReceiptMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotepay.PrintPaymentDeclineReceiptMessage
* @return {payments.Payment} 
*/
PrintPaymentDeclineReceiptMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotepay.PrintPaymentDeclineReceiptMessage
* @param {String} reason 
*/
PrintPaymentDeclineReceiptMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotepay.PrintPaymentDeclineReceiptMessage
* @return {String} 
*/
PrintPaymentDeclineReceiptMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* @memberof remotepay.PrintPaymentDeclineReceiptMessage
* @private
*/
PrintPaymentDeclineReceiptMessage.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PrintPaymentDeclineReceiptMessage.prototype.toString = function() {
  return JSON.stringify(this);
};

PrintPaymentDeclineReceiptMessage._meta_ =  {fields:  {}};
PrintPaymentDeclineReceiptMessage._meta_._class_ =  PrintPaymentDeclineReceiptMessage;
PrintPaymentDeclineReceiptMessage._meta_.fields["payment"] = {};
PrintPaymentDeclineReceiptMessage._meta_.fields["payment"].type = payments_Payment;
PrintPaymentDeclineReceiptMessage._meta_.fields["reason"] = {};
PrintPaymentDeclineReceiptMessage._meta_.fields["reason"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintPaymentDeclineReceiptMessage;
}

