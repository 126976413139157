/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof payments
*/
var TaxableAmountRate = function() {
  this._class_ = TaxableAmountRate;
  this.id = undefined;
  this.name = undefined;
  this.taxableAmount = undefined;
  this.rate = undefined;
  this.isVat = undefined;
  this.taxAmount = undefined;
  this.transactionRef = undefined;
};


/**
* Set the field value
* Tax rate id.
*
* @memberof payments.TaxableAmountRate
* @param {String} id 
*/
TaxableAmountRate.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Tax rate id.
* @memberof payments.TaxableAmountRate
* @return {String} 
*/
TaxableAmountRate.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Tax rate name. This field is optional.
*
* @memberof payments.TaxableAmountRate
* @param {String} name 
*/
TaxableAmountRate.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Tax rate name. This field is optional.
* @memberof payments.TaxableAmountRate
* @return {String} 
*/
TaxableAmountRate.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* The sub-total of line items that the tax was based on. For VAT, this amount includes tax. For all others, this amount does not include tax.
*
* @memberof payments.TaxableAmountRate
* @param {Number} taxableAmount must be a long integer
*/
TaxableAmountRate.prototype.setTaxableAmount = function(taxableAmount) {
  this.taxableAmount = taxableAmount;
};

/**
* Get the field value
* The sub-total of line items that the tax was based on. For VAT, this amount includes tax. For all others, this amount does not include tax.
* @memberof payments.TaxableAmountRate
* @return {Number} must be a long integer
*/
TaxableAmountRate.prototype.getTaxableAmount = function() {
  return this.taxableAmount;
};

/**
* Set the field value
* The tax rate. 100% = 1e7.
*
* @memberof payments.TaxableAmountRate
* @param {Number} rate must be a long integer
*/
TaxableAmountRate.prototype.setRate = function(rate) {
  this.rate = rate;
};

/**
* Get the field value
* The tax rate. 100% = 1e7.
* @memberof payments.TaxableAmountRate
* @return {Number} must be a long integer
*/
TaxableAmountRate.prototype.getRate = function() {
  return this.rate;
};

/**
* Set the field value
* Whether the taxable amount includes tax.
*
* @memberof payments.TaxableAmountRate
* @param {Boolean} isVat 
*/
TaxableAmountRate.prototype.setIsVat = function(isVat) {
  this.isVat = isVat;
};

/**
* Get the field value
* Whether the taxable amount includes tax.
* @memberof payments.TaxableAmountRate
* @return {Boolean} 
*/
TaxableAmountRate.prototype.getIsVat = function() {
  return this.isVat;
};

/**
* Set the field value
* The amount of tax collected. In cases where there are multiple payments for an order then it may be necessary to add an extra cent of tax to the last payment.
*
* @memberof payments.TaxableAmountRate
* @param {Number} taxAmount must be a long integer
*/
TaxableAmountRate.prototype.setTaxAmount = function(taxAmount) {
  this.taxAmount = taxAmount;
};

/**
* Get the field value
* The amount of tax collected. In cases where there are multiple payments for an order then it may be necessary to add an extra cent of tax to the last payment.
* @memberof payments.TaxableAmountRate
* @return {Number} must be a long integer
*/
TaxableAmountRate.prototype.getTaxAmount = function() {
  return this.taxAmount;
};

/**
* Set the field value
* The refund with which the tax rate is associated
*
* @memberof payments.TaxableAmountRate
* @param {base.Reference} transactionRef 
*/
TaxableAmountRate.prototype.setTransactionRef = function(transactionRef) {
  this.transactionRef = transactionRef;
};

/**
* Get the field value
* The refund with which the tax rate is associated
* @memberof payments.TaxableAmountRate
* @return {base.Reference} 
*/
TaxableAmountRate.prototype.getTransactionRef = function() {
  return this.transactionRef;
};

/**
* @memberof payments.TaxableAmountRate
* @private
*/
TaxableAmountRate.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

TaxableAmountRate.prototype.toString = function() {
  return JSON.stringify(this);
};

TaxableAmountRate._meta_ =  {fields:  {}};
TaxableAmountRate._meta_._class_ =  TaxableAmountRate;
TaxableAmountRate._meta_.fields["id"] = {};
TaxableAmountRate._meta_.fields["id"].type = String;
TaxableAmountRate._meta_.fields["name"] = {};
TaxableAmountRate._meta_.fields["name"].type = String;
TaxableAmountRate._meta_.fields["taxableAmount"] = {};
TaxableAmountRate._meta_.fields["taxableAmount"].type = Number;
TaxableAmountRate._meta_.fields["rate"] = {};
TaxableAmountRate._meta_.fields["rate"].type = Number;
TaxableAmountRate._meta_.fields["isVat"] = {};
TaxableAmountRate._meta_.fields["isVat"].type = Boolean;
TaxableAmountRate._meta_.fields["taxAmount"] = {};
TaxableAmountRate._meta_.fields["taxAmount"].type = Number;
TaxableAmountRate._meta_.fields["transactionRef"] = {};
TaxableAmountRate._meta_.fields["transactionRef"].type = base_Reference;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TaxableAmountRate;
}

