/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_VasConfigMessage = require("../remotemessage/VasConfigMessage");

/**
* @constructor
* @augments remotemessage.VasConfigMessage
* @memberof remotemessage
*/
var VasConfigChangedMessage = function() {
  remotemessage_VasConfigMessage.call(this);
  this._superClass_ = remotemessage_VasConfigMessage;
  this._class_ = VasConfigChangedMessage;
  this.setMethod(remotemessage_Method["VAS_CONFIG_CHANGED"]);
  this.proxyProvider = undefined;
};

VasConfigChangedMessage.prototype = Object.create(remotemessage_VasConfigMessage.prototype);
VasConfigChangedMessage.prototype.constructor = VasConfigChangedMessage;

/**
* Set the field value
* The package name of the proxy that is set for the vas providers.
*
* @memberof remotemessage.VasConfigChangedMessage
* @param {String} proxyProvider 
*/
VasConfigChangedMessage.prototype.setProxyProvider = function(proxyProvider) {
  this.proxyProvider = proxyProvider;
};

/**
* Get the field value
* The package name of the proxy that is set for the vas providers.
* @memberof remotemessage.VasConfigChangedMessage
* @return {String} 
*/
VasConfigChangedMessage.prototype.getProxyProvider = function() {
  return this.proxyProvider;
};

VasConfigChangedMessage._meta_ =  {fields:  {}};
VasConfigChangedMessage._meta_._class_ =  VasConfigChangedMessage;
VasConfigChangedMessage._meta_._superMeta_ = remotemessage_VasConfigMessage._meta_;
VasConfigChangedMessage._meta_.fields["proxyProvider"] = {};
VasConfigChangedMessage._meta_.fields["proxyProvider"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasConfigChangedMessage;
}

