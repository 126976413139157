/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var inventory_ItemStock = require("../inventory/ItemStock");
var inventory_TaxRate = require("../inventory/TaxRate");
var inventory_MenuItem = require("../inventory/MenuItem");
var inventory_ModifierGroup = require("../inventory/ModifierGroup");
var inventory_PriceType = require("../inventory/PriceType");
var inventory_Category = require("../inventory/Category");
var base_Reference = require("../base/Reference");
var inventory_Tag = require("../inventory/Tag");
var inventory_Option = require("../inventory/Option");

/**
* @constructor
* @memberof inventory
*/
var Item = function() {
  this._class_ = Item;
  this.id = undefined;
  this.hidden = false;
  this.itemGroup = undefined;
  this.options = undefined;
  this.name = undefined;
  this.alternateName = undefined;
  this.code = undefined;
  this.sku = undefined;
  this.price = undefined;
  this.priceType = undefined;
  this.defaultTaxRates = true;
  this.unitName = undefined;
  this.cost = undefined;
  this.isRevenue = false;
  this.stockCount = undefined;
  this.taxRates = undefined;
  this.modifierGroups = undefined;
  this.categories = undefined;
  this.tags = undefined;
  this.canonical = undefined;
  this.itemStock = undefined;
  this.menuItem = undefined;
  this.modifiedTime = undefined;
  this.deletedTime = undefined;
  this.priceWithoutVat = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof inventory.Item
* @param {String} id 
*/
Item.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof inventory.Item
* @return {String} 
*/
Item.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* True if this item is hidden from Clover Register App
*
* @memberof inventory.Item
* @param {Boolean|Null} hidden 
*/
Item.prototype.setHidden = function(hidden) {
  this.hidden = hidden;
};

/**
* Get the field value
* True if this item is hidden from Clover Register App
* @memberof inventory.Item
* @return {Boolean|Null} 
*/
Item.prototype.getHidden = function() {
  return this.hidden;
};

/**
* Set the field value
* Reference to an item group
*
* @memberof inventory.Item
* @param {Null|base.Reference} itemGroup 
*/
Item.prototype.setItemGroup = function(itemGroup) {
  this.itemGroup = itemGroup;
};

/**
* Get the field value
* Reference to an item group
* @memberof inventory.Item
* @return {Null|base.Reference} 
*/
Item.prototype.getItemGroup = function() {
  return this.itemGroup;
};

/**
* Set the field value
* List of options pertaining to this item's attribute variation
*
* @memberof inventory.Item
* @param {Null|Array.<inventory.Option>} options An array of 
*/
Item.prototype.setOptions = function(options) {
  this.options = options;
};

/**
* Get the field value
* List of options pertaining to this item's attribute variation
* @memberof inventory.Item
* @return {Null|Array.<inventory.Option>} An array of 
*/
Item.prototype.getOptions = function() {
  return this.options;
};

/**
* Set the field value
* Name of the item
*
* @memberof inventory.Item
* @param {String} name 
*/
Item.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Name of the item
* @memberof inventory.Item
* @return {String} 
*/
Item.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* Alternate name of the item
*
* @memberof inventory.Item
* @param {Null|String} alternateName 
*/
Item.prototype.setAlternateName = function(alternateName) {
  this.alternateName = alternateName;
};

/**
* Get the field value
* Alternate name of the item
* @memberof inventory.Item
* @return {Null|String} 
*/
Item.prototype.getAlternateName = function() {
  return this.alternateName;
};

/**
* Set the field value
* Product code, e.g. UPC or EAN
*
* @memberof inventory.Item
* @param {Null|String} code 
*/
Item.prototype.setCode = function(code) {
  this.code = code;
};

/**
* Get the field value
* Product code, e.g. UPC or EAN
* @memberof inventory.Item
* @return {Null|String} 
*/
Item.prototype.getCode = function() {
  return this.code;
};

/**
* Set the field value
* SKU of the item
*
* @memberof inventory.Item
* @param {Null|String} sku 
*/
Item.prototype.setSku = function(sku) {
  this.sku = sku;
};

/**
* Get the field value
* SKU of the item
* @memberof inventory.Item
* @return {Null|String} 
*/
Item.prototype.getSku = function() {
  return this.sku;
};

/**
* Set the field value
* Price of the item, typically in cents; use priceType and merchant currency to determine actual item price. For non-VAT merchants, this field is exclusive of tax. For VAT merchants, this field is inclusive of tax.
*
* @memberof inventory.Item
* @param {Number} price must be a long integer
*/
Item.prototype.setPrice = function(price) {
  this.price = price;
};

/**
* Get the field value
* Price of the item, typically in cents; use priceType and merchant currency to determine actual item price. For non-VAT merchants, this field is exclusive of tax. For VAT merchants, this field is inclusive of tax.
* @memberof inventory.Item
* @return {Number} must be a long integer
*/
Item.prototype.getPrice = function() {
  return this.price;
};

/**
* Set the field value
* @memberof inventory.Item
* @param {inventory.PriceType} priceType 
*/
Item.prototype.setPriceType = function(priceType) {
  this.priceType = priceType;
};

/**
* Get the field value
* @memberof inventory.Item
* @return {inventory.PriceType} 
*/
Item.prototype.getPriceType = function() {
  return this.priceType;
};

/**
* Set the field value
* Flag to indicate whether or not to use default tax rates
*
* @memberof inventory.Item
* @param {Boolean|Null} defaultTaxRates 
*/
Item.prototype.setDefaultTaxRates = function(defaultTaxRates) {
  this.defaultTaxRates = defaultTaxRates;
};

/**
* Get the field value
* Flag to indicate whether or not to use default tax rates
* @memberof inventory.Item
* @return {Boolean|Null} 
*/
Item.prototype.getDefaultTaxRates = function() {
  return this.defaultTaxRates;
};

/**
* Set the field value
* Unit name, e.g. oz, lb
*
* @memberof inventory.Item
* @param {Null|String} unitName 
*/
Item.prototype.setUnitName = function(unitName) {
  this.unitName = unitName;
};

/**
* Get the field value
* Unit name, e.g. oz, lb
* @memberof inventory.Item
* @return {Null|String} 
*/
Item.prototype.getUnitName = function() {
  return this.unitName;
};

/**
* Set the field value
* Cost of the item to merchant, as opposed to customer price
*
* @memberof inventory.Item
* @param {Null|Number} cost must be a long integer
*/
Item.prototype.setCost = function(cost) {
  this.cost = cost;
};

/**
* Get the field value
* Cost of the item to merchant, as opposed to customer price
* @memberof inventory.Item
* @return {Null|Number} must be a long integer
*/
Item.prototype.getCost = function() {
  return this.cost;
};

/**
* Set the field value
* True if this item should be counted as revenue, for example gift cards and donations would not
*
* @memberof inventory.Item
* @param {Boolean|Null} isRevenue 
*/
Item.prototype.setIsRevenue = function(isRevenue) {
  this.isRevenue = isRevenue;
};

/**
* Get the field value
* True if this item should be counted as revenue, for example gift cards and donations would not
* @memberof inventory.Item
* @return {Boolean|Null} 
*/
Item.prototype.getIsRevenue = function() {
  return this.isRevenue;
};

/**
* Set the field value
* Deprecated, use itemStock instead.
*
* @memberof inventory.Item
* @param {Null|Number} stockCount must be a long integer
*/
Item.prototype.setStockCount = function(stockCount) {
  this.stockCount = stockCount;
};

/**
* Get the field value
* Deprecated, use itemStock instead.
* @memberof inventory.Item
* @return {Null|Number} must be a long integer
*/
Item.prototype.getStockCount = function() {
  return this.stockCount;
};

/**
* Set the field value
* @memberof inventory.Item
* @param {Null|Array.<inventory.TaxRate>} taxRates An array of 
*/
Item.prototype.setTaxRates = function(taxRates) {
  this.taxRates = taxRates;
};

/**
* Get the field value
* @memberof inventory.Item
* @return {Null|Array.<inventory.TaxRate>} An array of 
*/
Item.prototype.getTaxRates = function() {
  return this.taxRates;
};

/**
* Set the field value
* @memberof inventory.Item
* @param {Null|Array.<inventory.ModifierGroup>} modifierGroups An array of 
*/
Item.prototype.setModifierGroups = function(modifierGroups) {
  this.modifierGroups = modifierGroups;
};

/**
* Get the field value
* @memberof inventory.Item
* @return {Null|Array.<inventory.ModifierGroup>} An array of 
*/
Item.prototype.getModifierGroups = function() {
  return this.modifierGroups;
};

/**
* Set the field value
* Categories associated with this item
*
* @memberof inventory.Item
* @param {Null|Array.<inventory.Category>} categories An array of 
*/
Item.prototype.setCategories = function(categories) {
  this.categories = categories;
};

/**
* Get the field value
* Categories associated with this item
* @memberof inventory.Item
* @return {Null|Array.<inventory.Category>} An array of 
*/
Item.prototype.getCategories = function() {
  return this.categories;
};

/**
* Set the field value
* Tags associated with this item
*
* @memberof inventory.Item
* @param {Null|Array.<inventory.Tag>} tags An array of 
*/
Item.prototype.setTags = function(tags) {
  this.tags = tags;
};

/**
* Get the field value
* Tags associated with this item
* @memberof inventory.Item
* @return {Null|Array.<inventory.Tag>} An array of 
*/
Item.prototype.getTags = function() {
  return this.tags;
};

/**
* Set the field value
* Reference to canonical items
*
* @memberof inventory.Item
* @param {Null|base.Reference} canonical 
*/
Item.prototype.setCanonical = function(canonical) {
  this.canonical = canonical;
};

/**
* Get the field value
* Reference to canonical items
* @memberof inventory.Item
* @return {Null|base.Reference} 
*/
Item.prototype.getCanonical = function() {
  return this.canonical;
};

/**
* Set the field value
* Item stock attribute that can be expanded to show stock quantity
*
* @memberof inventory.Item
* @param {Null|inventory.ItemStock} itemStock 
*/
Item.prototype.setItemStock = function(itemStock) {
  this.itemStock = itemStock;
};

/**
* Get the field value
* Item stock attribute that can be expanded to show stock quantity
* @memberof inventory.Item
* @return {Null|inventory.ItemStock} 
*/
Item.prototype.getItemStock = function() {
  return this.itemStock;
};

/**
* Set the field value
* Menu Item attribute that can be expanded to menu specific attributes
*
* @memberof inventory.Item
* @param {Null|inventory.MenuItem} menuItem 
*/
Item.prototype.setMenuItem = function(menuItem) {
  this.menuItem = menuItem;
};

/**
* Get the field value
* Menu Item attribute that can be expanded to menu specific attributes
* @memberof inventory.Item
* @return {Null|inventory.MenuItem} 
*/
Item.prototype.getMenuItem = function() {
  return this.menuItem;
};

/**
* Set the field value
* @memberof inventory.Item
* @param {Null|Number} modifiedTime must be a long integer
*/
Item.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* @memberof inventory.Item
* @return {Null|Number} must be a long integer
*/
Item.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* Timestamp when item was last deleted
*
* @memberof inventory.Item
* @param {Null|Number} deletedTime must be a long integer
*/
Item.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* Timestamp when item was last deleted
* @memberof inventory.Item
* @return {Null|Number} must be a long integer
*/
Item.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* Set the field value
* The price without value-added tax (VAT). For non-VAT merchants, this field is ignored. For VAT merchants, this field is the base price of an item.
*
* @memberof inventory.Item
* @param {Null|Number} priceWithoutVat must be a long integer
*/
Item.prototype.setPriceWithoutVat = function(priceWithoutVat) {
  this.priceWithoutVat = priceWithoutVat;
};

/**
* Get the field value
* The price without value-added tax (VAT). For non-VAT merchants, this field is ignored. For VAT merchants, this field is the base price of an item.
* @memberof inventory.Item
* @return {Null|Number} must be a long integer
*/
Item.prototype.getPriceWithoutVat = function() {
  return this.priceWithoutVat;
};

/**
* @memberof inventory.Item
* @private
*/
Item.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Item.prototype.toString = function() {
  return JSON.stringify(this);
};

Item._meta_ =  {fields:  {}};
Item._meta_._class_ =  Item;
Item._meta_.fields["id"] = {};
Item._meta_.fields["id"].type = String;
Item._meta_.fields["hidden"] = {};
Item._meta_.fields["hidden"].type = Boolean;
Item._meta_.fields["itemGroup"] = {};
Item._meta_.fields["itemGroup"].type = base_Reference;
Item._meta_.fields["options"] = {};
Item._meta_.fields["options"].type = Array;
Item._meta_.fields["options"].elementType = inventory_Option;
Item._meta_.fields["name"] = {};
Item._meta_.fields["name"].type = String;
Item._meta_.fields["alternateName"] = {};
Item._meta_.fields["alternateName"].type = String;
Item._meta_.fields["code"] = {};
Item._meta_.fields["code"].type = String;
Item._meta_.fields["sku"] = {};
Item._meta_.fields["sku"].type = String;
Item._meta_.fields["price"] = {};
Item._meta_.fields["price"].type = Number;
Item._meta_.fields["priceType"] = {};
Item._meta_.fields["priceType"].type = inventory_PriceType;
Item._meta_.fields["defaultTaxRates"] = {};
Item._meta_.fields["defaultTaxRates"].type = Boolean;
Item._meta_.fields["unitName"] = {};
Item._meta_.fields["unitName"].type = String;
Item._meta_.fields["cost"] = {};
Item._meta_.fields["cost"].type = Number;
Item._meta_.fields["isRevenue"] = {};
Item._meta_.fields["isRevenue"].type = Boolean;
Item._meta_.fields["stockCount"] = {};
Item._meta_.fields["stockCount"].type = Number;
Item._meta_.fields["taxRates"] = {};
Item._meta_.fields["taxRates"].type = Array;
Item._meta_.fields["taxRates"].elementType = inventory_TaxRate;
Item._meta_.fields["modifierGroups"] = {};
Item._meta_.fields["modifierGroups"].type = Array;
Item._meta_.fields["modifierGroups"].elementType = inventory_ModifierGroup;
Item._meta_.fields["categories"] = {};
Item._meta_.fields["categories"].type = Array;
Item._meta_.fields["categories"].elementType = inventory_Category;
Item._meta_.fields["tags"] = {};
Item._meta_.fields["tags"].type = Array;
Item._meta_.fields["tags"].elementType = inventory_Tag;
Item._meta_.fields["canonical"] = {};
Item._meta_.fields["canonical"].type = base_Reference;
Item._meta_.fields["itemStock"] = {};
Item._meta_.fields["itemStock"].type = inventory_ItemStock;
Item._meta_.fields["menuItem"] = {};
Item._meta_.fields["menuItem"].type = inventory_MenuItem;
Item._meta_.fields["modifiedTime"] = {};
Item._meta_.fields["modifiedTime"].type = Number;
Item._meta_.fields["deletedTime"] = {};
Item._meta_.fields["deletedTime"].type = Number;
Item._meta_.fields["priceWithoutVat"] = {};
Item._meta_.fields["priceWithoutVat"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Item;
}

