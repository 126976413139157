/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var EmployeePermissionsRequest = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = EmployeePermissionsRequest;
  this.setMethod(remotemessage_Method["EMPLOYEE_PERMISSIONS_REQUEST"]);
  this.reason = undefined;
  this.permissions = undefined;
  this.appPackage = undefined;
  this.showCancelButton = undefined;
};

EmployeePermissionsRequest.prototype = Object.create(remotemessage_Message.prototype);
EmployeePermissionsRequest.prototype.constructor = EmployeePermissionsRequest;

/**
* Set the field value
* Detail message from the server.
*
* @memberof remotemessage.EmployeePermissionsRequest
* @param {String} reason 
*/
EmployeePermissionsRequest.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* Detail message from the server.
* @memberof remotemessage.EmployeePermissionsRequest
* @return {String} 
*/
EmployeePermissionsRequest.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* @memberof remotemessage.EmployeePermissionsRequest
* @param {String} permissions 
*/
EmployeePermissionsRequest.prototype.setPermissions = function(permissions) {
  this.permissions = permissions;
};

/**
* Get the field value
* @memberof remotemessage.EmployeePermissionsRequest
* @return {String} 
*/
EmployeePermissionsRequest.prototype.getPermissions = function() {
  return this.permissions;
};

/**
* Set the field value
* @memberof remotemessage.EmployeePermissionsRequest
* @param {String} appPackage 
*/
EmployeePermissionsRequest.prototype.setAppPackage = function(appPackage) {
  this.appPackage = appPackage;
};

/**
* Get the field value
* @memberof remotemessage.EmployeePermissionsRequest
* @return {String} 
*/
EmployeePermissionsRequest.prototype.getAppPackage = function() {
  return this.appPackage;
};

/**
* Set the field value
* @memberof remotemessage.EmployeePermissionsRequest
* @param {Boolean} showCancelButton 
*/
EmployeePermissionsRequest.prototype.setShowCancelButton = function(showCancelButton) {
  this.showCancelButton = showCancelButton;
};

/**
* Get the field value
* @memberof remotemessage.EmployeePermissionsRequest
* @return {Boolean} 
*/
EmployeePermissionsRequest.prototype.getShowCancelButton = function() {
  return this.showCancelButton;
};

EmployeePermissionsRequest._meta_ =  {fields:  {}};
EmployeePermissionsRequest._meta_._class_ =  EmployeePermissionsRequest;
EmployeePermissionsRequest._meta_._superMeta_ = remotemessage_Message._meta_;
EmployeePermissionsRequest._meta_.fields["reason"] = {};
EmployeePermissionsRequest._meta_.fields["reason"].type = String;
EmployeePermissionsRequest._meta_.fields["permissions"] = {};
EmployeePermissionsRequest._meta_.fields["permissions"].type = String;
EmployeePermissionsRequest._meta_.fields["appPackage"] = {};
EmployeePermissionsRequest._meta_.fields["appPackage"].type = String;
EmployeePermissionsRequest._meta_.fields["showCancelButton"] = {};
EmployeePermissionsRequest._meta_.fields["showCancelButton"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = EmployeePermissionsRequest;
}

