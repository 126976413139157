import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
  },
  title: {
    fontSize: 12,
    letterSpacing: 1.25,
  },
  titleSeeMoreResults: {
    fontSize: 12,
    color: '#89909B',
    letterSpacing: 1.25,
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
    marginBottom: 20,
  },
});
