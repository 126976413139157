/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof payments
*/
var SignatureDisclaimer = function() {
  this._class_ = SignatureDisclaimer;
  this.disclaimerText = undefined;
  this.disclaimerValues = undefined;
};


/**
* Set the field value
* @memberof payments.SignatureDisclaimer
* @param {String|Null} disclaimerText 
*/
SignatureDisclaimer.prototype.setDisclaimerText = function(disclaimerText) {
  this.disclaimerText = disclaimerText;
};

/**
* Get the field value
* @memberof payments.SignatureDisclaimer
* @return {String|Null} 
*/
SignatureDisclaimer.prototype.getDisclaimerText = function() {
  return this.disclaimerText;
};

/**
* Set the field value
* Values that will be substituted in standard disclaimer text (txn date/time, account number, product label, etc.
*
* @memberof payments.SignatureDisclaimer
* @param {Object.<String,String>} disclaimerValues A map of <String> to <>
*/
SignatureDisclaimer.prototype.setDisclaimerValues = function(disclaimerValues) {
  this.disclaimerValues = disclaimerValues;
};

/**
* Get the field value
* Values that will be substituted in standard disclaimer text (txn date/time, account number, product label, etc.
* @memberof payments.SignatureDisclaimer
* @return {Object.<String,String>} A map of <String> to <>
*/
SignatureDisclaimer.prototype.getDisclaimerValues = function() {
  return this.disclaimerValues;
};

/**
* @memberof payments.SignatureDisclaimer
* @private
*/
SignatureDisclaimer.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

SignatureDisclaimer.prototype.toString = function() {
  return JSON.stringify(this);
};

SignatureDisclaimer._meta_ =  {fields:  {}};
SignatureDisclaimer._meta_._class_ =  SignatureDisclaimer;
SignatureDisclaimer._meta_.fields["disclaimerText"] = {};
SignatureDisclaimer._meta_.fields["disclaimerText"].type = String;
SignatureDisclaimer._meta_.fields["disclaimerValues"] = {};
SignatureDisclaimer._meta_.fields["disclaimerValues"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = SignatureDisclaimer;
}

