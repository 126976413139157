/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var employees_Employee = require("../employees/Employee");

/**
* @constructor
* @memberof employees
*/
var Shift = function() {
  this._class_ = Shift;
  this.id = undefined;
  this.employee = undefined;
  this.cashTipsCollected = undefined;
  this.serverBanking = undefined;
  this.inTime = undefined;
  this.overrideInTime = undefined;
  this.overrideInEmployee = undefined;
  this.outTime = undefined;
  this.overrideOutTime = undefined;
  this.overrideOutEmployee = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof employees.Shift
* @param {String} id 
*/
Shift.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof employees.Shift
* @return {String} 
*/
Shift.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* The employee that worked this shift
*
* @memberof employees.Shift
* @param {employees.Employee} employee 
*/
Shift.prototype.setEmployee = function(employee) {
  this.employee = employee;
};

/**
* Get the field value
* The employee that worked this shift
* @memberof employees.Shift
* @return {employees.Employee} 
*/
Shift.prototype.getEmployee = function() {
  return this.employee;
};

/**
* Set the field value
* Amount of cash tips collected
*
* @memberof employees.Shift
* @param {Number} cashTipsCollected must be a long integer
*/
Shift.prototype.setCashTipsCollected = function(cashTipsCollected) {
  this.cashTipsCollected = cashTipsCollected;
};

/**
* Get the field value
* Amount of cash tips collected
* @memberof employees.Shift
* @return {Number} must be a long integer
*/
Shift.prototype.getCashTipsCollected = function() {
  return this.cashTipsCollected;
};

/**
* Set the field value
* Whether the employee used server banking
*
* @memberof employees.Shift
* @param {Boolean} serverBanking 
*/
Shift.prototype.setServerBanking = function(serverBanking) {
  this.serverBanking = serverBanking;
};

/**
* Get the field value
* Whether the employee used server banking
* @memberof employees.Shift
* @return {Boolean} 
*/
Shift.prototype.getServerBanking = function() {
  return this.serverBanking;
};

/**
* Set the field value
* Clock in time
*
* @memberof employees.Shift
* @param {Number} inTime must be a long integer
*/
Shift.prototype.setInTime = function(inTime) {
  this.inTime = inTime;
};

/**
* Get the field value
* Clock in time
* @memberof employees.Shift
* @return {Number} must be a long integer
*/
Shift.prototype.getInTime = function() {
  return this.inTime;
};

/**
* Set the field value
* Overridden clock in time
*
* @memberof employees.Shift
* @param {Number} overrideInTime must be a long integer
*/
Shift.prototype.setOverrideInTime = function(overrideInTime) {
  this.overrideInTime = overrideInTime;
};

/**
* Get the field value
* Overridden clock in time
* @memberof employees.Shift
* @return {Number} must be a long integer
*/
Shift.prototype.getOverrideInTime = function() {
  return this.overrideInTime;
};

/**
* Set the field value
* The employee who overrode the clock in time
*
* @memberof employees.Shift
* @param {employees.Employee} overrideInEmployee 
*/
Shift.prototype.setOverrideInEmployee = function(overrideInEmployee) {
  this.overrideInEmployee = overrideInEmployee;
};

/**
* Get the field value
* The employee who overrode the clock in time
* @memberof employees.Shift
* @return {employees.Employee} 
*/
Shift.prototype.getOverrideInEmployee = function() {
  return this.overrideInEmployee;
};

/**
* Set the field value
* Clock out time
*
* @memberof employees.Shift
* @param {Number} outTime must be a long integer
*/
Shift.prototype.setOutTime = function(outTime) {
  this.outTime = outTime;
};

/**
* Get the field value
* Clock out time
* @memberof employees.Shift
* @return {Number} must be a long integer
*/
Shift.prototype.getOutTime = function() {
  return this.outTime;
};

/**
* Set the field value
* Overridden clock out time
*
* @memberof employees.Shift
* @param {Number} overrideOutTime must be a long integer
*/
Shift.prototype.setOverrideOutTime = function(overrideOutTime) {
  this.overrideOutTime = overrideOutTime;
};

/**
* Get the field value
* Overridden clock out time
* @memberof employees.Shift
* @return {Number} must be a long integer
*/
Shift.prototype.getOverrideOutTime = function() {
  return this.overrideOutTime;
};

/**
* Set the field value
* The employee who overrode the clock out time
*
* @memberof employees.Shift
* @param {employees.Employee} overrideOutEmployee 
*/
Shift.prototype.setOverrideOutEmployee = function(overrideOutEmployee) {
  this.overrideOutEmployee = overrideOutEmployee;
};

/**
* Get the field value
* The employee who overrode the clock out time
* @memberof employees.Shift
* @return {employees.Employee} 
*/
Shift.prototype.getOverrideOutEmployee = function() {
  return this.overrideOutEmployee;
};

/**
* @memberof employees.Shift
* @private
*/
Shift.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Shift.prototype.toString = function() {
  return JSON.stringify(this);
};

Shift._meta_ =  {fields:  {}};
Shift._meta_._class_ =  Shift;
Shift._meta_.fields["id"] = {};
Shift._meta_.fields["id"].type = String;
Shift._meta_.fields["employee"] = {};
Shift._meta_.fields["employee"].type = employees_Employee;
Shift._meta_.fields["cashTipsCollected"] = {};
Shift._meta_.fields["cashTipsCollected"].type = Number;
Shift._meta_.fields["serverBanking"] = {};
Shift._meta_.fields["serverBanking"].type = Boolean;
Shift._meta_.fields["inTime"] = {};
Shift._meta_.fields["inTime"].type = Number;
Shift._meta_.fields["overrideInTime"] = {};
Shift._meta_.fields["overrideInTime"].type = Number;
Shift._meta_.fields["overrideInEmployee"] = {};
Shift._meta_.fields["overrideInEmployee"].type = employees_Employee;
Shift._meta_.fields["outTime"] = {};
Shift._meta_.fields["outTime"].type = Number;
Shift._meta_.fields["overrideOutTime"] = {};
Shift._meta_.fields["overrideOutTime"].type = Number;
Shift._meta_.fields["overrideOutEmployee"] = {};
Shift._meta_.fields["overrideOutEmployee"].type = employees_Employee;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Shift;
}

