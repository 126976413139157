/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var VasPushMode = {
  PUSH_NONE : "PUSH_NONE",
  PUSH_ONLY : "PUSH_ONLY",
  PUSH_AND_GET : "PUSH_AND_GET"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasPushMode;
}

