/**
 * Autogenerated by Avro
 *
 * DO NOT EDIT DIRECTLY
 */


/**
 We can not include WELCOME_SCREEN, THANKYOU_SCREEN, DISPLAY_MESSAGE, DISPLAY_ORDER until we can filter them out in remote-pay before they go through the interface to older versions of the sdk
 * @memberof remotepay
 * @enum {string}
 */
var DeviceEventState = {
  ADD_SIGNATURE : "ADD_SIGNATURE",
  ADD_SIGNATURE_CANCEL_CONFIRM : "ADD_SIGNATURE_CANCEL_CONFIRM",
  ADD_TIP : "ADD_TIP",
  APPROVE_ELV_LIMIT_OVERRIDE : "APPROVE_ELV_LIMIT_OVERRIDE",
  APPROVED : "APPROVED",
  CANCELED : "CANCELED",
  CASHBACK_CONFIRM : "CASHBACK_CONFIRM",
  CASHBACK_SELECT : "CASHBACK_SELECT",
  CONFIGURING : "CONFIGURING",
  CONFIRM_AMOUNT : "CONFIRM_AMOUNT",
  CONFIRM_DUPLICATE_CHECK : "CONFIRM_DUPLICATE_CHECK",
  CONFIRM_PARTIAL_AUTH : "CONFIRM_PARTIAL_AUTH",
  CONTACTLESS_TAP_REQUIRED : "CONTACTLESS_TAP_REQUIRED",
  CUSTOM_ACTIVITY : "CUSTOM_ACTIVITY",
  CUSTOM_TIP_AMOUNT : "CUSTOM_TIP_AMOUNT",
  DCC_HOST_REQUEST : "DCC_HOST_REQUEST",
  DECLINED : "DECLINED",
  ENTER_INSTALLMENT_CODE : "ENTER_INSTALLMENT_CODE",
  ENTER_INSTALLMENTS : "ENTER_INSTALLMENTS",
  ENTER_PAN_LAST_FOUR : "ENTER_PAN_LAST_FOUR",
  ERROR_SCREEN : "ERROR_SCREEN",
  FAILED : "FAILED",
  FATAL : "FATAL",
  FISCAL_INVOICE_NUMBER : "FISCAL_INVOICE_NUMBER",
  FORCE_ACCEPTANCE : "FORCE_ACCEPTANCE",
  HANDLE_TENDER : "HANDLE_TENDER",
  INPUT_ERROR : "INPUT_ERROR",
  MANUAL_CARD_CVV : "MANUAL_CARD_CVV",
  MANUAL_CARD_CVV_UNREADABLE : "MANUAL_CARD_CVV_UNREADABLE",
  MANUAL_CARD_EXPIRATION : "MANUAL_CARD_EXPIRATION",
  MANUAL_CARD_NUMBER : "MANUAL_CARD_NUMBER",
  MANUAL_ENTRY_FALLBACK : "MANUAL_ENTRY_FALLBACK",
  OFFLINE_PAYMENT_CONFIRM : "OFFLINE_PAYMENT_CONFIRM",
  PACKET_EXCEPTION : "PACKET_EXCEPTION",
  PERSONAL_ID_ENTRY : "PERSONAL_ID_ENTRY",
  PERSONAL_ID_ENTRY_PAS : "PERSONAL_ID_ENTRY_PAS",
  PIN_BYPASS_CONFIRM : "PIN_BYPASS_CONFIRM",
  PIN_PAD : "PIN_PAD",
  PROCESSING : "PROCESSING",
  PROCESSING_CREDIT : "PROCESSING_CREDIT",
  PROCESSING_GO_ONLINE : "PROCESSING_GO_ONLINE",
  PROCESSING_SWIPE : "PROCESSING_SWIPE",
  RECEIPT_OPTIONS : "RECEIPT_OPTIONS",
  REMOVE_CARD : "REMOVE_CARD",
  RETURN_TO_MERCHANT : "RETURN_TO_MERCHANT",
  SELECT_ACCOUNT : "SELECT_ACCOUNT",
  SELECT_APPLICATION : "SELECT_APPLICATION",
  SELECT_DCC : "SELECT_DCC",
  SELECT_INSTALLMENT_PLAN : "SELECT_INSTALLMENT_PLAN",
  SELECT_LANGUAGE : "SELECT_LANGUAGE",
  SELECT_MULTI_MID : "SELECT_MULTI_MID",
  SELECT_TIP : "SELECT_TIP",
  SELECT_WITHDRAW_FROM_ACCOUNT : "SELECT_WITHDRAW_FROM_ACCOUNT",
  SENSORY_EXPERIENCE : "SENSORY_EXPERIENCE",
  SHOW_SEPA_MANDAT : "SHOW_SEPA_MANDAT",
  SIGNATURE_CUSTOMER_MODE : "SIGNATURE_CUSTOMER_MODE",
  SIGNATURE_ON_SCREEN_FALLBACK : "SIGNATURE_ON_SCREEN_FALLBACK",
  SIGNATURE_REJECT : "SIGNATURE_REJECT",
  START : "START",
  STARTING_CUSTOM_ACTIVITY : "STARTING_CUSTOM_ACTIVITY",
  START_QR_CODE_MODE : "START_QR_CODE_MODE",
  SWIPE_CVV_ENTRY : "SWIPE_CVV_ENTRY",
  TIMED_OUT : "TIMED_OUT",
  TRY_AGAIN : "TRY_AGAIN",
  VERIFY_SIGNATURE_ON_PAPER : "VERIFY_SIGNATURE_ON_PAPER",
  VERIFY_SIGNATURE_ON_PAPER_CONFIRM_VOID : "VERIFY_SIGNATURE_ON_PAPER_CONFIRM_VOID",
  VERIFY_SIGNATURE_ON_SCREEN : "VERIFY_SIGNATURE_ON_SCREEN",
  VERIFY_SIGNATURE_ON_SCREEN_CONFIRM_VOID : "VERIFY_SIGNATURE_ON_SCREEN_CONFIRM_VOID",
  VERIFY_SURCHARGES : "VERIFY_SURCHARGES",
  VOICE_REFERRAL_RESULT : "VOICE_REFERRAL_RESULT",
  VOID_CONFIRM : "VOID_CONFIRM",
  VOIDED : "VOIDED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
  module.exports = DeviceEventState;
}

