/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/** Send a request for a closeout */
/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var CloseoutRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = CloseoutRequest;
  this.allowOpenTabs = "false";
  this.batchId = undefined;
};

CloseoutRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
CloseoutRequest.prototype.constructor = CloseoutRequest;

/**
* Set the field value
* Allow closeout if there are open tabs
*
* @memberof remotepay.CloseoutRequest
* @param {Boolean} allowOpenTabs 
*/
CloseoutRequest.prototype.setAllowOpenTabs = function(allowOpenTabs) {
  this.allowOpenTabs = allowOpenTabs;
};

/**
* Get the field value
* Allow closeout if there are open tabs
* @memberof remotepay.CloseoutRequest
* @return {Boolean} 
*/
CloseoutRequest.prototype.getAllowOpenTabs = function() {
  return this.allowOpenTabs;
};

/**
* Set the field value
* The batch id to close out.  If omitted, then the default current batch is used.
*
* @memberof remotepay.CloseoutRequest
* @param {String} batchId 
*/
CloseoutRequest.prototype.setBatchId = function(batchId) {
  this.batchId = batchId;
};

/**
* Get the field value
* The batch id to close out.  If omitted, then the default current batch is used.
* @memberof remotepay.CloseoutRequest
* @return {String} 
*/
CloseoutRequest.prototype.getBatchId = function() {
  return this.batchId;
};

CloseoutRequest._meta_ =  {fields:  {}};
CloseoutRequest._meta_._class_ =  CloseoutRequest;
CloseoutRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
CloseoutRequest._meta_.fields["allowOpenTabs"] = {};
CloseoutRequest._meta_.fields["allowOpenTabs"].type = Boolean;
CloseoutRequest._meta_.fields["batchId"] = {};
CloseoutRequest._meta_.fields["batchId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CloseoutRequest;
}

