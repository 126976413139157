/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/** The result of an attempt to capture a previously made pre auth */
/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var CapturePreAuthResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = CapturePreAuthResponse;
  this.paymentId = undefined;
  this.amount = undefined;
  this.tipAmount = undefined;
};

CapturePreAuthResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
CapturePreAuthResponse.prototype.constructor = CapturePreAuthResponse;

/**
* Set the field value
* Unique identifier for a payment
*
* @memberof remotepay.CapturePreAuthResponse
* @param {String} paymentId 
*/
CapturePreAuthResponse.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier for a payment
* @memberof remotepay.CapturePreAuthResponse
* @return {String} 
*/
CapturePreAuthResponse.prototype.getPaymentId = function() {
  return this.paymentId;
};

/**
* Set the field value
* Total amount paid
*
* @memberof remotepay.CapturePreAuthResponse
* @param {Number} amount must be a long integer
*/
CapturePreAuthResponse.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Total amount paid
* @memberof remotepay.CapturePreAuthResponse
* @return {Number} must be a long integer
*/
CapturePreAuthResponse.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* Included tip
*
* @memberof remotepay.CapturePreAuthResponse
* @param {Number} tipAmount must be a long integer
*/
CapturePreAuthResponse.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Included tip
* @memberof remotepay.CapturePreAuthResponse
* @return {Number} must be a long integer
*/
CapturePreAuthResponse.prototype.getTipAmount = function() {
  return this.tipAmount;
};

CapturePreAuthResponse._meta_ =  {fields:  {}};
CapturePreAuthResponse._meta_._class_ =  CapturePreAuthResponse;
CapturePreAuthResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
CapturePreAuthResponse._meta_.fields["paymentId"] = {};
CapturePreAuthResponse._meta_.fields["paymentId"].type = String;
CapturePreAuthResponse._meta_.fields["amount"] = {};
CapturePreAuthResponse._meta_.fields["amount"].type = Number;
CapturePreAuthResponse._meta_.fields["tipAmount"] = {};
CapturePreAuthResponse._meta_.fields["tipAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CapturePreAuthResponse;
}

