/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var DiscoveryResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = DiscoveryResponseMessage;
  this.setMethod(remotemessage_Method["DISCOVERY_RESPONSE"]);
  this.merchantId = undefined;
  this.merchantName = undefined;
  this.merchantMId = undefined;
  this.name = undefined;
  this.serial = undefined;
  this.model = undefined;
  this.ready = "false";
  this.supportsTipAdjust = "false";
  this.supportsManualRefund = "false";
  this.supportsMultiPayToken = "false";
  this.supportsAcknowledgement = "false";
  this.supportsPreAuth = "false";
  this.supportsAuth = "false";
  this.supportsVaultCard = "false";
  this.supportsVoidPaymentResponse = "false";
};

DiscoveryResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
DiscoveryResponseMessage.prototype.constructor = DiscoveryResponseMessage;

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {String} merchantId 
*/
DiscoveryResponseMessage.prototype.setMerchantId = function(merchantId) {
  this.merchantId = merchantId;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {String} 
*/
DiscoveryResponseMessage.prototype.getMerchantId = function() {
  return this.merchantId;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {String} merchantName 
*/
DiscoveryResponseMessage.prototype.setMerchantName = function(merchantName) {
  this.merchantName = merchantName;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {String} 
*/
DiscoveryResponseMessage.prototype.getMerchantName = function() {
  return this.merchantName;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {String} merchantMId 
*/
DiscoveryResponseMessage.prototype.setMerchantMId = function(merchantMId) {
  this.merchantMId = merchantMId;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {String} 
*/
DiscoveryResponseMessage.prototype.getMerchantMId = function() {
  return this.merchantMId;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {String} name 
*/
DiscoveryResponseMessage.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {String} 
*/
DiscoveryResponseMessage.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {String} serial 
*/
DiscoveryResponseMessage.prototype.setSerial = function(serial) {
  this.serial = serial;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {String} 
*/
DiscoveryResponseMessage.prototype.getSerial = function() {
  return this.serial;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {String} model 
*/
DiscoveryResponseMessage.prototype.setModel = function(model) {
  this.model = model;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {String} 
*/
DiscoveryResponseMessage.prototype.getModel = function() {
  return this.model;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} ready 
*/
DiscoveryResponseMessage.prototype.setReady = function(ready) {
  this.ready = ready;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getReady = function() {
  return this.ready;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} supportsTipAdjust 
*/
DiscoveryResponseMessage.prototype.setSupportsTipAdjust = function(supportsTipAdjust) {
  this.supportsTipAdjust = supportsTipAdjust;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getSupportsTipAdjust = function() {
  return this.supportsTipAdjust;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} supportsManualRefund 
*/
DiscoveryResponseMessage.prototype.setSupportsManualRefund = function(supportsManualRefund) {
  this.supportsManualRefund = supportsManualRefund;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getSupportsManualRefund = function() {
  return this.supportsManualRefund;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} supportsMultiPayToken 
*/
DiscoveryResponseMessage.prototype.setSupportsMultiPayToken = function(supportsMultiPayToken) {
  this.supportsMultiPayToken = supportsMultiPayToken;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getSupportsMultiPayToken = function() {
  return this.supportsMultiPayToken;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} supportsAcknowledgement 
*/
DiscoveryResponseMessage.prototype.setSupportsAcknowledgement = function(supportsAcknowledgement) {
  this.supportsAcknowledgement = supportsAcknowledgement;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getSupportsAcknowledgement = function() {
  return this.supportsAcknowledgement;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} supportsPreAuth 
*/
DiscoveryResponseMessage.prototype.setSupportsPreAuth = function(supportsPreAuth) {
  this.supportsPreAuth = supportsPreAuth;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getSupportsPreAuth = function() {
  return this.supportsPreAuth;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} supportsAuth 
*/
DiscoveryResponseMessage.prototype.setSupportsAuth = function(supportsAuth) {
  this.supportsAuth = supportsAuth;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getSupportsAuth = function() {
  return this.supportsAuth;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} supportsVaultCard 
*/
DiscoveryResponseMessage.prototype.setSupportsVaultCard = function(supportsVaultCard) {
  this.supportsVaultCard = supportsVaultCard;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getSupportsVaultCard = function() {
  return this.supportsVaultCard;
};

/**
* Set the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @param {Boolean} supportsVoidPaymentResponse 
*/
DiscoveryResponseMessage.prototype.setSupportsVoidPaymentResponse = function(supportsVoidPaymentResponse) {
  this.supportsVoidPaymentResponse = supportsVoidPaymentResponse;
};

/**
* Get the field value
* @memberof remotemessage.DiscoveryResponseMessage
* @return {Boolean} 
*/
DiscoveryResponseMessage.prototype.getSupportsVoidPaymentResponse = function() {
  return this.supportsVoidPaymentResponse;
};

DiscoveryResponseMessage._meta_ =  {fields:  {}};
DiscoveryResponseMessage._meta_._class_ =  DiscoveryResponseMessage;
DiscoveryResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
DiscoveryResponseMessage._meta_.fields["merchantId"] = {};
DiscoveryResponseMessage._meta_.fields["merchantId"].type = String;
DiscoveryResponseMessage._meta_.fields["merchantName"] = {};
DiscoveryResponseMessage._meta_.fields["merchantName"].type = String;
DiscoveryResponseMessage._meta_.fields["merchantMId"] = {};
DiscoveryResponseMessage._meta_.fields["merchantMId"].type = String;
DiscoveryResponseMessage._meta_.fields["name"] = {};
DiscoveryResponseMessage._meta_.fields["name"].type = String;
DiscoveryResponseMessage._meta_.fields["serial"] = {};
DiscoveryResponseMessage._meta_.fields["serial"].type = String;
DiscoveryResponseMessage._meta_.fields["model"] = {};
DiscoveryResponseMessage._meta_.fields["model"].type = String;
DiscoveryResponseMessage._meta_.fields["ready"] = {};
DiscoveryResponseMessage._meta_.fields["ready"].type = Boolean;
DiscoveryResponseMessage._meta_.fields["supportsTipAdjust"] = {};
DiscoveryResponseMessage._meta_.fields["supportsTipAdjust"].type = Boolean;
DiscoveryResponseMessage._meta_.fields["supportsManualRefund"] = {};
DiscoveryResponseMessage._meta_.fields["supportsManualRefund"].type = Boolean;
DiscoveryResponseMessage._meta_.fields["supportsMultiPayToken"] = {};
DiscoveryResponseMessage._meta_.fields["supportsMultiPayToken"].type = Boolean;
DiscoveryResponseMessage._meta_.fields["supportsAcknowledgement"] = {};
DiscoveryResponseMessage._meta_.fields["supportsAcknowledgement"].type = Boolean;
DiscoveryResponseMessage._meta_.fields["supportsPreAuth"] = {};
DiscoveryResponseMessage._meta_.fields["supportsPreAuth"].type = Boolean;
DiscoveryResponseMessage._meta_.fields["supportsAuth"] = {};
DiscoveryResponseMessage._meta_.fields["supportsAuth"].type = Boolean;
DiscoveryResponseMessage._meta_.fields["supportsVaultCard"] = {};
DiscoveryResponseMessage._meta_.fields["supportsVaultCard"].type = Boolean;
DiscoveryResponseMessage._meta_.fields["supportsVoidPaymentResponse"] = {};
DiscoveryResponseMessage._meta_.fields["supportsVoidPaymentResponse"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DiscoveryResponseMessage;
}

