/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof printer
* @enum {string}
*/
var PrintCategory = {
  ORDER : "ORDER",
  RECEIPT : "RECEIPT",
  FISCAL : "FISCAL",
  LABEL : "LABEL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintCategory;
}

