/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_SelectedService = require("../payments/SelectedService");
var payments_PromotionalMessage = require("../payments/PromotionalMessage");
var payments_TxFormat = require("../payments/TxFormat");
var payments_ECommTransactionInfo = require("../payments/ECommTransactionInfo");
var payments_CardType = require("../payments/CardType");
var payments_CardEntryType = require("../payments/CardEntryType");
var payments_ReversalReason = require("../payments/ReversalReason");
var payments_TransactionResult = require("../payments/TransactionResult");
var payments_AccountType = require("../payments/AccountType");
var customers_IdentityDocument = require("../customers/IdentityDocument");

/**
* @constructor
* @memberof payments
*/
var TransactionInfo = function() {
  this._class_ = TransactionInfo;
  this.languageIndicator = undefined;
  this.transactionLocale = undefined;
  this.accountSelection = undefined;
  this.fiscalInvoiceNumber = undefined;
  this.installmentsQuantity = undefined;
  this.installmentsPlanCode = undefined;
  this.installmentsPlanId = undefined;
  this.installmentsPlanDesc = undefined;
  this.cardTypeLabel = undefined;
  this.cardSymbol = undefined;
  this.stan = undefined;
  this.identityDocument = undefined;
  this.batchNumber = undefined;
  this.receiptNumber = undefined;
  this.reversalStanRefNum = undefined;
  this.reversalStan = undefined;
  this.reversalMac = undefined;
  this.reversalMacKsn = undefined;
  this.terminalIdentification = undefined;
  this.merchantIdentifier = undefined;
  this.merchantNameLocation = undefined;
  this.maskedTrack2 = undefined;
  this.receiptExtraData = undefined;
  this.selectedService = undefined;
  this.transactionResult = undefined;
  this.transactionTags = undefined;
  this.txFormat = undefined;
  this.panMask = undefined;
  this.transactionSequenceCounter = undefined;
  this.applicationPanSequenceNumber = undefined;
  this.reversalReason = undefined;
  this.isTokenBasedTx = false;
  this.origTransactionSequenceCounter = undefined;
  this.transactionSequenceCounterUpdate = undefined;
  this.emergencyFlag = false;
  this.entryType = undefined;
  this.promotionalMessage = undefined;
  this.eCommTransactionInfo = undefined;
  this.clientCardType = undefined;
};


/**
* Set the field value
* 2 character language used for the transaction. Deprecated in factor of transactionLocale.
*
* @memberof payments.TransactionInfo
* @param {String} languageIndicator 
*/
TransactionInfo.prototype.setLanguageIndicator = function(languageIndicator) {
  this.languageIndicator = languageIndicator;
};

/**
* Get the field value
* 2 character language used for the transaction. Deprecated in factor of transactionLocale.
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getLanguageIndicator = function() {
  return this.languageIndicator;
};

/**
* Set the field value
* Locale for the transaction (e.g. en-CA)
*
* @memberof payments.TransactionInfo
* @param {String} transactionLocale 
*/
TransactionInfo.prototype.setTransactionLocale = function(transactionLocale) {
  this.transactionLocale = transactionLocale;
};

/**
* Get the field value
* Locale for the transaction (e.g. en-CA)
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getTransactionLocale = function() {
  return this.transactionLocale;
};

/**
* Set the field value
* @memberof payments.TransactionInfo
* @param {payments.AccountType} accountSelection 
*/
TransactionInfo.prototype.setAccountSelection = function(accountSelection) {
  this.accountSelection = accountSelection;
};

/**
* Get the field value
* @memberof payments.TransactionInfo
* @return {payments.AccountType} 
*/
TransactionInfo.prototype.getAccountSelection = function() {
  return this.accountSelection;
};

/**
* Set the field value
* Consists of 4 digits prefix + 8 digits
*
* @memberof payments.TransactionInfo
* @param {String} fiscalInvoiceNumber 
*/
TransactionInfo.prototype.setFiscalInvoiceNumber = function(fiscalInvoiceNumber) {
  this.fiscalInvoiceNumber = fiscalInvoiceNumber;
};

/**
* Get the field value
* Consists of 4 digits prefix + 8 digits
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getFiscalInvoiceNumber = function() {
  return this.fiscalInvoiceNumber;
};

/**
* Set the field value
* AR Installments: number of installments
*
* @memberof payments.TransactionInfo
* @param {Number} installmentsQuantity must be an integer
*/
TransactionInfo.prototype.setInstallmentsQuantity = function(installmentsQuantity) {
  this.installmentsQuantity = installmentsQuantity;
};

/**
* Get the field value
* AR Installments: number of installments
* @memberof payments.TransactionInfo
* @return {Number} must be an integer
*/
TransactionInfo.prototype.getInstallmentsQuantity = function() {
  return this.installmentsQuantity;
};

/**
* Set the field value
* AR Installments: plan alphanum code
*
* @memberof payments.TransactionInfo
* @param {String} installmentsPlanCode 
*/
TransactionInfo.prototype.setInstallmentsPlanCode = function(installmentsPlanCode) {
  this.installmentsPlanCode = installmentsPlanCode;
};

/**
* Get the field value
* AR Installments: plan alphanum code
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getInstallmentsPlanCode = function() {
  return this.installmentsPlanCode;
};

/**
* Set the field value
* AR Installments: selected plan id
*
* @memberof payments.TransactionInfo
* @param {String} installmentsPlanId 
*/
TransactionInfo.prototype.setInstallmentsPlanId = function(installmentsPlanId) {
  this.installmentsPlanId = installmentsPlanId;
};

/**
* Get the field value
* AR Installments: selected plan id
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getInstallmentsPlanId = function() {
  return this.installmentsPlanId;
};

/**
* Set the field value
* AR Installments: selected plan desc
*
* @memberof payments.TransactionInfo
* @param {String} installmentsPlanDesc 
*/
TransactionInfo.prototype.setInstallmentsPlanDesc = function(installmentsPlanDesc) {
  this.installmentsPlanDesc = installmentsPlanDesc;
};

/**
* Get the field value
* AR Installments: selected plan desc
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getInstallmentsPlanDesc = function() {
  return this.installmentsPlanDesc;
};

/**
* Set the field value
* Card type label
*
* @memberof payments.TransactionInfo
* @param {String} cardTypeLabel 
*/
TransactionInfo.prototype.setCardTypeLabel = function(cardTypeLabel) {
  this.cardTypeLabel = cardTypeLabel;
};

/**
* Get the field value
* Card type label
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getCardTypeLabel = function() {
  return this.cardTypeLabel;
};

/**
* Set the field value
* Card Symbol for identify in payment_card_config table
*
* @memberof payments.TransactionInfo
* @param {String} cardSymbol 
*/
TransactionInfo.prototype.setCardSymbol = function(cardSymbol) {
  this.cardSymbol = cardSymbol;
};

/**
* Get the field value
* Card Symbol for identify in payment_card_config table
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getCardSymbol = function() {
  return this.cardSymbol;
};

/**
* Set the field value
* STAN(System Audit Trace Number)
*
* @memberof payments.TransactionInfo
* @param {Number} stan must be an integer
*/
TransactionInfo.prototype.setStan = function(stan) {
  this.stan = stan;
};

/**
* Get the field value
* STAN(System Audit Trace Number)
* @memberof payments.TransactionInfo
* @return {Number} must be an integer
*/
TransactionInfo.prototype.getStan = function() {
  return this.stan;
};

/**
* Set the field value
* Customers identification number and type
*
* @memberof payments.TransactionInfo
* @param {customers.IdentityDocument|Null} identityDocument 
*/
TransactionInfo.prototype.setIdentityDocument = function(identityDocument) {
  this.identityDocument = identityDocument;
};

/**
* Get the field value
* Customers identification number and type
* @memberof payments.TransactionInfo
* @return {customers.IdentityDocument|Null} 
*/
TransactionInfo.prototype.getIdentityDocument = function() {
  return this.identityDocument;
};

/**
* Set the field value
* Transaction Batch Number
*
* @memberof payments.TransactionInfo
* @param {String} batchNumber 
*/
TransactionInfo.prototype.setBatchNumber = function(batchNumber) {
  this.batchNumber = batchNumber;
};

/**
* Get the field value
* Transaction Batch Number
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getBatchNumber = function() {
  return this.batchNumber;
};

/**
* Set the field value
* Transaction Receipt Number
*
* @memberof payments.TransactionInfo
* @param {String} receiptNumber 
*/
TransactionInfo.prototype.setReceiptNumber = function(receiptNumber) {
  this.receiptNumber = receiptNumber;
};

/**
* Get the field value
* Transaction Receipt Number
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getReceiptNumber = function() {
  return this.receiptNumber;
};

/**
* Set the field value
* Reversal STAN Ref Num
*
* @memberof payments.TransactionInfo
* @param {String} reversalStanRefNum 
*/
TransactionInfo.prototype.setReversalStanRefNum = function(reversalStanRefNum) {
  this.reversalStanRefNum = reversalStanRefNum;
};

/**
* Get the field value
* Reversal STAN Ref Num
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getReversalStanRefNum = function() {
  return this.reversalStanRefNum;
};

/**
* Set the field value
* STAN for reversal
*
* @memberof payments.TransactionInfo
* @param {Number} reversalStan must be an integer
*/
TransactionInfo.prototype.setReversalStan = function(reversalStan) {
  this.reversalStan = reversalStan;
};

/**
* Get the field value
* STAN for reversal
* @memberof payments.TransactionInfo
* @return {Number} must be an integer
*/
TransactionInfo.prototype.getReversalStan = function() {
  return this.reversalStan;
};

/**
* Set the field value
* MAC for reversal
*
* @memberof payments.TransactionInfo
* @param {String} reversalMac 
*/
TransactionInfo.prototype.setReversalMac = function(reversalMac) {
  this.reversalMac = reversalMac;
};

/**
* Get the field value
* MAC for reversal
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getReversalMac = function() {
  return this.reversalMac;
};

/**
* Set the field value
* MAC KSN for reversal
*
* @memberof payments.TransactionInfo
* @param {String} reversalMacKsn 
*/
TransactionInfo.prototype.setReversalMacKsn = function(reversalMacKsn) {
  this.reversalMacKsn = reversalMacKsn;
};

/**
* Get the field value
* MAC KSN for reversal
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getReversalMacKsn = function() {
  return this.reversalMacKsn;
};

/**
* Set the field value
* Designates the unique location of a terminal at a merchant
*
* @memberof payments.TransactionInfo
* @param {String} terminalIdentification 
*/
TransactionInfo.prototype.setTerminalIdentification = function(terminalIdentification) {
  this.terminalIdentification = terminalIdentification;
};

/**
* Get the field value
* Designates the unique location of a terminal at a merchant
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getTerminalIdentification = function() {
  return this.terminalIdentification;
};

/**
* Set the field value
* When concatenated with the Acquirer Identifier, uniquely identifies a given merchant
*
* @memberof payments.TransactionInfo
* @param {String} merchantIdentifier 
*/
TransactionInfo.prototype.setMerchantIdentifier = function(merchantIdentifier) {
  this.merchantIdentifier = merchantIdentifier;
};

/**
* Get the field value
* When concatenated with the Acquirer Identifier, uniquely identifies a given merchant
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getMerchantIdentifier = function() {
  return this.merchantIdentifier;
};

/**
* Set the field value
* Indicates the name and location of the merchant
*
* @memberof payments.TransactionInfo
* @param {String} merchantNameLocation 
*/
TransactionInfo.prototype.setMerchantNameLocation = function(merchantNameLocation) {
  this.merchantNameLocation = merchantNameLocation;
};

/**
* Get the field value
* Indicates the name and location of the merchant
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getMerchantNameLocation = function() {
  return this.merchantNameLocation;
};

/**
* Set the field value
* Masked track2 data
*
* @memberof payments.TransactionInfo
* @param {String} maskedTrack2 
*/
TransactionInfo.prototype.setMaskedTrack2 = function(maskedTrack2) {
  this.maskedTrack2 = maskedTrack2;
};

/**
* Get the field value
* Masked track2 data
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getMaskedTrack2 = function() {
  return this.maskedTrack2;
};

/**
* Set the field value
* Extra data for receipt
*
* @memberof payments.TransactionInfo
* @param {String} receiptExtraData 
*/
TransactionInfo.prototype.setReceiptExtraData = function(receiptExtraData) {
  this.receiptExtraData = receiptExtraData;
};

/**
* Get the field value
* Extra data for receipt
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getReceiptExtraData = function() {
  return this.receiptExtraData;
};

/**
* Set the field value
* Defines the Financial Service selected for the transaction
*
* @memberof payments.TransactionInfo
* @param {payments.SelectedService} selectedService 
*/
TransactionInfo.prototype.setSelectedService = function(selectedService) {
  this.selectedService = selectedService;
};

/**
* Get the field value
* Defines the Financial Service selected for the transaction
* @memberof payments.TransactionInfo
* @return {payments.SelectedService} 
*/
TransactionInfo.prototype.getSelectedService = function() {
  return this.selectedService;
};

/**
* Set the field value
* Result of the transaction
*
* @memberof payments.TransactionInfo
* @param {payments.TransactionResult} transactionResult 
*/
TransactionInfo.prototype.setTransactionResult = function(transactionResult) {
  this.transactionResult = transactionResult;
};

/**
* Get the field value
* Result of the transaction
* @memberof payments.TransactionInfo
* @return {payments.TransactionResult} 
*/
TransactionInfo.prototype.getTransactionResult = function() {
  return this.transactionResult;
};

/**
* Set the field value
* Contains a hex string with needed TLV tags for certification
*
* @memberof payments.TransactionInfo
* @param {String} transactionTags 
*/
TransactionInfo.prototype.setTransactionTags = function(transactionTags) {
  this.transactionTags = transactionTags;
};

/**
* Get the field value
* Contains a hex string with needed TLV tags for certification
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getTransactionTags = function() {
  return this.transactionTags;
};

/**
* Set the field value
* Contains the information how the data inside transactionTags should be coded - initially we cause default and nexo as formats
*
* @memberof payments.TransactionInfo
* @param {payments.TxFormat} txFormat 
*/
TransactionInfo.prototype.setTxFormat = function(txFormat) {
  this.txFormat = txFormat;
};

/**
* Get the field value
* Contains the information how the data inside transactionTags should be coded - initially we cause default and nexo as formats
* @memberof payments.TransactionInfo
* @return {payments.TxFormat} 
*/
TransactionInfo.prototype.getTxFormat = function() {
  return this.txFormat;
};

/**
* Set the field value
* Contains the information how the PAN should masked.
*
* @memberof payments.TransactionInfo
* @param {String} panMask 
*/
TransactionInfo.prototype.setPanMask = function(panMask) {
  this.panMask = panMask;
};

/**
* Get the field value
* Contains the information how the PAN should masked.
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getPanMask = function() {
  return this.panMask;
};

/**
* Set the field value
* Counter maintained by the terminal that is incremented for each transaction at the beginning of the Perform Service function.
*
* @memberof payments.TransactionInfo
* @param {String} transactionSequenceCounter 
*/
TransactionInfo.prototype.setTransactionSequenceCounter = function(transactionSequenceCounter) {
  this.transactionSequenceCounter = transactionSequenceCounter;
};

/**
* Get the field value
* Counter maintained by the terminal that is incremented for each transaction at the beginning of the Perform Service function.
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getTransactionSequenceCounter = function() {
  return this.transactionSequenceCounter;
};

/**
* Set the field value
* Identifies and differentiates cards with the same PAN.
*
* @memberof payments.TransactionInfo
* @param {String} applicationPanSequenceNumber 
*/
TransactionInfo.prototype.setApplicationPanSequenceNumber = function(applicationPanSequenceNumber) {
  this.applicationPanSequenceNumber = applicationPanSequenceNumber;
};

/**
* Get the field value
* Identifies and differentiates cards with the same PAN.
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getApplicationPanSequenceNumber = function() {
  return this.applicationPanSequenceNumber;
};

/**
* Set the field value
* Contains the reason why the transaction should be reversed in the host. It has to be mapped in server with the expected value by the corresponding gateway
*
* @memberof payments.TransactionInfo
* @param {payments.ReversalReason} reversalReason 
*/
TransactionInfo.prototype.setReversalReason = function(reversalReason) {
  this.reversalReason = reversalReason;
};

/**
* Get the field value
* Contains the reason why the transaction should be reversed in the host. It has to be mapped in server with the expected value by the corresponding gateway
* @memberof payments.TransactionInfo
* @return {payments.ReversalReason} 
*/
TransactionInfo.prototype.getReversalReason = function() {
  return this.reversalReason;
};

/**
* Set the field value
* Boolean to determine if the transaction done using a vaulted card is a token based transaction
*
* @memberof payments.TransactionInfo
* @param {Boolean} isTokenBasedTx 
*/
TransactionInfo.prototype.setIsTokenBasedTx = function(isTokenBasedTx) {
  this.isTokenBasedTx = isTokenBasedTx;
};

/**
* Get the field value
* Boolean to determine if the transaction done using a vaulted card is a token based transaction
* @memberof payments.TransactionInfo
* @return {Boolean} 
*/
TransactionInfo.prototype.getIsTokenBasedTx = function() {
  return this.isTokenBasedTx;
};

/**
* Set the field value
* For reversal and capture transactions, this contains the reference (transactionSequenceCounter) to the originating transaction.
*
* @memberof payments.TransactionInfo
* @param {String} origTransactionSequenceCounter 
*/
TransactionInfo.prototype.setOrigTransactionSequenceCounter = function(origTransactionSequenceCounter) {
  this.origTransactionSequenceCounter = origTransactionSequenceCounter;
};

/**
* Get the field value
* For reversal and capture transactions, this contains the reference (transactionSequenceCounter) to the originating transaction.
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getOrigTransactionSequenceCounter = function() {
  return this.origTransactionSequenceCounter;
};

/**
* Set the field value
* This field is populated when the TSC of a terminal is out of sync and is provided with an update.
*
* @memberof payments.TransactionInfo
* @param {String} transactionSequenceCounterUpdate 
*/
TransactionInfo.prototype.setTransactionSequenceCounterUpdate = function(transactionSequenceCounterUpdate) {
  this.transactionSequenceCounterUpdate = transactionSequenceCounterUpdate;
};

/**
* Get the field value
* This field is populated when the TSC of a terminal is out of sync and is provided with an update.
* @memberof payments.TransactionInfo
* @return {String} 
*/
TransactionInfo.prototype.getTransactionSequenceCounterUpdate = function() {
  return this.transactionSequenceCounterUpdate;
};

/**
* Set the field value
* Boolean value defining if the corresponding TX was performed in NEXO emergency mode according NEXO IS Spec chapter 10.2
*
* @memberof payments.TransactionInfo
* @param {Boolean} emergencyFlag 
*/
TransactionInfo.prototype.setEmergencyFlag = function(emergencyFlag) {
  this.emergencyFlag = emergencyFlag;
};

/**
* Get the field value
* Boolean value defining if the corresponding TX was performed in NEXO emergency mode according NEXO IS Spec chapter 10.2
* @memberof payments.TransactionInfo
* @return {Boolean} 
*/
TransactionInfo.prototype.getEmergencyFlag = function() {
  return this.emergencyFlag;
};

/**
* Set the field value
* This field contains the entry type of a transaction.
*
* @memberof payments.TransactionInfo
* @param {payments.CardEntryType} entryType 
*/
TransactionInfo.prototype.setEntryType = function(entryType) {
  this.entryType = entryType;
};

/**
* Get the field value
* This field contains the entry type of a transaction.
* @memberof payments.TransactionInfo
* @return {payments.CardEntryType} 
*/
TransactionInfo.prototype.getEntryType = function() {
  return this.entryType;
};

/**
* Set the field value
* @memberof payments.TransactionInfo
* @param {payments.PromotionalMessage} promotionalMessage 
*/
TransactionInfo.prototype.setPromotionalMessage = function(promotionalMessage) {
  this.promotionalMessage = promotionalMessage;
};

/**
* Get the field value
* @memberof payments.TransactionInfo
* @return {payments.PromotionalMessage} 
*/
TransactionInfo.prototype.getPromotionalMessage = function() {
  return this.promotionalMessage;
};

/**
* Set the field value
* @memberof payments.TransactionInfo
* @param {payments.ECommTransactionInfo} eCommTransactionInfo 
*/
TransactionInfo.prototype.setECommTransactionInfo = function(eCommTransactionInfo) {
  this.eCommTransactionInfo = eCommTransactionInfo;
};

/**
* Get the field value
* @memberof payments.TransactionInfo
* @return {payments.ECommTransactionInfo} 
*/
TransactionInfo.prototype.getECommTransactionInfo = function() {
  return this.eCommTransactionInfo;
};

/**
* Set the field value
* Client card type should be used only limited purpose only and should not be confused with cardType in other objects.
 This is a read only field and must not to be overwritten on server or client once set on client. 
 This is set on client only for MSR tx for now as the AIDs are not identified for MSR. 
 For few MSR tx, the card type was identified incorrectly on client side, and also on server side based on the bin match. 
 In order to get the correct card types, this client card type will be sent to the server
*
* @memberof payments.TransactionInfo
* @param {payments.CardType} clientCardType 
*/
TransactionInfo.prototype.setClientCardType = function(clientCardType) {
  this.clientCardType = clientCardType;
};

/**
* Get the field value
* Client card type should be used only limited purpose only and should not be confused with cardType in other objects.
 This is a read only field and must not to be overwritten on server or client once set on client. 
 This is set on client only for MSR tx for now as the AIDs are not identified for MSR. 
 For few MSR tx, the card type was identified incorrectly on client side, and also on server side based on the bin match. 
 In order to get the correct card types, this client card type will be sent to the server
* @memberof payments.TransactionInfo
* @return {payments.CardType} 
*/
TransactionInfo.prototype.getClientCardType = function() {
  return this.clientCardType;
};

/**
* @memberof payments.TransactionInfo
* @private
*/
TransactionInfo.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

TransactionInfo.prototype.toString = function() {
  return JSON.stringify(this);
};

TransactionInfo._meta_ =  {fields:  {}};
TransactionInfo._meta_._class_ =  TransactionInfo;
TransactionInfo._meta_.fields["languageIndicator"] = {};
TransactionInfo._meta_.fields["languageIndicator"].type = String;
TransactionInfo._meta_.fields["transactionLocale"] = {};
TransactionInfo._meta_.fields["transactionLocale"].type = String;
TransactionInfo._meta_.fields["accountSelection"] = {};
TransactionInfo._meta_.fields["accountSelection"].type = payments_AccountType;
TransactionInfo._meta_.fields["fiscalInvoiceNumber"] = {};
TransactionInfo._meta_.fields["fiscalInvoiceNumber"].type = String;
TransactionInfo._meta_.fields["installmentsQuantity"] = {};
TransactionInfo._meta_.fields["installmentsQuantity"].type = Number;
TransactionInfo._meta_.fields["installmentsPlanCode"] = {};
TransactionInfo._meta_.fields["installmentsPlanCode"].type = String;
TransactionInfo._meta_.fields["installmentsPlanId"] = {};
TransactionInfo._meta_.fields["installmentsPlanId"].type = String;
TransactionInfo._meta_.fields["installmentsPlanDesc"] = {};
TransactionInfo._meta_.fields["installmentsPlanDesc"].type = String;
TransactionInfo._meta_.fields["cardTypeLabel"] = {};
TransactionInfo._meta_.fields["cardTypeLabel"].type = String;
TransactionInfo._meta_.fields["cardSymbol"] = {};
TransactionInfo._meta_.fields["cardSymbol"].type = String;
TransactionInfo._meta_.fields["stan"] = {};
TransactionInfo._meta_.fields["stan"].type = Number;
TransactionInfo._meta_.fields["identityDocument"] = {};
TransactionInfo._meta_.fields["identityDocument"].type = customers_IdentityDocument;
TransactionInfo._meta_.fields["batchNumber"] = {};
TransactionInfo._meta_.fields["batchNumber"].type = String;
TransactionInfo._meta_.fields["receiptNumber"] = {};
TransactionInfo._meta_.fields["receiptNumber"].type = String;
TransactionInfo._meta_.fields["reversalStanRefNum"] = {};
TransactionInfo._meta_.fields["reversalStanRefNum"].type = String;
TransactionInfo._meta_.fields["reversalStan"] = {};
TransactionInfo._meta_.fields["reversalStan"].type = Number;
TransactionInfo._meta_.fields["reversalMac"] = {};
TransactionInfo._meta_.fields["reversalMac"].type = String;
TransactionInfo._meta_.fields["reversalMacKsn"] = {};
TransactionInfo._meta_.fields["reversalMacKsn"].type = String;
TransactionInfo._meta_.fields["terminalIdentification"] = {};
TransactionInfo._meta_.fields["terminalIdentification"].type = String;
TransactionInfo._meta_.fields["merchantIdentifier"] = {};
TransactionInfo._meta_.fields["merchantIdentifier"].type = String;
TransactionInfo._meta_.fields["merchantNameLocation"] = {};
TransactionInfo._meta_.fields["merchantNameLocation"].type = String;
TransactionInfo._meta_.fields["maskedTrack2"] = {};
TransactionInfo._meta_.fields["maskedTrack2"].type = String;
TransactionInfo._meta_.fields["receiptExtraData"] = {};
TransactionInfo._meta_.fields["receiptExtraData"].type = String;
TransactionInfo._meta_.fields["selectedService"] = {};
TransactionInfo._meta_.fields["selectedService"].type = payments_SelectedService;
TransactionInfo._meta_.fields["transactionResult"] = {};
TransactionInfo._meta_.fields["transactionResult"].type = payments_TransactionResult;
TransactionInfo._meta_.fields["transactionTags"] = {};
TransactionInfo._meta_.fields["transactionTags"].type = String;
TransactionInfo._meta_.fields["txFormat"] = {};
TransactionInfo._meta_.fields["txFormat"].type = payments_TxFormat;
TransactionInfo._meta_.fields["panMask"] = {};
TransactionInfo._meta_.fields["panMask"].type = String;
TransactionInfo._meta_.fields["transactionSequenceCounter"] = {};
TransactionInfo._meta_.fields["transactionSequenceCounter"].type = String;
TransactionInfo._meta_.fields["applicationPanSequenceNumber"] = {};
TransactionInfo._meta_.fields["applicationPanSequenceNumber"].type = String;
TransactionInfo._meta_.fields["reversalReason"] = {};
TransactionInfo._meta_.fields["reversalReason"].type = payments_ReversalReason;
TransactionInfo._meta_.fields["isTokenBasedTx"] = {};
TransactionInfo._meta_.fields["isTokenBasedTx"].type = Boolean;
TransactionInfo._meta_.fields["origTransactionSequenceCounter"] = {};
TransactionInfo._meta_.fields["origTransactionSequenceCounter"].type = String;
TransactionInfo._meta_.fields["transactionSequenceCounterUpdate"] = {};
TransactionInfo._meta_.fields["transactionSequenceCounterUpdate"].type = String;
TransactionInfo._meta_.fields["emergencyFlag"] = {};
TransactionInfo._meta_.fields["emergencyFlag"].type = Boolean;
TransactionInfo._meta_.fields["entryType"] = {};
TransactionInfo._meta_.fields["entryType"].type = payments_CardEntryType;
TransactionInfo._meta_.fields["promotionalMessage"] = {};
TransactionInfo._meta_.fields["promotionalMessage"].type = payments_PromotionalMessage;
TransactionInfo._meta_.fields["eCommTransactionInfo"] = {};
TransactionInfo._meta_.fields["eCommTransactionInfo"].type = payments_ECommTransactionInfo;
TransactionInfo._meta_.fields["clientCardType"] = {};
TransactionInfo._meta_.fields["clientCardType"].type = payments_CardType;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TransactionInfo;
}

