/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var TipResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = TipResponse;
  this.tipAmount = undefined;
};

TipResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
TipResponse.prototype.constructor = TipResponse;

/**
* Set the field value
* @memberof remotepay.TipResponse
* @param {Number} tipAmount must be a long integer
*/
TipResponse.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* @memberof remotepay.TipResponse
* @return {Number} must be a long integer
*/
TipResponse.prototype.getTipAmount = function() {
  return this.tipAmount;
};

TipResponse._meta_ =  {fields:  {}};
TipResponse._meta_._class_ =  TipResponse;
TipResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
TipResponse._meta_.fields["tipAmount"] = {};
TipResponse._meta_.fields["tipAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipResponse;
}

