import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import CardInformationListProps from "../types/components/CardInformationListProps";
import CardInformation from "./CardInformation";
import { styles } from "../styles/components/cardInformationList";

export default ({
  title,
  titleColor,
  items,
  dateLanguage,
  seeMoreText,
  onClickMore,
}: CardInformationListProps): JSX.Element => {
  return (
    <View style={styles.container}>
      <View style={styles.leftExternalSpace}></View>
      <View style={styles.contentContainer}>
      {title ? (
        <View style={styles.headerContainer}>
          <Text style={[styles.title, titleColor && { color: titleColor }]}>
            {title}
          </Text>
        </View>
      ) : null}
      {items.map((item) => (
        <CardInformation
          key={item.id}
          id={item.id}
          date={item.date}
          image={item.image}
          onClick={item.onClick}
          showDate={item.showDate}
          showTime={item.showTime}
          isForArticleListing={item.isForArticleListing}
          venueLogoPath={item.venueLogoPath}
          title={item.title}
          dateLanguage={dateLanguage}
        />
      ))}
      {onClickMore && (
        <View style={styles.footerContainer}>
          <TouchableOpacity onPress={onClickMore}>
            <Text style={styles.titleSeeMoreResults}>{seeMoreText}</Text>
          </TouchableOpacity>
        </View>
      )}
      </View>
    </View>
  );
};
