/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof order
*/
var DisplayDiscount = function() {
  this._class_ = DisplayDiscount;
  this.id = undefined;
  this.lineItemId = undefined;
  this.name = undefined;
  this.amount = undefined;
  this.percentage = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof order.DisplayDiscount
* @param {String} id 
*/
DisplayDiscount.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof order.DisplayDiscount
* @return {String} 
*/
DisplayDiscount.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* The lineItem with which the discount is associated
*
* @memberof order.DisplayDiscount
* @param {String} lineItemId 
*/
DisplayDiscount.prototype.setLineItemId = function(lineItemId) {
  this.lineItemId = lineItemId;
};

/**
* Get the field value
* The lineItem with which the discount is associated
* @memberof order.DisplayDiscount
* @return {String} 
*/
DisplayDiscount.prototype.getLineItemId = function() {
  return this.lineItemId;
};

/**
* Set the field value
* Name of the discount
*
* @memberof order.DisplayDiscount
* @param {String} name 
*/
DisplayDiscount.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Name of the discount
* @memberof order.DisplayDiscount
* @return {String} 
*/
DisplayDiscount.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* Formatted discount amount
*
* @memberof order.DisplayDiscount
* @param {String} amount 
*/
DisplayDiscount.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Formatted discount amount
* @memberof order.DisplayDiscount
* @return {String} 
*/
DisplayDiscount.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* Discount amount in percent
*
* @memberof order.DisplayDiscount
* @param {String} percentage 
*/
DisplayDiscount.prototype.setPercentage = function(percentage) {
  this.percentage = percentage;
};

/**
* Get the field value
* Discount amount in percent
* @memberof order.DisplayDiscount
* @return {String} 
*/
DisplayDiscount.prototype.getPercentage = function() {
  return this.percentage;
};

/**
* @memberof order.DisplayDiscount
* @private
*/
DisplayDiscount.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DisplayDiscount.prototype.toString = function() {
  return JSON.stringify(this);
};

DisplayDiscount._meta_ =  {fields:  {}};
DisplayDiscount._meta_._class_ =  DisplayDiscount;
DisplayDiscount._meta_.fields["id"] = {};
DisplayDiscount._meta_.fields["id"].type = String;
DisplayDiscount._meta_.fields["lineItemId"] = {};
DisplayDiscount._meta_.fields["lineItemId"].type = String;
DisplayDiscount._meta_.fields["name"] = {};
DisplayDiscount._meta_.fields["name"].type = String;
DisplayDiscount._meta_.fields["amount"] = {};
DisplayDiscount._meta_.fields["amount"].type = String;
DisplayDiscount._meta_.fields["percentage"] = {};
DisplayDiscount._meta_.fields["percentage"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DisplayDiscount;
}

