import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    labelTextInput: {
        marginTop: 5,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#89909b",
    },
    labelErrorInput: {
        marginTop: 5,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#FF0000",
    },
    labelResultError: {
        marginTop: 5,
        fontSize: 16,
        textTransform: "uppercase",
        color: "#FF0000",
        textAlign: "center"
    },
    textInputField: {
        borderColor: "#89909b",
        borderWidth: 1,
        marginTop: 10,
        backgroundColor: "#FFF",
        height: 40,
        fontSize: 18,
        borderRadius: 10,
        padding: 10,
    },
    submitButton: {
        marginTop: 20,
        marginBottom: 20,
        width: 350,
        height: 50,
        borderRadius: 12,
        alignSelf: "center",
        backgroundColor: "#000000",
    },
    submitTitle: {
        color: "#FFF",
        fontSize: 18,
    }
});