/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof device
*/
var DeviceProvisionStatus = function() {
  this._class_ = DeviceProvisionStatus;
  this.id = undefined;
  this.chipUid = undefined;
  this.imei = undefined;
  this.provisionInfo = undefined;
  this.lastActivationCode = undefined;
  this.activationCode = undefined;
  this.emailSent = undefined;
  this.serialNumber = undefined;
  this.merchantId = undefined;
  this.provisionedTime = undefined;
  this.activatedTime = undefined;
  this.resellerId = undefined;
  this.deletedTime = undefined;
  this.modifiedTime = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof device.DeviceProvisionStatus
* @param {String} id 
*/
DeviceProvisionStatus.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof device.DeviceProvisionStatus
* @return {String} 
*/
DeviceProvisionStatus.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {String} chipUid 
*/
DeviceProvisionStatus.prototype.setChipUid = function(chipUid) {
  this.chipUid = chipUid;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {String} 
*/
DeviceProvisionStatus.prototype.getChipUid = function() {
  return this.chipUid;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {String} imei 
*/
DeviceProvisionStatus.prototype.setImei = function(imei) {
  this.imei = imei;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {String} 
*/
DeviceProvisionStatus.prototype.getImei = function() {
  return this.imei;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {String} provisionInfo 
*/
DeviceProvisionStatus.prototype.setProvisionInfo = function(provisionInfo) {
  this.provisionInfo = provisionInfo;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {String} 
*/
DeviceProvisionStatus.prototype.getProvisionInfo = function() {
  return this.provisionInfo;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {String} lastActivationCode 
*/
DeviceProvisionStatus.prototype.setLastActivationCode = function(lastActivationCode) {
  this.lastActivationCode = lastActivationCode;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {String} 
*/
DeviceProvisionStatus.prototype.getLastActivationCode = function() {
  return this.lastActivationCode;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {String} activationCode 
*/
DeviceProvisionStatus.prototype.setActivationCode = function(activationCode) {
  this.activationCode = activationCode;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {String} 
*/
DeviceProvisionStatus.prototype.getActivationCode = function() {
  return this.activationCode;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {Boolean} emailSent 
*/
DeviceProvisionStatus.prototype.setEmailSent = function(emailSent) {
  this.emailSent = emailSent;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {Boolean} 
*/
DeviceProvisionStatus.prototype.getEmailSent = function() {
  return this.emailSent;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {String} serialNumber 
*/
DeviceProvisionStatus.prototype.setSerialNumber = function(serialNumber) {
  this.serialNumber = serialNumber;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {String} 
*/
DeviceProvisionStatus.prototype.getSerialNumber = function() {
  return this.serialNumber;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {Number} merchantId must be a long integer
*/
DeviceProvisionStatus.prototype.setMerchantId = function(merchantId) {
  this.merchantId = merchantId;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {Number} must be a long integer
*/
DeviceProvisionStatus.prototype.getMerchantId = function() {
  return this.merchantId;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {Number} provisionedTime must be a long integer
*/
DeviceProvisionStatus.prototype.setProvisionedTime = function(provisionedTime) {
  this.provisionedTime = provisionedTime;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {Number} must be a long integer
*/
DeviceProvisionStatus.prototype.getProvisionedTime = function() {
  return this.provisionedTime;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {Number} activatedTime must be a long integer
*/
DeviceProvisionStatus.prototype.setActivatedTime = function(activatedTime) {
  this.activatedTime = activatedTime;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {Number} must be a long integer
*/
DeviceProvisionStatus.prototype.getActivatedTime = function() {
  return this.activatedTime;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {Number} resellerId must be a long integer
*/
DeviceProvisionStatus.prototype.setResellerId = function(resellerId) {
  this.resellerId = resellerId;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {Number} must be a long integer
*/
DeviceProvisionStatus.prototype.getResellerId = function() {
  return this.resellerId;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {Number} deletedTime must be a long integer
*/
DeviceProvisionStatus.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {Number} must be a long integer
*/
DeviceProvisionStatus.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* Set the field value
* @memberof device.DeviceProvisionStatus
* @param {Number} modifiedTime must be a long integer
*/
DeviceProvisionStatus.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* @memberof device.DeviceProvisionStatus
* @return {Number} must be a long integer
*/
DeviceProvisionStatus.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* @memberof device.DeviceProvisionStatus
* @private
*/
DeviceProvisionStatus.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DeviceProvisionStatus.prototype.toString = function() {
  return JSON.stringify(this);
};

DeviceProvisionStatus._meta_ =  {fields:  {}};
DeviceProvisionStatus._meta_._class_ =  DeviceProvisionStatus;
DeviceProvisionStatus._meta_.fields["id"] = {};
DeviceProvisionStatus._meta_.fields["id"].type = String;
DeviceProvisionStatus._meta_.fields["chipUid"] = {};
DeviceProvisionStatus._meta_.fields["chipUid"].type = String;
DeviceProvisionStatus._meta_.fields["imei"] = {};
DeviceProvisionStatus._meta_.fields["imei"].type = String;
DeviceProvisionStatus._meta_.fields["provisionInfo"] = {};
DeviceProvisionStatus._meta_.fields["provisionInfo"].type = String;
DeviceProvisionStatus._meta_.fields["lastActivationCode"] = {};
DeviceProvisionStatus._meta_.fields["lastActivationCode"].type = String;
DeviceProvisionStatus._meta_.fields["activationCode"] = {};
DeviceProvisionStatus._meta_.fields["activationCode"].type = String;
DeviceProvisionStatus._meta_.fields["emailSent"] = {};
DeviceProvisionStatus._meta_.fields["emailSent"].type = Boolean;
DeviceProvisionStatus._meta_.fields["serialNumber"] = {};
DeviceProvisionStatus._meta_.fields["serialNumber"].type = String;
DeviceProvisionStatus._meta_.fields["merchantId"] = {};
DeviceProvisionStatus._meta_.fields["merchantId"].type = Number;
DeviceProvisionStatus._meta_.fields["provisionedTime"] = {};
DeviceProvisionStatus._meta_.fields["provisionedTime"].type = Number;
DeviceProvisionStatus._meta_.fields["activatedTime"] = {};
DeviceProvisionStatus._meta_.fields["activatedTime"].type = Number;
DeviceProvisionStatus._meta_.fields["resellerId"] = {};
DeviceProvisionStatus._meta_.fields["resellerId"].type = Number;
DeviceProvisionStatus._meta_.fields["deletedTime"] = {};
DeviceProvisionStatus._meta_.fields["deletedTime"].type = Number;
DeviceProvisionStatus._meta_.fields["modifiedTime"] = {};
DeviceProvisionStatus._meta_.fields["modifiedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeviceProvisionStatus;
}

