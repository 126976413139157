/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var QueryStatus = {
  FOUND : "FOUND",
  NOT_FOUND : "NOT_FOUND",
  IN_PROGRESS : "IN_PROGRESS"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = QueryStatus;
}

