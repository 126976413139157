import React from "react";
import { TextInput, View, Text } from 'react-native';
import { Button } from "@rneui/themed";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { styles } from "../styles/components/loginForm";
import LoginFormProps from "../types/components/LoginFormProps";
import LoginFormType from "../types/enums/LoginFormType";

export default ({loginFormType, passwordValidation, onConfirm }:LoginFormProps):JSX.Element => {
    const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);

    const loginFormValidation = yup.object().shape({
        user: loginFormType === LoginFormType.Email ? yup.string().email('Email Inválido').required('El Email es requerido') : 
        yup.string().required('El nombre de usuario es requerido'),
        password: passwordValidation
    })

    const handleSubmit = async () => {
        const loginData = {
            userField: formik.values.user,
            password: formik.values.password
        }
        const result = await onConfirm(loginData);
        if (!result) {
            formik.setSubmitting(false);
            setShowErrorMessage(true);
        }
    }

    const formik = useFormik({
        initialValues: {
            user: '',
            password: ''
        },
        validationSchema: loginFormValidation,
        onSubmit: () => handleSubmit(),
        validateOnBlur: false,
        validateOnChange: false
    });

    return (
        <View>
            <Text style={styles.labelTextInput}>{`Ingrese ${loginFormType === LoginFormType.Email ? 'su email' : 'nombre de usuario'}`}</Text>
            <TextInput
                style={styles.textInputField}
                onChangeText={formik.handleChange('user')}
                value={formik.values.user.trim()}
            ></TextInput>
            {formik.errors.user && (
                <Text style={styles.labelErrorInput}>{formik.errors.user}</Text>
            )}
            <Text style={styles.labelTextInput}>Ingrese su Contraseña</Text>
            <TextInput
                style={styles.textInputField}
                onChangeText={formik.handleChange('password')}
                value={formik.values.password}
                secureTextEntry={true}
            ></TextInput>
            {formik.errors.password && (
                <Text style={styles.labelErrorInput}>{formik.errors.password}</Text>
            )}
            {showErrorMessage && (
                <Text style={styles.labelResultError}>
                    Los datos que ingresaste no son correctos. Por favor, revisa e intenta de nuevo.
                </Text>
            )}
            <Button title="Ingresar" disabled={!formik.isValid && formik.isSubmitting} buttonStyle={styles.submitButton} titleStyle={styles.submitTitle} onPress={() => formik.handleSubmit()}/>
        </View>
    )
}