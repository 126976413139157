/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_TransactionType = require("../remotepay/TransactionType");
var remotepay_BaseTransactionRequest = require("../remotepay/BaseTransactionRequest");

/**
* @constructor
* @augments remotepay.BaseTransactionRequest
* @memberof remotepay
*/
var PreAuthRequest = function() {
  remotepay_BaseTransactionRequest.call(this);
  this._superClass_ = remotepay_BaseTransactionRequest;
  this._class_ = PreAuthRequest;
  this.type = remotepay_TransactionType["AUTH"];
};

PreAuthRequest.prototype = Object.create(remotepay_BaseTransactionRequest.prototype);
PreAuthRequest.prototype.constructor = PreAuthRequest;

PreAuthRequest._meta_ =  {fields:  {}};
PreAuthRequest._meta_._class_ =  PreAuthRequest;
PreAuthRequest._meta_._superMeta_ = remotepay_BaseTransactionRequest._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PreAuthRequest;
}

