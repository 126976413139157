/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/** This class represents the association between an item and an option */
/**
* @constructor
* @memberof inventory
*/
var OptionItem = function() {
  this._class_ = OptionItem;
  this.option = undefined;
  this.item = undefined;
};


/**
* Set the field value
* Reference to an option
*
* @memberof inventory.OptionItem
* @param {Null|base.Reference} option 
*/
OptionItem.prototype.setOption = function(option) {
  this.option = option;
};

/**
* Get the field value
* Reference to an option
* @memberof inventory.OptionItem
* @return {Null|base.Reference} 
*/
OptionItem.prototype.getOption = function() {
  return this.option;
};

/**
* Set the field value
* Reference to an item
*
* @memberof inventory.OptionItem
* @param {Null|base.Reference} item 
*/
OptionItem.prototype.setItem = function(item) {
  this.item = item;
};

/**
* Get the field value
* Reference to an item
* @memberof inventory.OptionItem
* @return {Null|base.Reference} 
*/
OptionItem.prototype.getItem = function() {
  return this.item;
};

/**
* @memberof inventory.OptionItem
* @private
*/
OptionItem.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

OptionItem.prototype.toString = function() {
  return JSON.stringify(this);
};

OptionItem._meta_ =  {fields:  {}};
OptionItem._meta_._class_ =  OptionItem;
OptionItem._meta_.fields["option"] = {};
OptionItem._meta_.fields["option"].type = base_Reference;
OptionItem._meta_.fields["item"] = {};
OptionItem._meta_.fields["item"].type = base_Reference;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OptionItem;
}

