/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof device
*/
var DeviceProvisionAssoc = function() {
  this._class_ = DeviceProvisionAssoc;
  this.serialNumber = undefined;
  this.associatedId = undefined;
};


/**
* Set the field value
* @memberof device.DeviceProvisionAssoc
* @param {String} serialNumber 
*/
DeviceProvisionAssoc.prototype.setSerialNumber = function(serialNumber) {
  this.serialNumber = serialNumber;
};

/**
* Get the field value
* @memberof device.DeviceProvisionAssoc
* @return {String} 
*/
DeviceProvisionAssoc.prototype.getSerialNumber = function() {
  return this.serialNumber;
};

/**
* Set the field value
* @memberof device.DeviceProvisionAssoc
* @param {String} associatedId 
*/
DeviceProvisionAssoc.prototype.setAssociatedId = function(associatedId) {
  this.associatedId = associatedId;
};

/**
* Get the field value
* @memberof device.DeviceProvisionAssoc
* @return {String} 
*/
DeviceProvisionAssoc.prototype.getAssociatedId = function() {
  return this.associatedId;
};

/**
* @memberof device.DeviceProvisionAssoc
* @private
*/
DeviceProvisionAssoc.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DeviceProvisionAssoc.prototype.toString = function() {
  return JSON.stringify(this);
};

DeviceProvisionAssoc._meta_ =  {fields:  {}};
DeviceProvisionAssoc._meta_._class_ =  DeviceProvisionAssoc;
DeviceProvisionAssoc._meta_.fields["serialNumber"] = {};
DeviceProvisionAssoc._meta_.fields["serialNumber"].type = String;
DeviceProvisionAssoc._meta_.fields["associatedId"] = {};
DeviceProvisionAssoc._meta_.fields["associatedId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeviceProvisionAssoc;
}

