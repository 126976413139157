/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_PayIntent = require("../remotemessage/PayIntent");
var remotemessage_Message = require("../remotemessage/Message");
var order_Order = require("../order/Order");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var TxStartRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = TxStartRequestMessage;
  this.setMethod(remotemessage_Method["TX_START"]);
  this.suppressOnScreenTips = undefined;
  this.order = undefined;
  this.payIntent = undefined;
  this.requestInfo = undefined;
};

TxStartRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
TxStartRequestMessage.prototype.constructor = TxStartRequestMessage;

/**
* Set the field value
* If true, then tips on the screen should not be displayed.
*
* @memberof remotemessage.TxStartRequestMessage
* @param {Boolean} suppressOnScreenTips 
*/
TxStartRequestMessage.prototype.setSuppressOnScreenTips = function(suppressOnScreenTips) {
  this.suppressOnScreenTips = suppressOnScreenTips;
};

/**
* Get the field value
* If true, then tips on the screen should not be displayed.
* @memberof remotemessage.TxStartRequestMessage
* @return {Boolean} 
*/
TxStartRequestMessage.prototype.getSuppressOnScreenTips = function() {
  return this.suppressOnScreenTips;
};

/**
* Set the field value
* The order
*
* @memberof remotemessage.TxStartRequestMessage
* @param {order.Order} order 
*/
TxStartRequestMessage.prototype.setOrder = function(order) {
  this.order = order;
};

/**
* Get the field value
* The order
* @memberof remotemessage.TxStartRequestMessage
* @return {order.Order} 
*/
TxStartRequestMessage.prototype.getOrder = function() {
  return this.order;
};

/**
* Set the field value
* The payIntent
*
* @memberof remotemessage.TxStartRequestMessage
* @param {remotemessage.PayIntent} payIntent 
*/
TxStartRequestMessage.prototype.setPayIntent = function(payIntent) {
  this.payIntent = payIntent;
};

/**
* Get the field value
* The payIntent
* @memberof remotemessage.TxStartRequestMessage
* @return {remotemessage.PayIntent} 
*/
TxStartRequestMessage.prototype.getPayIntent = function() {
  return this.payIntent;
};

/**
* Set the field value
* Extra information to include with the request, like type of request
*
* @memberof remotemessage.TxStartRequestMessage
* @param {String} requestInfo 
*/
TxStartRequestMessage.prototype.setRequestInfo = function(requestInfo) {
  this.requestInfo = requestInfo;
};

/**
* Get the field value
* Extra information to include with the request, like type of request
* @memberof remotemessage.TxStartRequestMessage
* @return {String} 
*/
TxStartRequestMessage.prototype.getRequestInfo = function() {
  return this.requestInfo;
};

TxStartRequestMessage._meta_ =  {fields:  {}};
TxStartRequestMessage._meta_._class_ =  TxStartRequestMessage;
TxStartRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
TxStartRequestMessage._meta_.fields["suppressOnScreenTips"] = {};
TxStartRequestMessage._meta_.fields["suppressOnScreenTips"].type = Boolean;
TxStartRequestMessage._meta_.fields["order"] = {};
TxStartRequestMessage._meta_.fields["order"].type = order_Order;
TxStartRequestMessage._meta_.fields["payIntent"] = {};
TxStartRequestMessage._meta_.fields["payIntent"].type = remotemessage_PayIntent;
TxStartRequestMessage._meta_.fields["requestInfo"] = {};
TxStartRequestMessage._meta_.fields["requestInfo"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TxStartRequestMessage;
}

