/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_BatchTotalType = require("../payments/BatchTotalType");

/**
* @constructor
* @memberof payments
*/
var BatchTotalStats = function() {
  this._class_ = BatchTotalStats;
  this.sales = undefined;
  this.refunds = undefined;
  this.net = undefined;
  this.giftCardLoads = undefined;
  this.giftCardCashOuts = undefined;
  this.tax = undefined;
  this.tips = undefined;
};


/**
* Set the field value
* @memberof payments.BatchTotalStats
* @param {payments.BatchTotalType} sales 
*/
BatchTotalStats.prototype.setSales = function(sales) {
  this.sales = sales;
};

/**
* Get the field value
* @memberof payments.BatchTotalStats
* @return {payments.BatchTotalType} 
*/
BatchTotalStats.prototype.getSales = function() {
  return this.sales;
};

/**
* Set the field value
* @memberof payments.BatchTotalStats
* @param {payments.BatchTotalType} refunds 
*/
BatchTotalStats.prototype.setRefunds = function(refunds) {
  this.refunds = refunds;
};

/**
* Get the field value
* @memberof payments.BatchTotalStats
* @return {payments.BatchTotalType} 
*/
BatchTotalStats.prototype.getRefunds = function() {
  return this.refunds;
};

/**
* Set the field value
* @memberof payments.BatchTotalStats
* @param {payments.BatchTotalType} net 
*/
BatchTotalStats.prototype.setNet = function(net) {
  this.net = net;
};

/**
* Get the field value
* @memberof payments.BatchTotalStats
* @return {payments.BatchTotalType} 
*/
BatchTotalStats.prototype.getNet = function() {
  return this.net;
};

/**
* Set the field value
* @memberof payments.BatchTotalStats
* @param {payments.BatchTotalType} giftCardLoads 
*/
BatchTotalStats.prototype.setGiftCardLoads = function(giftCardLoads) {
  this.giftCardLoads = giftCardLoads;
};

/**
* Get the field value
* @memberof payments.BatchTotalStats
* @return {payments.BatchTotalType} 
*/
BatchTotalStats.prototype.getGiftCardLoads = function() {
  return this.giftCardLoads;
};

/**
* Set the field value
* @memberof payments.BatchTotalStats
* @param {payments.BatchTotalType} giftCardCashOuts 
*/
BatchTotalStats.prototype.setGiftCardCashOuts = function(giftCardCashOuts) {
  this.giftCardCashOuts = giftCardCashOuts;
};

/**
* Get the field value
* @memberof payments.BatchTotalStats
* @return {payments.BatchTotalType} 
*/
BatchTotalStats.prototype.getGiftCardCashOuts = function() {
  return this.giftCardCashOuts;
};

/**
* Set the field value
* @memberof payments.BatchTotalStats
* @param {payments.BatchTotalType} tax 
*/
BatchTotalStats.prototype.setTax = function(tax) {
  this.tax = tax;
};

/**
* Get the field value
* @memberof payments.BatchTotalStats
* @return {payments.BatchTotalType} 
*/
BatchTotalStats.prototype.getTax = function() {
  return this.tax;
};

/**
* Set the field value
* @memberof payments.BatchTotalStats
* @param {payments.BatchTotalType} tips 
*/
BatchTotalStats.prototype.setTips = function(tips) {
  this.tips = tips;
};

/**
* Get the field value
* @memberof payments.BatchTotalStats
* @return {payments.BatchTotalType} 
*/
BatchTotalStats.prototype.getTips = function() {
  return this.tips;
};

/**
* @memberof payments.BatchTotalStats
* @private
*/
BatchTotalStats.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

BatchTotalStats.prototype.toString = function() {
  return JSON.stringify(this);
};

BatchTotalStats._meta_ =  {fields:  {}};
BatchTotalStats._meta_._class_ =  BatchTotalStats;
BatchTotalStats._meta_.fields["sales"] = {};
BatchTotalStats._meta_.fields["sales"].type = payments_BatchTotalType;
BatchTotalStats._meta_.fields["refunds"] = {};
BatchTotalStats._meta_.fields["refunds"].type = payments_BatchTotalType;
BatchTotalStats._meta_.fields["net"] = {};
BatchTotalStats._meta_.fields["net"].type = payments_BatchTotalType;
BatchTotalStats._meta_.fields["giftCardLoads"] = {};
BatchTotalStats._meta_.fields["giftCardLoads"].type = payments_BatchTotalType;
BatchTotalStats._meta_.fields["giftCardCashOuts"] = {};
BatchTotalStats._meta_.fields["giftCardCashOuts"].type = payments_BatchTotalType;
BatchTotalStats._meta_.fields["tax"] = {};
BatchTotalStats._meta_.fields["tax"].type = payments_BatchTotalType;
BatchTotalStats._meta_.fields["tips"] = {};
BatchTotalStats._meta_.fields["tips"].type = payments_BatchTotalType;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = BatchTotalStats;
}

