/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var pay_PaymentRequestCardDetails = require("../pay/PaymentRequestCardDetails");
var payments_Credit = require("../payments/Credit");

/**
* @constructor
* @memberof payments
*/
var CreditRequest = function() {
  this._class_ = CreditRequest;
  this.orderId = undefined;
  this.credit = undefined;
  this.paymentCard = undefined;
};


/**
* Set the field value
* Unique identifier of the order with which this payment is associated
*
* @memberof payments.CreditRequest
* @param {String} orderId 
*/
CreditRequest.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier of the order with which this payment is associated
* @memberof payments.CreditRequest
* @return {String} 
*/
CreditRequest.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* @memberof payments.CreditRequest
* @param {payments.Credit|Null} credit 
*/
CreditRequest.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* @memberof payments.CreditRequest
* @return {payments.Credit|Null} 
*/
CreditRequest.prototype.getCredit = function() {
  return this.credit;
};

/**
* Set the field value
* @memberof payments.CreditRequest
* @param {pay.PaymentRequestCardDetails|Null} paymentCard 
*/
CreditRequest.prototype.setPaymentCard = function(paymentCard) {
  this.paymentCard = paymentCard;
};

/**
* Get the field value
* @memberof payments.CreditRequest
* @return {pay.PaymentRequestCardDetails|Null} 
*/
CreditRequest.prototype.getPaymentCard = function() {
  return this.paymentCard;
};

/**
* @memberof payments.CreditRequest
* @private
*/
CreditRequest.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

CreditRequest.prototype.toString = function() {
  return JSON.stringify(this);
};

CreditRequest._meta_ =  {fields:  {}};
CreditRequest._meta_._class_ =  CreditRequest;
CreditRequest._meta_.fields["orderId"] = {};
CreditRequest._meta_.fields["orderId"].type = String;
CreditRequest._meta_.fields["credit"] = {};
CreditRequest._meta_.fields["credit"].type = payments_Credit;
CreditRequest._meta_.fields["paymentCard"] = {};
CreditRequest._meta_.fields["paymentCard"].type = pay_PaymentRequestCardDetails;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CreditRequest;
}

