/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var onlineorder_ProviderId = require("../onlineorder/ProviderId");
var onlineorder_OnlineOrderCustomer = require("../onlineorder/OnlineOrderCustomer");
var onlineorder_Reason = require("../onlineorder/Reason");
var base_Reference = require("../base/Reference");
var onlineorder_OrderState = require("../onlineorder/OrderState");

/**
* @constructor
* @memberof onlineorder
*/
var OnlineOrder = function() {
  this._class_ = OnlineOrder;
  this.id = undefined;
  this.merchantRef = undefined;
  this.onlineOrderId = undefined;
  this.receiptId = undefined;
  this.provider = undefined;
  this.providerId = undefined;
  this.orderState = undefined;
  this.onlineOrderCustomer = undefined;
  this.deliverTime = undefined;
  this.scheduledPickupTime = undefined;
  this.reason = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
  this.deletedTime = undefined;
};


/**
* Set the field value
* order id
*
* @memberof onlineorder.OnlineOrder
* @param {String} id 
*/
OnlineOrder.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* order id
* @memberof onlineorder.OnlineOrder
* @return {String} 
*/
OnlineOrder.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Reference to the merchant that created the order
*
* @memberof onlineorder.OnlineOrder
* @param {base.Reference} merchantRef 
*/
OnlineOrder.prototype.setMerchantRef = function(merchantRef) {
  this.merchantRef = merchantRef;
};

/**
* Get the field value
* Reference to the merchant that created the order
* @memberof onlineorder.OnlineOrder
* @return {base.Reference} 
*/
OnlineOrder.prototype.getMerchantRef = function() {
  return this.merchantRef;
};

/**
* Set the field value
* Online Order id
*
* @memberof onlineorder.OnlineOrder
* @param {String} onlineOrderId 
*/
OnlineOrder.prototype.setOnlineOrderId = function(onlineOrderId) {
  this.onlineOrderId = onlineOrderId;
};

/**
* Get the field value
* Online Order id
* @memberof onlineorder.OnlineOrder
* @return {String} 
*/
OnlineOrder.prototype.getOnlineOrderId = function() {
  return this.onlineOrderId;
};

/**
* Set the field value
* Receipt id
*
* @memberof onlineorder.OnlineOrder
* @param {String} receiptId 
*/
OnlineOrder.prototype.setReceiptId = function(receiptId) {
  this.receiptId = receiptId;
};

/**
* Get the field value
* Receipt id
* @memberof onlineorder.OnlineOrder
* @return {String} 
*/
OnlineOrder.prototype.getReceiptId = function() {
  return this.receiptId;
};

/**
* Set the field value
* online order provider
*
* @memberof onlineorder.OnlineOrder
* @param {base.Reference} provider 
*/
OnlineOrder.prototype.setProvider = function(provider) {
  this.provider = provider;
};

/**
* Get the field value
* online order provider
* @memberof onlineorder.OnlineOrder
* @return {base.Reference} 
*/
OnlineOrder.prototype.getProvider = function() {
  return this.provider;
};

/**
* Set the field value
* online order provider, to be compatible with the data digestion on client side in Oct apps cut, will be removed later.
*
* @memberof onlineorder.OnlineOrder
* @param {Null|onlineorder.ProviderId} providerId 
*/
OnlineOrder.prototype.setProviderId = function(providerId) {
  this.providerId = providerId;
};

/**
* Get the field value
* online order provider, to be compatible with the data digestion on client side in Oct apps cut, will be removed later.
* @memberof onlineorder.OnlineOrder
* @return {Null|onlineorder.ProviderId} 
*/
OnlineOrder.prototype.getProviderId = function() {
  return this.providerId;
};

/**
* Set the field value
* online order status
*
* @memberof onlineorder.OnlineOrder
* @param {Null|onlineorder.OrderState} orderState 
*/
OnlineOrder.prototype.setOrderState = function(orderState) {
  this.orderState = orderState;
};

/**
* Get the field value
* online order status
* @memberof onlineorder.OnlineOrder
* @return {Null|onlineorder.OrderState} 
*/
OnlineOrder.prototype.getOrderState = function() {
  return this.orderState;
};

/**
* Set the field value
* the customer who submitted the order request from provider
*
* @memberof onlineorder.OnlineOrder
* @param {Null|onlineorder.OnlineOrderCustomer} onlineOrderCustomer 
*/
OnlineOrder.prototype.setOnlineOrderCustomer = function(onlineOrderCustomer) {
  this.onlineOrderCustomer = onlineOrderCustomer;
};

/**
* Get the field value
* the customer who submitted the order request from provider
* @memberof onlineorder.OnlineOrder
* @return {Null|onlineorder.OnlineOrderCustomer} 
*/
OnlineOrder.prototype.getOnlineOrderCustomer = function() {
  return this.onlineOrderCustomer;
};

/**
* Set the field value
* The timestamp the order gets delivered
*
* @memberof onlineorder.OnlineOrder
* @param {Number} deliverTime must be a long integer
*/
OnlineOrder.prototype.setDeliverTime = function(deliverTime) {
  this.deliverTime = deliverTime;
};

/**
* Get the field value
* The timestamp the order gets delivered
* @memberof onlineorder.OnlineOrder
* @return {Number} must be a long integer
*/
OnlineOrder.prototype.getDeliverTime = function() {
  return this.deliverTime;
};

/**
* Set the field value
* The timestamp the order is scheduled to be picked up
*
* @memberof onlineorder.OnlineOrder
* @param {Number} scheduledPickupTime must be a long integer
*/
OnlineOrder.prototype.setScheduledPickupTime = function(scheduledPickupTime) {
  this.scheduledPickupTime = scheduledPickupTime;
};

/**
* Get the field value
* The timestamp the order is scheduled to be picked up
* @memberof onlineorder.OnlineOrder
* @return {Number} must be a long integer
*/
OnlineOrder.prototype.getScheduledPickupTime = function() {
  return this.scheduledPickupTime;
};

/**
* Set the field value
* The reason the online order gets rejected or cancelled
*
* @memberof onlineorder.OnlineOrder
* @param {Null|onlineorder.Reason} reason 
*/
OnlineOrder.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* The reason the online order gets rejected or cancelled
* @memberof onlineorder.OnlineOrder
* @return {Null|onlineorder.Reason} 
*/
OnlineOrder.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* Timestamp when the online ordering item was created
*
* @memberof onlineorder.OnlineOrder
* @param {Number} createdTime must be a long integer
*/
OnlineOrder.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Timestamp when the online ordering item was created
* @memberof onlineorder.OnlineOrder
* @return {Number} must be a long integer
*/
OnlineOrder.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* Timestamp when the online ordering item was last modified
*
* @memberof onlineorder.OnlineOrder
* @param {Number} modifiedTime must be a long integer
*/
OnlineOrder.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* Timestamp when the online ordering item was last modified
* @memberof onlineorder.OnlineOrder
* @return {Number} must be a long integer
*/
OnlineOrder.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* Timestamp when online ordering item was last deleted
*
* @memberof onlineorder.OnlineOrder
* @param {Number} deletedTime must be a long integer
*/
OnlineOrder.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* Timestamp when online ordering item was last deleted
* @memberof onlineorder.OnlineOrder
* @return {Number} must be a long integer
*/
OnlineOrder.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* @memberof onlineorder.OnlineOrder
* @private
*/
OnlineOrder.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

OnlineOrder.prototype.toString = function() {
  return JSON.stringify(this);
};

OnlineOrder._meta_ =  {fields:  {}};
OnlineOrder._meta_._class_ =  OnlineOrder;
OnlineOrder._meta_.fields["id"] = {};
OnlineOrder._meta_.fields["id"].type = String;
OnlineOrder._meta_.fields["merchantRef"] = {};
OnlineOrder._meta_.fields["merchantRef"].type = base_Reference;
OnlineOrder._meta_.fields["onlineOrderId"] = {};
OnlineOrder._meta_.fields["onlineOrderId"].type = String;
OnlineOrder._meta_.fields["receiptId"] = {};
OnlineOrder._meta_.fields["receiptId"].type = String;
OnlineOrder._meta_.fields["provider"] = {};
OnlineOrder._meta_.fields["provider"].type = base_Reference;
OnlineOrder._meta_.fields["providerId"] = {};
OnlineOrder._meta_.fields["providerId"].type = onlineorder_ProviderId;
OnlineOrder._meta_.fields["orderState"] = {};
OnlineOrder._meta_.fields["orderState"].type = onlineorder_OrderState;
OnlineOrder._meta_.fields["onlineOrderCustomer"] = {};
OnlineOrder._meta_.fields["onlineOrderCustomer"].type = onlineorder_OnlineOrderCustomer;
OnlineOrder._meta_.fields["deliverTime"] = {};
OnlineOrder._meta_.fields["deliverTime"].type = Number;
OnlineOrder._meta_.fields["scheduledPickupTime"] = {};
OnlineOrder._meta_.fields["scheduledPickupTime"].type = Number;
OnlineOrder._meta_.fields["reason"] = {};
OnlineOrder._meta_.fields["reason"].type = onlineorder_Reason;
OnlineOrder._meta_.fields["createdTime"] = {};
OnlineOrder._meta_.fields["createdTime"].type = Number;
OnlineOrder._meta_.fields["modifiedTime"] = {};
OnlineOrder._meta_.fields["modifiedTime"].type = Number;
OnlineOrder._meta_.fields["deletedTime"] = {};
OnlineOrder._meta_.fields["deletedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OnlineOrder;
}

