/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var ErrorCode = {
  ORDER_NOT_FOUND : "ORDER_NOT_FOUND",
  PAYMENT_NOT_FOUND : "PAYMENT_NOT_FOUND",
  FAIL : "FAIL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ErrorCode;
}

