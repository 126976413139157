/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
Protocol identifier
* @memberof payments
* @enum {string}
*/
var VasProtocol = {
  ST : "ST",
  PK : "PK"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasProtocol;
}

