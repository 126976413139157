/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/** Request to start a custom activity on a device. */
/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var CustomActivityRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = CustomActivityRequest;
  this.action = undefined;
  this.payload = undefined;
  this.nonBlocking = undefined;
};

CustomActivityRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
CustomActivityRequest.prototype.constructor = CustomActivityRequest;

/**
* Set the field value
* The name of the action for this activity
*
* @memberof remotepay.CustomActivityRequest
* @param {String} action 
*/
CustomActivityRequest.prototype.setAction = function(action) {
  this.action = action;
};

/**
* Get the field value
* The name of the action for this activity
* @memberof remotepay.CustomActivityRequest
* @return {String} 
*/
CustomActivityRequest.prototype.getAction = function() {
  return this.action;
};

/**
* Set the field value
* Data for this activity
*
* @memberof remotepay.CustomActivityRequest
* @param {String} payload 
*/
CustomActivityRequest.prototype.setPayload = function(payload) {
  this.payload = payload;
};

/**
* Get the field value
* Data for this activity
* @memberof remotepay.CustomActivityRequest
* @return {String} 
*/
CustomActivityRequest.prototype.getPayload = function() {
  return this.payload;
};

/**
* Set the field value
* If true, then the activity can be stopped in regular execution.
*
* @memberof remotepay.CustomActivityRequest
* @param {Boolean} nonBlocking 
*/
CustomActivityRequest.prototype.setNonBlocking = function(nonBlocking) {
  this.nonBlocking = nonBlocking;
};

/**
* Get the field value
* If true, then the activity can be stopped in regular execution.
* @memberof remotepay.CustomActivityRequest
* @return {Boolean} 
*/
CustomActivityRequest.prototype.getNonBlocking = function() {
  return this.nonBlocking;
};

CustomActivityRequest._meta_ =  {fields:  {}};
CustomActivityRequest._meta_._class_ =  CustomActivityRequest;
CustomActivityRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
CustomActivityRequest._meta_.fields["action"] = {};
CustomActivityRequest._meta_.fields["action"].type = String;
CustomActivityRequest._meta_.fields["payload"] = {};
CustomActivityRequest._meta_.fields["payload"].type = String;
CustomActivityRequest._meta_.fields["nonBlocking"] = {};
CustomActivityRequest._meta_.fields["nonBlocking"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CustomActivityRequest;
}

