/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Payment = require("../payments/Payment");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var SignatureVerifiedMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = SignatureVerifiedMessage;
  this.setMethod(remotemessage_Method["SIGNATURE_VERIFIED"]);
  this.payment = undefined;
  this.verified = undefined;
};

SignatureVerifiedMessage.prototype = Object.create(remotemessage_Message.prototype);
SignatureVerifiedMessage.prototype.constructor = SignatureVerifiedMessage;

/**
* Set the field value
* A payment
*
* @memberof remotemessage.SignatureVerifiedMessage
* @param {payments.Payment} payment 
*/
SignatureVerifiedMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* A payment
* @memberof remotemessage.SignatureVerifiedMessage
* @return {payments.Payment} 
*/
SignatureVerifiedMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* If true the signature is verified
*
* @memberof remotemessage.SignatureVerifiedMessage
* @param {Boolean} verified 
*/
SignatureVerifiedMessage.prototype.setVerified = function(verified) {
  this.verified = verified;
};

/**
* Get the field value
* If true the signature is verified
* @memberof remotemessage.SignatureVerifiedMessage
* @return {Boolean} 
*/
SignatureVerifiedMessage.prototype.getVerified = function() {
  return this.verified;
};

SignatureVerifiedMessage._meta_ =  {fields:  {}};
SignatureVerifiedMessage._meta_._class_ =  SignatureVerifiedMessage;
SignatureVerifiedMessage._meta_._superMeta_ = remotemessage_Message._meta_;
SignatureVerifiedMessage._meta_.fields["payment"] = {};
SignatureVerifiedMessage._meta_.fields["payment"].type = payments_Payment;
SignatureVerifiedMessage._meta_.fields["verified"] = {};
SignatureVerifiedMessage._meta_.fields["verified"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = SignatureVerifiedMessage;
}

