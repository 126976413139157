/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var DataEntryLocation = {
  ON_SCREEN : "ON_SCREEN",
  ON_PAPER : "ON_PAPER",
  NONE : "NONE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DataEntryLocation;
}

