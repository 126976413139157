import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { Card } from "@rneui/themed";
import moment from "moment";
import "moment/locale/es";
import CardInformationProps from "../types/components/CardInformationProps";
import { styles } from "../styles/components/cardInformation";
import CalendarIcon from '../assets/images/calendar-icon.svg';

export default ({
  id,
  image,
  title,
  date,
  dateLanguage,
  isForArticleListing,
  venueLogoPath,
  showDate,
  showTime,
  onClick,
}: CardInformationProps): JSX.Element => {
  const translateDateToSpanish = (): string => {
    moment.locale(dateLanguage);
    return moment(date).format("DD/MM/YY");
  };

  const idealHeightForListing = 200;

  const renderContent = (): JSX.Element => (
    <Card containerStyle={styles.container}>
      <Card.Image
        source={{ uri: image || "https://via.placeholder.com/350x150" }}
        style={[styles.cardImage, isForArticleListing && { height: idealHeightForListing }]}
      >
        <View style={styles.internalVenueLogoContainer}>
          <Image 
            source={{ uri: venueLogoPath || "https://via.placeholder.com/350x150" }}
            style={styles.venueLogo}
          />
        </View>
      </Card.Image>
      <View style={[styles.cardDataContainer, isForArticleListing && styles.bottomBordersShadow ]}>
      <Card.Title style={styles.title}>{title}</Card.Title>
      <View style={styles.row}>
        <View style={styles.iconImageContainer}>
          <Image source={CalendarIcon} style={styles.iconImage} />
        </View>
        <View style={styles.dateTimeContainer}>
          {showDate && date ? (
            <Text style={styles.date}> {translateDateToSpanish()}</Text>
          ) : null}
          {showTime && date ? (
            <Text style={styles.time}> {`- ${moment(date).format("HH:mm")}hs`}</Text> 
          ) : null}
        </View>
        {!showDate && !showTime && <View></View>}
      </View>
      </View>
    </Card>
  );
  if (onClick) {
    return (
      <TouchableOpacity
        style={styles.clickableOpacity}
        onPress={() => {
          onClick(id);
        }}
      >
        {renderContent()}
      </TouchableOpacity>
    );
  }
  return renderContent();
};
