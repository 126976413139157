/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var UiDirection = {
  ENTER : "ENTER",
  EXIT : "EXIT"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = UiDirection;
}

