/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var device_RomBuildType = require("../device/RomBuildType");

/**
* @constructor
* @memberof device
*/
var Rom = function() {
  this._class_ = Rom;
  this.id = undefined;
  this.createdAt = undefined;
  this.version = undefined;
  this.versionName = undefined;
  this.description = undefined;
  this.buildType = undefined;
  this.isCritical = undefined;
  this.published = undefined;
  this.enabled = undefined;
  this.associationTime = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof device.Rom
* @param {String} id 
*/
Rom.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof device.Rom
* @return {String} 
*/
Rom.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* When the ROM was created
*
* @memberof device.Rom
* @param {Number} createdAt must be a long integer
*/
Rom.prototype.setCreatedAt = function(createdAt) {
  this.createdAt = createdAt;
};

/**
* Get the field value
* When the ROM was created
* @memberof device.Rom
* @return {Number} must be a long integer
*/
Rom.prototype.getCreatedAt = function() {
  return this.createdAt;
};

/**
* Set the field value
* @memberof device.Rom
* @param {Number} version must be a long integer
*/
Rom.prototype.setVersion = function(version) {
  this.version = version;
};

/**
* Get the field value
* @memberof device.Rom
* @return {Number} must be a long integer
*/
Rom.prototype.getVersion = function() {
  return this.version;
};

/**
* Set the field value
* @memberof device.Rom
* @param {String} versionName 
*/
Rom.prototype.setVersionName = function(versionName) {
  this.versionName = versionName;
};

/**
* Get the field value
* @memberof device.Rom
* @return {String} 
*/
Rom.prototype.getVersionName = function() {
  return this.versionName;
};

/**
* Set the field value
* @memberof device.Rom
* @param {String} description 
*/
Rom.prototype.setDescription = function(description) {
  this.description = description;
};

/**
* Get the field value
* @memberof device.Rom
* @return {String} 
*/
Rom.prototype.getDescription = function() {
  return this.description;
};

/**
* Set the field value
* @memberof device.Rom
* @param {device.RomBuildType} buildType 
*/
Rom.prototype.setBuildType = function(buildType) {
  this.buildType = buildType;
};

/**
* Get the field value
* @memberof device.Rom
* @return {device.RomBuildType} 
*/
Rom.prototype.getBuildType = function() {
  return this.buildType;
};

/**
* Set the field value
* @memberof device.Rom
* @param {Boolean} isCritical 
*/
Rom.prototype.setIsCritical = function(isCritical) {
  this.isCritical = isCritical;
};

/**
* Get the field value
* @memberof device.Rom
* @return {Boolean} 
*/
Rom.prototype.getIsCritical = function() {
  return this.isCritical;
};

/**
* Set the field value
* @memberof device.Rom
* @param {Boolean} published 
*/
Rom.prototype.setPublished = function(published) {
  this.published = published;
};

/**
* Get the field value
* @memberof device.Rom
* @return {Boolean} 
*/
Rom.prototype.getPublished = function() {
  return this.published;
};

/**
* Set the field value
* @memberof device.Rom
* @param {Boolean} enabled 
*/
Rom.prototype.setEnabled = function(enabled) {
  this.enabled = enabled;
};

/**
* Get the field value
* @memberof device.Rom
* @return {Boolean} 
*/
Rom.prototype.getEnabled = function() {
  return this.enabled;
};

/**
* Set the field value
* When the ROM was associated with a merchant group
*
* @memberof device.Rom
* @param {Number} associationTime must be a long integer
*/
Rom.prototype.setAssociationTime = function(associationTime) {
  this.associationTime = associationTime;
};

/**
* Get the field value
* When the ROM was associated with a merchant group
* @memberof device.Rom
* @return {Number} must be a long integer
*/
Rom.prototype.getAssociationTime = function() {
  return this.associationTime;
};

/**
* @memberof device.Rom
* @private
*/
Rom.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Rom.prototype.toString = function() {
  return JSON.stringify(this);
};

Rom._meta_ =  {fields:  {}};
Rom._meta_._class_ =  Rom;
Rom._meta_.fields["id"] = {};
Rom._meta_.fields["id"].type = String;
Rom._meta_.fields["createdAt"] = {};
Rom._meta_.fields["createdAt"].type = Number;
Rom._meta_.fields["version"] = {};
Rom._meta_.fields["version"].type = Number;
Rom._meta_.fields["versionName"] = {};
Rom._meta_.fields["versionName"].type = String;
Rom._meta_.fields["description"] = {};
Rom._meta_.fields["description"].type = String;
Rom._meta_.fields["buildType"] = {};
Rom._meta_.fields["buildType"].type = device_RomBuildType;
Rom._meta_.fields["isCritical"] = {};
Rom._meta_.fields["isCritical"].type = Boolean;
Rom._meta_.fields["published"] = {};
Rom._meta_.fields["published"].type = Boolean;
Rom._meta_.fields["enabled"] = {};
Rom._meta_.fields["enabled"].type = Boolean;
Rom._meta_.fields["associationTime"] = {};
Rom._meta_.fields["associationTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Rom;
}

