/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof device
*/
var SwapRequestEvent = function() {
  this._class_ = SwapRequestEvent;
  this.id = undefined;
  this.type = undefined;
  this.serialNumber = undefined;
  this.createdTime = undefined;
  this.merchant = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof device.SwapRequestEvent
* @param {String} id 
*/
SwapRequestEvent.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof device.SwapRequestEvent
* @return {String} 
*/
SwapRequestEvent.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof device.SwapRequestEvent
* @param {String} type 
*/
SwapRequestEvent.prototype.setType = function(type) {
  this.type = type;
};

/**
* Get the field value
* @memberof device.SwapRequestEvent
* @return {String} 
*/
SwapRequestEvent.prototype.getType = function() {
  return this.type;
};

/**
* Set the field value
* @memberof device.SwapRequestEvent
* @param {String} serialNumber 
*/
SwapRequestEvent.prototype.setSerialNumber = function(serialNumber) {
  this.serialNumber = serialNumber;
};

/**
* Get the field value
* @memberof device.SwapRequestEvent
* @return {String} 
*/
SwapRequestEvent.prototype.getSerialNumber = function() {
  return this.serialNumber;
};

/**
* Set the field value
* @memberof device.SwapRequestEvent
* @param {Number} createdTime must be a long integer
*/
SwapRequestEvent.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* @memberof device.SwapRequestEvent
* @return {Number} must be a long integer
*/
SwapRequestEvent.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* @memberof device.SwapRequestEvent
* @param {base.Reference} merchant 
*/
SwapRequestEvent.prototype.setMerchant = function(merchant) {
  this.merchant = merchant;
};

/**
* Get the field value
* @memberof device.SwapRequestEvent
* @return {base.Reference} 
*/
SwapRequestEvent.prototype.getMerchant = function() {
  return this.merchant;
};

/**
* @memberof device.SwapRequestEvent
* @private
*/
SwapRequestEvent.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

SwapRequestEvent.prototype.toString = function() {
  return JSON.stringify(this);
};

SwapRequestEvent._meta_ =  {fields:  {}};
SwapRequestEvent._meta_._class_ =  SwapRequestEvent;
SwapRequestEvent._meta_.fields["id"] = {};
SwapRequestEvent._meta_.fields["id"].type = String;
SwapRequestEvent._meta_.fields["type"] = {};
SwapRequestEvent._meta_.fields["type"].type = String;
SwapRequestEvent._meta_.fields["serialNumber"] = {};
SwapRequestEvent._meta_.fields["serialNumber"].type = String;
SwapRequestEvent._meta_.fields["createdTime"] = {};
SwapRequestEvent._meta_.fields["createdTime"].type = Number;
SwapRequestEvent._meta_.fields["merchant"] = {};
SwapRequestEvent._meta_.fields["merchant"].type = base_Reference;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = SwapRequestEvent;
}

