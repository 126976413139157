/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof base
* @enum {string}
*/
var AppExclusionType = {
  ALL : "ALL",
  MARKET : "MARKET",
  SEARCH : "SEARCH",
  INSTALL : "INSTALL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AppExclusionType;
}

