/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var TipAdded = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = TipAdded;
  this.tipAmount = undefined;
};

TipAdded.prototype = Object.create(remotepay_BaseResponse.prototype);
TipAdded.prototype.constructor = TipAdded;

/**
* Set the field value
* Tip amount
*
* @memberof remotepay.TipAdded
* @param {Number} tipAmount must be a long integer
*/
TipAdded.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Tip amount
* @memberof remotepay.TipAdded
* @return {Number} must be a long integer
*/
TipAdded.prototype.getTipAmount = function() {
  return this.tipAmount;
};

TipAdded._meta_ =  {fields:  {}};
TipAdded._meta_._class_ =  TipAdded;
TipAdded._meta_._superMeta_ = remotepay_BaseResponse._meta_;
TipAdded._meta_.fields["tipAmount"] = {};
TipAdded._meta_.fields["tipAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipAdded;
}

