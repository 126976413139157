/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_ActivityMessage = require("../remotepay/ActivityMessage");

/** A message sent to custom activities */
/**
* @constructor
* @augments remotepay.ActivityMessage
* @memberof remotepay
*/
var MessageFromActivity = function() {
  remotepay_ActivityMessage.call(this);
  this._superClass_ = remotepay_ActivityMessage;
  this._class_ = MessageFromActivity;
};

MessageFromActivity.prototype = Object.create(remotepay_ActivityMessage.prototype);
MessageFromActivity.prototype.constructor = MessageFromActivity;

MessageFromActivity._meta_ =  {fields:  {}};
MessageFromActivity._meta_._class_ =  MessageFromActivity;
MessageFromActivity._meta_._superMeta_ = remotepay_ActivityMessage._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = MessageFromActivity;
}

