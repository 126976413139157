/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof payments
*/
var VaultedCard = function() {
  this._class_ = VaultedCard;
  this.first6 = undefined;
  this.last4 = undefined;
  this.cardholderName = undefined;
  this.expirationDate = undefined;
  this.token = undefined;
};


/**
* Set the field value
* @memberof payments.VaultedCard
* @param {String} first6 
*/
VaultedCard.prototype.setFirst6 = function(first6) {
  this.first6 = first6;
};

/**
* Get the field value
* @memberof payments.VaultedCard
* @return {String} 
*/
VaultedCard.prototype.getFirst6 = function() {
  return this.first6;
};

/**
* Set the field value
* @memberof payments.VaultedCard
* @param {String} last4 
*/
VaultedCard.prototype.setLast4 = function(last4) {
  this.last4 = last4;
};

/**
* Get the field value
* @memberof payments.VaultedCard
* @return {String} 
*/
VaultedCard.prototype.getLast4 = function() {
  return this.last4;
};

/**
* Set the field value
* @memberof payments.VaultedCard
* @param {String} cardholderName 
*/
VaultedCard.prototype.setCardholderName = function(cardholderName) {
  this.cardholderName = cardholderName;
};

/**
* Get the field value
* @memberof payments.VaultedCard
* @return {String} 
*/
VaultedCard.prototype.getCardholderName = function() {
  return this.cardholderName;
};

/**
* Set the field value
* @memberof payments.VaultedCard
* @param {String} expirationDate 
*/
VaultedCard.prototype.setExpirationDate = function(expirationDate) {
  this.expirationDate = expirationDate;
};

/**
* Get the field value
* @memberof payments.VaultedCard
* @return {String} 
*/
VaultedCard.prototype.getExpirationDate = function() {
  return this.expirationDate;
};

/**
* Set the field value
* @memberof payments.VaultedCard
* @param {String} token 
*/
VaultedCard.prototype.setToken = function(token) {
  this.token = token;
};

/**
* Get the field value
* @memberof payments.VaultedCard
* @return {String} 
*/
VaultedCard.prototype.getToken = function() {
  return this.token;
};

/**
* @memberof payments.VaultedCard
* @private
*/
VaultedCard.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

VaultedCard.prototype.toString = function() {
  return JSON.stringify(this);
};

VaultedCard._meta_ =  {fields:  {}};
VaultedCard._meta_._class_ =  VaultedCard;
VaultedCard._meta_.fields["first6"] = {};
VaultedCard._meta_.fields["first6"].type = String;
VaultedCard._meta_.fields["last4"] = {};
VaultedCard._meta_.fields["last4"].type = String;
VaultedCard._meta_.fields["cardholderName"] = {};
VaultedCard._meta_.fields["cardholderName"].type = String;
VaultedCard._meta_.fields["expirationDate"] = {};
VaultedCard._meta_.fields["expirationDate"].type = String;
VaultedCard._meta_.fields["token"] = {};
VaultedCard._meta_.fields["token"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VaultedCard;
}

