/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_CvmResult = require("../payments/CvmResult");
var pay_ErrorCode = require("../pay/ErrorCode");
var pay_TxResult = require("../pay/TxResult");
var pay_GwTxResult = require("../pay/GwTxResult");
var pay_ServiceCode1 = require("../pay/ServiceCode1");
var pay_ServiceCode2 = require("../pay/ServiceCode2");
var pay_ServiceCode3 = require("../pay/ServiceCode3");

/**
* @constructor
* @memberof pay
*/
var TransactionData = function() {
  this._class_ = TransactionData;
  this.txResult = undefined;
  this.txError = undefined;
  this.amount = undefined;
  this.tipAmount = undefined;
  this.cashBackAmount = undefined;
  this.errorCode = undefined;
  this.errorText = undefined;
  this.transactionDate = undefined;
  this.transactionTime = undefined;
  this.msrContainer = undefined;
  this.msrMaskedTrack1 = undefined;
  this.msrMaskedTrack2 = undefined;
  this.maskedManualPan = undefined;
  this.sredContainer = undefined;
  this.sred = undefined;
  this.ksn = undefined;
  this.transArmorContainer = undefined;
  this.transArmorKey = undefined;
  this.transArmorKeyId = undefined;
  this.transArmorEncryptedTrack1 = undefined;
  this.transArmorEncryptedTrack2 = undefined;
  this.transArmorEncryptedEmvTag57 = undefined;
  this.transArmorEncryptedEmvTag5A = undefined;
  this.transArmorEncryptedManualKeyedData = undefined;
  this.iccContainer = undefined;
  this.iccApplicationInterchangeProfile = undefined;
  this.iccMaskedEmv57 = undefined;
  this.iccMaskedEmv5A = undefined;
  this.iccApplicationPanSequenceNumber = undefined;
  this.iccApplicationExpirationDate = undefined;
  this.iccApplicationEffectiveDate = undefined;
  this.iccAmountAuthorized = undefined;
  this.iccAmountOther = undefined;
  this.iccTransactionCurrencyCode = undefined;
  this.iccTransactionCurrencyExponent = undefined;
  this.iccApplicationUsageControl = undefined;
  this.iccIssuerActionCodeDefault = undefined;
  this.iccIssuerActionCodeDenial = undefined;
  this.iccIssuerActionCodeOnline = undefined;
  this.iccApplicationLabel = undefined;
  this.iccApplicationCryptogram = undefined;
  this.iccApplicationIdentifierCard = undefined;
  this.iccApplicationIdentifierTerminal = undefined;
  this.iccApplicationTransactionCounter = undefined;
  this.iccApplicationVersionNumber = undefined;
  this.iccCryptogramInformationData = undefined;
  this.iccCvmResults = undefined;
  this.iccInterfaceDeviceSerialNumber = undefined;
  this.iccIssuerApplicationData = undefined;
  this.iccPosEntryModeCode = undefined;
  this.iccTerminalCapabilities = undefined;
  this.iccTerminalCountryCode = undefined;
  this.iccTerminalType = undefined;
  this.iccTvr = undefined;
  this.iccTransactionDate = undefined;
  this.iccTsi = undefined;
  this.iccTransactionType = undefined;
  this.iccUnpredictableNumber = undefined;
  this.iccTransactionTime = undefined;
  this.iccAdditionalTerminalCapabilities = undefined;
  this.iccTransactionCategoryCode = undefined;
  this.iccIssuerApplicationPreferredName = undefined;
  this.iccCardholderName = undefined;
  this.iccIssuerCodeTableIndex = undefined;
  this.iccIssuerScripts = undefined;
  this.iccIssuerScriptResults = undefined;
  this.debugTrack2EquivalentData = undefined;
  this.debugApplicationPan = undefined;
  this.debugAmountAuthorizedBinary = undefined;
  this.debugAmountOtherBinary = undefined;
  this.debugTransactionStatusInformation = undefined;
  this.debugPlainTrack1 = undefined;
  this.debugPlainTrack2 = undefined;
  this.schemePunATC = undefined;
  this.schemeThirdPartyData = undefined;
  this.schemeMerchantCustomData = undefined;
  this.schemeTerminalEntryCapability = undefined;
  this.pinBlockContainer = undefined;
  this.pinBlock = undefined;
  this.pinBlockKsn = undefined;
  this.mac = undefined;
  this.macKsn = undefined;
  this.gwContainer = undefined;
  this.gwIssuerAuthenticationData = undefined;
  this.gwIssuerScriptTemplate1 = undefined;
  this.gwIssuerScriptTemplate2 = undefined;
  this.gwIssuerAuthorizationResponseCode = undefined;
  this.gwMessageControlField = undefined;
  this.gwTxResult = undefined;
  this.cvmResult = undefined;
  this.serviceCode1 = undefined;
  this.serviceCode2 = undefined;
  this.serviceCode3 = undefined;
  this.offlineApprovalAuthCode = undefined;
  this.availableOfflineSpendingAmount = undefined;
};


/**
* Set the field value
* @memberof pay.TransactionData
* @param {pay.TxResult} txResult 
*/
TransactionData.prototype.setTxResult = function(txResult) {
  this.txResult = txResult;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {pay.TxResult} 
*/
TransactionData.prototype.getTxResult = function() {
  return this.txResult;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {pay.ErrorCode} txError 
*/
TransactionData.prototype.setTxError = function(txError) {
  this.txError = txError;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {pay.ErrorCode} 
*/
TransactionData.prototype.getTxError = function() {
  return this.txError;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {Number} amount must be a double
*/
TransactionData.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {Number} must be a double
*/
TransactionData.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {Number} tipAmount must be a double
*/
TransactionData.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {Number} must be a double
*/
TransactionData.prototype.getTipAmount = function() {
  return this.tipAmount;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {Number} cashBackAmount must be a double
*/
TransactionData.prototype.setCashBackAmount = function(cashBackAmount) {
  this.cashBackAmount = cashBackAmount;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {Number} must be a double
*/
TransactionData.prototype.getCashBackAmount = function() {
  return this.cashBackAmount;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} errorCode 
*/
TransactionData.prototype.setErrorCode = function(errorCode) {
  this.errorCode = errorCode;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getErrorCode = function() {
  return this.errorCode;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} errorText 
*/
TransactionData.prototype.setErrorText = function(errorText) {
  this.errorText = errorText;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getErrorText = function() {
  return this.errorText;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transactionDate 
*/
TransactionData.prototype.setTransactionDate = function(transactionDate) {
  this.transactionDate = transactionDate;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransactionDate = function() {
  return this.transactionDate;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transactionTime 
*/
TransactionData.prototype.setTransactionTime = function(transactionTime) {
  this.transactionTime = transactionTime;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransactionTime = function() {
  return this.transactionTime;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} msrContainer 
*/
TransactionData.prototype.setMsrContainer = function(msrContainer) {
  this.msrContainer = msrContainer;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getMsrContainer = function() {
  return this.msrContainer;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} msrMaskedTrack1 
*/
TransactionData.prototype.setMsrMaskedTrack1 = function(msrMaskedTrack1) {
  this.msrMaskedTrack1 = msrMaskedTrack1;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getMsrMaskedTrack1 = function() {
  return this.msrMaskedTrack1;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} msrMaskedTrack2 
*/
TransactionData.prototype.setMsrMaskedTrack2 = function(msrMaskedTrack2) {
  this.msrMaskedTrack2 = msrMaskedTrack2;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getMsrMaskedTrack2 = function() {
  return this.msrMaskedTrack2;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} maskedManualPan 
*/
TransactionData.prototype.setMaskedManualPan = function(maskedManualPan) {
  this.maskedManualPan = maskedManualPan;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getMaskedManualPan = function() {
  return this.maskedManualPan;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} sredContainer 
*/
TransactionData.prototype.setSredContainer = function(sredContainer) {
  this.sredContainer = sredContainer;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getSredContainer = function() {
  return this.sredContainer;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} sred 
*/
TransactionData.prototype.setSred = function(sred) {
  this.sred = sred;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getSred = function() {
  return this.sred;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} ksn 
*/
TransactionData.prototype.setKsn = function(ksn) {
  this.ksn = ksn;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getKsn = function() {
  return this.ksn;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transArmorContainer 
*/
TransactionData.prototype.setTransArmorContainer = function(transArmorContainer) {
  this.transArmorContainer = transArmorContainer;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransArmorContainer = function() {
  return this.transArmorContainer;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transArmorKey 
*/
TransactionData.prototype.setTransArmorKey = function(transArmorKey) {
  this.transArmorKey = transArmorKey;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransArmorKey = function() {
  return this.transArmorKey;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transArmorKeyId 
*/
TransactionData.prototype.setTransArmorKeyId = function(transArmorKeyId) {
  this.transArmorKeyId = transArmorKeyId;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransArmorKeyId = function() {
  return this.transArmorKeyId;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transArmorEncryptedTrack1 
*/
TransactionData.prototype.setTransArmorEncryptedTrack1 = function(transArmorEncryptedTrack1) {
  this.transArmorEncryptedTrack1 = transArmorEncryptedTrack1;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransArmorEncryptedTrack1 = function() {
  return this.transArmorEncryptedTrack1;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transArmorEncryptedTrack2 
*/
TransactionData.prototype.setTransArmorEncryptedTrack2 = function(transArmorEncryptedTrack2) {
  this.transArmorEncryptedTrack2 = transArmorEncryptedTrack2;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransArmorEncryptedTrack2 = function() {
  return this.transArmorEncryptedTrack2;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transArmorEncryptedEmvTag57 
*/
TransactionData.prototype.setTransArmorEncryptedEmvTag57 = function(transArmorEncryptedEmvTag57) {
  this.transArmorEncryptedEmvTag57 = transArmorEncryptedEmvTag57;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransArmorEncryptedEmvTag57 = function() {
  return this.transArmorEncryptedEmvTag57;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transArmorEncryptedEmvTag5A 
*/
TransactionData.prototype.setTransArmorEncryptedEmvTag5A = function(transArmorEncryptedEmvTag5A) {
  this.transArmorEncryptedEmvTag5A = transArmorEncryptedEmvTag5A;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransArmorEncryptedEmvTag5A = function() {
  return this.transArmorEncryptedEmvTag5A;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} transArmorEncryptedManualKeyedData 
*/
TransactionData.prototype.setTransArmorEncryptedManualKeyedData = function(transArmorEncryptedManualKeyedData) {
  this.transArmorEncryptedManualKeyedData = transArmorEncryptedManualKeyedData;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getTransArmorEncryptedManualKeyedData = function() {
  return this.transArmorEncryptedManualKeyedData;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccContainer 
*/
TransactionData.prototype.setIccContainer = function(iccContainer) {
  this.iccContainer = iccContainer;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccContainer = function() {
  return this.iccContainer;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationInterchangeProfile 
*/
TransactionData.prototype.setIccApplicationInterchangeProfile = function(iccApplicationInterchangeProfile) {
  this.iccApplicationInterchangeProfile = iccApplicationInterchangeProfile;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationInterchangeProfile = function() {
  return this.iccApplicationInterchangeProfile;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccMaskedEmv57 
*/
TransactionData.prototype.setIccMaskedEmv57 = function(iccMaskedEmv57) {
  this.iccMaskedEmv57 = iccMaskedEmv57;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccMaskedEmv57 = function() {
  return this.iccMaskedEmv57;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccMaskedEmv5A 
*/
TransactionData.prototype.setIccMaskedEmv5A = function(iccMaskedEmv5A) {
  this.iccMaskedEmv5A = iccMaskedEmv5A;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccMaskedEmv5A = function() {
  return this.iccMaskedEmv5A;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationPanSequenceNumber 
*/
TransactionData.prototype.setIccApplicationPanSequenceNumber = function(iccApplicationPanSequenceNumber) {
  this.iccApplicationPanSequenceNumber = iccApplicationPanSequenceNumber;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationPanSequenceNumber = function() {
  return this.iccApplicationPanSequenceNumber;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationExpirationDate 
*/
TransactionData.prototype.setIccApplicationExpirationDate = function(iccApplicationExpirationDate) {
  this.iccApplicationExpirationDate = iccApplicationExpirationDate;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationExpirationDate = function() {
  return this.iccApplicationExpirationDate;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationEffectiveDate 
*/
TransactionData.prototype.setIccApplicationEffectiveDate = function(iccApplicationEffectiveDate) {
  this.iccApplicationEffectiveDate = iccApplicationEffectiveDate;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationEffectiveDate = function() {
  return this.iccApplicationEffectiveDate;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccAmountAuthorized 
*/
TransactionData.prototype.setIccAmountAuthorized = function(iccAmountAuthorized) {
  this.iccAmountAuthorized = iccAmountAuthorized;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccAmountAuthorized = function() {
  return this.iccAmountAuthorized;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccAmountOther 
*/
TransactionData.prototype.setIccAmountOther = function(iccAmountOther) {
  this.iccAmountOther = iccAmountOther;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccAmountOther = function() {
  return this.iccAmountOther;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTransactionCurrencyCode 
*/
TransactionData.prototype.setIccTransactionCurrencyCode = function(iccTransactionCurrencyCode) {
  this.iccTransactionCurrencyCode = iccTransactionCurrencyCode;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTransactionCurrencyCode = function() {
  return this.iccTransactionCurrencyCode;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTransactionCurrencyExponent 
*/
TransactionData.prototype.setIccTransactionCurrencyExponent = function(iccTransactionCurrencyExponent) {
  this.iccTransactionCurrencyExponent = iccTransactionCurrencyExponent;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTransactionCurrencyExponent = function() {
  return this.iccTransactionCurrencyExponent;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationUsageControl 
*/
TransactionData.prototype.setIccApplicationUsageControl = function(iccApplicationUsageControl) {
  this.iccApplicationUsageControl = iccApplicationUsageControl;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationUsageControl = function() {
  return this.iccApplicationUsageControl;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccIssuerActionCodeDefault 
*/
TransactionData.prototype.setIccIssuerActionCodeDefault = function(iccIssuerActionCodeDefault) {
  this.iccIssuerActionCodeDefault = iccIssuerActionCodeDefault;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccIssuerActionCodeDefault = function() {
  return this.iccIssuerActionCodeDefault;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccIssuerActionCodeDenial 
*/
TransactionData.prototype.setIccIssuerActionCodeDenial = function(iccIssuerActionCodeDenial) {
  this.iccIssuerActionCodeDenial = iccIssuerActionCodeDenial;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccIssuerActionCodeDenial = function() {
  return this.iccIssuerActionCodeDenial;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccIssuerActionCodeOnline 
*/
TransactionData.prototype.setIccIssuerActionCodeOnline = function(iccIssuerActionCodeOnline) {
  this.iccIssuerActionCodeOnline = iccIssuerActionCodeOnline;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccIssuerActionCodeOnline = function() {
  return this.iccIssuerActionCodeOnline;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationLabel 
*/
TransactionData.prototype.setIccApplicationLabel = function(iccApplicationLabel) {
  this.iccApplicationLabel = iccApplicationLabel;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationLabel = function() {
  return this.iccApplicationLabel;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationCryptogram 
*/
TransactionData.prototype.setIccApplicationCryptogram = function(iccApplicationCryptogram) {
  this.iccApplicationCryptogram = iccApplicationCryptogram;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationCryptogram = function() {
  return this.iccApplicationCryptogram;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationIdentifierCard 
*/
TransactionData.prototype.setIccApplicationIdentifierCard = function(iccApplicationIdentifierCard) {
  this.iccApplicationIdentifierCard = iccApplicationIdentifierCard;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationIdentifierCard = function() {
  return this.iccApplicationIdentifierCard;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationIdentifierTerminal 
*/
TransactionData.prototype.setIccApplicationIdentifierTerminal = function(iccApplicationIdentifierTerminal) {
  this.iccApplicationIdentifierTerminal = iccApplicationIdentifierTerminal;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationIdentifierTerminal = function() {
  return this.iccApplicationIdentifierTerminal;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationTransactionCounter 
*/
TransactionData.prototype.setIccApplicationTransactionCounter = function(iccApplicationTransactionCounter) {
  this.iccApplicationTransactionCounter = iccApplicationTransactionCounter;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationTransactionCounter = function() {
  return this.iccApplicationTransactionCounter;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccApplicationVersionNumber 
*/
TransactionData.prototype.setIccApplicationVersionNumber = function(iccApplicationVersionNumber) {
  this.iccApplicationVersionNumber = iccApplicationVersionNumber;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccApplicationVersionNumber = function() {
  return this.iccApplicationVersionNumber;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccCryptogramInformationData 
*/
TransactionData.prototype.setIccCryptogramInformationData = function(iccCryptogramInformationData) {
  this.iccCryptogramInformationData = iccCryptogramInformationData;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccCryptogramInformationData = function() {
  return this.iccCryptogramInformationData;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccCvmResults 
*/
TransactionData.prototype.setIccCvmResults = function(iccCvmResults) {
  this.iccCvmResults = iccCvmResults;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccCvmResults = function() {
  return this.iccCvmResults;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccInterfaceDeviceSerialNumber 
*/
TransactionData.prototype.setIccInterfaceDeviceSerialNumber = function(iccInterfaceDeviceSerialNumber) {
  this.iccInterfaceDeviceSerialNumber = iccInterfaceDeviceSerialNumber;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccInterfaceDeviceSerialNumber = function() {
  return this.iccInterfaceDeviceSerialNumber;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccIssuerApplicationData 
*/
TransactionData.prototype.setIccIssuerApplicationData = function(iccIssuerApplicationData) {
  this.iccIssuerApplicationData = iccIssuerApplicationData;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccIssuerApplicationData = function() {
  return this.iccIssuerApplicationData;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccPosEntryModeCode 
*/
TransactionData.prototype.setIccPosEntryModeCode = function(iccPosEntryModeCode) {
  this.iccPosEntryModeCode = iccPosEntryModeCode;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccPosEntryModeCode = function() {
  return this.iccPosEntryModeCode;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTerminalCapabilities 
*/
TransactionData.prototype.setIccTerminalCapabilities = function(iccTerminalCapabilities) {
  this.iccTerminalCapabilities = iccTerminalCapabilities;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTerminalCapabilities = function() {
  return this.iccTerminalCapabilities;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTerminalCountryCode 
*/
TransactionData.prototype.setIccTerminalCountryCode = function(iccTerminalCountryCode) {
  this.iccTerminalCountryCode = iccTerminalCountryCode;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTerminalCountryCode = function() {
  return this.iccTerminalCountryCode;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTerminalType 
*/
TransactionData.prototype.setIccTerminalType = function(iccTerminalType) {
  this.iccTerminalType = iccTerminalType;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTerminalType = function() {
  return this.iccTerminalType;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTvr 
*/
TransactionData.prototype.setIccTvr = function(iccTvr) {
  this.iccTvr = iccTvr;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTvr = function() {
  return this.iccTvr;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTransactionDate 
*/
TransactionData.prototype.setIccTransactionDate = function(iccTransactionDate) {
  this.iccTransactionDate = iccTransactionDate;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTransactionDate = function() {
  return this.iccTransactionDate;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTsi 
*/
TransactionData.prototype.setIccTsi = function(iccTsi) {
  this.iccTsi = iccTsi;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTsi = function() {
  return this.iccTsi;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTransactionType 
*/
TransactionData.prototype.setIccTransactionType = function(iccTransactionType) {
  this.iccTransactionType = iccTransactionType;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTransactionType = function() {
  return this.iccTransactionType;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccUnpredictableNumber 
*/
TransactionData.prototype.setIccUnpredictableNumber = function(iccUnpredictableNumber) {
  this.iccUnpredictableNumber = iccUnpredictableNumber;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccUnpredictableNumber = function() {
  return this.iccUnpredictableNumber;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTransactionTime 
*/
TransactionData.prototype.setIccTransactionTime = function(iccTransactionTime) {
  this.iccTransactionTime = iccTransactionTime;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTransactionTime = function() {
  return this.iccTransactionTime;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccAdditionalTerminalCapabilities 
*/
TransactionData.prototype.setIccAdditionalTerminalCapabilities = function(iccAdditionalTerminalCapabilities) {
  this.iccAdditionalTerminalCapabilities = iccAdditionalTerminalCapabilities;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccAdditionalTerminalCapabilities = function() {
  return this.iccAdditionalTerminalCapabilities;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccTransactionCategoryCode 
*/
TransactionData.prototype.setIccTransactionCategoryCode = function(iccTransactionCategoryCode) {
  this.iccTransactionCategoryCode = iccTransactionCategoryCode;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccTransactionCategoryCode = function() {
  return this.iccTransactionCategoryCode;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccIssuerApplicationPreferredName 
*/
TransactionData.prototype.setIccIssuerApplicationPreferredName = function(iccIssuerApplicationPreferredName) {
  this.iccIssuerApplicationPreferredName = iccIssuerApplicationPreferredName;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccIssuerApplicationPreferredName = function() {
  return this.iccIssuerApplicationPreferredName;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccCardholderName 
*/
TransactionData.prototype.setIccCardholderName = function(iccCardholderName) {
  this.iccCardholderName = iccCardholderName;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccCardholderName = function() {
  return this.iccCardholderName;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccIssuerCodeTableIndex 
*/
TransactionData.prototype.setIccIssuerCodeTableIndex = function(iccIssuerCodeTableIndex) {
  this.iccIssuerCodeTableIndex = iccIssuerCodeTableIndex;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccIssuerCodeTableIndex = function() {
  return this.iccIssuerCodeTableIndex;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccIssuerScripts 
*/
TransactionData.prototype.setIccIssuerScripts = function(iccIssuerScripts) {
  this.iccIssuerScripts = iccIssuerScripts;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccIssuerScripts = function() {
  return this.iccIssuerScripts;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} iccIssuerScriptResults 
*/
TransactionData.prototype.setIccIssuerScriptResults = function(iccIssuerScriptResults) {
  this.iccIssuerScriptResults = iccIssuerScriptResults;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getIccIssuerScriptResults = function() {
  return this.iccIssuerScriptResults;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} debugTrack2EquivalentData 
*/
TransactionData.prototype.setDebugTrack2EquivalentData = function(debugTrack2EquivalentData) {
  this.debugTrack2EquivalentData = debugTrack2EquivalentData;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getDebugTrack2EquivalentData = function() {
  return this.debugTrack2EquivalentData;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} debugApplicationPan 
*/
TransactionData.prototype.setDebugApplicationPan = function(debugApplicationPan) {
  this.debugApplicationPan = debugApplicationPan;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getDebugApplicationPan = function() {
  return this.debugApplicationPan;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} debugAmountAuthorizedBinary 
*/
TransactionData.prototype.setDebugAmountAuthorizedBinary = function(debugAmountAuthorizedBinary) {
  this.debugAmountAuthorizedBinary = debugAmountAuthorizedBinary;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getDebugAmountAuthorizedBinary = function() {
  return this.debugAmountAuthorizedBinary;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} debugAmountOtherBinary 
*/
TransactionData.prototype.setDebugAmountOtherBinary = function(debugAmountOtherBinary) {
  this.debugAmountOtherBinary = debugAmountOtherBinary;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getDebugAmountOtherBinary = function() {
  return this.debugAmountOtherBinary;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} debugTransactionStatusInformation 
*/
TransactionData.prototype.setDebugTransactionStatusInformation = function(debugTransactionStatusInformation) {
  this.debugTransactionStatusInformation = debugTransactionStatusInformation;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getDebugTransactionStatusInformation = function() {
  return this.debugTransactionStatusInformation;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} debugPlainTrack1 
*/
TransactionData.prototype.setDebugPlainTrack1 = function(debugPlainTrack1) {
  this.debugPlainTrack1 = debugPlainTrack1;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getDebugPlainTrack1 = function() {
  return this.debugPlainTrack1;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} debugPlainTrack2 
*/
TransactionData.prototype.setDebugPlainTrack2 = function(debugPlainTrack2) {
  this.debugPlainTrack2 = debugPlainTrack2;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getDebugPlainTrack2 = function() {
  return this.debugPlainTrack2;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} schemePunATC 
*/
TransactionData.prototype.setSchemePunATC = function(schemePunATC) {
  this.schemePunATC = schemePunATC;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getSchemePunATC = function() {
  return this.schemePunATC;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} schemeThirdPartyData 
*/
TransactionData.prototype.setSchemeThirdPartyData = function(schemeThirdPartyData) {
  this.schemeThirdPartyData = schemeThirdPartyData;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getSchemeThirdPartyData = function() {
  return this.schemeThirdPartyData;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} schemeMerchantCustomData 
*/
TransactionData.prototype.setSchemeMerchantCustomData = function(schemeMerchantCustomData) {
  this.schemeMerchantCustomData = schemeMerchantCustomData;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getSchemeMerchantCustomData = function() {
  return this.schemeMerchantCustomData;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} schemeTerminalEntryCapability 
*/
TransactionData.prototype.setSchemeTerminalEntryCapability = function(schemeTerminalEntryCapability) {
  this.schemeTerminalEntryCapability = schemeTerminalEntryCapability;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getSchemeTerminalEntryCapability = function() {
  return this.schemeTerminalEntryCapability;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} pinBlockContainer 
*/
TransactionData.prototype.setPinBlockContainer = function(pinBlockContainer) {
  this.pinBlockContainer = pinBlockContainer;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getPinBlockContainer = function() {
  return this.pinBlockContainer;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} pinBlock 
*/
TransactionData.prototype.setPinBlock = function(pinBlock) {
  this.pinBlock = pinBlock;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getPinBlock = function() {
  return this.pinBlock;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} pinBlockKsn 
*/
TransactionData.prototype.setPinBlockKsn = function(pinBlockKsn) {
  this.pinBlockKsn = pinBlockKsn;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getPinBlockKsn = function() {
  return this.pinBlockKsn;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} mac 
*/
TransactionData.prototype.setMac = function(mac) {
  this.mac = mac;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getMac = function() {
  return this.mac;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} macKsn 
*/
TransactionData.prototype.setMacKsn = function(macKsn) {
  this.macKsn = macKsn;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getMacKsn = function() {
  return this.macKsn;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} gwContainer 
*/
TransactionData.prototype.setGwContainer = function(gwContainer) {
  this.gwContainer = gwContainer;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getGwContainer = function() {
  return this.gwContainer;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} gwIssuerAuthenticationData 
*/
TransactionData.prototype.setGwIssuerAuthenticationData = function(gwIssuerAuthenticationData) {
  this.gwIssuerAuthenticationData = gwIssuerAuthenticationData;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getGwIssuerAuthenticationData = function() {
  return this.gwIssuerAuthenticationData;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} gwIssuerScriptTemplate1 
*/
TransactionData.prototype.setGwIssuerScriptTemplate1 = function(gwIssuerScriptTemplate1) {
  this.gwIssuerScriptTemplate1 = gwIssuerScriptTemplate1;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getGwIssuerScriptTemplate1 = function() {
  return this.gwIssuerScriptTemplate1;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} gwIssuerScriptTemplate2 
*/
TransactionData.prototype.setGwIssuerScriptTemplate2 = function(gwIssuerScriptTemplate2) {
  this.gwIssuerScriptTemplate2 = gwIssuerScriptTemplate2;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getGwIssuerScriptTemplate2 = function() {
  return this.gwIssuerScriptTemplate2;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} gwIssuerAuthorizationResponseCode 
*/
TransactionData.prototype.setGwIssuerAuthorizationResponseCode = function(gwIssuerAuthorizationResponseCode) {
  this.gwIssuerAuthorizationResponseCode = gwIssuerAuthorizationResponseCode;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getGwIssuerAuthorizationResponseCode = function() {
  return this.gwIssuerAuthorizationResponseCode;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} gwMessageControlField 
*/
TransactionData.prototype.setGwMessageControlField = function(gwMessageControlField) {
  this.gwMessageControlField = gwMessageControlField;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getGwMessageControlField = function() {
  return this.gwMessageControlField;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {pay.GwTxResult} gwTxResult 
*/
TransactionData.prototype.setGwTxResult = function(gwTxResult) {
  this.gwTxResult = gwTxResult;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {pay.GwTxResult} 
*/
TransactionData.prototype.getGwTxResult = function() {
  return this.gwTxResult;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {payments.CvmResult} cvmResult 
*/
TransactionData.prototype.setCvmResult = function(cvmResult) {
  this.cvmResult = cvmResult;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {payments.CvmResult} 
*/
TransactionData.prototype.getCvmResult = function() {
  return this.cvmResult;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {pay.ServiceCode1} serviceCode1 
*/
TransactionData.prototype.setServiceCode1 = function(serviceCode1) {
  this.serviceCode1 = serviceCode1;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {pay.ServiceCode1} 
*/
TransactionData.prototype.getServiceCode1 = function() {
  return this.serviceCode1;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {pay.ServiceCode2} serviceCode2 
*/
TransactionData.prototype.setServiceCode2 = function(serviceCode2) {
  this.serviceCode2 = serviceCode2;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {pay.ServiceCode2} 
*/
TransactionData.prototype.getServiceCode2 = function() {
  return this.serviceCode2;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {pay.ServiceCode3} serviceCode3 
*/
TransactionData.prototype.setServiceCode3 = function(serviceCode3) {
  this.serviceCode3 = serviceCode3;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {pay.ServiceCode3} 
*/
TransactionData.prototype.getServiceCode3 = function() {
  return this.serviceCode3;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {String} offlineApprovalAuthCode 
*/
TransactionData.prototype.setOfflineApprovalAuthCode = function(offlineApprovalAuthCode) {
  this.offlineApprovalAuthCode = offlineApprovalAuthCode;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {String} 
*/
TransactionData.prototype.getOfflineApprovalAuthCode = function() {
  return this.offlineApprovalAuthCode;
};

/**
* Set the field value
* @memberof pay.TransactionData
* @param {Number} availableOfflineSpendingAmount must be a double
*/
TransactionData.prototype.setAvailableOfflineSpendingAmount = function(availableOfflineSpendingAmount) {
  this.availableOfflineSpendingAmount = availableOfflineSpendingAmount;
};

/**
* Get the field value
* @memberof pay.TransactionData
* @return {Number} must be a double
*/
TransactionData.prototype.getAvailableOfflineSpendingAmount = function() {
  return this.availableOfflineSpendingAmount;
};

/**
* @memberof pay.TransactionData
* @private
*/
TransactionData.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

TransactionData.prototype.toString = function() {
  return JSON.stringify(this);
};

TransactionData._meta_ =  {fields:  {}};
TransactionData._meta_._class_ =  TransactionData;
TransactionData._meta_.fields["txResult"] = {};
TransactionData._meta_.fields["txResult"].type = pay_TxResult;
TransactionData._meta_.fields["txError"] = {};
TransactionData._meta_.fields["txError"].type = pay_ErrorCode;
TransactionData._meta_.fields["amount"] = {};
TransactionData._meta_.fields["amount"].type = Number;
TransactionData._meta_.fields["tipAmount"] = {};
TransactionData._meta_.fields["tipAmount"].type = Number;
TransactionData._meta_.fields["cashBackAmount"] = {};
TransactionData._meta_.fields["cashBackAmount"].type = Number;
TransactionData._meta_.fields["errorCode"] = {};
TransactionData._meta_.fields["errorCode"].type = String;
TransactionData._meta_.fields["errorText"] = {};
TransactionData._meta_.fields["errorText"].type = String;
TransactionData._meta_.fields["transactionDate"] = {};
TransactionData._meta_.fields["transactionDate"].type = String;
TransactionData._meta_.fields["transactionTime"] = {};
TransactionData._meta_.fields["transactionTime"].type = String;
TransactionData._meta_.fields["msrContainer"] = {};
TransactionData._meta_.fields["msrContainer"].type = String;
TransactionData._meta_.fields["msrMaskedTrack1"] = {};
TransactionData._meta_.fields["msrMaskedTrack1"].type = String;
TransactionData._meta_.fields["msrMaskedTrack2"] = {};
TransactionData._meta_.fields["msrMaskedTrack2"].type = String;
TransactionData._meta_.fields["maskedManualPan"] = {};
TransactionData._meta_.fields["maskedManualPan"].type = String;
TransactionData._meta_.fields["sredContainer"] = {};
TransactionData._meta_.fields["sredContainer"].type = String;
TransactionData._meta_.fields["sred"] = {};
TransactionData._meta_.fields["sred"].type = String;
TransactionData._meta_.fields["ksn"] = {};
TransactionData._meta_.fields["ksn"].type = String;
TransactionData._meta_.fields["transArmorContainer"] = {};
TransactionData._meta_.fields["transArmorContainer"].type = String;
TransactionData._meta_.fields["transArmorKey"] = {};
TransactionData._meta_.fields["transArmorKey"].type = String;
TransactionData._meta_.fields["transArmorKeyId"] = {};
TransactionData._meta_.fields["transArmorKeyId"].type = String;
TransactionData._meta_.fields["transArmorEncryptedTrack1"] = {};
TransactionData._meta_.fields["transArmorEncryptedTrack1"].type = String;
TransactionData._meta_.fields["transArmorEncryptedTrack2"] = {};
TransactionData._meta_.fields["transArmorEncryptedTrack2"].type = String;
TransactionData._meta_.fields["transArmorEncryptedEmvTag57"] = {};
TransactionData._meta_.fields["transArmorEncryptedEmvTag57"].type = String;
TransactionData._meta_.fields["transArmorEncryptedEmvTag5A"] = {};
TransactionData._meta_.fields["transArmorEncryptedEmvTag5A"].type = String;
TransactionData._meta_.fields["transArmorEncryptedManualKeyedData"] = {};
TransactionData._meta_.fields["transArmorEncryptedManualKeyedData"].type = String;
TransactionData._meta_.fields["iccContainer"] = {};
TransactionData._meta_.fields["iccContainer"].type = String;
TransactionData._meta_.fields["iccApplicationInterchangeProfile"] = {};
TransactionData._meta_.fields["iccApplicationInterchangeProfile"].type = String;
TransactionData._meta_.fields["iccMaskedEmv57"] = {};
TransactionData._meta_.fields["iccMaskedEmv57"].type = String;
TransactionData._meta_.fields["iccMaskedEmv5A"] = {};
TransactionData._meta_.fields["iccMaskedEmv5A"].type = String;
TransactionData._meta_.fields["iccApplicationPanSequenceNumber"] = {};
TransactionData._meta_.fields["iccApplicationPanSequenceNumber"].type = String;
TransactionData._meta_.fields["iccApplicationExpirationDate"] = {};
TransactionData._meta_.fields["iccApplicationExpirationDate"].type = String;
TransactionData._meta_.fields["iccApplicationEffectiveDate"] = {};
TransactionData._meta_.fields["iccApplicationEffectiveDate"].type = String;
TransactionData._meta_.fields["iccAmountAuthorized"] = {};
TransactionData._meta_.fields["iccAmountAuthorized"].type = String;
TransactionData._meta_.fields["iccAmountOther"] = {};
TransactionData._meta_.fields["iccAmountOther"].type = String;
TransactionData._meta_.fields["iccTransactionCurrencyCode"] = {};
TransactionData._meta_.fields["iccTransactionCurrencyCode"].type = String;
TransactionData._meta_.fields["iccTransactionCurrencyExponent"] = {};
TransactionData._meta_.fields["iccTransactionCurrencyExponent"].type = String;
TransactionData._meta_.fields["iccApplicationUsageControl"] = {};
TransactionData._meta_.fields["iccApplicationUsageControl"].type = String;
TransactionData._meta_.fields["iccIssuerActionCodeDefault"] = {};
TransactionData._meta_.fields["iccIssuerActionCodeDefault"].type = String;
TransactionData._meta_.fields["iccIssuerActionCodeDenial"] = {};
TransactionData._meta_.fields["iccIssuerActionCodeDenial"].type = String;
TransactionData._meta_.fields["iccIssuerActionCodeOnline"] = {};
TransactionData._meta_.fields["iccIssuerActionCodeOnline"].type = String;
TransactionData._meta_.fields["iccApplicationLabel"] = {};
TransactionData._meta_.fields["iccApplicationLabel"].type = String;
TransactionData._meta_.fields["iccApplicationCryptogram"] = {};
TransactionData._meta_.fields["iccApplicationCryptogram"].type = String;
TransactionData._meta_.fields["iccApplicationIdentifierCard"] = {};
TransactionData._meta_.fields["iccApplicationIdentifierCard"].type = String;
TransactionData._meta_.fields["iccApplicationIdentifierTerminal"] = {};
TransactionData._meta_.fields["iccApplicationIdentifierTerminal"].type = String;
TransactionData._meta_.fields["iccApplicationTransactionCounter"] = {};
TransactionData._meta_.fields["iccApplicationTransactionCounter"].type = String;
TransactionData._meta_.fields["iccApplicationVersionNumber"] = {};
TransactionData._meta_.fields["iccApplicationVersionNumber"].type = String;
TransactionData._meta_.fields["iccCryptogramInformationData"] = {};
TransactionData._meta_.fields["iccCryptogramInformationData"].type = String;
TransactionData._meta_.fields["iccCvmResults"] = {};
TransactionData._meta_.fields["iccCvmResults"].type = String;
TransactionData._meta_.fields["iccInterfaceDeviceSerialNumber"] = {};
TransactionData._meta_.fields["iccInterfaceDeviceSerialNumber"].type = String;
TransactionData._meta_.fields["iccIssuerApplicationData"] = {};
TransactionData._meta_.fields["iccIssuerApplicationData"].type = String;
TransactionData._meta_.fields["iccPosEntryModeCode"] = {};
TransactionData._meta_.fields["iccPosEntryModeCode"].type = String;
TransactionData._meta_.fields["iccTerminalCapabilities"] = {};
TransactionData._meta_.fields["iccTerminalCapabilities"].type = String;
TransactionData._meta_.fields["iccTerminalCountryCode"] = {};
TransactionData._meta_.fields["iccTerminalCountryCode"].type = String;
TransactionData._meta_.fields["iccTerminalType"] = {};
TransactionData._meta_.fields["iccTerminalType"].type = String;
TransactionData._meta_.fields["iccTvr"] = {};
TransactionData._meta_.fields["iccTvr"].type = String;
TransactionData._meta_.fields["iccTransactionDate"] = {};
TransactionData._meta_.fields["iccTransactionDate"].type = String;
TransactionData._meta_.fields["iccTsi"] = {};
TransactionData._meta_.fields["iccTsi"].type = String;
TransactionData._meta_.fields["iccTransactionType"] = {};
TransactionData._meta_.fields["iccTransactionType"].type = String;
TransactionData._meta_.fields["iccUnpredictableNumber"] = {};
TransactionData._meta_.fields["iccUnpredictableNumber"].type = String;
TransactionData._meta_.fields["iccTransactionTime"] = {};
TransactionData._meta_.fields["iccTransactionTime"].type = String;
TransactionData._meta_.fields["iccAdditionalTerminalCapabilities"] = {};
TransactionData._meta_.fields["iccAdditionalTerminalCapabilities"].type = String;
TransactionData._meta_.fields["iccTransactionCategoryCode"] = {};
TransactionData._meta_.fields["iccTransactionCategoryCode"].type = String;
TransactionData._meta_.fields["iccIssuerApplicationPreferredName"] = {};
TransactionData._meta_.fields["iccIssuerApplicationPreferredName"].type = String;
TransactionData._meta_.fields["iccCardholderName"] = {};
TransactionData._meta_.fields["iccCardholderName"].type = String;
TransactionData._meta_.fields["iccIssuerCodeTableIndex"] = {};
TransactionData._meta_.fields["iccIssuerCodeTableIndex"].type = String;
TransactionData._meta_.fields["iccIssuerScripts"] = {};
TransactionData._meta_.fields["iccIssuerScripts"].type = String;
TransactionData._meta_.fields["iccIssuerScriptResults"] = {};
TransactionData._meta_.fields["iccIssuerScriptResults"].type = String;
TransactionData._meta_.fields["debugTrack2EquivalentData"] = {};
TransactionData._meta_.fields["debugTrack2EquivalentData"].type = String;
TransactionData._meta_.fields["debugApplicationPan"] = {};
TransactionData._meta_.fields["debugApplicationPan"].type = String;
TransactionData._meta_.fields["debugAmountAuthorizedBinary"] = {};
TransactionData._meta_.fields["debugAmountAuthorizedBinary"].type = String;
TransactionData._meta_.fields["debugAmountOtherBinary"] = {};
TransactionData._meta_.fields["debugAmountOtherBinary"].type = String;
TransactionData._meta_.fields["debugTransactionStatusInformation"] = {};
TransactionData._meta_.fields["debugTransactionStatusInformation"].type = String;
TransactionData._meta_.fields["debugPlainTrack1"] = {};
TransactionData._meta_.fields["debugPlainTrack1"].type = String;
TransactionData._meta_.fields["debugPlainTrack2"] = {};
TransactionData._meta_.fields["debugPlainTrack2"].type = String;
TransactionData._meta_.fields["schemePunATC"] = {};
TransactionData._meta_.fields["schemePunATC"].type = String;
TransactionData._meta_.fields["schemeThirdPartyData"] = {};
TransactionData._meta_.fields["schemeThirdPartyData"].type = String;
TransactionData._meta_.fields["schemeMerchantCustomData"] = {};
TransactionData._meta_.fields["schemeMerchantCustomData"].type = String;
TransactionData._meta_.fields["schemeTerminalEntryCapability"] = {};
TransactionData._meta_.fields["schemeTerminalEntryCapability"].type = String;
TransactionData._meta_.fields["pinBlockContainer"] = {};
TransactionData._meta_.fields["pinBlockContainer"].type = String;
TransactionData._meta_.fields["pinBlock"] = {};
TransactionData._meta_.fields["pinBlock"].type = String;
TransactionData._meta_.fields["pinBlockKsn"] = {};
TransactionData._meta_.fields["pinBlockKsn"].type = String;
TransactionData._meta_.fields["mac"] = {};
TransactionData._meta_.fields["mac"].type = String;
TransactionData._meta_.fields["macKsn"] = {};
TransactionData._meta_.fields["macKsn"].type = String;
TransactionData._meta_.fields["gwContainer"] = {};
TransactionData._meta_.fields["gwContainer"].type = String;
TransactionData._meta_.fields["gwIssuerAuthenticationData"] = {};
TransactionData._meta_.fields["gwIssuerAuthenticationData"].type = String;
TransactionData._meta_.fields["gwIssuerScriptTemplate1"] = {};
TransactionData._meta_.fields["gwIssuerScriptTemplate1"].type = String;
TransactionData._meta_.fields["gwIssuerScriptTemplate2"] = {};
TransactionData._meta_.fields["gwIssuerScriptTemplate2"].type = String;
TransactionData._meta_.fields["gwIssuerAuthorizationResponseCode"] = {};
TransactionData._meta_.fields["gwIssuerAuthorizationResponseCode"].type = String;
TransactionData._meta_.fields["gwMessageControlField"] = {};
TransactionData._meta_.fields["gwMessageControlField"].type = String;
TransactionData._meta_.fields["gwTxResult"] = {};
TransactionData._meta_.fields["gwTxResult"].type = pay_GwTxResult;
TransactionData._meta_.fields["cvmResult"] = {};
TransactionData._meta_.fields["cvmResult"].type = payments_CvmResult;
TransactionData._meta_.fields["serviceCode1"] = {};
TransactionData._meta_.fields["serviceCode1"].type = pay_ServiceCode1;
TransactionData._meta_.fields["serviceCode2"] = {};
TransactionData._meta_.fields["serviceCode2"].type = pay_ServiceCode2;
TransactionData._meta_.fields["serviceCode3"] = {};
TransactionData._meta_.fields["serviceCode3"].type = pay_ServiceCode3;
TransactionData._meta_.fields["offlineApprovalAuthCode"] = {};
TransactionData._meta_.fields["offlineApprovalAuthCode"].type = String;
TransactionData._meta_.fields["availableOfflineSpendingAmount"] = {};
TransactionData._meta_.fields["availableOfflineSpendingAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TransactionData;
}

