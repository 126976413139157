/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var ExternalDeviceSubState = {
  CUSTOM_ACTIVITY : "CUSTOM_ACTIVITY",
  STARTING_PAYMENT_FLOW : "STARTING_PAYMENT_FLOW",
  PROCESSING_PAYMENT : "PROCESSING_PAYMENT",
  PROCESSING_CARD_DATA : "PROCESSING_CARD_DATA",
  PROCESSING_CREDIT : "PROCESSING_CREDIT",
  VERIFY_SIGNATURE : "VERIFY_SIGNATURE",
  TIP_SCREEN : "TIP_SCREEN",
  RECEIPT_SCREEN : "RECEIPT_SCREEN",
  CONFIRM_PAYMENT : "CONFIRM_PAYMENT",
  UNKNOWN : "UNKNOWN"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ExternalDeviceSubState;
}

