/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var FraudResponseCodeType = {
  AVS : "AVS",
  CVV : "CVV"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = FraudResponseCodeType;
}

