/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var RetrieveCustomActivitiesRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = RetrieveCustomActivitiesRequestMessage;
  this.setMethod(remotemessage_Method["RETRIEVE_CUSTOM_ACTIVITIES_REQUEST"]);
  this.filters = undefined;
};

RetrieveCustomActivitiesRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
RetrieveCustomActivitiesRequestMessage.prototype.constructor = RetrieveCustomActivitiesRequestMessage;

/**
* Set the field value
* List of category names to filter the results on.
*
* @memberof remotemessage.RetrieveCustomActivitiesRequestMessage
* @param {Array.<String>} filters An array of 
*/
RetrieveCustomActivitiesRequestMessage.prototype.setFilters = function(filters) {
  this.filters = filters;
};

/**
* Get the field value
* List of category names to filter the results on.
* @memberof remotemessage.RetrieveCustomActivitiesRequestMessage
* @return {Array.<String>} An array of 
*/
RetrieveCustomActivitiesRequestMessage.prototype.getFilters = function() {
  return this.filters;
};

RetrieveCustomActivitiesRequestMessage._meta_ =  {fields:  {}};
RetrieveCustomActivitiesRequestMessage._meta_._class_ =  RetrieveCustomActivitiesRequestMessage;
RetrieveCustomActivitiesRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
RetrieveCustomActivitiesRequestMessage._meta_.fields["filters"] = {};
RetrieveCustomActivitiesRequestMessage._meta_.fields["filters"].type = Array;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RetrieveCustomActivitiesRequestMessage;
}

