/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof remotemessage
*/
var RemoveDiscountAction = function() {
  this._class_ = RemoveDiscountAction;
  this.discountId = undefined;
  this.lineItemId = undefined;
};


/**
* Set the field value
* @memberof remotemessage.RemoveDiscountAction
* @param {String} discountId 
*/
RemoveDiscountAction.prototype.setDiscountId = function(discountId) {
  this.discountId = discountId;
};

/**
* Get the field value
* @memberof remotemessage.RemoveDiscountAction
* @return {String} 
*/
RemoveDiscountAction.prototype.getDiscountId = function() {
  return this.discountId;
};

/**
* Set the field value
* @memberof remotemessage.RemoveDiscountAction
* @param {String} lineItemId 
*/
RemoveDiscountAction.prototype.setLineItemId = function(lineItemId) {
  this.lineItemId = lineItemId;
};

/**
* Get the field value
* @memberof remotemessage.RemoveDiscountAction
* @return {String} 
*/
RemoveDiscountAction.prototype.getLineItemId = function() {
  return this.lineItemId;
};

/**
* @memberof remotemessage.RemoveDiscountAction
* @private
*/
RemoveDiscountAction.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

RemoveDiscountAction.prototype.toString = function() {
  return JSON.stringify(this);
};

RemoveDiscountAction._meta_ =  {fields:  {}};
RemoveDiscountAction._meta_._class_ =  RemoveDiscountAction;
RemoveDiscountAction._meta_.fields["discountId"] = {};
RemoveDiscountAction._meta_.fields["discountId"].type = String;
RemoveDiscountAction._meta_.fields["lineItemId"] = {};
RemoveDiscountAction._meta_.fields["lineItemId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RemoveDiscountAction;
}

