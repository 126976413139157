/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_ExternalDeviceState = require("../remotepay/ExternalDeviceState");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var ResetDeviceResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = ResetDeviceResponse;
  this.state = undefined;
};

ResetDeviceResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
ResetDeviceResponse.prototype.constructor = ResetDeviceResponse;

/**
* Set the field value
* High level state of the connected device.
*
* @memberof remotepay.ResetDeviceResponse
* @param {remotepay.ExternalDeviceState} state 
*/
ResetDeviceResponse.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* High level state of the connected device.
* @memberof remotepay.ResetDeviceResponse
* @return {remotepay.ExternalDeviceState} 
*/
ResetDeviceResponse.prototype.getState = function() {
  return this.state;
};

ResetDeviceResponse._meta_ =  {fields:  {}};
ResetDeviceResponse._meta_._class_ =  ResetDeviceResponse;
ResetDeviceResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
ResetDeviceResponse._meta_.fields["state"] = {};
ResetDeviceResponse._meta_.fields["state"].type = remotepay_ExternalDeviceState;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ResetDeviceResponse;
}

