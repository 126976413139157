/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var inventory_ModifierGroup = require("../inventory/ModifierGroup");

/**
* @constructor
* @memberof inventory
*/
var ModifierGroupArray = function() {
  this._class_ = ModifierGroupArray;
  this.modifierGroups = undefined;
};


/**
* Set the field value
* @memberof inventory.ModifierGroupArray
* @param {Array.<inventory.ModifierGroup>} modifierGroups An array of 
*/
ModifierGroupArray.prototype.setModifierGroups = function(modifierGroups) {
  this.modifierGroups = modifierGroups;
};

/**
* Get the field value
* @memberof inventory.ModifierGroupArray
* @return {Array.<inventory.ModifierGroup>} An array of 
*/
ModifierGroupArray.prototype.getModifierGroups = function() {
  return this.modifierGroups;
};

/**
* @memberof inventory.ModifierGroupArray
* @private
*/
ModifierGroupArray.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

ModifierGroupArray.prototype.toString = function() {
  return JSON.stringify(this);
};

ModifierGroupArray._meta_ =  {fields:  {}};
ModifierGroupArray._meta_._class_ =  ModifierGroupArray;
ModifierGroupArray._meta_.fields["modifierGroups"] = {};
ModifierGroupArray._meta_.fields["modifierGroups"].type = Array;
ModifierGroupArray._meta_.fields["modifierGroups"].elementType = inventory_ModifierGroup;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ModifierGroupArray;
}

