/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_DeviceErrorEventCode = require("../remotepay/DeviceErrorEventCode");
var remotepay_DeviceEventState = require("../remotepay/DeviceEventState");

/**
* @constructor
* @memberof remotepay
*/
var DeviceEventEnum = function() {
  this._class_ = DeviceEventEnum;
  this.deviceErrorEventCode = undefined;
  this.deviceEventState = undefined;
};


/**
* Set the field value
* @memberof remotepay.DeviceEventEnum
* @param {remotepay.DeviceErrorEventCode} deviceErrorEventCode 
*/
DeviceEventEnum.prototype.setDeviceErrorEventCode = function(deviceErrorEventCode) {
  this.deviceErrorEventCode = deviceErrorEventCode;
};

/**
* Get the field value
* @memberof remotepay.DeviceEventEnum
* @return {remotepay.DeviceErrorEventCode} 
*/
DeviceEventEnum.prototype.getDeviceErrorEventCode = function() {
  return this.deviceErrorEventCode;
};

/**
* Set the field value
* @memberof remotepay.DeviceEventEnum
* @param {remotepay.DeviceEventState} deviceEventState 
*/
DeviceEventEnum.prototype.setDeviceEventState = function(deviceEventState) {
  this.deviceEventState = deviceEventState;
};

/**
* Get the field value
* @memberof remotepay.DeviceEventEnum
* @return {remotepay.DeviceEventState} 
*/
DeviceEventEnum.prototype.getDeviceEventState = function() {
  return this.deviceEventState;
};

/**
* @memberof remotepay.DeviceEventEnum
* @private
*/
DeviceEventEnum.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DeviceEventEnum.prototype.toString = function() {
  return JSON.stringify(this);
};

DeviceEventEnum._meta_ =  {fields:  {}};
DeviceEventEnum._meta_._class_ =  DeviceEventEnum;
DeviceEventEnum._meta_.fields["deviceErrorEventCode"] = {};
DeviceEventEnum._meta_.fields["deviceErrorEventCode"].type = remotepay_DeviceErrorEventCode;
DeviceEventEnum._meta_.fields["deviceEventState"] = {};
DeviceEventEnum._meta_.fields["deviceEventState"].type = remotepay_DeviceEventState;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeviceEventEnum;
}

