/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_VasPayloadResponseType = require("../payments/VasPayloadResponseType");

/** Response from the provider after receiving the payload */
/**
* @constructor
* @memberof payments
*/
var VasPayloadResponse = function() {
  this._class_ = VasPayloadResponse;
  this.responseType = undefined;
};


/**
* Set the field value
* @memberof payments.VasPayloadResponse
* @param {payments.VasPayloadResponseType} responseType 
*/
VasPayloadResponse.prototype.setResponseType = function(responseType) {
  this.responseType = responseType;
};

/**
* Get the field value
* @memberof payments.VasPayloadResponse
* @return {payments.VasPayloadResponseType} 
*/
VasPayloadResponse.prototype.getResponseType = function() {
  return this.responseType;
};

/**
* @memberof payments.VasPayloadResponse
* @private
*/
VasPayloadResponse.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

VasPayloadResponse.prototype.toString = function() {
  return JSON.stringify(this);
};

VasPayloadResponse._meta_ =  {fields:  {}};
VasPayloadResponse._meta_._class_ =  VasPayloadResponse;
VasPayloadResponse._meta_.fields["responseType"] = {};
VasPayloadResponse._meta_.fields["responseType"].type = payments_VasPayloadResponseType;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasPayloadResponse;
}

