/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var VaultCardMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = VaultCardMessage;
  this.setMethod(remotemessage_Method["VAULT_CARD"]);
  this.cardEntryMethods = undefined;
  this.tokenType = undefined;
  this.singleUse = false;
};

VaultCardMessage.prototype = Object.create(remotemessage_Message.prototype);
VaultCardMessage.prototype.constructor = VaultCardMessage;

/**
* Set the field value
* Allowed entry methods
*
* @memberof remotemessage.VaultCardMessage
* @param {Number} cardEntryMethods must be an integer
*/
VaultCardMessage.prototype.setCardEntryMethods = function(cardEntryMethods) {
  this.cardEntryMethods = cardEntryMethods;
};

/**
* Get the field value
* Allowed entry methods
* @memberof remotemessage.VaultCardMessage
* @return {Number} must be an integer
*/
VaultCardMessage.prototype.getCardEntryMethods = function() {
  return this.cardEntryMethods;
};

/**
* Set the field value
* If set, this indicates the token type.  If unset or unrecognized, the default legacy type of tokenization is used
*
* @memberof remotemessage.VaultCardMessage
* @param {String} tokenType 
*/
VaultCardMessage.prototype.setTokenType = function(tokenType) {
  this.tokenType = tokenType;
};

/**
* Get the field value
* If set, this indicates the token type.  If unset or unrecognized, the default legacy type of tokenization is used
* @memberof remotemessage.VaultCardMessage
* @return {String} 
*/
VaultCardMessage.prototype.getTokenType = function() {
  return this.tokenType;
};

/**
* Set the field value
* If true, then request a token that can be used only one time.
*
* @memberof remotemessage.VaultCardMessage
* @param {Boolean} singleUse 
*/
VaultCardMessage.prototype.setSingleUse = function(singleUse) {
  this.singleUse = singleUse;
};

/**
* Get the field value
* If true, then request a token that can be used only one time.
* @memberof remotemessage.VaultCardMessage
* @return {Boolean} 
*/
VaultCardMessage.prototype.getSingleUse = function() {
  return this.singleUse;
};

VaultCardMessage._meta_ =  {fields:  {}};
VaultCardMessage._meta_._class_ =  VaultCardMessage;
VaultCardMessage._meta_._superMeta_ = remotemessage_Message._meta_;
VaultCardMessage._meta_.fields["cardEntryMethods"] = {};
VaultCardMessage._meta_.fields["cardEntryMethods"].type = Number;
VaultCardMessage._meta_.fields["tokenType"] = {};
VaultCardMessage._meta_.fields["tokenType"].type = String;
VaultCardMessage._meta_.fields["singleUse"] = {};
VaultCardMessage._meta_.fields["singleUse"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VaultCardMessage;
}

