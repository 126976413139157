import React from "react";
import { View, Text, TextInput, useWindowDimensions } from "react-native";
import { Card, Button } from "@rneui/themed";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CodeField, Cursor } from "react-native-confirmation-code-field";
import Loading from "./Loading";
import LoginWithEmailModalProps from "../types/components/LoginWithEmailModalProps";
import { styles } from "../styles/components/loginWithEmailModal";

export default ({
  loginEmailStorageKey,
  sendEmail,
  sendValidation,
  onAbort,
  loginEmailColor,
  validationLength = 4,
  localizedTexts
}: LoginWithEmailModalProps): JSX.Element => {
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [verificationCode, setVerificationCode] = React.useState<string>("");
  const [
    emailSentSuccessfully,
    setEmailSentSuccessfully,
  ] = React.useState<boolean>(false);
  const [
    emailResentSuccessfully,
    setEmailResentSuccessfully,
  ] = React.useState<boolean>(false);
  const [
    showInvalidCodeMessage,
    setShowInvalidCodeMessage,
  ] = React.useState<boolean>(false);

  const EMAIL_REGEX = /\S+@\S+\.\S+/;

  // Input email form
  const inputMail = () => {
    return (
      <>
            <Text style={styles.inputEmail__label}>{localizedTexts.headerEmailTitle}</Text>
            <TextInput
              keyboardType="email-address"
              value={email}
              onChangeText={setEmail}
              style={styles.inputEmail__input}
              placeholder={localizedTexts.emailInputPlaceholder}
              placeholderTextColor="#C4C4C4"
            />
            {email !== "" && !EMAIL_REGEX.test(email) && (
              <View>
                <Text style={styles.inputEmail__invalid}>
                  {localizedTexts.invalidEmail}
                </Text>
              </View>
            )}
      </>
    );
  };

  // Input verification code
  const inputVerificationCode = () => {
    return (
      <>
        {emailResentSuccessfully && (
          <Text style={styles.verificationCode__success}>
            {localizedTexts.successfullyForwardedEmail}
          </Text>
        )}
        <Text style={styles.verificationCode__title}>{localizedTexts.verificationTitle}</Text>
        <View style={width >= 768 && styles.verificationCode__inputContainer}>
          <View
            style={width >= 768 && styles.verificationCode__inputSubContainer}
          >
            <CodeField
              value={verificationCode}
              onChangeText={setVerificationCode}
              cellCount={validationLength}
              rootStyle={styles.verificationCode__input}
              keyboardType="number-pad"
              textContentType="oneTimeCode"
              renderCell={({ index, symbol, isFocused }) => (
                <Text
                  key={index}
                  style={[
                    styles.verificationCode__cell,
                    isFocused && styles.verificationCode__focusCell,
                  ]}
                >
                  {symbol || (isFocused ? <Cursor /> : null)}
                </Text>
              )}
            />
          </View>
        </View>
        {showInvalidCodeMessage && (
          <Text style={styles.verificationCode__invalidInput}>
            {localizedTexts.wrongCode}
          </Text>
        )}
      </>
    );
  };

  const getLoginStartedEmail = async (): Promise<void> => {
    try {
      const email = await AsyncStorage.getItem(loginEmailStorageKey);
      if (email) {
        setEmail(email);
        setEmailSentSuccessfully(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const storeLoginStartedEmail = async (email: string): Promise<void> => {
    try {
      await AsyncStorage.setItem(loginEmailStorageKey, email);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLoginStartedEmail = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(loginEmailStorageKey);
    } catch (error) {
      console.log(error);
    }
  };

  const onSendEmail = async (resent?: boolean) => {
    setIsLoading(true);
    const result = await sendEmail(email);
    if (result) {
      await storeLoginStartedEmail(email);
      setEmailSentSuccessfully(true);
      if (resent) setEmailResentSuccessfully(true);
    }
    setIsLoading(false);
  };

  const onSendValidation = async () => {
    const result = await sendValidation(email, verificationCode);
    if (result) {
      deleteLoginStartedEmail();
    } else {
      setVerificationCode("");
      setShowInvalidCodeMessage(true);
    }
  };

  const isButtonDisabled = (): boolean => {
    if (emailSentSuccessfully) return verificationCode.length < 4;
    return !email || !EMAIL_REGEX.test(email);
  };

  React.useEffect(() => {
    getLoginStartedEmail();
  }, []);

  return (
    <>
        <Card.Title style={styles.legendText}>{localizedTexts.headerEmailTitle}</Card.Title>
        <Card.Divider />
        {isLoading && <Loading />}
        <View style={styles.inputEmail__container}>
          {emailSentSuccessfully ? inputVerificationCode() : inputMail()}
        </View>
        <Button
          title={localizedTexts.confirmCode + (emailSentSuccessfully ? localizedTexts.code : localizedTexts.email)}
          type="clear"
          buttonStyle={[styles.btn, { backgroundColor: loginEmailColor }]}
          titleStyle={[styles.btnText]}
          disabled={isButtonDisabled()}
          disabledStyle={styles.disabledBtn}
          disabledTitleStyle={styles.btnText}
          onPress={async () => {
            setIsLoading(true);
            emailSentSuccessfully
              ? await onSendValidation()
              : await onSendEmail();
            setIsLoading(false);
          }}
        />
        <View>
          <Button
            title={localizedTexts.continueAsGuest}
            titleStyle={{ color: loginEmailColor }}
            containerStyle={styles.loginLaterBtn}
            type="clear"
            onPress={() => onAbort()}
          />
        </View>
        {emailSentSuccessfully && (
          <View style={styles.btnContainer}>
            <Button
              title={localizedTexts.reSendCode}
              titleStyle={[styles.textUnderline, { color: loginEmailColor }]}
              type="clear"
              disabled={isLoading}
              onPress={async () => {
                await onSendEmail(true);
              }}
            />
            <Button
              title={localizedTexts.changeEmail}
              titleStyle={[styles.textUnderline, { color: loginEmailColor }]}
              type="clear"
              disabled={isLoading}
              onPress={async () => {
                setEmail("");
                setEmailSentSuccessfully(false);
                setEmailResentSuccessfully(false);
                await deleteLoginStartedEmail();
              }}
            />
          </View>
        )}
    </>
  );
};
