/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/** Request to update the ROM metadata for a device accessory. */
/**
* @constructor
* @memberof device
*/
var AccessoryRomMetadataUpdateRequest = function() {
  this._class_ = AccessoryRomMetadataUpdateRequest;
  this.cpuId = undefined;
  this.version = undefined;
  this.buildType = undefined;
};


/**
* Set the field value
* @memberof device.AccessoryRomMetadataUpdateRequest
* @param {String} cpuId 
*/
AccessoryRomMetadataUpdateRequest.prototype.setCpuId = function(cpuId) {
  this.cpuId = cpuId;
};

/**
* Get the field value
* @memberof device.AccessoryRomMetadataUpdateRequest
* @return {String} 
*/
AccessoryRomMetadataUpdateRequest.prototype.getCpuId = function() {
  return this.cpuId;
};

/**
* Set the field value
* @memberof device.AccessoryRomMetadataUpdateRequest
* @param {Number} version must be an integer
*/
AccessoryRomMetadataUpdateRequest.prototype.setVersion = function(version) {
  this.version = version;
};

/**
* Get the field value
* @memberof device.AccessoryRomMetadataUpdateRequest
* @return {Number} must be an integer
*/
AccessoryRomMetadataUpdateRequest.prototype.getVersion = function() {
  return this.version;
};

/**
* Set the field value
* @memberof device.AccessoryRomMetadataUpdateRequest
* @param {String} buildType 
*/
AccessoryRomMetadataUpdateRequest.prototype.setBuildType = function(buildType) {
  this.buildType = buildType;
};

/**
* Get the field value
* @memberof device.AccessoryRomMetadataUpdateRequest
* @return {String} 
*/
AccessoryRomMetadataUpdateRequest.prototype.getBuildType = function() {
  return this.buildType;
};

/**
* @memberof device.AccessoryRomMetadataUpdateRequest
* @private
*/
AccessoryRomMetadataUpdateRequest.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

AccessoryRomMetadataUpdateRequest.prototype.toString = function() {
  return JSON.stringify(this);
};

AccessoryRomMetadataUpdateRequest._meta_ =  {fields:  {}};
AccessoryRomMetadataUpdateRequest._meta_._class_ =  AccessoryRomMetadataUpdateRequest;
AccessoryRomMetadataUpdateRequest._meta_.fields["cpuId"] = {};
AccessoryRomMetadataUpdateRequest._meta_.fields["cpuId"].type = String;
AccessoryRomMetadataUpdateRequest._meta_.fields["version"] = {};
AccessoryRomMetadataUpdateRequest._meta_.fields["version"].type = Number;
AccessoryRomMetadataUpdateRequest._meta_.fields["buildType"] = {};
AccessoryRomMetadataUpdateRequest._meta_.fields["buildType"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AccessoryRomMetadataUpdateRequest;
}

