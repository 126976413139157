/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var TipAddedMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = TipAddedMessage;
  this.setMethod(remotemessage_Method["TIP_ADDED"]);
  this.tipAmount = undefined;
};

TipAddedMessage.prototype = Object.create(remotemessage_Message.prototype);
TipAddedMessage.prototype.constructor = TipAddedMessage;

/**
* Set the field value
* Amount paid in tips
*
* @memberof remotemessage.TipAddedMessage
* @param {Number} tipAmount must be a long integer
*/
TipAddedMessage.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Amount paid in tips
* @memberof remotemessage.TipAddedMessage
* @return {Number} must be a long integer
*/
TipAddedMessage.prototype.getTipAmount = function() {
  return this.tipAmount;
};

TipAddedMessage._meta_ =  {fields:  {}};
TipAddedMessage._meta_._class_ =  TipAddedMessage;
TipAddedMessage._meta_._superMeta_ = remotemessage_Message._meta_;
TipAddedMessage._meta_.fields["tipAmount"] = {};
TipAddedMessage._meta_.fields["tipAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipAddedMessage;
}

