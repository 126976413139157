import React from "react";
import { Platform, View, TouchableOpacity, Text, Linking, Image } from "react-native";
import { Card } from "@rneui/themed";
import { Entypo } from '@expo/vector-icons';
import * as AppleAuthentication from 'expo-apple-authentication';
import ButtonWithIcon from "./ButtonWithIcon";
import { styles } from "../styles/components/loginModal";
import LoginModalProps from "../types/components/LoginModalProps";

export default ({
  facebookPromptAsync,
  googlePromptAsync,
  onAbort,
  onLoginWithEmail,
  onLoginWithPartnerNumber,
  onLoginWithPhoneNumber,
  onLoginWithAppleDevice,
  partnerLoginButtonColor,
  guestEnabled,
  emailEnabled,
  phoneEnabled,
  facebookEnabled,
  googleEnabled,
  partnerEnabled,
  venueIcon,
  appleLoginEnabled,
  useDifferentIconSet,
  venueMainColor,
  venueSecondaryColor,
  termsAndConditionsLink,
  localizedTexts
}: LoginModalProps): JSX.Element => {

  return (
    <>
      <View style={styles.headerContainer}>
        <Card.Title style={styles.legendText}>{localizedTexts.headerTitle}</Card.Title>
        <TouchableOpacity onPress={() => onAbort()}>
        <Entypo
        name='cross'
        color='#000000'
        style={styles.exitButton}
        size={35}
         />
        </TouchableOpacity>
      </View>
      <View>
        {guestEnabled && (
          <ButtonWithIcon
            icon="user"
            text={localizedTexts.guestButton}
            colorText={venueMainColor ? "#FFF": "#434040"}
            backgroundColor={venueMainColor ?? "#DEE0E5"}
            usingDiferentIcon={useDifferentIconSet}
            onPress={() => onAbort()}
          />
        )}
        {emailEnabled && (
          <ButtonWithIcon
            icon="envelope"
            text={localizedTexts.emailButton}
            colorText={venueMainColor ?? "#FFF"}  
            backgroundColor={venueSecondaryColor ?? "#0f6657"}
            onPress={() => onLoginWithEmail()}
          />
        )}
        {phoneEnabled && (
          <ButtonWithIcon
            icon="phone"
            text={localizedTexts.phoneButton}
            colorText="#FFF"
            backgroundColor="#ff5050"
            onPress={() => onLoginWithPhoneNumber()}
          />
        )}
        {facebookEnabled && (
          <ButtonWithIcon
            icon="facebook"
            text={localizedTexts.facebookButton}
            colorText="#FFF"
            backgroundColor="#1877f2"
            onPress={facebookPromptAsync}
          />
        )}
        {partnerEnabled && (
          <ButtonWithIcon
            text={localizedTexts.partnerButton}
            colorText={!partnerLoginButtonColor ? venueMainColor : "#ffffff"}
            venueIcon={venueIcon}
            backgroundColor={partnerLoginButtonColor ?? "#f1f1f1"}
            onPress={onLoginWithPartnerNumber}
          />
        )}
        {googleEnabled && (
          <ButtonWithIcon
            icon="google"
            text={localizedTexts.googleButton}
            colorText="#434040"
            backgroundColor="#f1f1f1"
            onPress={googlePromptAsync}
            usingDiferentIcon={useDifferentIconSet}
          />
        )}
        {appleLoginEnabled && (
          <AppleAuthentication.AppleAuthenticationButton
           buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
           buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
           cornerRadius={12}
           style={styles.appleButtonContainer}
           onPress={async () => {
             try {
               const credential = await AppleAuthentication.signInAsync({
                 requestedScopes: [
                   AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                   AppleAuthentication.AppleAuthenticationScope.EMAIL,
                 ],
               });
               if (credential) {
                onLoginWithAppleDevice(credential.user, credential.email);
               }
               // signed in
             } catch (e: any) {
               if (e.code === 'ERR_CANCELED') {
                 // handle that the user canceled the sign-in flow
               } else {
                 // handle other errors
               }
             }
           }}
         />
        )}
      </View>
      {termsAndConditionsLink && (
        <View style={styles.termsAndConditions}>
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS === "web") {
                window.open(termsAndConditionsLink, "_blank");
              } else {
                Linking.openURL(termsAndConditionsLink);
              }
            }}
          >
            <Text style={styles.termsAndConditionsText}>
              <Text>
                {localizedTexts.termsAndConditionsTitle}
              </Text>
            </Text>
          </TouchableOpacity>
        </View>
      )}
      <Card.Divider />
    </>
  );
};
