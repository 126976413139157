/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof employees
* @enum {string}
*/
var EmployeePermission = {
  MERCHANT_R : "MERCHANT_R",
  MERCHANT_W : "MERCHANT_W",
  PAYMENTS_R : "PAYMENTS_R",
  PAYMENTS_W : "PAYMENTS_W",
  ORDERS_R : "ORDERS_R",
  ORDERS_W : "ORDERS_W",
  PROCESS_CARDS : "PROCESS_CARDS"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = EmployeePermission;
}

