/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Payment = require("../payments/Payment");
var payments_Refund = require("../payments/Refund");
var payments_Credit = require("../payments/Credit");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var ShowReceiptOptionsMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = ShowReceiptOptionsMessage;
  this.setMethod(remotemessage_Method["SHOW_RECEIPT_OPTIONS"]);
  this.orderId = undefined;
  this.paymentId = undefined;
  this.refundId = undefined;
  this.creditId = undefined;
  this.disableCloverPrinting = undefined;
  this.payment = undefined;
  this.credit = undefined;
  this.refund = undefined;
  this.isReprint = undefined;
};

ShowReceiptOptionsMessage.prototype = Object.create(remotemessage_Message.prototype);
ShowReceiptOptionsMessage.prototype.constructor = ShowReceiptOptionsMessage;

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {String} orderId 
*/
ShowReceiptOptionsMessage.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {String} 
*/
ShowReceiptOptionsMessage.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {String} paymentId 
*/
ShowReceiptOptionsMessage.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {String} 
*/
ShowReceiptOptionsMessage.prototype.getPaymentId = function() {
  return this.paymentId;
};

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {String} refundId 
*/
ShowReceiptOptionsMessage.prototype.setRefundId = function(refundId) {
  this.refundId = refundId;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {String} 
*/
ShowReceiptOptionsMessage.prototype.getRefundId = function() {
  return this.refundId;
};

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {String} creditId 
*/
ShowReceiptOptionsMessage.prototype.setCreditId = function(creditId) {
  this.creditId = creditId;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {String} 
*/
ShowReceiptOptionsMessage.prototype.getCreditId = function() {
  return this.creditId;
};

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {Boolean} disableCloverPrinting 
*/
ShowReceiptOptionsMessage.prototype.setDisableCloverPrinting = function(disableCloverPrinting) {
  this.disableCloverPrinting = disableCloverPrinting;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {Boolean} 
*/
ShowReceiptOptionsMessage.prototype.getDisableCloverPrinting = function() {
  return this.disableCloverPrinting;
};

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {payments.Payment} payment 
*/
ShowReceiptOptionsMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {payments.Payment} 
*/
ShowReceiptOptionsMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {payments.Credit} credit 
*/
ShowReceiptOptionsMessage.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {payments.Credit} 
*/
ShowReceiptOptionsMessage.prototype.getCredit = function() {
  return this.credit;
};

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {payments.Refund} refund 
*/
ShowReceiptOptionsMessage.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {payments.Refund} 
*/
ShowReceiptOptionsMessage.prototype.getRefund = function() {
  return this.refund;
};

/**
* Set the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @param {Boolean} isReprint 
*/
ShowReceiptOptionsMessage.prototype.setIsReprint = function(isReprint) {
  this.isReprint = isReprint;
};

/**
* Get the field value
* @memberof remotemessage.ShowReceiptOptionsMessage
* @return {Boolean} 
*/
ShowReceiptOptionsMessage.prototype.getIsReprint = function() {
  return this.isReprint;
};

ShowReceiptOptionsMessage._meta_ =  {fields:  {}};
ShowReceiptOptionsMessage._meta_._class_ =  ShowReceiptOptionsMessage;
ShowReceiptOptionsMessage._meta_._superMeta_ = remotemessage_Message._meta_;
ShowReceiptOptionsMessage._meta_.fields["orderId"] = {};
ShowReceiptOptionsMessage._meta_.fields["orderId"].type = String;
ShowReceiptOptionsMessage._meta_.fields["paymentId"] = {};
ShowReceiptOptionsMessage._meta_.fields["paymentId"].type = String;
ShowReceiptOptionsMessage._meta_.fields["refundId"] = {};
ShowReceiptOptionsMessage._meta_.fields["refundId"].type = String;
ShowReceiptOptionsMessage._meta_.fields["creditId"] = {};
ShowReceiptOptionsMessage._meta_.fields["creditId"].type = String;
ShowReceiptOptionsMessage._meta_.fields["disableCloverPrinting"] = {};
ShowReceiptOptionsMessage._meta_.fields["disableCloverPrinting"].type = Boolean;
ShowReceiptOptionsMessage._meta_.fields["payment"] = {};
ShowReceiptOptionsMessage._meta_.fields["payment"].type = payments_Payment;
ShowReceiptOptionsMessage._meta_.fields["credit"] = {};
ShowReceiptOptionsMessage._meta_.fields["credit"].type = payments_Credit;
ShowReceiptOptionsMessage._meta_.fields["refund"] = {};
ShowReceiptOptionsMessage._meta_.fields["refund"].type = payments_Refund;
ShowReceiptOptionsMessage._meta_.fields["isReprint"] = {};
ShowReceiptOptionsMessage._meta_.fields["isReprint"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ShowReceiptOptionsMessage;
}

