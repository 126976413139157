/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof pay
* @enum {string}
*/
var GwTxResult = {
  APPROVED_ONLINE : "APPROVED_ONLINE",
  DECLINED_ONLINE : "DECLINED_ONLINE",
  UNABLE_TO_GO_ONLINE : "UNABLE_TO_GO_ONLINE",
  REFERRAL_ONLINE : "REFERRAL_ONLINE",
  REFERRAL_OFFLINE : "REFERRAL_OFFLINE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = GwTxResult;
}

