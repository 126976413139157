/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof loyalty
*/
var LoyaltyDataConfig = function() {
  this._class_ = LoyaltyDataConfig;
  this.type = undefined;
  this.configuration = undefined;
};


/**
* Set the field value
* The string type of the loyalty data of interest.
*
* @memberof loyalty.LoyaltyDataConfig
* @param {String} type 
*/
LoyaltyDataConfig.prototype.setType = function(type) {
  this.type = type;
};

/**
* Get the field value
* The string type of the loyalty data of interest.
* @memberof loyalty.LoyaltyDataConfig
* @return {String} 
*/
LoyaltyDataConfig.prototype.getType = function() {
  return this.type;
};

/**
* Set the field value
* Configuration for the data type.
*
* @memberof loyalty.LoyaltyDataConfig
* @param {Object.<String,String>} configuration A map of <String> to <>
*/
LoyaltyDataConfig.prototype.setConfiguration = function(configuration) {
  this.configuration = configuration;
};

/**
* Get the field value
* Configuration for the data type.
* @memberof loyalty.LoyaltyDataConfig
* @return {Object.<String,String>} A map of <String> to <>
*/
LoyaltyDataConfig.prototype.getConfiguration = function() {
  return this.configuration;
};

/**
* @memberof loyalty.LoyaltyDataConfig
* @private
*/
LoyaltyDataConfig.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

LoyaltyDataConfig.prototype.toString = function() {
  return JSON.stringify(this);
};

LoyaltyDataConfig._meta_ =  {fields:  {}};
LoyaltyDataConfig._meta_._class_ =  LoyaltyDataConfig;
LoyaltyDataConfig._meta_.fields["type"] = {};
LoyaltyDataConfig._meta_.fields["type"].type = String;
LoyaltyDataConfig._meta_.fields["configuration"] = {};
LoyaltyDataConfig._meta_.fields["configuration"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = LoyaltyDataConfig;
}

