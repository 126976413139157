/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");
var payments_Payment = require("../payments/Payment");
var base_Signature = require("../base/Signature");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var VerifySignatureRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = VerifySignatureRequest;
  this.payment = undefined;
  this.signature = undefined;
};

VerifySignatureRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
VerifySignatureRequest.prototype.constructor = VerifySignatureRequest;

/**
* Set the field value
* Payment that the signature is verifying for
*
* @memberof remotepay.VerifySignatureRequest
* @param {payments.Payment} payment 
*/
VerifySignatureRequest.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* Payment that the signature is verifying for
* @memberof remotepay.VerifySignatureRequest
* @return {payments.Payment} 
*/
VerifySignatureRequest.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotepay.VerifySignatureRequest
* @param {base.Signature} signature 
*/
VerifySignatureRequest.prototype.setSignature = function(signature) {
  this.signature = signature;
};

/**
* Get the field value
* @memberof remotepay.VerifySignatureRequest
* @return {base.Signature} 
*/
VerifySignatureRequest.prototype.getSignature = function() {
  return this.signature;
};

VerifySignatureRequest._meta_ =  {fields:  {}};
VerifySignatureRequest._meta_._class_ =  VerifySignatureRequest;
VerifySignatureRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
VerifySignatureRequest._meta_.fields["payment"] = {};
VerifySignatureRequest._meta_.fields["payment"].type = payments_Payment;
VerifySignatureRequest._meta_.fields["signature"] = {};
VerifySignatureRequest._meta_.fields["signature"].type = base_Signature;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VerifySignatureRequest;
}

