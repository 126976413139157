/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof payments
*/
var PaymentTaxRate = function() {
  this._class_ = PaymentTaxRate;
  this.id = undefined;
  this.name = undefined;
  this.rate = undefined;
  this.isDefault = undefined;
  this.taxableAmount = undefined;
  this.isVat = undefined;
  this.taxAmount = undefined;
};


/**
* Set the field value
* Tax rate id.
*
* @memberof payments.PaymentTaxRate
* @param {String} id 
*/
PaymentTaxRate.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Tax rate id.
* @memberof payments.PaymentTaxRate
* @return {String} 
*/
PaymentTaxRate.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Tax rate name. This field is optional.
*
* @memberof payments.PaymentTaxRate
* @param {String} name 
*/
PaymentTaxRate.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Tax rate name. This field is optional.
* @memberof payments.PaymentTaxRate
* @return {String} 
*/
PaymentTaxRate.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* The tax rate. 100% = 1e7.
*
* @memberof payments.PaymentTaxRate
* @param {Number} rate must be a long integer
*/
PaymentTaxRate.prototype.setRate = function(rate) {
  this.rate = rate;
};

/**
* Get the field value
* The tax rate. 100% = 1e7.
* @memberof payments.PaymentTaxRate
* @return {Number} must be a long integer
*/
PaymentTaxRate.prototype.getRate = function() {
  return this.rate;
};

/**
* Set the field value
* Whether the tax rate was configued as a default tax rate at the time that the payment was created.
*
* @memberof payments.PaymentTaxRate
* @param {Boolean} isDefault 
*/
PaymentTaxRate.prototype.setIsDefault = function(isDefault) {
  this.isDefault = isDefault;
};

/**
* Get the field value
* Whether the tax rate was configued as a default tax rate at the time that the payment was created.
* @memberof payments.PaymentTaxRate
* @return {Boolean} 
*/
PaymentTaxRate.prototype.getIsDefault = function() {
  return this.isDefault;
};

/**
* Set the field value
* The sub-total of line items that the tax was based on. For VAT, this amount includes tax. For all others, this amount does not include tax.
*
* @memberof payments.PaymentTaxRate
* @param {Number} taxableAmount must be a long integer
*/
PaymentTaxRate.prototype.setTaxableAmount = function(taxableAmount) {
  this.taxableAmount = taxableAmount;
};

/**
* Get the field value
* The sub-total of line items that the tax was based on. For VAT, this amount includes tax. For all others, this amount does not include tax.
* @memberof payments.PaymentTaxRate
* @return {Number} must be a long integer
*/
PaymentTaxRate.prototype.getTaxableAmount = function() {
  return this.taxableAmount;
};

/**
* Set the field value
* Whether merchant is configured as VAT
*
* @memberof payments.PaymentTaxRate
* @param {Boolean} isVat 
*/
PaymentTaxRate.prototype.setIsVat = function(isVat) {
  this.isVat = isVat;
};

/**
* Get the field value
* Whether merchant is configured as VAT
* @memberof payments.PaymentTaxRate
* @return {Boolean} 
*/
PaymentTaxRate.prototype.getIsVat = function() {
  return this.isVat;
};

/**
* Set the field value
* The amount of tax collected. In cases where there are multiple payments for an order then it may be necessary to add an extra cent of tax to the last payment.
*
* @memberof payments.PaymentTaxRate
* @param {Number} taxAmount must be a long integer
*/
PaymentTaxRate.prototype.setTaxAmount = function(taxAmount) {
  this.taxAmount = taxAmount;
};

/**
* Get the field value
* The amount of tax collected. In cases where there are multiple payments for an order then it may be necessary to add an extra cent of tax to the last payment.
* @memberof payments.PaymentTaxRate
* @return {Number} must be a long integer
*/
PaymentTaxRate.prototype.getTaxAmount = function() {
  return this.taxAmount;
};

/**
* @memberof payments.PaymentTaxRate
* @private
*/
PaymentTaxRate.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PaymentTaxRate.prototype.toString = function() {
  return JSON.stringify(this);
};

PaymentTaxRate._meta_ =  {fields:  {}};
PaymentTaxRate._meta_._class_ =  PaymentTaxRate;
PaymentTaxRate._meta_.fields["id"] = {};
PaymentTaxRate._meta_.fields["id"].type = String;
PaymentTaxRate._meta_.fields["name"] = {};
PaymentTaxRate._meta_.fields["name"].type = String;
PaymentTaxRate._meta_.fields["rate"] = {};
PaymentTaxRate._meta_.fields["rate"].type = Number;
PaymentTaxRate._meta_.fields["isDefault"] = {};
PaymentTaxRate._meta_.fields["isDefault"].type = Boolean;
PaymentTaxRate._meta_.fields["taxableAmount"] = {};
PaymentTaxRate._meta_.fields["taxableAmount"].type = Number;
PaymentTaxRate._meta_.fields["isVat"] = {};
PaymentTaxRate._meta_.fields["isVat"].type = Boolean;
PaymentTaxRate._meta_.fields["taxAmount"] = {};
PaymentTaxRate._meta_.fields["taxAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PaymentTaxRate;
}

