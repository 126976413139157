/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var printer_Printer = require("../printer/Printer");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/** contains the list of printers */
/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var RetrievePrintersResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = RetrievePrintersResponse;
  this.printers = undefined;
};

RetrievePrintersResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
RetrievePrintersResponse.prototype.constructor = RetrievePrintersResponse;

/**
* Set the field value
* The list of printers (note, there is some v1-v3 confusion at the moment)
*
* @memberof remotepay.RetrievePrintersResponse
* @param {Array.<printer.Printer>} printers An array of 
*/
RetrievePrintersResponse.prototype.setPrinters = function(printers) {
  this.printers = printers;
};

/**
* Get the field value
* The list of printers (note, there is some v1-v3 confusion at the moment)
* @memberof remotepay.RetrievePrintersResponse
* @return {Array.<printer.Printer>} An array of 
*/
RetrievePrintersResponse.prototype.getPrinters = function() {
  return this.printers;
};

RetrievePrintersResponse._meta_ =  {fields:  {}};
RetrievePrintersResponse._meta_._class_ =  RetrievePrintersResponse;
RetrievePrintersResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
RetrievePrintersResponse._meta_.fields["printers"] = {};
RetrievePrintersResponse._meta_.fields["printers"].type = Array;
RetrievePrintersResponse._meta_.fields["printers"].elementType = printer_Printer;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RetrievePrintersResponse;
}

