/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Credit = require("../payments/Credit");

/**
* @constructor
* @memberof payments
*/
var CreditResponse = function() {
  this._class_ = CreditResponse;
  this.requestSuccessful = undefined;
  this.responseErrorMessage = undefined;
  this.credit = undefined;
  this.clientData = undefined;
};


/**
* Set the field value
* @memberof payments.CreditResponse
* @param {Boolean} requestSuccessful 
*/
CreditResponse.prototype.setRequestSuccessful = function(requestSuccessful) {
  this.requestSuccessful = requestSuccessful;
};

/**
* Get the field value
* @memberof payments.CreditResponse
* @return {Boolean} 
*/
CreditResponse.prototype.getRequestSuccessful = function() {
  return this.requestSuccessful;
};

/**
* Set the field value
* @memberof payments.CreditResponse
* @param {String|Null} responseErrorMessage 
*/
CreditResponse.prototype.setResponseErrorMessage = function(responseErrorMessage) {
  this.responseErrorMessage = responseErrorMessage;
};

/**
* Get the field value
* @memberof payments.CreditResponse
* @return {String|Null} 
*/
CreditResponse.prototype.getResponseErrorMessage = function() {
  return this.responseErrorMessage;
};

/**
* Set the field value
* @memberof payments.CreditResponse
* @param {payments.Credit|Null} credit 
*/
CreditResponse.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* @memberof payments.CreditResponse
* @return {payments.Credit|Null} 
*/
CreditResponse.prototype.getCredit = function() {
  return this.credit;
};

/**
* Set the field value
* Additional data sent back from the gateway
*
* @memberof payments.CreditResponse
* @param {Object.<String,String>} clientData A map of <String> to <>
*/
CreditResponse.prototype.setClientData = function(clientData) {
  this.clientData = clientData;
};

/**
* Get the field value
* Additional data sent back from the gateway
* @memberof payments.CreditResponse
* @return {Object.<String,String>} A map of <String> to <>
*/
CreditResponse.prototype.getClientData = function() {
  return this.clientData;
};

/**
* @memberof payments.CreditResponse
* @private
*/
CreditResponse.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

CreditResponse.prototype.toString = function() {
  return JSON.stringify(this);
};

CreditResponse._meta_ =  {fields:  {}};
CreditResponse._meta_._class_ =  CreditResponse;
CreditResponse._meta_.fields["requestSuccessful"] = {};
CreditResponse._meta_.fields["requestSuccessful"].type = Boolean;
CreditResponse._meta_.fields["responseErrorMessage"] = {};
CreditResponse._meta_.fields["responseErrorMessage"].type = String;
CreditResponse._meta_.fields["credit"] = {};
CreditResponse._meta_.fields["credit"].type = payments_Credit;
CreditResponse._meta_.fields["clientData"] = {};
CreditResponse._meta_.fields["clientData"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CreditResponse;
}

