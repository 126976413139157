/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof employees
* @enum {string}
*/
var AccountRole = {
  OWNER : "OWNER",
  ADMIN : "ADMIN",
  MANAGER : "MANAGER",
  EMPLOYEE : "EMPLOYEE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AccountRole;
}

