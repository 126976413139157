/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var CapturePreAuthMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = CapturePreAuthMessage;
  this.setMethod(remotemessage_Method["CAPTURE_PREAUTH"]);
  this.amount = undefined;
  this.tipAmount = undefined;
  this.paymentId = undefined;
};

CapturePreAuthMessage.prototype = Object.create(remotemessage_Message.prototype);
CapturePreAuthMessage.prototype.constructor = CapturePreAuthMessage;

/**
* Set the field value
* Total amount paid
*
* @memberof remotemessage.CapturePreAuthMessage
* @param {Number} amount must be a long integer
*/
CapturePreAuthMessage.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Total amount paid
* @memberof remotemessage.CapturePreAuthMessage
* @return {Number} must be a long integer
*/
CapturePreAuthMessage.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* Amount paid in tips
*
* @memberof remotemessage.CapturePreAuthMessage
* @param {Number|Null} tipAmount must be a long integer, 
*/
CapturePreAuthMessage.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Amount paid in tips
* @memberof remotemessage.CapturePreAuthMessage
* @return {Number|Null} must be a long integer, 
*/
CapturePreAuthMessage.prototype.getTipAmount = function() {
  return this.tipAmount;
};

/**
* Set the field value
* Unique identifier for a payment
*
* @memberof remotemessage.CapturePreAuthMessage
* @param {String} paymentId 
*/
CapturePreAuthMessage.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier for a payment
* @memberof remotemessage.CapturePreAuthMessage
* @return {String} 
*/
CapturePreAuthMessage.prototype.getPaymentId = function() {
  return this.paymentId;
};

CapturePreAuthMessage._meta_ =  {fields:  {}};
CapturePreAuthMessage._meta_._class_ =  CapturePreAuthMessage;
CapturePreAuthMessage._meta_._superMeta_ = remotemessage_Message._meta_;
CapturePreAuthMessage._meta_.fields["amount"] = {};
CapturePreAuthMessage._meta_.fields["amount"].type = Number;
CapturePreAuthMessage._meta_.fields["tipAmount"] = {};
CapturePreAuthMessage._meta_.fields["tipAmount"].type = Number;
CapturePreAuthMessage._meta_.fields["paymentId"] = {};
CapturePreAuthMessage._meta_.fields["paymentId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CapturePreAuthMessage;
}

