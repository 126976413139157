/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_TransactionType = require("../remotepay/TransactionType");
var remotepay_TransactionRequest = require("../remotepay/TransactionRequest");

/** A authorization request */
/**
* @constructor
* @augments remotepay.TransactionRequest
* @memberof remotepay
*/
var AuthRequest = function() {
  remotepay_TransactionRequest.call(this);
  this._superClass_ = remotepay_TransactionRequest;
  this._class_ = AuthRequest;
  this.type = remotepay_TransactionType["PAYMENT"];
};

AuthRequest.prototype = Object.create(remotepay_TransactionRequest.prototype);
AuthRequest.prototype.constructor = AuthRequest;

AuthRequest._meta_ =  {fields:  {}};
AuthRequest._meta_._class_ =  AuthRequest;
AuthRequest._meta_._superMeta_ = remotepay_TransactionRequest._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AuthRequest;
}

