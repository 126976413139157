/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_BatchType = require("../payments/BatchType");
var payments_BatchState = require("../payments/BatchState");

/**
* @constructor
* @memberof payments
*/
var FailedBatchSettlement = function() {
  this._class_ = FailedBatchSettlement;
  this.merchantName = undefined;
  this.mid = undefined;
  this.cid = undefined;
  this.batchId = undefined;
  this.txCount = undefined;
  this.state = undefined;
  this.batchType = undefined;
  this.createdTime = undefined;
  this.retries = undefined;
};


/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {String} merchantName 
*/
FailedBatchSettlement.prototype.setMerchantName = function(merchantName) {
  this.merchantName = merchantName;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {String} 
*/
FailedBatchSettlement.prototype.getMerchantName = function() {
  return this.merchantName;
};

/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {String} mid 
*/
FailedBatchSettlement.prototype.setMid = function(mid) {
  this.mid = mid;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {String} 
*/
FailedBatchSettlement.prototype.getMid = function() {
  return this.mid;
};

/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {String} cid 
*/
FailedBatchSettlement.prototype.setCid = function(cid) {
  this.cid = cid;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {String} 
*/
FailedBatchSettlement.prototype.getCid = function() {
  return this.cid;
};

/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {String} batchId 
*/
FailedBatchSettlement.prototype.setBatchId = function(batchId) {
  this.batchId = batchId;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {String} 
*/
FailedBatchSettlement.prototype.getBatchId = function() {
  return this.batchId;
};

/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {Number} txCount must be an integer
*/
FailedBatchSettlement.prototype.setTxCount = function(txCount) {
  this.txCount = txCount;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {Number} must be an integer
*/
FailedBatchSettlement.prototype.getTxCount = function() {
  return this.txCount;
};

/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {payments.BatchState} state 
*/
FailedBatchSettlement.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {payments.BatchState} 
*/
FailedBatchSettlement.prototype.getState = function() {
  return this.state;
};

/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {payments.BatchType} batchType 
*/
FailedBatchSettlement.prototype.setBatchType = function(batchType) {
  this.batchType = batchType;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {payments.BatchType} 
*/
FailedBatchSettlement.prototype.getBatchType = function() {
  return this.batchType;
};

/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {Number} createdTime must be a long integer
*/
FailedBatchSettlement.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {Number} must be a long integer
*/
FailedBatchSettlement.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* @memberof payments.FailedBatchSettlement
* @param {Number} retries must be an integer
*/
FailedBatchSettlement.prototype.setRetries = function(retries) {
  this.retries = retries;
};

/**
* Get the field value
* @memberof payments.FailedBatchSettlement
* @return {Number} must be an integer
*/
FailedBatchSettlement.prototype.getRetries = function() {
  return this.retries;
};

/**
* @memberof payments.FailedBatchSettlement
* @private
*/
FailedBatchSettlement.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

FailedBatchSettlement.prototype.toString = function() {
  return JSON.stringify(this);
};

FailedBatchSettlement._meta_ =  {fields:  {}};
FailedBatchSettlement._meta_._class_ =  FailedBatchSettlement;
FailedBatchSettlement._meta_.fields["merchantName"] = {};
FailedBatchSettlement._meta_.fields["merchantName"].type = String;
FailedBatchSettlement._meta_.fields["mid"] = {};
FailedBatchSettlement._meta_.fields["mid"].type = String;
FailedBatchSettlement._meta_.fields["cid"] = {};
FailedBatchSettlement._meta_.fields["cid"].type = String;
FailedBatchSettlement._meta_.fields["batchId"] = {};
FailedBatchSettlement._meta_.fields["batchId"].type = String;
FailedBatchSettlement._meta_.fields["txCount"] = {};
FailedBatchSettlement._meta_.fields["txCount"].type = Number;
FailedBatchSettlement._meta_.fields["state"] = {};
FailedBatchSettlement._meta_.fields["state"].type = payments_BatchState;
FailedBatchSettlement._meta_.fields["batchType"] = {};
FailedBatchSettlement._meta_.fields["batchType"].type = payments_BatchType;
FailedBatchSettlement._meta_.fields["createdTime"] = {};
FailedBatchSettlement._meta_.fields["createdTime"].type = Number;
FailedBatchSettlement._meta_.fields["retries"] = {};
FailedBatchSettlement._meta_.fields["retries"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = FailedBatchSettlement;
}

