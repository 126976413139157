/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof order
* @enum {string}
*/
var CustomerIdMethod = {
  NAME : "NAME",
  TABLE : "TABLE",
  NAME_TABLE : "NAME_TABLE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CustomerIdMethod;
}

