/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_TxState = require("../remotemessage/TxState");

/**
* @constructor
* @memberof remotemessage
*/
var TxStateEnum = function() {
  this._class_ = TxStateEnum;
  this.txState = undefined;
};


/**
* Set the field value
* @memberof remotemessage.TxStateEnum
* @param {remotemessage.TxState} txState 
*/
TxStateEnum.prototype.setTxState = function(txState) {
  this.txState = txState;
};

/**
* Get the field value
* @memberof remotemessage.TxStateEnum
* @return {remotemessage.TxState} 
*/
TxStateEnum.prototype.getTxState = function() {
  return this.txState;
};

/**
* @memberof remotemessage.TxStateEnum
* @private
*/
TxStateEnum.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

TxStateEnum.prototype.toString = function() {
  return JSON.stringify(this);
};

TxStateEnum._meta_ =  {fields:  {}};
TxStateEnum._meta_._class_ =  TxStateEnum;
TxStateEnum._meta_.fields["txState"] = {};
TxStateEnum._meta_.fields["txState"].type = remotemessage_TxState;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TxStateEnum;
}

