/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Refund = require("../payments/Refund");

/**
* @constructor
* @memberof payments
*/
var ServiceFeeRefundRequest = function() {
  this._class_ = ServiceFeeRefundRequest;
  this.refund = undefined;
};


/**
* Set the field value
* Service fee refund object
*
* @memberof payments.ServiceFeeRefundRequest
* @param {payments.Refund} refund 
*/
ServiceFeeRefundRequest.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* Service fee refund object
* @memberof payments.ServiceFeeRefundRequest
* @return {payments.Refund} 
*/
ServiceFeeRefundRequest.prototype.getRefund = function() {
  return this.refund;
};

/**
* @memberof payments.ServiceFeeRefundRequest
* @private
*/
ServiceFeeRefundRequest.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

ServiceFeeRefundRequest.prototype.toString = function() {
  return JSON.stringify(this);
};

ServiceFeeRefundRequest._meta_ =  {fields:  {}};
ServiceFeeRefundRequest._meta_._class_ =  ServiceFeeRefundRequest;
ServiceFeeRefundRequest._meta_.fields["refund"] = {};
ServiceFeeRefundRequest._meta_.fields["refund"].type = payments_Refund;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ServiceFeeRefundRequest;
}

