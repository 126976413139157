/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Payment = require("../payments/Payment");
var order_Order = require("../order/Order");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var PaymentPrintMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = PaymentPrintMessage;
  this.setMethod(remotemessage_Method["PRINT_PAYMENT"]);
  this.payment = undefined;
  this.order = undefined;
};

PaymentPrintMessage.prototype = Object.create(remotemessage_Message.prototype);
PaymentPrintMessage.prototype.constructor = PaymentPrintMessage;

/**
* Set the field value
* @memberof remotemessage.PaymentPrintMessage
* @param {payments.Payment} payment 
*/
PaymentPrintMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotemessage.PaymentPrintMessage
* @return {payments.Payment} 
*/
PaymentPrintMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotemessage.PaymentPrintMessage
* @param {order.Order} order 
*/
PaymentPrintMessage.prototype.setOrder = function(order) {
  this.order = order;
};

/**
* Get the field value
* @memberof remotemessage.PaymentPrintMessage
* @return {order.Order} 
*/
PaymentPrintMessage.prototype.getOrder = function() {
  return this.order;
};

PaymentPrintMessage._meta_ =  {fields:  {}};
PaymentPrintMessage._meta_._class_ =  PaymentPrintMessage;
PaymentPrintMessage._meta_._superMeta_ = remotemessage_Message._meta_;
PaymentPrintMessage._meta_.fields["payment"] = {};
PaymentPrintMessage._meta_.fields["payment"].type = payments_Payment;
PaymentPrintMessage._meta_.fields["order"] = {};
PaymentPrintMessage._meta_.fields["order"].type = order_Order;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PaymentPrintMessage;
}

