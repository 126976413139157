/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Credit = require("../payments/Credit");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var DeclineCreditPrintMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = DeclineCreditPrintMessage;
  this.setMethod(remotemessage_Method["PRINT_CREDIT_DECLINE"]);
  this.credit = undefined;
  this.reason = undefined;
};

DeclineCreditPrintMessage.prototype = Object.create(remotemessage_Message.prototype);
DeclineCreditPrintMessage.prototype.constructor = DeclineCreditPrintMessage;

/**
* Set the field value
* @memberof remotemessage.DeclineCreditPrintMessage
* @param {payments.Credit} credit 
*/
DeclineCreditPrintMessage.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* @memberof remotemessage.DeclineCreditPrintMessage
* @return {payments.Credit} 
*/
DeclineCreditPrintMessage.prototype.getCredit = function() {
  return this.credit;
};

/**
* Set the field value
* @memberof remotemessage.DeclineCreditPrintMessage
* @param {String} reason 
*/
DeclineCreditPrintMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotemessage.DeclineCreditPrintMessage
* @return {String} 
*/
DeclineCreditPrintMessage.prototype.getReason = function() {
  return this.reason;
};

DeclineCreditPrintMessage._meta_ =  {fields:  {}};
DeclineCreditPrintMessage._meta_._class_ =  DeclineCreditPrintMessage;
DeclineCreditPrintMessage._meta_._superMeta_ = remotemessage_Message._meta_;
DeclineCreditPrintMessage._meta_.fields["credit"] = {};
DeclineCreditPrintMessage._meta_.fields["credit"].type = payments_Credit;
DeclineCreditPrintMessage._meta_.fields["reason"] = {};
DeclineCreditPrintMessage._meta_.fields["reason"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeclineCreditPrintMessage;
}

