/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof payments
*/
var GermanInfo = function() {
  this._class_ = GermanInfo;
  this.cardTrack2 = undefined;
  this.cardSequenceNumber = undefined;
  this.transactionCaseGermany = undefined;
  this.transactionTypeGermany = undefined;
  this.terminalID = undefined;
  this.traceNumber = undefined;
  this.oldTraceNumber = undefined;
  this.receiptNumber = undefined;
  this.transactionAID = undefined;
  this.transactionMSApp = undefined;
  this.transactionScriptResults = undefined;
  this.receiptType = undefined;
  this.customerTransactionDOLValues = undefined;
  this.merchantTransactionDOLValues = undefined;
  this.merchantJournalDOL = undefined;
  this.merchantJournalDOLValues = undefined;
  this.configMerchantId = undefined;
  this.configProductLabel = undefined;
  this.hostResponseAidParBMP53 = undefined;
  this.hostResponsePrintDataBM60 = undefined;
  this.sepaElvReceiptFormat = undefined;
  this.sepaElvExtAppLabel = undefined;
  this.sepaElvPreNotification = undefined;
  this.sepaElvMandate = undefined;
  this.sepaElvCreditorId = undefined;
  this.sepaElvMandateId = undefined;
  this.sepaElvIban = undefined;
};


/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} cardTrack2 
*/
GermanInfo.prototype.setCardTrack2 = function(cardTrack2) {
  this.cardTrack2 = cardTrack2;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getCardTrack2 = function() {
  return this.cardTrack2;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} cardSequenceNumber 
*/
GermanInfo.prototype.setCardSequenceNumber = function(cardSequenceNumber) {
  this.cardSequenceNumber = cardSequenceNumber;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getCardSequenceNumber = function() {
  return this.cardSequenceNumber;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} transactionCaseGermany 
*/
GermanInfo.prototype.setTransactionCaseGermany = function(transactionCaseGermany) {
  this.transactionCaseGermany = transactionCaseGermany;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getTransactionCaseGermany = function() {
  return this.transactionCaseGermany;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} transactionTypeGermany 
*/
GermanInfo.prototype.setTransactionTypeGermany = function(transactionTypeGermany) {
  this.transactionTypeGermany = transactionTypeGermany;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getTransactionTypeGermany = function() {
  return this.transactionTypeGermany;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} terminalID 
*/
GermanInfo.prototype.setTerminalID = function(terminalID) {
  this.terminalID = terminalID;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getTerminalID = function() {
  return this.terminalID;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} traceNumber 
*/
GermanInfo.prototype.setTraceNumber = function(traceNumber) {
  this.traceNumber = traceNumber;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getTraceNumber = function() {
  return this.traceNumber;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} oldTraceNumber 
*/
GermanInfo.prototype.setOldTraceNumber = function(oldTraceNumber) {
  this.oldTraceNumber = oldTraceNumber;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getOldTraceNumber = function() {
  return this.oldTraceNumber;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} receiptNumber 
*/
GermanInfo.prototype.setReceiptNumber = function(receiptNumber) {
  this.receiptNumber = receiptNumber;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getReceiptNumber = function() {
  return this.receiptNumber;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} transactionAID 
*/
GermanInfo.prototype.setTransactionAID = function(transactionAID) {
  this.transactionAID = transactionAID;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getTransactionAID = function() {
  return this.transactionAID;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} transactionMSApp 
*/
GermanInfo.prototype.setTransactionMSApp = function(transactionMSApp) {
  this.transactionMSApp = transactionMSApp;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getTransactionMSApp = function() {
  return this.transactionMSApp;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} transactionScriptResults 
*/
GermanInfo.prototype.setTransactionScriptResults = function(transactionScriptResults) {
  this.transactionScriptResults = transactionScriptResults;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getTransactionScriptResults = function() {
  return this.transactionScriptResults;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} receiptType 
*/
GermanInfo.prototype.setReceiptType = function(receiptType) {
  this.receiptType = receiptType;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getReceiptType = function() {
  return this.receiptType;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} customerTransactionDOLValues 
*/
GermanInfo.prototype.setCustomerTransactionDOLValues = function(customerTransactionDOLValues) {
  this.customerTransactionDOLValues = customerTransactionDOLValues;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getCustomerTransactionDOLValues = function() {
  return this.customerTransactionDOLValues;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} merchantTransactionDOLValues 
*/
GermanInfo.prototype.setMerchantTransactionDOLValues = function(merchantTransactionDOLValues) {
  this.merchantTransactionDOLValues = merchantTransactionDOLValues;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getMerchantTransactionDOLValues = function() {
  return this.merchantTransactionDOLValues;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} merchantJournalDOL 
*/
GermanInfo.prototype.setMerchantJournalDOL = function(merchantJournalDOL) {
  this.merchantJournalDOL = merchantJournalDOL;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getMerchantJournalDOL = function() {
  return this.merchantJournalDOL;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} merchantJournalDOLValues 
*/
GermanInfo.prototype.setMerchantJournalDOLValues = function(merchantJournalDOLValues) {
  this.merchantJournalDOLValues = merchantJournalDOLValues;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getMerchantJournalDOLValues = function() {
  return this.merchantJournalDOLValues;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} configMerchantId 
*/
GermanInfo.prototype.setConfigMerchantId = function(configMerchantId) {
  this.configMerchantId = configMerchantId;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getConfigMerchantId = function() {
  return this.configMerchantId;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} configProductLabel 
*/
GermanInfo.prototype.setConfigProductLabel = function(configProductLabel) {
  this.configProductLabel = configProductLabel;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getConfigProductLabel = function() {
  return this.configProductLabel;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} hostResponseAidParBMP53 
*/
GermanInfo.prototype.setHostResponseAidParBMP53 = function(hostResponseAidParBMP53) {
  this.hostResponseAidParBMP53 = hostResponseAidParBMP53;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getHostResponseAidParBMP53 = function() {
  return this.hostResponseAidParBMP53;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} hostResponsePrintDataBM60 
*/
GermanInfo.prototype.setHostResponsePrintDataBM60 = function(hostResponsePrintDataBM60) {
  this.hostResponsePrintDataBM60 = hostResponsePrintDataBM60;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getHostResponsePrintDataBM60 = function() {
  return this.hostResponsePrintDataBM60;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} sepaElvReceiptFormat 
*/
GermanInfo.prototype.setSepaElvReceiptFormat = function(sepaElvReceiptFormat) {
  this.sepaElvReceiptFormat = sepaElvReceiptFormat;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getSepaElvReceiptFormat = function() {
  return this.sepaElvReceiptFormat;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} sepaElvExtAppLabel 
*/
GermanInfo.prototype.setSepaElvExtAppLabel = function(sepaElvExtAppLabel) {
  this.sepaElvExtAppLabel = sepaElvExtAppLabel;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getSepaElvExtAppLabel = function() {
  return this.sepaElvExtAppLabel;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} sepaElvPreNotification 
*/
GermanInfo.prototype.setSepaElvPreNotification = function(sepaElvPreNotification) {
  this.sepaElvPreNotification = sepaElvPreNotification;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getSepaElvPreNotification = function() {
  return this.sepaElvPreNotification;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} sepaElvMandate 
*/
GermanInfo.prototype.setSepaElvMandate = function(sepaElvMandate) {
  this.sepaElvMandate = sepaElvMandate;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getSepaElvMandate = function() {
  return this.sepaElvMandate;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} sepaElvCreditorId 
*/
GermanInfo.prototype.setSepaElvCreditorId = function(sepaElvCreditorId) {
  this.sepaElvCreditorId = sepaElvCreditorId;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getSepaElvCreditorId = function() {
  return this.sepaElvCreditorId;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} sepaElvMandateId 
*/
GermanInfo.prototype.setSepaElvMandateId = function(sepaElvMandateId) {
  this.sepaElvMandateId = sepaElvMandateId;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getSepaElvMandateId = function() {
  return this.sepaElvMandateId;
};

/**
* Set the field value
* @memberof payments.GermanInfo
* @param {String} sepaElvIban 
*/
GermanInfo.prototype.setSepaElvIban = function(sepaElvIban) {
  this.sepaElvIban = sepaElvIban;
};

/**
* Get the field value
* @memberof payments.GermanInfo
* @return {String} 
*/
GermanInfo.prototype.getSepaElvIban = function() {
  return this.sepaElvIban;
};

/**
* @memberof payments.GermanInfo
* @private
*/
GermanInfo.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

GermanInfo.prototype.toString = function() {
  return JSON.stringify(this);
};

GermanInfo._meta_ =  {fields:  {}};
GermanInfo._meta_._class_ =  GermanInfo;
GermanInfo._meta_.fields["cardTrack2"] = {};
GermanInfo._meta_.fields["cardTrack2"].type = String;
GermanInfo._meta_.fields["cardSequenceNumber"] = {};
GermanInfo._meta_.fields["cardSequenceNumber"].type = String;
GermanInfo._meta_.fields["transactionCaseGermany"] = {};
GermanInfo._meta_.fields["transactionCaseGermany"].type = String;
GermanInfo._meta_.fields["transactionTypeGermany"] = {};
GermanInfo._meta_.fields["transactionTypeGermany"].type = String;
GermanInfo._meta_.fields["terminalID"] = {};
GermanInfo._meta_.fields["terminalID"].type = String;
GermanInfo._meta_.fields["traceNumber"] = {};
GermanInfo._meta_.fields["traceNumber"].type = String;
GermanInfo._meta_.fields["oldTraceNumber"] = {};
GermanInfo._meta_.fields["oldTraceNumber"].type = String;
GermanInfo._meta_.fields["receiptNumber"] = {};
GermanInfo._meta_.fields["receiptNumber"].type = String;
GermanInfo._meta_.fields["transactionAID"] = {};
GermanInfo._meta_.fields["transactionAID"].type = String;
GermanInfo._meta_.fields["transactionMSApp"] = {};
GermanInfo._meta_.fields["transactionMSApp"].type = String;
GermanInfo._meta_.fields["transactionScriptResults"] = {};
GermanInfo._meta_.fields["transactionScriptResults"].type = String;
GermanInfo._meta_.fields["receiptType"] = {};
GermanInfo._meta_.fields["receiptType"].type = String;
GermanInfo._meta_.fields["customerTransactionDOLValues"] = {};
GermanInfo._meta_.fields["customerTransactionDOLValues"].type = String;
GermanInfo._meta_.fields["merchantTransactionDOLValues"] = {};
GermanInfo._meta_.fields["merchantTransactionDOLValues"].type = String;
GermanInfo._meta_.fields["merchantJournalDOL"] = {};
GermanInfo._meta_.fields["merchantJournalDOL"].type = String;
GermanInfo._meta_.fields["merchantJournalDOLValues"] = {};
GermanInfo._meta_.fields["merchantJournalDOLValues"].type = String;
GermanInfo._meta_.fields["configMerchantId"] = {};
GermanInfo._meta_.fields["configMerchantId"].type = String;
GermanInfo._meta_.fields["configProductLabel"] = {};
GermanInfo._meta_.fields["configProductLabel"].type = String;
GermanInfo._meta_.fields["hostResponseAidParBMP53"] = {};
GermanInfo._meta_.fields["hostResponseAidParBMP53"].type = String;
GermanInfo._meta_.fields["hostResponsePrintDataBM60"] = {};
GermanInfo._meta_.fields["hostResponsePrintDataBM60"].type = String;
GermanInfo._meta_.fields["sepaElvReceiptFormat"] = {};
GermanInfo._meta_.fields["sepaElvReceiptFormat"].type = String;
GermanInfo._meta_.fields["sepaElvExtAppLabel"] = {};
GermanInfo._meta_.fields["sepaElvExtAppLabel"].type = String;
GermanInfo._meta_.fields["sepaElvPreNotification"] = {};
GermanInfo._meta_.fields["sepaElvPreNotification"].type = String;
GermanInfo._meta_.fields["sepaElvMandate"] = {};
GermanInfo._meta_.fields["sepaElvMandate"].type = String;
GermanInfo._meta_.fields["sepaElvCreditorId"] = {};
GermanInfo._meta_.fields["sepaElvCreditorId"].type = String;
GermanInfo._meta_.fields["sepaElvMandateId"] = {};
GermanInfo._meta_.fields["sepaElvMandateId"].type = String;
GermanInfo._meta_.fields["sepaElvIban"] = {};
GermanInfo._meta_.fields["sepaElvIban"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = GermanInfo;
}

