/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof payments
*/
var PromotionalMessage = function() {
  this._class_ = PromotionalMessage;
  this.message = undefined;
  this.showOnMerchantReceipt = undefined;
  this.showOnCustomerReceipt = undefined;
  this.showOnDisplay = undefined;
};


/**
* Set the field value
* @memberof payments.PromotionalMessage
* @param {String} message 
*/
PromotionalMessage.prototype.setMessage = function(message) {
  this.message = message;
};

/**
* Get the field value
* @memberof payments.PromotionalMessage
* @return {String} 
*/
PromotionalMessage.prototype.getMessage = function() {
  return this.message;
};

/**
* Set the field value
* @memberof payments.PromotionalMessage
* @param {Boolean} showOnMerchantReceipt 
*/
PromotionalMessage.prototype.setShowOnMerchantReceipt = function(showOnMerchantReceipt) {
  this.showOnMerchantReceipt = showOnMerchantReceipt;
};

/**
* Get the field value
* @memberof payments.PromotionalMessage
* @return {Boolean} 
*/
PromotionalMessage.prototype.getShowOnMerchantReceipt = function() {
  return this.showOnMerchantReceipt;
};

/**
* Set the field value
* @memberof payments.PromotionalMessage
* @param {Boolean} showOnCustomerReceipt 
*/
PromotionalMessage.prototype.setShowOnCustomerReceipt = function(showOnCustomerReceipt) {
  this.showOnCustomerReceipt = showOnCustomerReceipt;
};

/**
* Get the field value
* @memberof payments.PromotionalMessage
* @return {Boolean} 
*/
PromotionalMessage.prototype.getShowOnCustomerReceipt = function() {
  return this.showOnCustomerReceipt;
};

/**
* Set the field value
* @memberof payments.PromotionalMessage
* @param {Boolean} showOnDisplay 
*/
PromotionalMessage.prototype.setShowOnDisplay = function(showOnDisplay) {
  this.showOnDisplay = showOnDisplay;
};

/**
* Get the field value
* @memberof payments.PromotionalMessage
* @return {Boolean} 
*/
PromotionalMessage.prototype.getShowOnDisplay = function() {
  return this.showOnDisplay;
};

/**
* @memberof payments.PromotionalMessage
* @private
*/
PromotionalMessage.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PromotionalMessage.prototype.toString = function() {
  return JSON.stringify(this);
};

PromotionalMessage._meta_ =  {fields:  {}};
PromotionalMessage._meta_._class_ =  PromotionalMessage;
PromotionalMessage._meta_.fields["message"] = {};
PromotionalMessage._meta_.fields["message"].type = String;
PromotionalMessage._meta_.fields["showOnMerchantReceipt"] = {};
PromotionalMessage._meta_.fields["showOnMerchantReceipt"].type = Boolean;
PromotionalMessage._meta_.fields["showOnCustomerReceipt"] = {};
PromotionalMessage._meta_.fields["showOnCustomerReceipt"].type = Boolean;
PromotionalMessage._meta_.fields["showOnDisplay"] = {};
PromotionalMessage._meta_.fields["showOnDisplay"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PromotionalMessage;
}

