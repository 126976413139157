/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var AVSResult = {
  SUCCESS : "SUCCESS",
  ZIP_CODE_MATCH : "ZIP_CODE_MATCH",
  ZIP_CODE_MATCH_ADDRESS_NOT_CHECKED : "ZIP_CODE_MATCH_ADDRESS_NOT_CHECKED",
  ADDRESS_MATCH : "ADDRESS_MATCH",
  ADDRESS_MATCH_ZIP_NOT_CHECKED : "ADDRESS_MATCH_ZIP_NOT_CHECKED",
  NEITHER_MATCH : "NEITHER_MATCH",
  SERVICE_FAILURE : "SERVICE_FAILURE",
  SERVICE_UNAVAILABLE : "SERVICE_UNAVAILABLE",
  NOT_CHECKED : "NOT_CHECKED",
  ZIP_CODE_NOT_MATCHED_ADDRESS_NOT_CHECKED : "ZIP_CODE_NOT_MATCHED_ADDRESS_NOT_CHECKED",
  ADDRESS_NOT_MATCHED_ZIP_CODE_NOT_CHECKED : "ADDRESS_NOT_MATCHED_ZIP_CODE_NOT_CHECKED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AVSResult;
}

