/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_CreditRefund = require("../payments/CreditRefund");

/**
* @constructor
* @memberof payments
*/
var CreditRefundResponse = function() {
  this._class_ = CreditRefundResponse;
  this.requestSuccessful = undefined;
  this.responseErrorMessage = undefined;
  this.creditRefund = undefined;
  this.clientData = undefined;
};


/**
* Set the field value
* @memberof payments.CreditRefundResponse
* @param {Boolean} requestSuccessful 
*/
CreditRefundResponse.prototype.setRequestSuccessful = function(requestSuccessful) {
  this.requestSuccessful = requestSuccessful;
};

/**
* Get the field value
* @memberof payments.CreditRefundResponse
* @return {Boolean} 
*/
CreditRefundResponse.prototype.getRequestSuccessful = function() {
  return this.requestSuccessful;
};

/**
* Set the field value
* @memberof payments.CreditRefundResponse
* @param {String|Null} responseErrorMessage 
*/
CreditRefundResponse.prototype.setResponseErrorMessage = function(responseErrorMessage) {
  this.responseErrorMessage = responseErrorMessage;
};

/**
* Get the field value
* @memberof payments.CreditRefundResponse
* @return {String|Null} 
*/
CreditRefundResponse.prototype.getResponseErrorMessage = function() {
  return this.responseErrorMessage;
};

/**
* Set the field value
* @memberof payments.CreditRefundResponse
* @param {payments.CreditRefund|Null} creditRefund 
*/
CreditRefundResponse.prototype.setCreditRefund = function(creditRefund) {
  this.creditRefund = creditRefund;
};

/**
* Get the field value
* @memberof payments.CreditRefundResponse
* @return {payments.CreditRefund|Null} 
*/
CreditRefundResponse.prototype.getCreditRefund = function() {
  return this.creditRefund;
};

/**
* Set the field value
* Additional data sent back from the gateway
*
* @memberof payments.CreditRefundResponse
* @param {Object.<String,String>} clientData A map of <String> to <>
*/
CreditRefundResponse.prototype.setClientData = function(clientData) {
  this.clientData = clientData;
};

/**
* Get the field value
* Additional data sent back from the gateway
* @memberof payments.CreditRefundResponse
* @return {Object.<String,String>} A map of <String> to <>
*/
CreditRefundResponse.prototype.getClientData = function() {
  return this.clientData;
};

/**
* @memberof payments.CreditRefundResponse
* @private
*/
CreditRefundResponse.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

CreditRefundResponse.prototype.toString = function() {
  return JSON.stringify(this);
};

CreditRefundResponse._meta_ =  {fields:  {}};
CreditRefundResponse._meta_._class_ =  CreditRefundResponse;
CreditRefundResponse._meta_.fields["requestSuccessful"] = {};
CreditRefundResponse._meta_.fields["requestSuccessful"].type = Boolean;
CreditRefundResponse._meta_.fields["responseErrorMessage"] = {};
CreditRefundResponse._meta_.fields["responseErrorMessage"].type = String;
CreditRefundResponse._meta_.fields["creditRefund"] = {};
CreditRefundResponse._meta_.fields["creditRefund"].type = payments_CreditRefund;
CreditRefundResponse._meta_.fields["clientData"] = {};
CreditRefundResponse._meta_.fields["clientData"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CreditRefundResponse;
}

