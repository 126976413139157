/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof pay
* @enum {string}
*/
var ServiceCode2 = {
  NORMAL : "NORMAL",
  CONTACT_ISSUER_VIA_ONLINE_MEANS : "CONTACT_ISSUER_VIA_ONLINE_MEANS",
  CONTACT_ISSUER_VIA_ONLINE_MEANS_EXCEPT_UNDER_BILATERAL_AGREEMENT : "CONTACT_ISSUER_VIA_ONLINE_MEANS_EXCEPT_UNDER_BILATERAL_AGREEMENT",
  UNDEFINED : "UNDEFINED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ServiceCode2;
}

