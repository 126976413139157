/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var TerminalMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = TerminalMessage;
  this.setMethod(remotemessage_Method["TERMINAL_MESSAGE"]);
  this.text = undefined;
};

TerminalMessage.prototype = Object.create(remotemessage_Message.prototype);
TerminalMessage.prototype.constructor = TerminalMessage;

/**
* Set the field value
* The message to display.
*
* @memberof remotemessage.TerminalMessage
* @param {String} text 
*/
TerminalMessage.prototype.setText = function(text) {
  this.text = text;
};

/**
* Get the field value
* The message to display.
* @memberof remotemessage.TerminalMessage
* @return {String} 
*/
TerminalMessage.prototype.getText = function() {
  return this.text;
};

TerminalMessage._meta_ =  {fields:  {}};
TerminalMessage._meta_._class_ =  TerminalMessage;
TerminalMessage._meta_._superMeta_ = remotemessage_Message._meta_;
TerminalMessage._meta_.fields["text"] = {};
TerminalMessage._meta_.fields["text"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TerminalMessage;
}

