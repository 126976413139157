/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var CardInfoRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = CardInfoRequest;
  this.cardEntryMethods = undefined;
};

CardInfoRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
CardInfoRequest.prototype.constructor = CardInfoRequest;

/**
* Set the field value
* Allowed entry methods
*
* @memberof remotepay.CardInfoRequest
* @param {Number} cardEntryMethods must be an integer
*/
CardInfoRequest.prototype.setCardEntryMethods = function(cardEntryMethods) {
  this.cardEntryMethods = cardEntryMethods;
};

/**
* Get the field value
* Allowed entry methods
* @memberof remotepay.CardInfoRequest
* @return {Number} must be an integer
*/
CardInfoRequest.prototype.getCardEntryMethods = function() {
  return this.cardEntryMethods;
};

CardInfoRequest._meta_ =  {fields:  {}};
CardInfoRequest._meta_._class_ =  CardInfoRequest;
CardInfoRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
CardInfoRequest._meta_.fields["cardEntryMethods"] = {};
CardInfoRequest._meta_.fields["cardEntryMethods"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardInfoRequest;
}

