/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof onlineorder
* @enum {string}
*/
var Reason = {
  MENU_ITEM_UNAVAILABLE : "MENU_ITEM_UNAVAILABLE",
  RESTAURANT_CLOSED : "RESTAURANT_CLOSED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Reason;
}

