/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var TokenRequestType = {
  VERIFICATION : "VERIFICATION",
  TOKEN_REQUEST : "TOKEN_REQUEST"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TokenRequestType;
}

