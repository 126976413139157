/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof payments
*/
var ServiceChargeAmount = function() {
  this._class_ = ServiceChargeAmount;
  this.id = undefined;
  this.name = undefined;
  this.amount = undefined;
};


/**
* Set the field value
* @memberof payments.ServiceChargeAmount
* @param {String} id 
*/
ServiceChargeAmount.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* @memberof payments.ServiceChargeAmount
* @return {String} 
*/
ServiceChargeAmount.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof payments.ServiceChargeAmount
* @param {String} name 
*/
ServiceChargeAmount.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* @memberof payments.ServiceChargeAmount
* @return {String} 
*/
ServiceChargeAmount.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* @memberof payments.ServiceChargeAmount
* @param {Number} amount must be a long integer
*/
ServiceChargeAmount.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* @memberof payments.ServiceChargeAmount
* @return {Number} must be a long integer
*/
ServiceChargeAmount.prototype.getAmount = function() {
  return this.amount;
};

/**
* @memberof payments.ServiceChargeAmount
* @private
*/
ServiceChargeAmount.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

ServiceChargeAmount.prototype.toString = function() {
  return JSON.stringify(this);
};

ServiceChargeAmount._meta_ =  {fields:  {}};
ServiceChargeAmount._meta_._class_ =  ServiceChargeAmount;
ServiceChargeAmount._meta_.fields["id"] = {};
ServiceChargeAmount._meta_.fields["id"].type = String;
ServiceChargeAmount._meta_.fields["name"] = {};
ServiceChargeAmount._meta_.fields["name"].type = String;
ServiceChargeAmount._meta_.fields["amount"] = {};
ServiceChargeAmount._meta_.fields["amount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ServiceChargeAmount;
}

