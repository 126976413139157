/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Payment = require("../payments/Payment");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");
var remotepay_QueryStatus = require("../remotepay/QueryStatus");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var RetrievePaymentResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = RetrievePaymentResponse;
  this.externalPaymentId = undefined;
  this.queryStatus = undefined;
  this.payment = undefined;
};

RetrievePaymentResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
RetrievePaymentResponse.prototype.constructor = RetrievePaymentResponse;

/**
* Set the field value
* The externalPaymentId used when a payment was created
*
* @memberof remotepay.RetrievePaymentResponse
* @param {String} externalPaymentId 
*/
RetrievePaymentResponse.prototype.setExternalPaymentId = function(externalPaymentId) {
  this.externalPaymentId = externalPaymentId;
};

/**
* Get the field value
* The externalPaymentId used when a payment was created
* @memberof remotepay.RetrievePaymentResponse
* @return {String} 
*/
RetrievePaymentResponse.prototype.getExternalPaymentId = function() {
  return this.externalPaymentId;
};

/**
* Set the field value
* The status of the query
*
* @memberof remotepay.RetrievePaymentResponse
* @param {remotepay.QueryStatus} queryStatus 
*/
RetrievePaymentResponse.prototype.setQueryStatus = function(queryStatus) {
  this.queryStatus = queryStatus;
};

/**
* Get the field value
* The status of the query
* @memberof remotepay.RetrievePaymentResponse
* @return {remotepay.QueryStatus} 
*/
RetrievePaymentResponse.prototype.getQueryStatus = function() {
  return this.queryStatus;
};

/**
* Set the field value
* Payment information
*
* @memberof remotepay.RetrievePaymentResponse
* @param {payments.Payment} payment 
*/
RetrievePaymentResponse.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* Payment information
* @memberof remotepay.RetrievePaymentResponse
* @return {payments.Payment} 
*/
RetrievePaymentResponse.prototype.getPayment = function() {
  return this.payment;
};

RetrievePaymentResponse._meta_ =  {fields:  {}};
RetrievePaymentResponse._meta_._class_ =  RetrievePaymentResponse;
RetrievePaymentResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
RetrievePaymentResponse._meta_.fields["externalPaymentId"] = {};
RetrievePaymentResponse._meta_.fields["externalPaymentId"].type = String;
RetrievePaymentResponse._meta_.fields["queryStatus"] = {};
RetrievePaymentResponse._meta_.fields["queryStatus"].type = remotepay_QueryStatus;
RetrievePaymentResponse._meta_.fields["payment"] = {};
RetrievePaymentResponse._meta_.fields["payment"].type = payments_Payment;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RetrievePaymentResponse;
}

