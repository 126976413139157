import * as React from "react";
import axios from "axios";
import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";

WebBrowser.maybeCompleteAuthSession();

type GoogleAuthRequestConfig = Partial<Google.GoogleAuthRequestConfig>;

type GoogleUserData = {
  email: string;
  name: string;
};

const useGoogleAuth = (googleAuthRequestConfig: GoogleAuthRequestConfig) => {
  const [googleSignInAuthResult, setGoogleSignInAuthResult] =
    React.useState<any>();
  const [googleUserData, setGoogleUserData] = React.useState<GoogleUserData>();
  const [, response, promptAsync] = Google.useAuthRequest(
    googleAuthRequestConfig
  );

  React.useEffect(() => {
    const getUserData = async () => {
      let accessToken;
      if (
        googleSignInAuthResult &&
        googleSignInAuthResult.type === "success" &&
        googleSignInAuthResult.authentication.accessToken
      ) {
        accessToken = googleSignInAuthResult.authentication.accessToken;
      } else if (
        response &&
        response.type === "success" &&
        response.authentication?.accessToken
      ) {
        setGoogleSignInAuthResult(response);
        accessToken = response.authentication.accessToken;
      }
      if (!accessToken) return;
      try {
        const { data } = await axios.get(
          "https://www.googleapis.com/oauth2/v2/userinfo",
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        setGoogleUserData({
          email: data.email,
          name: data.name,
        });
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, [googleSignInAuthResult, response]);

  const googlePromptAsync = async () => {
    promptAsync();
  };

  return { googleUserData, googleSignInAuthResult, googlePromptAsync };
};

export { useGoogleAuth as default, GoogleAuthRequestConfig, GoogleUserData };
