/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof printer
* @enum {string}
*/
var PrinterType = {
  NETWORK : "NETWORK",
  MY_LOCAL : "MY_LOCAL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrinterType;
}

