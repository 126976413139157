import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    border: 0,
    elevation: 0, // Remove Shadow for Android
    shadowColor: 'rgba(0,0,0, 0.0)', // Remove Shadow for iOS
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 10,
    justifyContent: "center"
  },
  cardImage: {
    borderTopLeftRadius: 10, 
    borderTopRightRadius: 10, 
    overflow: "hidden", 
    resizeMode: 'cover',
  },
  internalVenueLogoContainer: {
    marginLeft: 15,
    position: 'absolute',
    flexDirection: 'column',
    alignSelf: "flex-start",
    bottom: 20,
  },
  venueLogo: {
    borderRadius: 5,
    height: 39,
    width: 39,
  },
  cardDataContainer: {
    justifyContent: "center", 
    paddingHorizontal: 15, 
    paddingBottom: 15
  },
  bottomBordersShadow: {
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10, 
    shadowOpacity: 0.15,
      shadowOffset: {
        height: 2, 
        width: 1
      }
  },
  title: {
    marginTop: 16,
    textAlign: "left",
    fontWeight: "500",
    fontSize: 12,
    color: "#000000"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  iconImageContainer: {
    marginTop: 5, 
    marginBottom: 5 
  },
  iconImage: {
    height: 20, 
    width: 20
  },
  dateTimeContainer: { 
    marginTop: 5, 
    flexDirection: "row"
  },
  date: {
    textTransform: "capitalize",
    fontSize: 12,
    fontWeight: "400",
    color: "#687684",
    fontStyle: "normal",
    lineHeight: 18
  },
  time: {
    textTransform: "capitalize",
    fontSize: 12,
    color: "#687684",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: 18
  },
  clickableOpacity: {
    margin: 0,
    outlineStyle: "none"
  }
});
