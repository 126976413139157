/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_ChallengeType = require("../base/ChallengeType");

/**
* @constructor
* @memberof base
*/
var ChallengeTypeEnum = function() {
  this._class_ = ChallengeTypeEnum;
  this.challengeType = undefined;
};


/**
* Set the field value
* @memberof base.ChallengeTypeEnum
* @param {base.ChallengeType} challengeType 
*/
ChallengeTypeEnum.prototype.setChallengeType = function(challengeType) {
  this.challengeType = challengeType;
};

/**
* Get the field value
* @memberof base.ChallengeTypeEnum
* @return {base.ChallengeType} 
*/
ChallengeTypeEnum.prototype.getChallengeType = function() {
  return this.challengeType;
};

/**
* @memberof base.ChallengeTypeEnum
* @private
*/
ChallengeTypeEnum.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

ChallengeTypeEnum.prototype.toString = function() {
  return JSON.stringify(this);
};

ChallengeTypeEnum._meta_ =  {fields:  {}};
ChallengeTypeEnum._meta_._class_ =  ChallengeTypeEnum;
ChallengeTypeEnum._meta_.fields["challengeType"] = {};
ChallengeTypeEnum._meta_.fields["challengeType"].type = base_ChallengeType;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ChallengeTypeEnum;
}

