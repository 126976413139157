import React, { useEffect } from 'react';
import { onErrorDefault, onReadyDefault } from '../util/initial';
import { initBrick } from '../util/renderBrick';
import { DEBOUNCE_TIME_RENDER } from '../util/constants';
// /**
//  * Status Screen Brick allows you to show buyer the status of a purchase made with any payment method provided by Checkout Bricks.
//  *
//  * Usage:
//  *
//  * ```ts
//  * import StatusScreen, {initMercadoPago} from '@mercadopago/sdk-react'
//  *
//  * initMercadoPago('YOUR_PUBLIC_KEY')
//  *
//  * const Example = () => {
//  *   return(
//  *     <StatusScreen
//  *       initialization={{ preferenceId: '<PREFERENCE_ID>'}} // PREFERENCE_ID generated in backend
//  *       onReady={() => {}} // Callback called when Brick is ready
//  *       onError={() => {}} // Callback called for all Brick error cases
//  *     />
//  *   )
//  * }
//  * export default Example
//  * ```
//  *
//  * @see {@link https://www.mercadopago.com/developers/en/docs/checkout-bricks/status-screen-brick/introduction Status Screen Brick documentation} for more information.
//  */
const StatusScreen = ({ onReady = onReadyDefault, onError = onErrorDefault, customization, initialization, locale, }) => {
    useEffect(() => {
        // CardPayment uses a debounce to prevent unnecessary reRenders.
        let timer;
        const StatusScreenBrickConfig = {
            settings: {
                initialization,
                customization,
                locale,
                callbacks: {
                    onReady,
                    onError,
                },
            },
            name: 'statusScreen',
            divId: 'statusScreenBrick_container',
            controller: 'statusScreenBrickController',
        };
        timer = setTimeout(() => {
            initBrick(StatusScreenBrickConfig);
        }, DEBOUNCE_TIME_RENDER);
        return () => {
            var _a;
            clearTimeout(timer);
            (_a = window.statusScreenBrickController) === null || _a === void 0 ? void 0 : _a.unmount();
        };
    }, [initialization, customization, onReady, onError]);
    return React.createElement("div", { id: "statusScreenBrick_container" });
};
export default StatusScreen;
