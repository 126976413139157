/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_GiftCardState = require("../payments/GiftCardState");
var payments_Payment = require("../payments/Payment");
var payments_TxType = require("../payments/TxType");

/**
* @constructor
* @memberof payments
*/
var GiftCardResponse = function() {
  this._class_ = GiftCardResponse;
  this.txType = undefined;
  this.state = payments_GiftCardState["INVALID"];
  this.requestAmount = undefined;
  this.payment = undefined;
  this.responseMessage = undefined;
  this.requestSuccessful = false;
  this.accountNumber = undefined;
  this.scv = undefined;
  this.giftCardTxUuid = undefined;
  this.transactionId = undefined;
  this.referenceUuid = undefined;
  this.authCode = undefined;
  this.begBal = undefined;
  this.endBal = undefined;
  this.holdBal = undefined;
  this.syncPaymentObject = undefined;
};


/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {payments.TxType} txType 
*/
GiftCardResponse.prototype.setTxType = function(txType) {
  this.txType = txType;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {payments.TxType} 
*/
GiftCardResponse.prototype.getTxType = function() {
  return this.txType;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {payments.GiftCardState} state 
*/
GiftCardResponse.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {payments.GiftCardState} 
*/
GiftCardResponse.prototype.getState = function() {
  return this.state;
};

/**
* Set the field value
* Transaction Amount
*
* @memberof payments.GiftCardResponse
* @param {Number} requestAmount must be a long integer
*/
GiftCardResponse.prototype.setRequestAmount = function(requestAmount) {
  this.requestAmount = requestAmount;
};

/**
* Get the field value
* Transaction Amount
* @memberof payments.GiftCardResponse
* @return {Number} must be a long integer
*/
GiftCardResponse.prototype.getRequestAmount = function() {
  return this.requestAmount;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {payments.Payment} payment 
*/
GiftCardResponse.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {payments.Payment} 
*/
GiftCardResponse.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {String} responseMessage 
*/
GiftCardResponse.prototype.setResponseMessage = function(responseMessage) {
  this.responseMessage = responseMessage;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {String} 
*/
GiftCardResponse.prototype.getResponseMessage = function() {
  return this.responseMessage;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {Boolean} requestSuccessful 
*/
GiftCardResponse.prototype.setRequestSuccessful = function(requestSuccessful) {
  this.requestSuccessful = requestSuccessful;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {Boolean} 
*/
GiftCardResponse.prototype.getRequestSuccessful = function() {
  return this.requestSuccessful;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {String} accountNumber 
*/
GiftCardResponse.prototype.setAccountNumber = function(accountNumber) {
  this.accountNumber = accountNumber;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {String} 
*/
GiftCardResponse.prototype.getAccountNumber = function() {
  return this.accountNumber;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {String} scv 
*/
GiftCardResponse.prototype.setScv = function(scv) {
  this.scv = scv;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {String} 
*/
GiftCardResponse.prototype.getScv = function() {
  return this.scv;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {String} giftCardTxUuid 
*/
GiftCardResponse.prototype.setGiftCardTxUuid = function(giftCardTxUuid) {
  this.giftCardTxUuid = giftCardTxUuid;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {String} 
*/
GiftCardResponse.prototype.getGiftCardTxUuid = function() {
  return this.giftCardTxUuid;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {String} transactionId 
*/
GiftCardResponse.prototype.setTransactionId = function(transactionId) {
  this.transactionId = transactionId;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {String} 
*/
GiftCardResponse.prototype.getTransactionId = function() {
  return this.transactionId;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {String} referenceUuid 
*/
GiftCardResponse.prototype.setReferenceUuid = function(referenceUuid) {
  this.referenceUuid = referenceUuid;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {String} 
*/
GiftCardResponse.prototype.getReferenceUuid = function() {
  return this.referenceUuid;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {String} authCode 
*/
GiftCardResponse.prototype.setAuthCode = function(authCode) {
  this.authCode = authCode;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {String} 
*/
GiftCardResponse.prototype.getAuthCode = function() {
  return this.authCode;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {Null|Number} begBal must be a long integer
*/
GiftCardResponse.prototype.setBegBal = function(begBal) {
  this.begBal = begBal;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {Null|Number} must be a long integer
*/
GiftCardResponse.prototype.getBegBal = function() {
  return this.begBal;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {Null|Number} endBal must be a long integer
*/
GiftCardResponse.prototype.setEndBal = function(endBal) {
  this.endBal = endBal;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {Null|Number} must be a long integer
*/
GiftCardResponse.prototype.getEndBal = function() {
  return this.endBal;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {Null|Number} holdBal must be a long integer
*/
GiftCardResponse.prototype.setHoldBal = function(holdBal) {
  this.holdBal = holdBal;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {Null|Number} must be a long integer
*/
GiftCardResponse.prototype.getHoldBal = function() {
  return this.holdBal;
};

/**
* Set the field value
* @memberof payments.GiftCardResponse
* @param {Boolean} syncPaymentObject 
*/
GiftCardResponse.prototype.setSyncPaymentObject = function(syncPaymentObject) {
  this.syncPaymentObject = syncPaymentObject;
};

/**
* Get the field value
* @memberof payments.GiftCardResponse
* @return {Boolean} 
*/
GiftCardResponse.prototype.getSyncPaymentObject = function() {
  return this.syncPaymentObject;
};

/**
* @memberof payments.GiftCardResponse
* @private
*/
GiftCardResponse.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

GiftCardResponse.prototype.toString = function() {
  return JSON.stringify(this);
};

GiftCardResponse._meta_ =  {fields:  {}};
GiftCardResponse._meta_._class_ =  GiftCardResponse;
GiftCardResponse._meta_.fields["txType"] = {};
GiftCardResponse._meta_.fields["txType"].type = payments_TxType;
GiftCardResponse._meta_.fields["state"] = {};
GiftCardResponse._meta_.fields["state"].type = payments_GiftCardState;
GiftCardResponse._meta_.fields["requestAmount"] = {};
GiftCardResponse._meta_.fields["requestAmount"].type = Number;
GiftCardResponse._meta_.fields["payment"] = {};
GiftCardResponse._meta_.fields["payment"].type = payments_Payment;
GiftCardResponse._meta_.fields["responseMessage"] = {};
GiftCardResponse._meta_.fields["responseMessage"].type = String;
GiftCardResponse._meta_.fields["requestSuccessful"] = {};
GiftCardResponse._meta_.fields["requestSuccessful"].type = Boolean;
GiftCardResponse._meta_.fields["accountNumber"] = {};
GiftCardResponse._meta_.fields["accountNumber"].type = String;
GiftCardResponse._meta_.fields["scv"] = {};
GiftCardResponse._meta_.fields["scv"].type = String;
GiftCardResponse._meta_.fields["giftCardTxUuid"] = {};
GiftCardResponse._meta_.fields["giftCardTxUuid"].type = String;
GiftCardResponse._meta_.fields["transactionId"] = {};
GiftCardResponse._meta_.fields["transactionId"].type = String;
GiftCardResponse._meta_.fields["referenceUuid"] = {};
GiftCardResponse._meta_.fields["referenceUuid"].type = String;
GiftCardResponse._meta_.fields["authCode"] = {};
GiftCardResponse._meta_.fields["authCode"].type = String;
GiftCardResponse._meta_.fields["begBal"] = {};
GiftCardResponse._meta_.fields["begBal"].type = Number;
GiftCardResponse._meta_.fields["endBal"] = {};
GiftCardResponse._meta_.fields["endBal"].type = Number;
GiftCardResponse._meta_.fields["holdBal"] = {};
GiftCardResponse._meta_.fields["holdBal"].type = Number;
GiftCardResponse._meta_.fields["syncPaymentObject"] = {};
GiftCardResponse._meta_.fields["syncPaymentObject"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = GiftCardResponse;
}

