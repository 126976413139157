/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var pay_PaymentRequestCardDetails = require("../pay/PaymentRequestCardDetails");
var payments_Refund = require("../payments/Refund");

/**
* @constructor
* @memberof payments
*/
var RefundRequest = function() {
  this._class_ = RefundRequest;
  this.refund = undefined;
  this.card = undefined;
  this.isAdjustment = undefined;
};


/**
* Set the field value
* @memberof payments.RefundRequest
* @param {payments.Refund|Null} refund 
*/
RefundRequest.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* @memberof payments.RefundRequest
* @return {payments.Refund|Null} 
*/
RefundRequest.prototype.getRefund = function() {
  return this.refund;
};

/**
* Set the field value
* @memberof payments.RefundRequest
* @param {pay.PaymentRequestCardDetails|Null} card 
*/
RefundRequest.prototype.setCard = function(card) {
  this.card = card;
};

/**
* Get the field value
* @memberof payments.RefundRequest
* @return {pay.PaymentRequestCardDetails|Null} 
*/
RefundRequest.prototype.getCard = function() {
  return this.card;
};

/**
* Set the field value
* @memberof payments.RefundRequest
* @param {Boolean} isAdjustment 
*/
RefundRequest.prototype.setIsAdjustment = function(isAdjustment) {
  this.isAdjustment = isAdjustment;
};

/**
* Get the field value
* @memberof payments.RefundRequest
* @return {Boolean} 
*/
RefundRequest.prototype.getIsAdjustment = function() {
  return this.isAdjustment;
};

/**
* @memberof payments.RefundRequest
* @private
*/
RefundRequest.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

RefundRequest.prototype.toString = function() {
  return JSON.stringify(this);
};

RefundRequest._meta_ =  {fields:  {}};
RefundRequest._meta_._class_ =  RefundRequest;
RefundRequest._meta_.fields["refund"] = {};
RefundRequest._meta_.fields["refund"].type = payments_Refund;
RefundRequest._meta_.fields["card"] = {};
RefundRequest._meta_.fields["card"].type = pay_PaymentRequestCardDetails;
RefundRequest._meta_.fields["isAdjustment"] = {};
RefundRequest._meta_.fields["isAdjustment"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RefundRequest;
}

