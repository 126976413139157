/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var printer_Printer = require("../printer/Printer");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var OpenCashDrawerMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = OpenCashDrawerMessage;
  this.setMethod(remotemessage_Method["OPEN_CASH_DRAWER"]);
  this.reason = undefined;
  this.printer = undefined;
};

OpenCashDrawerMessage.prototype = Object.create(remotemessage_Message.prototype);
OpenCashDrawerMessage.prototype.constructor = OpenCashDrawerMessage;

/**
* Set the field value
* The reason the cash drawer was opened.
*
* @memberof remotemessage.OpenCashDrawerMessage
* @param {String} reason 
*/
OpenCashDrawerMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* The reason the cash drawer was opened.
* @memberof remotemessage.OpenCashDrawerMessage
* @return {String} 
*/
OpenCashDrawerMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* The ID of the specific drawer to open.
*
* @memberof remotemessage.OpenCashDrawerMessage
* @param {printer.Printer} printer 
*/
OpenCashDrawerMessage.prototype.setPrinter = function(printer) {
  this.printer = printer;
};

/**
* Get the field value
* The ID of the specific drawer to open.
* @memberof remotemessage.OpenCashDrawerMessage
* @return {printer.Printer} 
*/
OpenCashDrawerMessage.prototype.getPrinter = function() {
  return this.printer;
};

OpenCashDrawerMessage._meta_ =  {fields:  {}};
OpenCashDrawerMessage._meta_._class_ =  OpenCashDrawerMessage;
OpenCashDrawerMessage._meta_._superMeta_ = remotemessage_Message._meta_;
OpenCashDrawerMessage._meta_.fields["reason"] = {};
OpenCashDrawerMessage._meta_.fields["reason"].type = String;
OpenCashDrawerMessage._meta_.fields["printer"] = {};
OpenCashDrawerMessage._meta_.fields["printer"].type = printer_Printer;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OpenCashDrawerMessage;
}

