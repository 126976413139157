/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_ResultStatus = require("../remotemessage/ResultStatus");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var CapturePreAuthResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = CapturePreAuthResponseMessage;
  this.setMethod(remotemessage_Method["CAPTURE_PREAUTH_RESPONSE"]);
  this.status = undefined;
  this.reason = undefined;
  this.message = undefined;
  this.paymentId = undefined;
  this.amount = undefined;
  this.tipAmount = undefined;
};

CapturePreAuthResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
CapturePreAuthResponseMessage.prototype.constructor = CapturePreAuthResponseMessage;

/**
* Set the field value
* The status of the request.
*
* @memberof remotemessage.CapturePreAuthResponseMessage
* @param {remotemessage.ResultStatus} status 
*/
CapturePreAuthResponseMessage.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* The status of the request.
* @memberof remotemessage.CapturePreAuthResponseMessage
* @return {remotemessage.ResultStatus} 
*/
CapturePreAuthResponseMessage.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* Detail message from the server.
*
* @memberof remotemessage.CapturePreAuthResponseMessage
* @param {String} reason 
*/
CapturePreAuthResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* Detail message from the server.
* @memberof remotemessage.CapturePreAuthResponseMessage
* @return {String} 
*/
CapturePreAuthResponseMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* @memberof remotemessage.CapturePreAuthResponseMessage
* @param {String} message 
*/
CapturePreAuthResponseMessage.prototype.setMessage = function(message) {
  this.message = message;
};

/**
* Get the field value
* @memberof remotemessage.CapturePreAuthResponseMessage
* @return {String} 
*/
CapturePreAuthResponseMessage.prototype.getMessage = function() {
  return this.message;
};

/**
* Set the field value
* Unique identifier for a payment
*
* @memberof remotemessage.CapturePreAuthResponseMessage
* @param {String} paymentId 
*/
CapturePreAuthResponseMessage.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier for a payment
* @memberof remotemessage.CapturePreAuthResponseMessage
* @return {String} 
*/
CapturePreAuthResponseMessage.prototype.getPaymentId = function() {
  return this.paymentId;
};

/**
* Set the field value
* Total amount paid
*
* @memberof remotemessage.CapturePreAuthResponseMessage
* @param {Number} amount must be a long integer
*/
CapturePreAuthResponseMessage.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Total amount paid
* @memberof remotemessage.CapturePreAuthResponseMessage
* @return {Number} must be a long integer
*/
CapturePreAuthResponseMessage.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* Amount paid in tips
*
* @memberof remotemessage.CapturePreAuthResponseMessage
* @param {Number|Null} tipAmount must be a long integer, 
*/
CapturePreAuthResponseMessage.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Amount paid in tips
* @memberof remotemessage.CapturePreAuthResponseMessage
* @return {Number|Null} must be a long integer, 
*/
CapturePreAuthResponseMessage.prototype.getTipAmount = function() {
  return this.tipAmount;
};

CapturePreAuthResponseMessage._meta_ =  {fields:  {}};
CapturePreAuthResponseMessage._meta_._class_ =  CapturePreAuthResponseMessage;
CapturePreAuthResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
CapturePreAuthResponseMessage._meta_.fields["status"] = {};
CapturePreAuthResponseMessage._meta_.fields["status"].type = remotemessage_ResultStatus;
CapturePreAuthResponseMessage._meta_.fields["reason"] = {};
CapturePreAuthResponseMessage._meta_.fields["reason"].type = String;
CapturePreAuthResponseMessage._meta_.fields["message"] = {};
CapturePreAuthResponseMessage._meta_.fields["message"].type = String;
CapturePreAuthResponseMessage._meta_.fields["paymentId"] = {};
CapturePreAuthResponseMessage._meta_.fields["paymentId"].type = String;
CapturePreAuthResponseMessage._meta_.fields["amount"] = {};
CapturePreAuthResponseMessage._meta_.fields["amount"].type = Number;
CapturePreAuthResponseMessage._meta_.fields["tipAmount"] = {};
CapturePreAuthResponseMessage._meta_.fields["tipAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CapturePreAuthResponseMessage;
}

