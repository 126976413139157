import React, { ComponentProps } from "react";
import { ScrollView } from "react-native";
import useDraggableScroll from "../hooks/useDraggableScroll";

export const DraggableScrollView = React.forwardRef<
  ScrollView,
  ComponentProps<typeof ScrollView>
>((props, ref) => {
  const { refs } = useDraggableScroll<ScrollView>({
    outerRef: ref,
    cursor: "grab",
  });
  return <ScrollView ref={refs} {...props} />;
});
