/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_ExternalDeviceState = require("../remotepay/ExternalDeviceState");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");
var remotepay_ExternalDeviceStateData = require("../remotepay/ExternalDeviceStateData");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var InvalidStateTransitionResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = InvalidStateTransitionResponse;
  this.requestedTransition = undefined;
  this.state = undefined;
  this.data = undefined;
};

InvalidStateTransitionResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
InvalidStateTransitionResponse.prototype.constructor = InvalidStateTransitionResponse;

/**
* Set the field value
* The state that Pay Display was unable to transition to.
*
* @memberof remotepay.InvalidStateTransitionResponse
* @param {String} requestedTransition 
*/
InvalidStateTransitionResponse.prototype.setRequestedTransition = function(requestedTransition) {
  this.requestedTransition = requestedTransition;
};

/**
* Get the field value
* The state that Pay Display was unable to transition to.
* @memberof remotepay.InvalidStateTransitionResponse
* @return {String} 
*/
InvalidStateTransitionResponse.prototype.getRequestedTransition = function() {
  return this.requestedTransition;
};

/**
* Set the field value
* High level state of the connected device.
*
* @memberof remotepay.InvalidStateTransitionResponse
* @param {remotepay.ExternalDeviceState} state 
*/
InvalidStateTransitionResponse.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* High level state of the connected device.
* @memberof remotepay.InvalidStateTransitionResponse
* @return {remotepay.ExternalDeviceState} 
*/
InvalidStateTransitionResponse.prototype.getState = function() {
  return this.state;
};

/**
* Set the field value
* Additional data elements supplementing the state of the connected device.
*
* @memberof remotepay.InvalidStateTransitionResponse
* @param {remotepay.ExternalDeviceStateData} data 
*/
InvalidStateTransitionResponse.prototype.setData = function(data) {
  this.data = data;
};

/**
* Get the field value
* Additional data elements supplementing the state of the connected device.
* @memberof remotepay.InvalidStateTransitionResponse
* @return {remotepay.ExternalDeviceStateData} 
*/
InvalidStateTransitionResponse.prototype.getData = function() {
  return this.data;
};

InvalidStateTransitionResponse._meta_ =  {fields:  {}};
InvalidStateTransitionResponse._meta_._class_ =  InvalidStateTransitionResponse;
InvalidStateTransitionResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
InvalidStateTransitionResponse._meta_.fields["requestedTransition"] = {};
InvalidStateTransitionResponse._meta_.fields["requestedTransition"].type = String;
InvalidStateTransitionResponse._meta_.fields["state"] = {};
InvalidStateTransitionResponse._meta_.fields["state"].type = remotepay_ExternalDeviceState;
InvalidStateTransitionResponse._meta_.fields["data"] = {};
InvalidStateTransitionResponse._meta_.fields["data"].type = remotepay_ExternalDeviceStateData;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = InvalidStateTransitionResponse;
}

