/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var TipAdjustResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = TipAdjustResponseMessage;
  this.setMethod(remotemessage_Method["TIP_ADJUST_RESPONSE"]);
  this.success = undefined;
  this.amount = undefined;
  this.orderId = undefined;
  this.message = undefined;
  this.reason = undefined;
  this.paymentId = undefined;
};

TipAdjustResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
TipAdjustResponseMessage.prototype.constructor = TipAdjustResponseMessage;

/**
* Set the field value
* If true the tip adjustment succeeded
*
* @memberof remotemessage.TipAdjustResponseMessage
* @param {Boolean} success 
*/
TipAdjustResponseMessage.prototype.setSuccess = function(success) {
  this.success = success;
};

/**
* Get the field value
* If true the tip adjustment succeeded
* @memberof remotemessage.TipAdjustResponseMessage
* @return {Boolean} 
*/
TipAdjustResponseMessage.prototype.getSuccess = function() {
  return this.success;
};

/**
* Set the field value
* Amount of tip
*
* @memberof remotemessage.TipAdjustResponseMessage
* @param {Number} amount must be a long integer
*/
TipAdjustResponseMessage.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Amount of tip
* @memberof remotemessage.TipAdjustResponseMessage
* @return {Number} must be a long integer
*/
TipAdjustResponseMessage.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* Unique identifier for a order
*
* @memberof remotemessage.TipAdjustResponseMessage
* @param {String} orderId 
*/
TipAdjustResponseMessage.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier for a order
* @memberof remotemessage.TipAdjustResponseMessage
* @return {String} 
*/
TipAdjustResponseMessage.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* @memberof remotemessage.TipAdjustResponseMessage
* @param {String} message 
*/
TipAdjustResponseMessage.prototype.setMessage = function(message) {
  this.message = message;
};

/**
* Get the field value
* @memberof remotemessage.TipAdjustResponseMessage
* @return {String} 
*/
TipAdjustResponseMessage.prototype.getMessage = function() {
  return this.message;
};

/**
* Set the field value
* @memberof remotemessage.TipAdjustResponseMessage
* @param {String} reason 
*/
TipAdjustResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotemessage.TipAdjustResponseMessage
* @return {String} 
*/
TipAdjustResponseMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* Unique identifier for a payment
*
* @memberof remotemessage.TipAdjustResponseMessage
* @param {String} paymentId 
*/
TipAdjustResponseMessage.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier for a payment
* @memberof remotemessage.TipAdjustResponseMessage
* @return {String} 
*/
TipAdjustResponseMessage.prototype.getPaymentId = function() {
  return this.paymentId;
};

TipAdjustResponseMessage._meta_ =  {fields:  {}};
TipAdjustResponseMessage._meta_._class_ =  TipAdjustResponseMessage;
TipAdjustResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
TipAdjustResponseMessage._meta_.fields["success"] = {};
TipAdjustResponseMessage._meta_.fields["success"].type = Boolean;
TipAdjustResponseMessage._meta_.fields["amount"] = {};
TipAdjustResponseMessage._meta_.fields["amount"].type = Number;
TipAdjustResponseMessage._meta_.fields["orderId"] = {};
TipAdjustResponseMessage._meta_.fields["orderId"].type = String;
TipAdjustResponseMessage._meta_.fields["message"] = {};
TipAdjustResponseMessage._meta_.fields["message"].type = String;
TipAdjustResponseMessage._meta_.fields["reason"] = {};
TipAdjustResponseMessage._meta_.fields["reason"].type = String;
TipAdjustResponseMessage._meta_.fields["paymentId"] = {};
TipAdjustResponseMessage._meta_.fields["paymentId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipAdjustResponseMessage;
}

