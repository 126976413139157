/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/** Used to track the origin of a distributed call. */
/**
* @constructor
* @memberof apps
*/
var AppTracking = function() {
  this._class_ = AppTracking;
  this.developerAppId = undefined;
  this.applicationName = undefined;
  this.applicationID = undefined;
  this.applicationVersion = undefined;
  this.sourceSDK = undefined;
  this.sourceSDKVersion = undefined;
};


/**
* Set the field value
* The uuid from the developer application.  This is typically populated and used only on the back end.
*
* @memberof apps.AppTracking
* @param {String} developerAppId 
*/
AppTracking.prototype.setDeveloperAppId = function(developerAppId) {
  this.developerAppId = developerAppId;
};

/**
* Get the field value
* The uuid from the developer application.  This is typically populated and used only on the back end.
* @memberof apps.AppTracking
* @return {String} 
*/
AppTracking.prototype.getDeveloperAppId = function() {
  return this.developerAppId;
};

/**
* Set the field value
* The name of the developer application.
*
* @memberof apps.AppTracking
* @param {String} applicationName 
*/
AppTracking.prototype.setApplicationName = function(applicationName) {
  this.applicationName = applicationName;
};

/**
* Get the field value
* The name of the developer application.
* @memberof apps.AppTracking
* @return {String} 
*/
AppTracking.prototype.getApplicationName = function() {
  return this.applicationName;
};

/**
* Set the field value
* A string representing an application
*
* @memberof apps.AppTracking
* @param {String} applicationID 
*/
AppTracking.prototype.setApplicationID = function(applicationID) {
  this.applicationID = applicationID;
};

/**
* Get the field value
* A string representing an application
* @memberof apps.AppTracking
* @return {String} 
*/
AppTracking.prototype.getApplicationID = function() {
  return this.applicationID;
};

/**
* Set the field value
* A string representing a semanticversion.  See http://semver.org/
*
* @memberof apps.AppTracking
* @param {String} applicationVersion 
*/
AppTracking.prototype.setApplicationVersion = function(applicationVersion) {
  this.applicationVersion = applicationVersion;
};

/**
* Get the field value
* A string representing a semanticversion.  See http://semver.org/
* @memberof apps.AppTracking
* @return {String} 
*/
AppTracking.prototype.getApplicationVersion = function() {
  return this.applicationVersion;
};

/**
* Set the field value
* A string representing a SDK
*
* @memberof apps.AppTracking
* @param {String} sourceSDK 
*/
AppTracking.prototype.setSourceSDK = function(sourceSDK) {
  this.sourceSDK = sourceSDK;
};

/**
* Get the field value
* A string representing a SDK
* @memberof apps.AppTracking
* @return {String} 
*/
AppTracking.prototype.getSourceSDK = function() {
  return this.sourceSDK;
};

/**
* Set the field value
* A string representing a semanticversion.  See http://semver.org/
*
* @memberof apps.AppTracking
* @param {String} sourceSDKVersion 
*/
AppTracking.prototype.setSourceSDKVersion = function(sourceSDKVersion) {
  this.sourceSDKVersion = sourceSDKVersion;
};

/**
* Get the field value
* A string representing a semanticversion.  See http://semver.org/
* @memberof apps.AppTracking
* @return {String} 
*/
AppTracking.prototype.getSourceSDKVersion = function() {
  return this.sourceSDKVersion;
};

/**
* @memberof apps.AppTracking
* @private
*/
AppTracking.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

AppTracking.prototype.toString = function() {
  return JSON.stringify(this);
};

AppTracking._meta_ =  {fields:  {}};
AppTracking._meta_._class_ =  AppTracking;
AppTracking._meta_.fields["developerAppId"] = {};
AppTracking._meta_.fields["developerAppId"].type = String;
AppTracking._meta_.fields["applicationName"] = {};
AppTracking._meta_.fields["applicationName"].type = String;
AppTracking._meta_.fields["applicationID"] = {};
AppTracking._meta_.fields["applicationID"].type = String;
AppTracking._meta_.fields["applicationVersion"] = {};
AppTracking._meta_.fields["applicationVersion"].type = String;
AppTracking._meta_.fields["sourceSDK"] = {};
AppTracking._meta_.fields["sourceSDK"].type = String;
AppTracking._meta_.fields["sourceSDKVersion"] = {};
AppTracking._meta_.fields["sourceSDKVersion"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AppTracking;
}

