/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var inventory_MenuSection = require("../inventory/MenuSection");
var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof inventory
*/
var Category = function() {
  this._class_ = Category;
  this.id = undefined;
  this.name = undefined;
  this.sortOrder = undefined;
  this.items = undefined;
  this.deleted = undefined;
  this.modifiedTime = undefined;
  this.canonical = undefined;
  this.menuSection = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof inventory.Category
* @param {String} id 
*/
Category.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof inventory.Category
* @return {String} 
*/
Category.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* The name of the category which will appear in Clover Register App.
*
* @memberof inventory.Category
* @param {String} name 
*/
Category.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* The name of the category which will appear in Clover Register App.
* @memberof inventory.Category
* @return {String} 
*/
Category.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* Integer used to determine how this category is sorted against other categories.
*
* @memberof inventory.Category
* @param {Null|Number} sortOrder must be an integer
*/
Category.prototype.setSortOrder = function(sortOrder) {
  this.sortOrder = sortOrder;
};

/**
* Get the field value
* Integer used to determine how this category is sorted against other categories.
* @memberof inventory.Category
* @return {Null|Number} must be an integer
*/
Category.prototype.getSortOrder = function() {
  return this.sortOrder;
};

/**
* Set the field value
* Ordered list of items associated with this category.
*
* @memberof inventory.Category
* @param {Null|Array.<base.Reference>} items An array of 
*/
Category.prototype.setItems = function(items) {
  this.items = items;
};

/**
* Get the field value
* Ordered list of items associated with this category.
* @memberof inventory.Category
* @return {Null|Array.<base.Reference>} An array of 
*/
Category.prototype.getItems = function() {
  return this.items;
};

/**
* Set the field value
* Whether the category has been deleted.
*
* @memberof inventory.Category
* @param {Null|Boolean} deleted 
*/
Category.prototype.setDeleted = function(deleted) {
  this.deleted = deleted;
};

/**
* Get the field value
* Whether the category has been deleted.
* @memberof inventory.Category
* @return {Null|Boolean} 
*/
Category.prototype.getDeleted = function() {
  return this.deleted;
};

/**
* Set the field value
* The time this category was last modified
*
* @memberof inventory.Category
* @param {Null|Number} modifiedTime must be a long integer
*/
Category.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* The time this category was last modified
* @memberof inventory.Category
* @return {Null|Number} must be a long integer
*/
Category.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* Reference to canonical category
*
* @memberof inventory.Category
* @param {Null|base.Reference} canonical 
*/
Category.prototype.setCanonical = function(canonical) {
  this.canonical = canonical;
};

/**
* Get the field value
* Reference to canonical category
* @memberof inventory.Category
* @return {Null|base.Reference} 
*/
Category.prototype.getCanonical = function() {
  return this.canonical;
};

/**
* Set the field value
* Menu Section attribute that can be expanded to menu section specific attributes
*
* @memberof inventory.Category
* @param {Null|inventory.MenuSection} menuSection 
*/
Category.prototype.setMenuSection = function(menuSection) {
  this.menuSection = menuSection;
};

/**
* Get the field value
* Menu Section attribute that can be expanded to menu section specific attributes
* @memberof inventory.Category
* @return {Null|inventory.MenuSection} 
*/
Category.prototype.getMenuSection = function() {
  return this.menuSection;
};

/**
* @memberof inventory.Category
* @private
*/
Category.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Category.prototype.toString = function() {
  return JSON.stringify(this);
};

Category._meta_ =  {fields:  {}};
Category._meta_._class_ =  Category;
Category._meta_.fields["id"] = {};
Category._meta_.fields["id"].type = String;
Category._meta_.fields["name"] = {};
Category._meta_.fields["name"].type = String;
Category._meta_.fields["sortOrder"] = {};
Category._meta_.fields["sortOrder"].type = Number;
Category._meta_.fields["items"] = {};
Category._meta_.fields["items"].type = Array;
Category._meta_.fields["items"].elementType = base_Reference;
Category._meta_.fields["deleted"] = {};
Category._meta_.fields["deleted"].type = Boolean;
Category._meta_.fields["modifiedTime"] = {};
Category._meta_.fields["modifiedTime"].type = Number;
Category._meta_.fields["canonical"] = {};
Category._meta_.fields["canonical"].type = base_Reference;
Category._meta_.fields["menuSection"] = {};
Category._meta_.fields["menuSection"].type = inventory_MenuSection;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Category;
}

