import { StyleSheet, Platform } from "react-native";

export const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10,
    marginBottom: 10
  },
  headerTitle: {
    fontSize: 12,
    letterSpacing: 1.25,
    fontWeight: "500"
  },
  dotsColumnContainer: {
    flexDirection: "column"
  },
  dotsRowsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    zIndex: 8,
    elevation: 8,
    alignSelf: 'center',
    marginTop: 10,
    bottom: 0,
  },
  dot: {
    aspectRatio: Platform.OS === "web" ? 3 : 1,
    width: Platform.OS === "web" ? 9 : 10,
    height: Platform.OS === "web" ? 9 : 18,
    marginHorizontal: 2,
    marginVertical: 2,
    borderRadius: 9,
    borderWidth: 1,
    zIndex: 8,
    elevation: 8,
    backgroundColor:  "#D9D9D9",
    borderColor:  "#D9D9D9"
    },
});
