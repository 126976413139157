/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_DataProviderConfig = require("../remotepay/DataProviderConfig");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var CustomerProvidedDataEvent = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = CustomerProvidedDataEvent;
  this.eventId = undefined;
  this.config = undefined;
  this.data = undefined;
};

CustomerProvidedDataEvent.prototype = Object.create(remotepay_BaseResponse.prototype);
CustomerProvidedDataEvent.prototype.constructor = CustomerProvidedDataEvent;

/**
* Set the field value
* An id that represents this collection event
*
* @memberof remotepay.CustomerProvidedDataEvent
* @param {String} eventId 
*/
CustomerProvidedDataEvent.prototype.setEventId = function(eventId) {
  this.eventId = eventId;
};

/**
* Get the field value
* An id that represents this collection event
* @memberof remotepay.CustomerProvidedDataEvent
* @return {String} 
*/
CustomerProvidedDataEvent.prototype.getEventId = function() {
  return this.eventId;
};

/**
* Set the field value
* The configuration that identifies what this event data represents.  This is used when interpreting the data.
*
* @memberof remotepay.CustomerProvidedDataEvent
* @param {remotepay.DataProviderConfig} config 
*/
CustomerProvidedDataEvent.prototype.setConfig = function(config) {
  this.config = config;
};

/**
* Get the field value
* The configuration that identifies what this event data represents.  This is used when interpreting the data.
* @memberof remotepay.CustomerProvidedDataEvent
* @return {remotepay.DataProviderConfig} 
*/
CustomerProvidedDataEvent.prototype.getConfig = function() {
  return this.config;
};

/**
* Set the field value
* The data that was collected.  This is similar to a CLOB.
*
* @memberof remotepay.CustomerProvidedDataEvent
* @param {String} data 
*/
CustomerProvidedDataEvent.prototype.setData = function(data) {
  this.data = data;
};

/**
* Get the field value
* The data that was collected.  This is similar to a CLOB.
* @memberof remotepay.CustomerProvidedDataEvent
* @return {String} 
*/
CustomerProvidedDataEvent.prototype.getData = function() {
  return this.data;
};

CustomerProvidedDataEvent._meta_ =  {fields:  {}};
CustomerProvidedDataEvent._meta_._class_ =  CustomerProvidedDataEvent;
CustomerProvidedDataEvent._meta_._superMeta_ = remotepay_BaseResponse._meta_;
CustomerProvidedDataEvent._meta_.fields["eventId"] = {};
CustomerProvidedDataEvent._meta_.fields["eventId"].type = String;
CustomerProvidedDataEvent._meta_.fields["config"] = {};
CustomerProvidedDataEvent._meta_.fields["config"].type = remotepay_DataProviderConfig;
CustomerProvidedDataEvent._meta_.fields["data"] = {};
CustomerProvidedDataEvent._meta_.fields["data"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CustomerProvidedDataEvent;
}

