/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof hours
*/
var HourRange = function() {
  this._class_ = HourRange;
  this.start = undefined;
  this.end = undefined;
};


/**
* Set the field value
* @memberof hours.HourRange
* @param {Number} start must be an integer
*/
HourRange.prototype.setStart = function(start) {
  this.start = start;
};

/**
* Get the field value
* @memberof hours.HourRange
* @return {Number} must be an integer
*/
HourRange.prototype.getStart = function() {
  return this.start;
};

/**
* Set the field value
* @memberof hours.HourRange
* @param {Number} end must be an integer
*/
HourRange.prototype.setEnd = function(end) {
  this.end = end;
};

/**
* Get the field value
* @memberof hours.HourRange
* @return {Number} must be an integer
*/
HourRange.prototype.getEnd = function() {
  return this.end;
};

/**
* @memberof hours.HourRange
* @private
*/
HourRange.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

HourRange.prototype.toString = function() {
  return JSON.stringify(this);
};

HourRange._meta_ =  {fields:  {}};
HourRange._meta_._class_ =  HourRange;
HourRange._meta_.fields["start"] = {};
HourRange._meta_.fields["start"].type = Number;
HourRange._meta_.fields["end"] = {};
HourRange._meta_.fields["end"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = HourRange;
}

