/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_PayIntent = require("../remotemessage/PayIntent");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var CardDataRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = CardDataRequestMessage;
  this.setMethod(remotemessage_Method["CARD_DATA"]);
  this.payIntent = undefined;
};

CardDataRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
CardDataRequestMessage.prototype.constructor = CardDataRequestMessage;

/**
* Set the field value
* The payIntent
*
* @memberof remotemessage.CardDataRequestMessage
* @param {remotemessage.PayIntent} payIntent 
*/
CardDataRequestMessage.prototype.setPayIntent = function(payIntent) {
  this.payIntent = payIntent;
};

/**
* Get the field value
* The payIntent
* @memberof remotemessage.CardDataRequestMessage
* @return {remotemessage.PayIntent} 
*/
CardDataRequestMessage.prototype.getPayIntent = function() {
  return this.payIntent;
};

CardDataRequestMessage._meta_ =  {fields:  {}};
CardDataRequestMessage._meta_._class_ =  CardDataRequestMessage;
CardDataRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
CardDataRequestMessage._meta_.fields["payIntent"] = {};
CardDataRequestMessage._meta_.fields["payIntent"].type = remotemessage_PayIntent;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardDataRequestMessage;
}

