/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Credit = require("../payments/Credit");

/**
* @constructor
* @memberof remotepay
*/
var PrintManualRefundDeclineReceiptMessage = function() {
  this._class_ = PrintManualRefundDeclineReceiptMessage;
  this.credit = undefined;
  this.reason = undefined;
};


/**
* Set the field value
* @memberof remotepay.PrintManualRefundDeclineReceiptMessage
* @param {payments.Credit} credit 
*/
PrintManualRefundDeclineReceiptMessage.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* @memberof remotepay.PrintManualRefundDeclineReceiptMessage
* @return {payments.Credit} 
*/
PrintManualRefundDeclineReceiptMessage.prototype.getCredit = function() {
  return this.credit;
};

/**
* Set the field value
* @memberof remotepay.PrintManualRefundDeclineReceiptMessage
* @param {String} reason 
*/
PrintManualRefundDeclineReceiptMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotepay.PrintManualRefundDeclineReceiptMessage
* @return {String} 
*/
PrintManualRefundDeclineReceiptMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* @memberof remotepay.PrintManualRefundDeclineReceiptMessage
* @private
*/
PrintManualRefundDeclineReceiptMessage.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PrintManualRefundDeclineReceiptMessage.prototype.toString = function() {
  return JSON.stringify(this);
};

PrintManualRefundDeclineReceiptMessage._meta_ =  {fields:  {}};
PrintManualRefundDeclineReceiptMessage._meta_._class_ =  PrintManualRefundDeclineReceiptMessage;
PrintManualRefundDeclineReceiptMessage._meta_.fields["credit"] = {};
PrintManualRefundDeclineReceiptMessage._meta_.fields["credit"].type = payments_Credit;
PrintManualRefundDeclineReceiptMessage._meta_.fields["reason"] = {};
PrintManualRefundDeclineReceiptMessage._meta_.fields["reason"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PrintManualRefundDeclineReceiptMessage;
}

