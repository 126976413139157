/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");
var merchant_TipSuggestion = require("../merchant/TipSuggestion");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var TipRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = TipRequest;
  this.tipSuggestions = undefined;
  this.tippableAmount = undefined;
};

TipRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
TipRequest.prototype.constructor = TipRequest;

/**
* Set the field value
* @memberof remotepay.TipRequest
* @param {Array.<merchant.TipSuggestion>} tipSuggestions An array of 
*/
TipRequest.prototype.setTipSuggestions = function(tipSuggestions) {
  this.tipSuggestions = tipSuggestions;
};

/**
* Get the field value
* @memberof remotepay.TipRequest
* @return {Array.<merchant.TipSuggestion>} An array of 
*/
TipRequest.prototype.getTipSuggestions = function() {
  return this.tipSuggestions;
};

/**
* Set the field value
* @memberof remotepay.TipRequest
* @param {Number} tippableAmount must be a long integer
*/
TipRequest.prototype.setTippableAmount = function(tippableAmount) {
  this.tippableAmount = tippableAmount;
};

/**
* Get the field value
* @memberof remotepay.TipRequest
* @return {Number} must be a long integer
*/
TipRequest.prototype.getTippableAmount = function() {
  return this.tippableAmount;
};

TipRequest._meta_ =  {fields:  {}};
TipRequest._meta_._class_ =  TipRequest;
TipRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
TipRequest._meta_.fields["tipSuggestions"] = {};
TipRequest._meta_.fields["tipSuggestions"].type = Array;
TipRequest._meta_.fields["tipSuggestions"].elementType = merchant_TipSuggestion;
TipRequest._meta_.fields["tippableAmount"] = {};
TipRequest._meta_.fields["tippableAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipRequest;
}

