/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_VasPushMode = require("../payments/VasPushMode");
var payments_VasMode = require("../payments/VasMode");
var payments_VasDataType = require("../payments/VasDataType");

/**
* @constructor
* @memberof payments
*/
var VasSettings = function() {
  this._class_ = VasSettings;
  this.vasMode = undefined;
  this.serviceTypes = undefined;
  this.extras = undefined;
  this.pushMode = undefined;
};


/**
* Set the field value
* Populated per tx.  If not passed PAY_ONLY is the default behavior
*
* @memberof payments.VasSettings
* @param {payments.VasMode} vasMode 
*/
VasSettings.prototype.setVasMode = function(vasMode) {
  this.vasMode = vasMode;
};

/**
* Get the field value
* Populated per tx.  If not passed PAY_ONLY is the default behavior
* @memberof payments.VasSettings
* @return {payments.VasMode} 
*/
VasSettings.prototype.getVasMode = function() {
  return this.vasMode;
};

/**
* Set the field value
* Vas service types of interest for this txn
*
* @memberof payments.VasSettings
* @param {Array.<payments.VasDataType>} serviceTypes An array of 
*/
VasSettings.prototype.setServiceTypes = function(serviceTypes) {
  this.serviceTypes = serviceTypes;
};

/**
* Get the field value
* Vas service types of interest for this txn
* @memberof payments.VasSettings
* @return {Array.<payments.VasDataType>} An array of 
*/
VasSettings.prototype.getServiceTypes = function() {
  return this.serviceTypes;
};

/**
* Set the field value
* Additional context relevant extras such as EXTRA_ORDER_ID
*
* @memberof payments.VasSettings
* @param {Object.<String,String>} extras A map of <String> to <>
*/
VasSettings.prototype.setExtras = function(extras) {
  this.extras = extras;
};

/**
* Get the field value
* Additional context relevant extras such as EXTRA_ORDER_ID
* @memberof payments.VasSettings
* @return {Object.<String,String>} A map of <String> to <>
*/
VasSettings.prototype.getExtras = function() {
  return this.extras;
};

/**
* Set the field value
* Indicates if vas is push url only, no push, or push in addition to GET
*
* @memberof payments.VasSettings
* @param {payments.VasPushMode} pushMode 
*/
VasSettings.prototype.setPushMode = function(pushMode) {
  this.pushMode = pushMode;
};

/**
* Get the field value
* Indicates if vas is push url only, no push, or push in addition to GET
* @memberof payments.VasSettings
* @return {payments.VasPushMode} 
*/
VasSettings.prototype.getPushMode = function() {
  return this.pushMode;
};

/**
* @memberof payments.VasSettings
* @private
*/
VasSettings.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

VasSettings.prototype.toString = function() {
  return JSON.stringify(this);
};

VasSettings._meta_ =  {fields:  {}};
VasSettings._meta_._class_ =  VasSettings;
VasSettings._meta_.fields["vasMode"] = {};
VasSettings._meta_.fields["vasMode"].type = payments_VasMode;
VasSettings._meta_.fields["serviceTypes"] = {};
VasSettings._meta_.fields["serviceTypes"].type = Array;
VasSettings._meta_.fields["serviceTypes"].elementType = payments_VasDataType;
VasSettings._meta_.fields["extras"] = {};
VasSettings._meta_.fields["extras"].type = Object;
VasSettings._meta_.fields["pushMode"] = {};
VasSettings._meta_.fields["pushMode"].type = payments_VasPushMode;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasSettings;
}

