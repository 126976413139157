/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var ecomm_EcommerceIndicator = require("../ecomm/EcommerceIndicator");
var tokens_TokenType = require("../tokens/TokenType");

/**
* @constructor
* @memberof payments
*/
var ECommTransactionInfo = function() {
  this._class_ = ECommTransactionInfo;
  this.eCommTransaction = undefined;
  this.credentialOnFile = undefined;
  this.tokenType = undefined;
  this.ecommIndicator = undefined;
};


/**
* Set the field value
* Set to True when it is an Ecomm Transaction
*
* @memberof payments.ECommTransactionInfo
* @param {Boolean} eCommTransaction 
*/
ECommTransactionInfo.prototype.setECommTransaction = function(eCommTransaction) {
  this.eCommTransaction = eCommTransaction;
};

/**
* Get the field value
* Set to True when it is an Ecomm Transaction
* @memberof payments.ECommTransactionInfo
* @return {Boolean} 
*/
ECommTransactionInfo.prototype.getECommTransaction = function() {
  return this.eCommTransaction;
};

/**
* Set the field value
* Set to true when Card On File is used for the transaction
*
* @memberof payments.ECommTransactionInfo
* @param {Boolean} credentialOnFile 
*/
ECommTransactionInfo.prototype.setCredentialOnFile = function(credentialOnFile) {
  this.credentialOnFile = credentialOnFile;
};

/**
* Get the field value
* Set to true when Card On File is used for the transaction
* @memberof payments.ECommTransactionInfo
* @return {Boolean} 
*/
ECommTransactionInfo.prototype.getCredentialOnFile = function() {
  return this.credentialOnFile;
};

/**
* Set the field value
* @memberof payments.ECommTransactionInfo
* @param {tokens.TokenType} tokenType 
*/
ECommTransactionInfo.prototype.setTokenType = function(tokenType) {
  this.tokenType = tokenType;
};

/**
* Get the field value
* @memberof payments.ECommTransactionInfo
* @return {tokens.TokenType} 
*/
ECommTransactionInfo.prototype.getTokenType = function() {
  return this.tokenType;
};

/**
* Set the field value
* @memberof payments.ECommTransactionInfo
* @param {ecomm.EcommerceIndicator} ecommIndicator 
*/
ECommTransactionInfo.prototype.setEcommIndicator = function(ecommIndicator) {
  this.ecommIndicator = ecommIndicator;
};

/**
* Get the field value
* @memberof payments.ECommTransactionInfo
* @return {ecomm.EcommerceIndicator} 
*/
ECommTransactionInfo.prototype.getEcommIndicator = function() {
  return this.ecommIndicator;
};

/**
* @memberof payments.ECommTransactionInfo
* @private
*/
ECommTransactionInfo.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

ECommTransactionInfo.prototype.toString = function() {
  return JSON.stringify(this);
};

ECommTransactionInfo._meta_ =  {fields:  {}};
ECommTransactionInfo._meta_._class_ =  ECommTransactionInfo;
ECommTransactionInfo._meta_.fields["eCommTransaction"] = {};
ECommTransactionInfo._meta_.fields["eCommTransaction"].type = Boolean;
ECommTransactionInfo._meta_.fields["credentialOnFile"] = {};
ECommTransactionInfo._meta_.fields["credentialOnFile"].type = Boolean;
ECommTransactionInfo._meta_.fields["tokenType"] = {};
ECommTransactionInfo._meta_.fields["tokenType"].type = tokens_TokenType;
ECommTransactionInfo._meta_.fields["ecommIndicator"] = {};
ECommTransactionInfo._meta_.fields["ecommIndicator"].type = ecomm_EcommerceIndicator;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ECommTransactionInfo;
}

