/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof hours
* @enum {string}
*/
var ReferenceType = {
  ORDER_TYPE : "ORDER_TYPE",
  ITEM_GROUP : "ITEM_GROUP",
  SOFTWARE_UPDATE_EXCLUSION : "SOFTWARE_UPDATE_EXCLUSION",
  ONLINE_ORDER_SERVICE : "ONLINE_ORDER_SERVICE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ReferenceType;
}

