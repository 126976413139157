/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var PairingState = {
  UNINITIALIZED : "UNINITIALIZED",
  PAIRED : "PAIRED",
  FAILED : "FAILED",
  INITIAL : "INITIAL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PairingState;
}

