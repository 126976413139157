/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var BreakMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = BreakMessage;
  this.setMethod(remotemessage_Method["BREAK"]);
};

BreakMessage.prototype = Object.create(remotemessage_Message.prototype);
BreakMessage.prototype.constructor = BreakMessage;

BreakMessage._meta_ =  {fields:  {}};
BreakMessage._meta_._class_ =  BreakMessage;
BreakMessage._meta_._superMeta_ = remotemessage_Message._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = BreakMessage;
}

