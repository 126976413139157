/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");
var remotepay_CustomerInfo = require("../remotepay/CustomerInfo");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var SetCustomerInfoRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = SetCustomerInfoRequest;
  this.customerInfo = undefined;
};

SetCustomerInfoRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
SetCustomerInfoRequest.prototype.constructor = SetCustomerInfoRequest;

/**
* Set the field value
* Customer information  for a 'current' customer.  This indicates a customer of interest.
*
* @memberof remotepay.SetCustomerInfoRequest
* @param {remotepay.CustomerInfo} customerInfo 
*/
SetCustomerInfoRequest.prototype.setCustomerInfo = function(customerInfo) {
  this.customerInfo = customerInfo;
};

/**
* Get the field value
* Customer information  for a 'current' customer.  This indicates a customer of interest.
* @memberof remotepay.SetCustomerInfoRequest
* @return {remotepay.CustomerInfo} 
*/
SetCustomerInfoRequest.prototype.getCustomerInfo = function() {
  return this.customerInfo;
};

SetCustomerInfoRequest._meta_ =  {fields:  {}};
SetCustomerInfoRequest._meta_._class_ =  SetCustomerInfoRequest;
SetCustomerInfoRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
SetCustomerInfoRequest._meta_.fields["customerInfo"] = {};
SetCustomerInfoRequest._meta_.fields["customerInfo"].type = remotepay_CustomerInfo;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = SetCustomerInfoRequest;
}

