/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/** Used in loyalty systems. */
/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var LoyaltyPointsCalculatedMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = LoyaltyPointsCalculatedMessage;
  this.setMethod(remotemessage_Method["SHOW_LOYALTY_POINTS_CALCULATED"]);
  this.loyaltyUuid = undefined;
  this.points = undefined;
  this.orderId = undefined;
};

LoyaltyPointsCalculatedMessage.prototype = Object.create(remotemessage_Message.prototype);
LoyaltyPointsCalculatedMessage.prototype.constructor = LoyaltyPointsCalculatedMessage;

/**
* Set the field value
* The id for the loyalty entity (likely a customer id of some type)
*
* @memberof remotemessage.LoyaltyPointsCalculatedMessage
* @param {String} loyaltyUuid 
*/
LoyaltyPointsCalculatedMessage.prototype.setLoyaltyUuid = function(loyaltyUuid) {
  this.loyaltyUuid = loyaltyUuid;
};

/**
* Get the field value
* The id for the loyalty entity (likely a customer id of some type)
* @memberof remotemessage.LoyaltyPointsCalculatedMessage
* @return {String} 
*/
LoyaltyPointsCalculatedMessage.prototype.getLoyaltyUuid = function() {
  return this.loyaltyUuid;
};

/**
* Set the field value
* The number of points calculated for the event
*
* @memberof remotemessage.LoyaltyPointsCalculatedMessage
* @param {Number} points must be a long integer
*/
LoyaltyPointsCalculatedMessage.prototype.setPoints = function(points) {
  this.points = points;
};

/**
* Get the field value
* The number of points calculated for the event
* @memberof remotemessage.LoyaltyPointsCalculatedMessage
* @return {Number} must be a long integer
*/
LoyaltyPointsCalculatedMessage.prototype.getPoints = function() {
  return this.points;
};

/**
* Set the field value
* The order with which the awarded are associated
*
* @memberof remotemessage.LoyaltyPointsCalculatedMessage
* @param {String} orderId 
*/
LoyaltyPointsCalculatedMessage.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* The order with which the awarded are associated
* @memberof remotemessage.LoyaltyPointsCalculatedMessage
* @return {String} 
*/
LoyaltyPointsCalculatedMessage.prototype.getOrderId = function() {
  return this.orderId;
};

LoyaltyPointsCalculatedMessage._meta_ =  {fields:  {}};
LoyaltyPointsCalculatedMessage._meta_._class_ =  LoyaltyPointsCalculatedMessage;
LoyaltyPointsCalculatedMessage._meta_._superMeta_ = remotemessage_Message._meta_;
LoyaltyPointsCalculatedMessage._meta_.fields["loyaltyUuid"] = {};
LoyaltyPointsCalculatedMessage._meta_.fields["loyaltyUuid"].type = String;
LoyaltyPointsCalculatedMessage._meta_.fields["points"] = {};
LoyaltyPointsCalculatedMessage._meta_.fields["points"].type = Number;
LoyaltyPointsCalculatedMessage._meta_.fields["orderId"] = {};
LoyaltyPointsCalculatedMessage._meta_.fields["orderId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = LoyaltyPointsCalculatedMessage;
}

