/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof inventory
* @enum {string}
*/
var TaxType = {
  VAT_TAXABLE : "VAT_TAXABLE",
  VAT_NON_TAXABLE : "VAT_NON_TAXABLE",
  VAT_EXEMPT : "VAT_EXEMPT",
  INTERNAL_TAX : "INTERNAL_TAX"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TaxType;
}

