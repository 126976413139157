import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  btn: {
    width: 350,
    height: 68,
    borderRadius: 12,
    marginBottom: 20,
    alignSelf: "center",
  },
  btnText: {
    color: "#FFF",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: "500"
  },
  icon: {
    fontSize: 20,
    color: "#FFF",
    marginRight: 15,
    position: "absolute",
    left: 30,
  },
});
