/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var device_DeviceAccessoryBuildType = require("../device/DeviceAccessoryBuildType");
var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof device
*/
var DeviceAccessory = function() {
  this._class_ = DeviceAccessory;
  this.id = undefined;
  this.model = undefined;
  this.serial = undefined;
  this.associatedDevice = undefined;
  this.pedCertificate = undefined;
  this.buildType = undefined;
  this.deviceType = undefined;
  this.versionCode = undefined;
  this.currentRom = undefined;
  this.secureReports = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof device.DeviceAccessory
* @param {String} id 
*/
DeviceAccessory.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof device.DeviceAccessory
* @return {String} 
*/
DeviceAccessory.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {String} model 
*/
DeviceAccessory.prototype.setModel = function(model) {
  this.model = model;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {String} 
*/
DeviceAccessory.prototype.getModel = function() {
  return this.model;
};

/**
* Set the field value
* The accessories' serial number.
*
* @memberof device.DeviceAccessory
* @param {String} serial 
*/
DeviceAccessory.prototype.setSerial = function(serial) {
  this.serial = serial;
};

/**
* Get the field value
* The accessories' serial number.
* @memberof device.DeviceAccessory
* @return {String} 
*/
DeviceAccessory.prototype.getSerial = function() {
  return this.serial;
};

/**
* Set the field value
* The device that this accessory is currently connected to, a 128-bit UUID, not a normal base-13 Clover ID.
*
* @memberof device.DeviceAccessory
* @param {base.Reference} associatedDevice 
*/
DeviceAccessory.prototype.setAssociatedDevice = function(associatedDevice) {
  this.associatedDevice = associatedDevice;
};

/**
* Get the field value
* The device that this accessory is currently connected to, a 128-bit UUID, not a normal base-13 Clover ID.
* @memberof device.DeviceAccessory
* @return {base.Reference} 
*/
DeviceAccessory.prototype.getAssociatedDevice = function() {
  return this.associatedDevice;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {String} pedCertificate 
*/
DeviceAccessory.prototype.setPedCertificate = function(pedCertificate) {
  this.pedCertificate = pedCertificate;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {String} 
*/
DeviceAccessory.prototype.getPedCertificate = function() {
  return this.pedCertificate;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {device.DeviceAccessoryBuildType} buildType 
*/
DeviceAccessory.prototype.setBuildType = function(buildType) {
  this.buildType = buildType;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {device.DeviceAccessoryBuildType} 
*/
DeviceAccessory.prototype.getBuildType = function() {
  return this.buildType;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {base.Reference} deviceType 
*/
DeviceAccessory.prototype.setDeviceType = function(deviceType) {
  this.deviceType = deviceType;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {base.Reference} 
*/
DeviceAccessory.prototype.getDeviceType = function() {
  return this.deviceType;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {Number} versionCode must be a long integer
*/
DeviceAccessory.prototype.setVersionCode = function(versionCode) {
  this.versionCode = versionCode;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {Number} must be a long integer
*/
DeviceAccessory.prototype.getVersionCode = function() {
  return this.versionCode;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {base.Reference} currentRom 
*/
DeviceAccessory.prototype.setCurrentRom = function(currentRom) {
  this.currentRom = currentRom;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {base.Reference} 
*/
DeviceAccessory.prototype.getCurrentRom = function() {
  return this.currentRom;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {Array.<base.Reference>} secureReports An array of 
*/
DeviceAccessory.prototype.setSecureReports = function(secureReports) {
  this.secureReports = secureReports;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {Array.<base.Reference>} An array of 
*/
DeviceAccessory.prototype.getSecureReports = function() {
  return this.secureReports;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {Number} createdTime must be a long integer
*/
DeviceAccessory.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {Number} must be a long integer
*/
DeviceAccessory.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* @memberof device.DeviceAccessory
* @param {Number} modifiedTime must be a long integer
*/
DeviceAccessory.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* @memberof device.DeviceAccessory
* @return {Number} must be a long integer
*/
DeviceAccessory.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* @memberof device.DeviceAccessory
* @private
*/
DeviceAccessory.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DeviceAccessory.prototype.toString = function() {
  return JSON.stringify(this);
};

DeviceAccessory._meta_ =  {fields:  {}};
DeviceAccessory._meta_._class_ =  DeviceAccessory;
DeviceAccessory._meta_.fields["id"] = {};
DeviceAccessory._meta_.fields["id"].type = String;
DeviceAccessory._meta_.fields["model"] = {};
DeviceAccessory._meta_.fields["model"].type = String;
DeviceAccessory._meta_.fields["serial"] = {};
DeviceAccessory._meta_.fields["serial"].type = String;
DeviceAccessory._meta_.fields["associatedDevice"] = {};
DeviceAccessory._meta_.fields["associatedDevice"].type = base_Reference;
DeviceAccessory._meta_.fields["pedCertificate"] = {};
DeviceAccessory._meta_.fields["pedCertificate"].type = String;
DeviceAccessory._meta_.fields["buildType"] = {};
DeviceAccessory._meta_.fields["buildType"].type = device_DeviceAccessoryBuildType;
DeviceAccessory._meta_.fields["deviceType"] = {};
DeviceAccessory._meta_.fields["deviceType"].type = base_Reference;
DeviceAccessory._meta_.fields["versionCode"] = {};
DeviceAccessory._meta_.fields["versionCode"].type = Number;
DeviceAccessory._meta_.fields["currentRom"] = {};
DeviceAccessory._meta_.fields["currentRom"].type = base_Reference;
DeviceAccessory._meta_.fields["secureReports"] = {};
DeviceAccessory._meta_.fields["secureReports"].type = Array;
DeviceAccessory._meta_.fields["secureReports"].elementType = base_Reference;
DeviceAccessory._meta_.fields["createdTime"] = {};
DeviceAccessory._meta_.fields["createdTime"].type = Number;
DeviceAccessory._meta_.fields["modifiedTime"] = {};
DeviceAccessory._meta_.fields["modifiedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeviceAccessory;
}

