import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  itemContainer: {
    width: "50%",
  },
  itemMediumContainer: {
    width: "25%",
  },
  image: {
    width: "100%",
    height: 200,
  },
  imageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  imageTextContainer: {
    maxWidth: "90%",
    backgroundColor: "#000",
    opacity: 0.65,
  },
  imageText: {
    color: "#fff",
    fontSize: 12,
  },
});
