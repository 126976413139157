/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Payment = require("../payments/Payment");
var payments_Refund = require("../payments/Refund");

/**
* @constructor
* @memberof payments
*/
var RefundResponse = function() {
  this._class_ = RefundResponse;
  this.requestSuccessful = undefined;
  this.responseErrorMessage = undefined;
  this.refund = undefined;
  this.payment = undefined;
  this.extra = undefined;
};


/**
* Set the field value
* @memberof payments.RefundResponse
* @param {Boolean} requestSuccessful 
*/
RefundResponse.prototype.setRequestSuccessful = function(requestSuccessful) {
  this.requestSuccessful = requestSuccessful;
};

/**
* Get the field value
* @memberof payments.RefundResponse
* @return {Boolean} 
*/
RefundResponse.prototype.getRequestSuccessful = function() {
  return this.requestSuccessful;
};

/**
* Set the field value
* @memberof payments.RefundResponse
* @param {String|Null} responseErrorMessage 
*/
RefundResponse.prototype.setResponseErrorMessage = function(responseErrorMessage) {
  this.responseErrorMessage = responseErrorMessage;
};

/**
* Get the field value
* @memberof payments.RefundResponse
* @return {String|Null} 
*/
RefundResponse.prototype.getResponseErrorMessage = function() {
  return this.responseErrorMessage;
};

/**
* Set the field value
* @memberof payments.RefundResponse
* @param {payments.Refund|Null} refund 
*/
RefundResponse.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* @memberof payments.RefundResponse
* @return {payments.Refund|Null} 
*/
RefundResponse.prototype.getRefund = function() {
  return this.refund;
};

/**
* Set the field value
* @memberof payments.RefundResponse
* @param {payments.Payment|Null} payment 
*/
RefundResponse.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof payments.RefundResponse
* @return {payments.Payment|Null} 
*/
RefundResponse.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* extra stuff
*
* @memberof payments.RefundResponse
* @param {Object.<String,String>} extra A map of <String> to <>
*/
RefundResponse.prototype.setExtra = function(extra) {
  this.extra = extra;
};

/**
* Get the field value
* extra stuff
* @memberof payments.RefundResponse
* @return {Object.<String,String>} A map of <String> to <>
*/
RefundResponse.prototype.getExtra = function() {
  return this.extra;
};

/**
* @memberof payments.RefundResponse
* @private
*/
RefundResponse.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

RefundResponse.prototype.toString = function() {
  return JSON.stringify(this);
};

RefundResponse._meta_ =  {fields:  {}};
RefundResponse._meta_._class_ =  RefundResponse;
RefundResponse._meta_.fields["requestSuccessful"] = {};
RefundResponse._meta_.fields["requestSuccessful"].type = Boolean;
RefundResponse._meta_.fields["responseErrorMessage"] = {};
RefundResponse._meta_.fields["responseErrorMessage"].type = String;
RefundResponse._meta_.fields["refund"] = {};
RefundResponse._meta_.fields["refund"].type = payments_Refund;
RefundResponse._meta_.fields["payment"] = {};
RefundResponse._meta_.fields["payment"].type = payments_Payment;
RefundResponse._meta_.fields["extra"] = {};
RefundResponse._meta_.fields["extra"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RefundResponse;
}

