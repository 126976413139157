/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof customers
*/
var EmailAddress = function() {
  this._class_ = EmailAddress;
  this.id = undefined;
  this.emailAddress = undefined;
  this.verifiedTime = undefined;
  this.primaryEmail = undefined;
};


/**
* Set the field value
* @memberof customers.EmailAddress
* @param {String} id 
*/
EmailAddress.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* @memberof customers.EmailAddress
* @return {String} 
*/
EmailAddress.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof customers.EmailAddress
* @param {String} emailAddress 
*/
EmailAddress.prototype.setEmailAddress = function(emailAddress) {
  this.emailAddress = emailAddress;
};

/**
* Get the field value
* @memberof customers.EmailAddress
* @return {String} 
*/
EmailAddress.prototype.getEmailAddress = function() {
  return this.emailAddress;
};

/**
* Set the field value
* @memberof customers.EmailAddress
* @param {Number} verifiedTime must be a long integer
*/
EmailAddress.prototype.setVerifiedTime = function(verifiedTime) {
  this.verifiedTime = verifiedTime;
};

/**
* Get the field value
* @memberof customers.EmailAddress
* @return {Number} must be a long integer
*/
EmailAddress.prototype.getVerifiedTime = function() {
  return this.verifiedTime;
};

/**
* Set the field value
* @memberof customers.EmailAddress
* @param {Boolean} primaryEmail 
*/
EmailAddress.prototype.setPrimaryEmail = function(primaryEmail) {
  this.primaryEmail = primaryEmail;
};

/**
* Get the field value
* @memberof customers.EmailAddress
* @return {Boolean} 
*/
EmailAddress.prototype.getPrimaryEmail = function() {
  return this.primaryEmail;
};

/**
* @memberof customers.EmailAddress
* @private
*/
EmailAddress.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

EmailAddress.prototype.toString = function() {
  return JSON.stringify(this);
};

EmailAddress._meta_ =  {fields:  {}};
EmailAddress._meta_._class_ =  EmailAddress;
EmailAddress._meta_.fields["id"] = {};
EmailAddress._meta_.fields["id"].type = String;
EmailAddress._meta_.fields["emailAddress"] = {};
EmailAddress._meta_.fields["emailAddress"].type = String;
EmailAddress._meta_.fields["verifiedTime"] = {};
EmailAddress._meta_.fields["verifiedTime"].type = Number;
EmailAddress._meta_.fields["primaryEmail"] = {};
EmailAddress._meta_.fields["primaryEmail"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = EmailAddress;
}

