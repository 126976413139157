/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof order
*/
var DisplayPayment = function() {
  this._class_ = DisplayPayment;
  this.id = undefined;
  this.label = undefined;
  this.amount = undefined;
  this.tipAmount = undefined;
  this.taxAmount = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof order.DisplayPayment
* @param {String} id 
*/
DisplayPayment.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof order.DisplayPayment
* @return {String} 
*/
DisplayPayment.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Formatted display string for the tender e.g. credit card, cash, etc.
*
* @memberof order.DisplayPayment
* @param {String} label 
*/
DisplayPayment.prototype.setLabel = function(label) {
  this.label = label;
};

/**
* Get the field value
* Formatted display string for the tender e.g. credit card, cash, etc.
* @memberof order.DisplayPayment
* @return {String} 
*/
DisplayPayment.prototype.getLabel = function() {
  return this.label;
};

/**
* Set the field value
* Formatted total amount paid
*
* @memberof order.DisplayPayment
* @param {String} amount 
*/
DisplayPayment.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Formatted total amount paid
* @memberof order.DisplayPayment
* @return {String} 
*/
DisplayPayment.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* Formatted amount paid in tips
*
* @memberof order.DisplayPayment
* @param {String|Null} tipAmount 
*/
DisplayPayment.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Formatted amount paid in tips
* @memberof order.DisplayPayment
* @return {String|Null} 
*/
DisplayPayment.prototype.getTipAmount = function() {
  return this.tipAmount;
};

/**
* Set the field value
* Formatted amount paid in tax
*
* @memberof order.DisplayPayment
* @param {Number|Null} taxAmount must be a long integer, 
*/
DisplayPayment.prototype.setTaxAmount = function(taxAmount) {
  this.taxAmount = taxAmount;
};

/**
* Get the field value
* Formatted amount paid in tax
* @memberof order.DisplayPayment
* @return {Number|Null} must be a long integer, 
*/
DisplayPayment.prototype.getTaxAmount = function() {
  return this.taxAmount;
};

/**
* @memberof order.DisplayPayment
* @private
*/
DisplayPayment.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DisplayPayment.prototype.toString = function() {
  return JSON.stringify(this);
};

DisplayPayment._meta_ =  {fields:  {}};
DisplayPayment._meta_._class_ =  DisplayPayment;
DisplayPayment._meta_.fields["id"] = {};
DisplayPayment._meta_.fields["id"].type = String;
DisplayPayment._meta_.fields["label"] = {};
DisplayPayment._meta_.fields["label"].type = String;
DisplayPayment._meta_.fields["amount"] = {};
DisplayPayment._meta_.fields["amount"].type = String;
DisplayPayment._meta_.fields["tipAmount"] = {};
DisplayPayment._meta_.fields["tipAmount"].type = String;
DisplayPayment._meta_.fields["taxAmount"] = {};
DisplayPayment._meta_.fields["taxAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DisplayPayment;
}

