/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof ecomm
* @enum {string}
*/
var EcommerceIndicator = {
  ecom : "ecom",
  moto : "moto",
  recurring : "recurring"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = EcommerceIndicator;
}

