/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_ResultStatus = require("../remotemessage/ResultStatus");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var RequestTipResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = RequestTipResponseMessage;
  this.setMethod(remotemessage_Method["REQUEST_TIP_RESPONSE"]);
  this.amount = undefined;
  this.status = undefined;
  this.reason = undefined;
};

RequestTipResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
RequestTipResponseMessage.prototype.constructor = RequestTipResponseMessage;

/**
* Set the field value
* @memberof remotemessage.RequestTipResponseMessage
* @param {Number} amount must be a long integer
*/
RequestTipResponseMessage.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* @memberof remotemessage.RequestTipResponseMessage
* @return {Number} must be a long integer
*/
RequestTipResponseMessage.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* @memberof remotemessage.RequestTipResponseMessage
* @param {remotemessage.ResultStatus} status 
*/
RequestTipResponseMessage.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* @memberof remotemessage.RequestTipResponseMessage
* @return {remotemessage.ResultStatus} 
*/
RequestTipResponseMessage.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* @memberof remotemessage.RequestTipResponseMessage
* @param {String} reason 
*/
RequestTipResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotemessage.RequestTipResponseMessage
* @return {String} 
*/
RequestTipResponseMessage.prototype.getReason = function() {
  return this.reason;
};

RequestTipResponseMessage._meta_ =  {fields:  {}};
RequestTipResponseMessage._meta_._class_ =  RequestTipResponseMessage;
RequestTipResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
RequestTipResponseMessage._meta_.fields["amount"] = {};
RequestTipResponseMessage._meta_.fields["amount"].type = Number;
RequestTipResponseMessage._meta_.fields["status"] = {};
RequestTipResponseMessage._meta_.fields["status"].type = remotemessage_ResultStatus;
RequestTipResponseMessage._meta_.fields["reason"] = {};
RequestTipResponseMessage._meta_.fields["reason"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RequestTipResponseMessage;
}

