/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof base
* @enum {string}
*/
var ApprovalStatus = {
  NEW : "NEW",
  PENDING : "PENDING",
  APPROVED_PENDING_SIGNING : "APPROVED_PENDING_SIGNING",
  APPROVED : "APPROVED",
  DENIED : "DENIED",
  PUBLISHED : "PUBLISHED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ApprovalStatus;
}

