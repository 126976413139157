/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_BatchCardTotal = require("../payments/BatchCardTotal");

/**
* @constructor
* @memberof payments
*/
var EndpointTotalStats = function() {
  this._class_ = EndpointTotalStats;
  this.success = undefined;
  this.endpointName = undefined;
  this.batchNumber = undefined;
  this.terminalId = undefined;
  this.count = undefined;
  this.total = undefined;
  this.cardTotals = undefined;
};


/**
* Set the field value
* Indicates if the closeout for terminalId/endpointName/batchNumber was successfully performed or not
*
* @memberof payments.EndpointTotalStats
* @param {Boolean} success 
*/
EndpointTotalStats.prototype.setSuccess = function(success) {
  this.success = success;
};

/**
* Get the field value
* Indicates if the closeout for terminalId/endpointName/batchNumber was successfully performed or not
* @memberof payments.EndpointTotalStats
* @return {Boolean} 
*/
EndpointTotalStats.prototype.getSuccess = function() {
  return this.success;
};

/**
* Set the field value
* Name for the given endpoint
*
* @memberof payments.EndpointTotalStats
* @param {String} endpointName 
*/
EndpointTotalStats.prototype.setEndpointName = function(endpointName) {
  this.endpointName = endpointName;
};

/**
* Get the field value
* Name for the given endpoint
* @memberof payments.EndpointTotalStats
* @return {String} 
*/
EndpointTotalStats.prototype.getEndpointName = function() {
  return this.endpointName;
};

/**
* Set the field value
* The batchNumber for this endpoint
*
* @memberof payments.EndpointTotalStats
* @param {String} batchNumber 
*/
EndpointTotalStats.prototype.setBatchNumber = function(batchNumber) {
  this.batchNumber = batchNumber;
};

/**
* Get the field value
* The batchNumber for this endpoint
* @memberof payments.EndpointTotalStats
* @return {String} 
*/
EndpointTotalStats.prototype.getBatchNumber = function() {
  return this.batchNumber;
};

/**
* Set the field value
* The terminal id for this endpoint & batch details
*
* @memberof payments.EndpointTotalStats
* @param {String} terminalId 
*/
EndpointTotalStats.prototype.setTerminalId = function(terminalId) {
  this.terminalId = terminalId;
};

/**
* Get the field value
* The terminal id for this endpoint & batch details
* @memberof payments.EndpointTotalStats
* @return {String} 
*/
EndpointTotalStats.prototype.getTerminalId = function() {
  return this.terminalId;
};

/**
* Set the field value
* Total count of endpoint transactions
*
* @memberof payments.EndpointTotalStats
* @param {Number} count must be a long integer
*/
EndpointTotalStats.prototype.setCount = function(count) {
  this.count = count;
};

/**
* Get the field value
* Total count of endpoint transactions
* @memberof payments.EndpointTotalStats
* @return {Number} must be a long integer
*/
EndpointTotalStats.prototype.getCount = function() {
  return this.count;
};

/**
* Set the field value
* Total amount for transactions
*
* @memberof payments.EndpointTotalStats
* @param {Number} total must be a long integer
*/
EndpointTotalStats.prototype.setTotal = function(total) {
  this.total = total;
};

/**
* Get the field value
* Total amount for transactions
* @memberof payments.EndpointTotalStats
* @return {Number} must be a long integer
*/
EndpointTotalStats.prototype.getTotal = function() {
  return this.total;
};

/**
* Set the field value
* @memberof payments.EndpointTotalStats
* @param {Array.<payments.BatchCardTotal>} cardTotals An array of 
*/
EndpointTotalStats.prototype.setCardTotals = function(cardTotals) {
  this.cardTotals = cardTotals;
};

/**
* Get the field value
* @memberof payments.EndpointTotalStats
* @return {Array.<payments.BatchCardTotal>} An array of 
*/
EndpointTotalStats.prototype.getCardTotals = function() {
  return this.cardTotals;
};

/**
* @memberof payments.EndpointTotalStats
* @private
*/
EndpointTotalStats.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

EndpointTotalStats.prototype.toString = function() {
  return JSON.stringify(this);
};

EndpointTotalStats._meta_ =  {fields:  {}};
EndpointTotalStats._meta_._class_ =  EndpointTotalStats;
EndpointTotalStats._meta_.fields["success"] = {};
EndpointTotalStats._meta_.fields["success"].type = Boolean;
EndpointTotalStats._meta_.fields["endpointName"] = {};
EndpointTotalStats._meta_.fields["endpointName"].type = String;
EndpointTotalStats._meta_.fields["batchNumber"] = {};
EndpointTotalStats._meta_.fields["batchNumber"].type = String;
EndpointTotalStats._meta_.fields["terminalId"] = {};
EndpointTotalStats._meta_.fields["terminalId"].type = String;
EndpointTotalStats._meta_.fields["count"] = {};
EndpointTotalStats._meta_.fields["count"].type = Number;
EndpointTotalStats._meta_.fields["total"] = {};
EndpointTotalStats._meta_.fields["total"].type = Number;
EndpointTotalStats._meta_.fields["cardTotals"] = {};
EndpointTotalStats._meta_.fields["cardTotals"].type = Array;
EndpointTotalStats._meta_.fields["cardTotals"].elementType = payments_BatchCardTotal;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = EndpointTotalStats;
}

