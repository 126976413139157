/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof base
* @enum {string}
*/
var BusinessTypeCode = {
  FULL_SERVICE_RESTAURANT : "FULL_SERVICE_RESTAURANT",
  QUICK_SERVICE_RESTAURANT : "QUICK_SERVICE_RESTAURANT",
  COFFEE_SHOP : "COFFEE_SHOP",
  BAKERY : "BAKERY",
  BAR : "BAR",
  FOOD_TRUCK : "FOOD_TRUCK",
  FROZEN_YOGURT : "FROZEN_YOGURT",
  PIZZA : "PIZZA",
  DELI : "DELI",
  OTHER_RESTAURANT : "OTHER_RESTAURANT",
  BOUTIQUE : "BOUTIQUE",
  CONVENIENCE_STORE : "CONVENIENCE_STORE",
  GROCERY : "GROCERY",
  LIQUOR_STORE : "LIQUOR_STORE",
  VAPE_SHOP : "VAPE_SHOP",
  JEWELRY : "JEWELRY",
  FLORIST : "FLORIST",
  OTHER_RETAIL : "OTHER_RETAIL",
  FITNESS : "FITNESS",
  SPA : "SPA",
  HAIR_SALON : "HAIR_SALON",
  HOME_AND_REPAIR : "HOME_AND_REPAIR",
  PROFESSIONAL_SERVICES : "PROFESSIONAL_SERVICES",
  CATERING : "CATERING",
  OTHER_SERVICE : "OTHER_SERVICE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = BusinessTypeCode;
}

