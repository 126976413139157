/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var BatchState = {
  OPEN : "OPEN",
  QUEUED_FOR_PROCESSING : "QUEUED_FOR_PROCESSING",
  PROCESSING : "PROCESSING",
  CLOSED : "CLOSED",
  FAILED : "FAILED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = BatchState;
}

