import React from "react";
import { Dimensions, Text, View } from "react-native";
import AdversitingBanner from "./AdvertisingBanner";
import { EvilIcons } from '@expo/vector-icons';
import AdvertisingBannerCarouselProps from "../types/components/AdvertisingBannerCarouselProps";
import { styles } from "../styles/components/advertisingBannerCarousel";
import { DraggableScrollView } from "./DraggableScrollView";

export default ({
  items,
  colorTitle,
  title,
  itemsCustomStyles,
  dotVenueColor,
  isForTotemDisplay
}: AdvertisingBannerCarouselProps): JSX.Element => {
  const { width } = Dimensions.get("window");
  const [activeIndexNumber, setActiveIndexNumber] = React.useState(0);
  const titleStyleForTotem = { fontSize: 48, lineHeight: 24 };
  const hasTitle = title && title.length > 0 ? true : false;

  const renderItem = (item) => {
    return (
      <AdversitingBanner
        id={item.id}
        title={item.title}
        description={item.description}
        image={item.image}
        logo={item.logo}
        price={item.price}
        onPress={item.onPress}
        color={item.color}
        colorTitle={item.colorTitle}
        customCarouselStyles={itemsCustomStyles}
        isForTotemDisplay={isForTotemDisplay}
      />
    );
  };

  return (
    <>
    {hasTitle ? (
      <View style={styles.header}>
        <Text style={[styles.headerTitle, { color: isForTotemDisplay ? "#000000" : colorTitle }, isForTotemDisplay && titleStyleForTotem]}>{title}</Text>
      </View>
    ) : null}
      {isForTotemDisplay ? (
        <>
          <View style={{ flexDirection: "row", marginHorizontal: 10, alignSelf: "center" }}>
            {activeIndexNumber !== 0 && (
              <View style={{ flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <EvilIcons 
                  name="chevron-left" 
                  size={40} 
                  color="#000000" 
                  onPress={() => { 
                    setActiveIndexNumber(activeIndexNumber - 1)
                  }}
                />
              </View>
            )}
            <View
              style={{ marginLeft: 10 }}
            >
              {renderItem(items[activeIndexNumber])}
            </View>
            {activeIndexNumber !== items.length - 1 && (
              <View style={{ flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <EvilIcons 
                  name="chevron-right" 
                  size={40} color="#000000" 
                  onPress={() => {
                    setActiveIndexNumber(activeIndexNumber + 1)
                  }}
                />
              </View>
            )}
          </View>
        </>
      ) : (
        <>
        <DraggableScrollView horizontal showsHorizontalScrollIndicator={false} pagingEnabled={true} onScroll={e => {
          const currentSlide = Math.round(e.nativeEvent.contentOffset.x / e.nativeEvent.layoutMeasurement.width);
          setActiveIndexNumber(currentSlide)
        }} scrollEventThrottle={16}>
          {items.map((item, index) => (
            <View
              key={item.id}
              style={[index > 0 && { marginLeft: width * 0.05 }]}
            >
              {renderItem(item)}
            </View>
          ))}
        </DraggableScrollView>
        </>
      )}
      {!isForTotemDisplay && (
        <View style={styles.dotsColumnContainer}>
        <View style={styles.dotsRowsContainer}>
        {items.map((_, index) => (
           <View
           key={index}
           style={[index == activeIndexNumber ? [styles.dot,{ backgroundColor: dotVenueColor, borderColor: dotVenueColor }] : styles.dot]}>
          </View>
        ))}
        </View>
        </View>
      )}
    </>
  );
};
