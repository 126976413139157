/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof base
*/
var CardData = function() {
  this._class_ = CardData;
  this.track1 = undefined;
  this.track2 = undefined;
  this.track3 = undefined;
  this.encrypted = undefined;
  this.maskedTrack1 = undefined;
  this.maskedTrack2 = undefined;
  this.maskedTrack3 = undefined;
  this.pan = undefined;
  this.cardholderName = undefined;
  this.firstName = undefined;
  this.lastName = undefined;
  this.exp = undefined;
  this.last4 = undefined;
  this.first6 = undefined;
};


/**
* Set the field value
* The track1 data from the card.
*
* @memberof base.CardData
* @param {String} track1 
*/
CardData.prototype.setTrack1 = function(track1) {
  this.track1 = track1;
};

/**
* Get the field value
* The track1 data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getTrack1 = function() {
  return this.track1;
};

/**
* Set the field value
* The track2 data from the card.
*
* @memberof base.CardData
* @param {String} track2 
*/
CardData.prototype.setTrack2 = function(track2) {
  this.track2 = track2;
};

/**
* Get the field value
* The track2 data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getTrack2 = function() {
  return this.track2;
};

/**
* Set the field value
* The track3 data from the card.
*
* @memberof base.CardData
* @param {String} track3 
*/
CardData.prototype.setTrack3 = function(track3) {
  this.track3 = track3;
};

/**
* Get the field value
* The track3 data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getTrack3 = function() {
  return this.track3;
};

/**
* Set the field value
* Indicates if the data is encrypted.
*
* @memberof base.CardData
* @param {Boolean} encrypted 
*/
CardData.prototype.setEncrypted = function(encrypted) {
  this.encrypted = encrypted;
};

/**
* Get the field value
* Indicates if the data is encrypted.
* @memberof base.CardData
* @return {Boolean} 
*/
CardData.prototype.getEncrypted = function() {
  return this.encrypted;
};

/**
* Set the field value
* The track1 data from the card.
*
* @memberof base.CardData
* @param {String} maskedTrack1 
*/
CardData.prototype.setMaskedTrack1 = function(maskedTrack1) {
  this.maskedTrack1 = maskedTrack1;
};

/**
* Get the field value
* The track1 data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getMaskedTrack1 = function() {
  return this.maskedTrack1;
};

/**
* Set the field value
* The track2 data from the card.
*
* @memberof base.CardData
* @param {String} maskedTrack2 
*/
CardData.prototype.setMaskedTrack2 = function(maskedTrack2) {
  this.maskedTrack2 = maskedTrack2;
};

/**
* Get the field value
* The track2 data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getMaskedTrack2 = function() {
  return this.maskedTrack2;
};

/**
* Set the field value
* The track3 data from the card.
*
* @memberof base.CardData
* @param {String} maskedTrack3 
*/
CardData.prototype.setMaskedTrack3 = function(maskedTrack3) {
  this.maskedTrack3 = maskedTrack3;
};

/**
* Get the field value
* The track3 data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getMaskedTrack3 = function() {
  return this.maskedTrack3;
};

/**
* Set the field value
* The pan data from the card.
*
* @memberof base.CardData
* @param {String} pan 
*/
CardData.prototype.setPan = function(pan) {
  this.pan = pan;
};

/**
* Get the field value
* The pan data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getPan = function() {
  return this.pan;
};

/**
* Set the field value
* The cardholderName data from the card.
*
* @memberof base.CardData
* @param {String} cardholderName 
*/
CardData.prototype.setCardholderName = function(cardholderName) {
  this.cardholderName = cardholderName;
};

/**
* Get the field value
* The cardholderName data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getCardholderName = function() {
  return this.cardholderName;
};

/**
* Set the field value
* The firstName data from the card.
*
* @memberof base.CardData
* @param {String} firstName 
*/
CardData.prototype.setFirstName = function(firstName) {
  this.firstName = firstName;
};

/**
* Get the field value
* The firstName data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getFirstName = function() {
  return this.firstName;
};

/**
* Set the field value
* The lastName data from the card.
*
* @memberof base.CardData
* @param {String} lastName 
*/
CardData.prototype.setLastName = function(lastName) {
  this.lastName = lastName;
};

/**
* Get the field value
* The lastName data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getLastName = function() {
  return this.lastName;
};

/**
* Set the field value
* The exp data from the card.
*
* @memberof base.CardData
* @param {String} exp 
*/
CardData.prototype.setExp = function(exp) {
  this.exp = exp;
};

/**
* Get the field value
* The exp data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getExp = function() {
  return this.exp;
};

/**
* Set the field value
* The last4 data from the card.
*
* @memberof base.CardData
* @param {String} last4 
*/
CardData.prototype.setLast4 = function(last4) {
  this.last4 = last4;
};

/**
* Get the field value
* The last4 data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getLast4 = function() {
  return this.last4;
};

/**
* Set the field value
* The first6 data from the card.
*
* @memberof base.CardData
* @param {String} first6 
*/
CardData.prototype.setFirst6 = function(first6) {
  this.first6 = first6;
};

/**
* Get the field value
* The first6 data from the card.
* @memberof base.CardData
* @return {String} 
*/
CardData.prototype.getFirst6 = function() {
  return this.first6;
};

/**
* @memberof base.CardData
* @private
*/
CardData.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

CardData.prototype.toString = function() {
  return JSON.stringify(this);
};

CardData._meta_ =  {fields:  {}};
CardData._meta_._class_ =  CardData;
CardData._meta_.fields["track1"] = {};
CardData._meta_.fields["track1"].type = String;
CardData._meta_.fields["track2"] = {};
CardData._meta_.fields["track2"].type = String;
CardData._meta_.fields["track3"] = {};
CardData._meta_.fields["track3"].type = String;
CardData._meta_.fields["encrypted"] = {};
CardData._meta_.fields["encrypted"].type = Boolean;
CardData._meta_.fields["maskedTrack1"] = {};
CardData._meta_.fields["maskedTrack1"].type = String;
CardData._meta_.fields["maskedTrack2"] = {};
CardData._meta_.fields["maskedTrack2"].type = String;
CardData._meta_.fields["maskedTrack3"] = {};
CardData._meta_.fields["maskedTrack3"].type = String;
CardData._meta_.fields["pan"] = {};
CardData._meta_.fields["pan"].type = String;
CardData._meta_.fields["cardholderName"] = {};
CardData._meta_.fields["cardholderName"].type = String;
CardData._meta_.fields["firstName"] = {};
CardData._meta_.fields["firstName"].type = String;
CardData._meta_.fields["lastName"] = {};
CardData._meta_.fields["lastName"].type = String;
CardData._meta_.fields["exp"] = {};
CardData._meta_.fields["exp"].type = String;
CardData._meta_.fields["last4"] = {};
CardData._meta_.fields["last4"].type = String;
CardData._meta_.fields["first6"] = {};
CardData._meta_.fields["first6"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardData;
}

