/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var loyalty_LoyaltyDataConfig = require("../loyalty/LoyaltyDataConfig");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var CustomerProvidedDataMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = CustomerProvidedDataMessage;
  this.setMethod(remotemessage_Method["CUSTOMER_PROVIDED_DATA_MESSAGE"]);
  this.eventId = undefined;
  this.config = undefined;
  this.data = undefined;
};

CustomerProvidedDataMessage.prototype = Object.create(remotemessage_Message.prototype);
CustomerProvidedDataMessage.prototype.constructor = CustomerProvidedDataMessage;

/**
* Set the field value
* An id that represents this collection event
*
* @memberof remotemessage.CustomerProvidedDataMessage
* @param {String} eventId 
*/
CustomerProvidedDataMessage.prototype.setEventId = function(eventId) {
  this.eventId = eventId;
};

/**
* Get the field value
* An id that represents this collection event
* @memberof remotemessage.CustomerProvidedDataMessage
* @return {String} 
*/
CustomerProvidedDataMessage.prototype.getEventId = function() {
  return this.eventId;
};

/**
* Set the field value
* The configuration that identifies what this event data represents.  This is used when interpreting the data.
*
* @memberof remotemessage.CustomerProvidedDataMessage
* @param {loyalty.LoyaltyDataConfig} config 
*/
CustomerProvidedDataMessage.prototype.setConfig = function(config) {
  this.config = config;
};

/**
* Get the field value
* The configuration that identifies what this event data represents.  This is used when interpreting the data.
* @memberof remotemessage.CustomerProvidedDataMessage
* @return {loyalty.LoyaltyDataConfig} 
*/
CustomerProvidedDataMessage.prototype.getConfig = function() {
  return this.config;
};

/**
* Set the field value
* The data that was collected.  This is similar to a CLOB.
*
* @memberof remotemessage.CustomerProvidedDataMessage
* @param {String} data 
*/
CustomerProvidedDataMessage.prototype.setData = function(data) {
  this.data = data;
};

/**
* Get the field value
* The data that was collected.  This is similar to a CLOB.
* @memberof remotemessage.CustomerProvidedDataMessage
* @return {String} 
*/
CustomerProvidedDataMessage.prototype.getData = function() {
  return this.data;
};

CustomerProvidedDataMessage._meta_ =  {fields:  {}};
CustomerProvidedDataMessage._meta_._class_ =  CustomerProvidedDataMessage;
CustomerProvidedDataMessage._meta_._superMeta_ = remotemessage_Message._meta_;
CustomerProvidedDataMessage._meta_.fields["eventId"] = {};
CustomerProvidedDataMessage._meta_.fields["eventId"].type = String;
CustomerProvidedDataMessage._meta_.fields["config"] = {};
CustomerProvidedDataMessage._meta_.fields["config"].type = loyalty_LoyaltyDataConfig;
CustomerProvidedDataMessage._meta_.fields["data"] = {};
CustomerProvidedDataMessage._meta_.fields["data"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CustomerProvidedDataMessage;
}

