/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_TxState = require("../remotemessage/TxState");
var remotemessage_Message = require("../remotemessage/Message");
var remotemessage_ErrorCode = require("../remotemessage/ErrorCode");
var payments_Refund = require("../payments/Refund");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var RefundResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = RefundResponseMessage;
  this.setMethod(remotemessage_Method["REFUND_RESPONSE"]);
  this.orderId = undefined;
  this.paymentId = undefined;
  this.refund = undefined;
  this.reason = undefined;
  this.message = undefined;
  this.code = undefined;
};

RefundResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
RefundResponseMessage.prototype.constructor = RefundResponseMessage;

/**
* Set the field value
* Unique identifier for a order
*
* @memberof remotemessage.RefundResponseMessage
* @param {String} orderId 
*/
RefundResponseMessage.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier for a order
* @memberof remotemessage.RefundResponseMessage
* @return {String} 
*/
RefundResponseMessage.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* Unique identifier for a payment
*
* @memberof remotemessage.RefundResponseMessage
* @param {String} paymentId 
*/
RefundResponseMessage.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier for a payment
* @memberof remotemessage.RefundResponseMessage
* @return {String} 
*/
RefundResponseMessage.prototype.getPaymentId = function() {
  return this.paymentId;
};

/**
* Set the field value
* The refund
*
* @memberof remotemessage.RefundResponseMessage
* @param {payments.Refund} refund 
*/
RefundResponseMessage.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* The refund
* @memberof remotemessage.RefundResponseMessage
* @return {payments.Refund} 
*/
RefundResponseMessage.prototype.getRefund = function() {
  return this.refund;
};

/**
* Set the field value
* Detail code if an error is encountered
*
* @memberof remotemessage.RefundResponseMessage
* @param {remotemessage.ErrorCode} reason 
*/
RefundResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* Detail code if an error is encountered
* @memberof remotemessage.RefundResponseMessage
* @return {remotemessage.ErrorCode} 
*/
RefundResponseMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* Detail message
*
* @memberof remotemessage.RefundResponseMessage
* @param {String} message 
*/
RefundResponseMessage.prototype.setMessage = function(message) {
  this.message = message;
};

/**
* Get the field value
* Detail message
* @memberof remotemessage.RefundResponseMessage
* @return {String} 
*/
RefundResponseMessage.prototype.getMessage = function() {
  return this.message;
};

/**
* Set the field value
* Transaction state (success|fail)
*
* @memberof remotemessage.RefundResponseMessage
* @param {remotemessage.TxState} code 
*/
RefundResponseMessage.prototype.setCode = function(code) {
  this.code = code;
};

/**
* Get the field value
* Transaction state (success|fail)
* @memberof remotemessage.RefundResponseMessage
* @return {remotemessage.TxState} 
*/
RefundResponseMessage.prototype.getCode = function() {
  return this.code;
};

RefundResponseMessage._meta_ =  {fields:  {}};
RefundResponseMessage._meta_._class_ =  RefundResponseMessage;
RefundResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
RefundResponseMessage._meta_.fields["orderId"] = {};
RefundResponseMessage._meta_.fields["orderId"].type = String;
RefundResponseMessage._meta_.fields["paymentId"] = {};
RefundResponseMessage._meta_.fields["paymentId"].type = String;
RefundResponseMessage._meta_.fields["refund"] = {};
RefundResponseMessage._meta_.fields["refund"].type = payments_Refund;
RefundResponseMessage._meta_.fields["reason"] = {};
RefundResponseMessage._meta_.fields["reason"].type = remotemessage_ErrorCode;
RefundResponseMessage._meta_.fields["message"] = {};
RefundResponseMessage._meta_.fields["message"].type = String;
RefundResponseMessage._meta_.fields["code"] = {};
RefundResponseMessage._meta_.fields["code"].type = remotemessage_TxState;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RefundResponseMessage;
}

