/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof remotepay
*/
var PlatformError = function() {
  this._class_ = PlatformError;
  this.message = undefined;
};


/**
* Set the field value
* Description of the error.
*
* @memberof remotepay.PlatformError
* @param {String} message 
*/
PlatformError.prototype.setMessage = function(message) {
  this.message = message;
};

/**
* Get the field value
* Description of the error.
* @memberof remotepay.PlatformError
* @return {String} 
*/
PlatformError.prototype.getMessage = function() {
  return this.message;
};

/**
* @memberof remotepay.PlatformError
* @private
*/
PlatformError.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PlatformError.prototype.toString = function() {
  return JSON.stringify(this);
};

PlatformError._meta_ =  {fields:  {}};
PlatformError._meta_._class_ =  PlatformError;
PlatformError._meta_.fields["message"] = {};
PlatformError._meta_.fields["message"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PlatformError;
}

