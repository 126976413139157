/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_PaymentResponse = require("../remotepay/PaymentResponse");

/** The response to an authorization request */
/**
* @constructor
* @augments remotepay.PaymentResponse
* @memberof remotepay
*/
var AuthResponse = function() {
  remotepay_PaymentResponse.call(this);
  this._superClass_ = remotepay_PaymentResponse;
  this._class_ = AuthResponse;
};

AuthResponse.prototype = Object.create(remotepay_PaymentResponse.prototype);
AuthResponse.prototype.constructor = AuthResponse;

AuthResponse._meta_ =  {fields:  {}};
AuthResponse._meta_._class_ =  AuthResponse;
AuthResponse._meta_._superMeta_ = remotepay_PaymentResponse._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AuthResponse;
}

