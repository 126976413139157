/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var customers_CustomerInfo = require("../customers/CustomerInfo");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var CustomerInfoMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = CustomerInfoMessage;
  this.setMethod(remotemessage_Method["CUSTOMER_INFO_MESSAGE"]);
  this.customer = undefined;
};

CustomerInfoMessage.prototype = Object.create(remotemessage_Message.prototype);
CustomerInfoMessage.prototype.constructor = CustomerInfoMessage;

/**
* Set the field value
* The customer
*
* @memberof remotemessage.CustomerInfoMessage
* @param {customers.CustomerInfo} customer 
*/
CustomerInfoMessage.prototype.setCustomer = function(customer) {
  this.customer = customer;
};

/**
* Get the field value
* The customer
* @memberof remotemessage.CustomerInfoMessage
* @return {customers.CustomerInfo} 
*/
CustomerInfoMessage.prototype.getCustomer = function() {
  return this.customer;
};

CustomerInfoMessage._meta_ =  {fields:  {}};
CustomerInfoMessage._meta_._class_ =  CustomerInfoMessage;
CustomerInfoMessage._meta_._superMeta_ = remotemessage_Message._meta_;
CustomerInfoMessage._meta_.fields["customer"] = {};
CustomerInfoMessage._meta_.fields["customer"].type = customers_CustomerInfo;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CustomerInfoMessage;
}

