/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var customers_Customer = require("../customers/Customer");
var payments_CardTransaction = require("../payments/CardTransaction");
var payments_CreditRefund = require("../payments/CreditRefund");
var payments_Result = require("../payments/Result");
var payments_TaxableAmountRate = require("../payments/TaxableAmountRate");
var apps_AppTracking = require("../apps/AppTracking");
var payments_TransactionInfo = require("../payments/TransactionInfo");
var payments_DCCInfo = require("../payments/DCCInfo");
var base_Reference = require("../base/Reference");
var base_Tender = require("../base/Tender");
var payments_TransactionSettings = require("../payments/TransactionSettings");
var payments_GermanInfo = require("../payments/GermanInfo");

/**
* @constructor
* @memberof payments
*/
var Credit = function() {
  this._class_ = Credit;
  this.id = undefined;
  this.orderRef = undefined;
  this.device = undefined;
  this.tender = undefined;
  this.employee = undefined;
  this.customers = undefined;
  this.amount = undefined;
  this.taxAmount = undefined;
  this.taxRates = undefined;
  this.createdTime = undefined;
  this.clientCreatedTime = undefined;
  this.cardTransaction = undefined;
  this.voided = undefined;
  this.voidReason = undefined;
  this.dccInfo = undefined;
  this.transactionSettings = undefined;
  this.creditRefunds = undefined;
  this.germanInfo = undefined;
  this.appTracking = undefined;
  this.result = undefined;
  this.transactionInfo = undefined;
  this.merchant = undefined;
  this.externalReferenceId = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof payments.Credit
* @param {String} id 
*/
Credit.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof payments.Credit
* @return {String} 
*/
Credit.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* The order with which the credit is associated
*
* @memberof payments.Credit
* @param {base.Reference} orderRef 
*/
Credit.prototype.setOrderRef = function(orderRef) {
  this.orderRef = orderRef;
};

/**
* Get the field value
* The order with which the credit is associated
* @memberof payments.Credit
* @return {base.Reference} 
*/
Credit.prototype.getOrderRef = function() {
  return this.orderRef;
};

/**
* Set the field value
* Device which processed this credit, a 128-bit UUID, not a normal base-13 Clover ID.
*
* @memberof payments.Credit
* @param {base.Reference|Null} device 
*/
Credit.prototype.setDevice = function(device) {
  this.device = device;
};

/**
* Get the field value
* Device which processed this credit, a 128-bit UUID, not a normal base-13 Clover ID.
* @memberof payments.Credit
* @return {base.Reference|Null} 
*/
Credit.prototype.getDevice = function() {
  return this.device;
};

/**
* Set the field value
* The tender type associated with this payment, e.g. credit card, cash, etc.
*
* @memberof payments.Credit
* @param {base.Tender} tender 
*/
Credit.prototype.setTender = function(tender) {
  this.tender = tender;
};

/**
* Get the field value
* The tender type associated with this payment, e.g. credit card, cash, etc.
* @memberof payments.Credit
* @return {base.Tender} 
*/
Credit.prototype.getTender = function() {
  return this.tender;
};

/**
* Set the field value
* The employee who processed the payment
*
* @memberof payments.Credit
* @param {base.Reference} employee 
*/
Credit.prototype.setEmployee = function(employee) {
  this.employee = employee;
};

/**
* Get the field value
* The employee who processed the payment
* @memberof payments.Credit
* @return {base.Reference} 
*/
Credit.prototype.getEmployee = function() {
  return this.employee;
};

/**
* Set the field value
* Customer who received the credit/refund
*
* @memberof payments.Credit
* @param {customers.Customer} customers 
*/
Credit.prototype.setCustomers = function(customers) {
  this.customers = customers;
};

/**
* Get the field value
* Customer who received the credit/refund
* @memberof payments.Credit
* @return {customers.Customer} 
*/
Credit.prototype.getCustomers = function() {
  return this.customers;
};

/**
* Set the field value
* Amount paid
*
* @memberof payments.Credit
* @param {Number} amount must be a long integer
*/
Credit.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* Amount paid
* @memberof payments.Credit
* @return {Number} must be a long integer
*/
Credit.prototype.getAmount = function() {
  return this.amount;
};

/**
* Set the field value
* Amount paid in tax
*
* @memberof payments.Credit
* @param {Number|Null} taxAmount must be a long integer, 
*/
Credit.prototype.setTaxAmount = function(taxAmount) {
  this.taxAmount = taxAmount;
};

/**
* Get the field value
* Amount paid in tax
* @memberof payments.Credit
* @return {Number|Null} must be a long integer, 
*/
Credit.prototype.getTaxAmount = function() {
  return this.taxAmount;
};

/**
* Set the field value
* @memberof payments.Credit
* @param {Array.<payments.TaxableAmountRate>} taxRates An array of 
*/
Credit.prototype.setTaxRates = function(taxRates) {
  this.taxRates = taxRates;
};

/**
* Get the field value
* @memberof payments.Credit
* @return {Array.<payments.TaxableAmountRate>} An array of 
*/
Credit.prototype.getTaxRates = function() {
  return this.taxRates;
};

/**
* Set the field value
* Time payment was recorded on server
*
* @memberof payments.Credit
* @param {Number} createdTime must be a long integer
*/
Credit.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Time payment was recorded on server
* @memberof payments.Credit
* @return {Number} must be a long integer
*/
Credit.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* @memberof payments.Credit
* @param {Number} clientCreatedTime must be a long integer
*/
Credit.prototype.setClientCreatedTime = function(clientCreatedTime) {
  this.clientCreatedTime = clientCreatedTime;
};

/**
* Get the field value
* @memberof payments.Credit
* @return {Number} must be a long integer
*/
Credit.prototype.getClientCreatedTime = function() {
  return this.clientCreatedTime;
};

/**
* Set the field value
* Information about the card used for credit/debit card payments
*
* @memberof payments.Credit
* @param {payments.CardTransaction} cardTransaction 
*/
Credit.prototype.setCardTransaction = function(cardTransaction) {
  this.cardTransaction = cardTransaction;
};

/**
* Get the field value
* Information about the card used for credit/debit card payments
* @memberof payments.Credit
* @return {payments.CardTransaction} 
*/
Credit.prototype.getCardTransaction = function() {
  return this.cardTransaction;
};

/**
* Set the field value
* @memberof payments.Credit
* @param {Boolean} voided 
*/
Credit.prototype.setVoided = function(voided) {
  this.voided = voided;
};

/**
* Get the field value
* @memberof payments.Credit
* @return {Boolean} 
*/
Credit.prototype.getVoided = function() {
  return this.voided;
};

/**
* Set the field value
* @memberof payments.Credit
* @param {String} voidReason 
*/
Credit.prototype.setVoidReason = function(voidReason) {
  this.voidReason = voidReason;
};

/**
* Get the field value
* @memberof payments.Credit
* @return {String} 
*/
Credit.prototype.getVoidReason = function() {
  return this.voidReason;
};

/**
* Set the field value
* Dynamic Currency Conversion information
*
* @memberof payments.Credit
* @param {payments.DCCInfo|Null} dccInfo 
*/
Credit.prototype.setDccInfo = function(dccInfo) {
  this.dccInfo = dccInfo;
};

/**
* Get the field value
* Dynamic Currency Conversion information
* @memberof payments.Credit
* @return {payments.DCCInfo|Null} 
*/
Credit.prototype.getDccInfo = function() {
  return this.dccInfo;
};

/**
* Set the field value
* Per transaction settings for the payment
*
* @memberof payments.Credit
* @param {payments.TransactionSettings} transactionSettings 
*/
Credit.prototype.setTransactionSettings = function(transactionSettings) {
  this.transactionSettings = transactionSettings;
};

/**
* Get the field value
* Per transaction settings for the payment
* @memberof payments.Credit
* @return {payments.TransactionSettings} 
*/
Credit.prototype.getTransactionSettings = function() {
  return this.transactionSettings;
};

/**
* Set the field value
* @memberof payments.Credit
* @param {Array.<payments.CreditRefund>} creditRefunds An array of 
*/
Credit.prototype.setCreditRefunds = function(creditRefunds) {
  this.creditRefunds = creditRefunds;
};

/**
* Get the field value
* @memberof payments.Credit
* @return {Array.<payments.CreditRefund>} An array of 
*/
Credit.prototype.getCreditRefunds = function() {
  return this.creditRefunds;
};

/**
* Set the field value
* German region-specific information
*
* @memberof payments.Credit
* @param {payments.GermanInfo|Null} germanInfo 
*/
Credit.prototype.setGermanInfo = function(germanInfo) {
  this.germanInfo = germanInfo;
};

/**
* Get the field value
* German region-specific information
* @memberof payments.Credit
* @return {payments.GermanInfo|Null} 
*/
Credit.prototype.getGermanInfo = function() {
  return this.germanInfo;
};

/**
* Set the field value
* Tracking information for the app that created this credit.
*
* @memberof payments.Credit
* @param {apps.AppTracking|Null} appTracking 
*/
Credit.prototype.setAppTracking = function(appTracking) {
  this.appTracking = appTracking;
};

/**
* Get the field value
* Tracking information for the app that created this credit.
* @memberof payments.Credit
* @return {apps.AppTracking|Null} 
*/
Credit.prototype.getAppTracking = function() {
  return this.appTracking;
};

/**
* Set the field value
* @memberof payments.Credit
* @param {payments.Result} result 
*/
Credit.prototype.setResult = function(result) {
  this.result = result;
};

/**
* Get the field value
* @memberof payments.Credit
* @return {payments.Result} 
*/
Credit.prototype.getResult = function() {
  return this.result;
};

/**
* Set the field value
* Transaction information
*
* @memberof payments.Credit
* @param {payments.TransactionInfo|Null} transactionInfo 
*/
Credit.prototype.setTransactionInfo = function(transactionInfo) {
  this.transactionInfo = transactionInfo;
};

/**
* Get the field value
* Transaction information
* @memberof payments.Credit
* @return {payments.TransactionInfo|Null} 
*/
Credit.prototype.getTransactionInfo = function() {
  return this.transactionInfo;
};

/**
* Set the field value
* @memberof payments.Credit
* @param {base.Reference} merchant 
*/
Credit.prototype.setMerchant = function(merchant) {
  this.merchant = merchant;
};

/**
* Get the field value
* @memberof payments.Credit
* @return {base.Reference} 
*/
Credit.prototype.getMerchant = function() {
  return this.merchant;
};

/**
* Set the field value
* The external reference id if associated with the credit
*
* @memberof payments.Credit
* @param {String|Null} externalReferenceId 
*/
Credit.prototype.setExternalReferenceId = function(externalReferenceId) {
  this.externalReferenceId = externalReferenceId;
};

/**
* Get the field value
* The external reference id if associated with the credit
* @memberof payments.Credit
* @return {String|Null} 
*/
Credit.prototype.getExternalReferenceId = function() {
  return this.externalReferenceId;
};

/**
* @memberof payments.Credit
* @private
*/
Credit.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Credit.prototype.toString = function() {
  return JSON.stringify(this);
};

Credit._meta_ =  {fields:  {}};
Credit._meta_._class_ =  Credit;
Credit._meta_.fields["id"] = {};
Credit._meta_.fields["id"].type = String;
Credit._meta_.fields["orderRef"] = {};
Credit._meta_.fields["orderRef"].type = base_Reference;
Credit._meta_.fields["device"] = {};
Credit._meta_.fields["device"].type = base_Reference;
Credit._meta_.fields["tender"] = {};
Credit._meta_.fields["tender"].type = base_Tender;
Credit._meta_.fields["employee"] = {};
Credit._meta_.fields["employee"].type = base_Reference;
Credit._meta_.fields["customers"] = {};
Credit._meta_.fields["customers"].type = customers_Customer;
Credit._meta_.fields["amount"] = {};
Credit._meta_.fields["amount"].type = Number;
Credit._meta_.fields["taxAmount"] = {};
Credit._meta_.fields["taxAmount"].type = Number;
Credit._meta_.fields["taxRates"] = {};
Credit._meta_.fields["taxRates"].type = Array;
Credit._meta_.fields["taxRates"].elementType = payments_TaxableAmountRate;
Credit._meta_.fields["createdTime"] = {};
Credit._meta_.fields["createdTime"].type = Number;
Credit._meta_.fields["clientCreatedTime"] = {};
Credit._meta_.fields["clientCreatedTime"].type = Number;
Credit._meta_.fields["cardTransaction"] = {};
Credit._meta_.fields["cardTransaction"].type = payments_CardTransaction;
Credit._meta_.fields["voided"] = {};
Credit._meta_.fields["voided"].type = Boolean;
Credit._meta_.fields["voidReason"] = {};
Credit._meta_.fields["voidReason"].type = String;
Credit._meta_.fields["dccInfo"] = {};
Credit._meta_.fields["dccInfo"].type = payments_DCCInfo;
Credit._meta_.fields["transactionSettings"] = {};
Credit._meta_.fields["transactionSettings"].type = payments_TransactionSettings;
Credit._meta_.fields["creditRefunds"] = {};
Credit._meta_.fields["creditRefunds"].type = Array;
Credit._meta_.fields["creditRefunds"].elementType = payments_CreditRefund;
Credit._meta_.fields["germanInfo"] = {};
Credit._meta_.fields["germanInfo"].type = payments_GermanInfo;
Credit._meta_.fields["appTracking"] = {};
Credit._meta_.fields["appTracking"].type = apps_AppTracking;
Credit._meta_.fields["result"] = {};
Credit._meta_.fields["result"].type = payments_Result;
Credit._meta_.fields["transactionInfo"] = {};
Credit._meta_.fields["transactionInfo"].type = payments_TransactionInfo;
Credit._meta_.fields["merchant"] = {};
Credit._meta_.fields["merchant"].type = base_Reference;
Credit._meta_.fields["externalReferenceId"] = {};
Credit._meta_.fields["externalReferenceId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Credit;
}

