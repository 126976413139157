/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/** This is a wrapper calss which holds a list of request uuids */
/**
* @constructor
* @memberof device
*/
var DeviceRequests = function() {
  this._class_ = DeviceRequests;
  this.requestUuids = undefined;
};


/**
* Set the field value
* @memberof device.DeviceRequests
* @param {Array.<String>} requestUuids An array of 
*/
DeviceRequests.prototype.setRequestUuids = function(requestUuids) {
  this.requestUuids = requestUuids;
};

/**
* Get the field value
* @memberof device.DeviceRequests
* @return {Array.<String>} An array of 
*/
DeviceRequests.prototype.getRequestUuids = function() {
  return this.requestUuids;
};

/**
* @memberof device.DeviceRequests
* @private
*/
DeviceRequests.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DeviceRequests.prototype.toString = function() {
  return JSON.stringify(this);
};

DeviceRequests._meta_ =  {fields:  {}};
DeviceRequests._meta_._class_ =  DeviceRequests;
DeviceRequests._meta_.fields["requestUuids"] = {};
DeviceRequests._meta_.fields["requestUuids"].type = Array;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeviceRequests;
}

