/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var TipAdjustAuthResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = TipAdjustAuthResponse;
  this.tipAmount = undefined;
  this.paymentId = undefined;
};

TipAdjustAuthResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
TipAdjustAuthResponse.prototype.constructor = TipAdjustAuthResponse;

/**
* Set the field value
* Amount of tip
*
* @memberof remotepay.TipAdjustAuthResponse
* @param {Number} tipAmount must be a long integer
*/
TipAdjustAuthResponse.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Amount of tip
* @memberof remotepay.TipAdjustAuthResponse
* @return {Number} must be a long integer
*/
TipAdjustAuthResponse.prototype.getTipAmount = function() {
  return this.tipAmount;
};

/**
* Set the field value
* Unique identifier for a payment
*
* @memberof remotepay.TipAdjustAuthResponse
* @param {String} paymentId 
*/
TipAdjustAuthResponse.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier for a payment
* @memberof remotepay.TipAdjustAuthResponse
* @return {String} 
*/
TipAdjustAuthResponse.prototype.getPaymentId = function() {
  return this.paymentId;
};

TipAdjustAuthResponse._meta_ =  {fields:  {}};
TipAdjustAuthResponse._meta_._class_ =  TipAdjustAuthResponse;
TipAdjustAuthResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
TipAdjustAuthResponse._meta_.fields["tipAmount"] = {};
TipAdjustAuthResponse._meta_.fields["tipAmount"].type = Number;
TipAdjustAuthResponse._meta_.fields["paymentId"] = {};
TipAdjustAuthResponse._meta_.fields["paymentId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipAdjustAuthResponse;
}

