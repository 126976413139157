/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Payment = require("../payments/Payment");

/**
* @constructor
* @memberof payments
*/
var PaymentResponse = function() {
  this._class_ = PaymentResponse;
  this.requestSuccessful = undefined;
  this.responseErrorMessage = undefined;
  this.payment = undefined;
  this.clientData = undefined;
  this.syncPaymentObject = undefined;
};


/**
* Set the field value
* @memberof payments.PaymentResponse
* @param {Boolean} requestSuccessful 
*/
PaymentResponse.prototype.setRequestSuccessful = function(requestSuccessful) {
  this.requestSuccessful = requestSuccessful;
};

/**
* Get the field value
* @memberof payments.PaymentResponse
* @return {Boolean} 
*/
PaymentResponse.prototype.getRequestSuccessful = function() {
  return this.requestSuccessful;
};

/**
* Set the field value
* @memberof payments.PaymentResponse
* @param {String|Null} responseErrorMessage 
*/
PaymentResponse.prototype.setResponseErrorMessage = function(responseErrorMessage) {
  this.responseErrorMessage = responseErrorMessage;
};

/**
* Get the field value
* @memberof payments.PaymentResponse
* @return {String|Null} 
*/
PaymentResponse.prototype.getResponseErrorMessage = function() {
  return this.responseErrorMessage;
};

/**
* Set the field value
* @memberof payments.PaymentResponse
* @param {payments.Payment|Null} payment 
*/
PaymentResponse.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof payments.PaymentResponse
* @return {payments.Payment|Null} 
*/
PaymentResponse.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* Additional data sent back from the gateway
*
* @memberof payments.PaymentResponse
* @param {Object.<String,String>} clientData A map of <String> to <>
*/
PaymentResponse.prototype.setClientData = function(clientData) {
  this.clientData = clientData;
};

/**
* Get the field value
* Additional data sent back from the gateway
* @memberof payments.PaymentResponse
* @return {Object.<String,String>} A map of <String> to <>
*/
PaymentResponse.prototype.getClientData = function() {
  return this.clientData;
};

/**
* Set the field value
* @memberof payments.PaymentResponse
* @param {Boolean} syncPaymentObject 
*/
PaymentResponse.prototype.setSyncPaymentObject = function(syncPaymentObject) {
  this.syncPaymentObject = syncPaymentObject;
};

/**
* Get the field value
* @memberof payments.PaymentResponse
* @return {Boolean} 
*/
PaymentResponse.prototype.getSyncPaymentObject = function() {
  return this.syncPaymentObject;
};

/**
* @memberof payments.PaymentResponse
* @private
*/
PaymentResponse.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PaymentResponse.prototype.toString = function() {
  return JSON.stringify(this);
};

PaymentResponse._meta_ =  {fields:  {}};
PaymentResponse._meta_._class_ =  PaymentResponse;
PaymentResponse._meta_.fields["requestSuccessful"] = {};
PaymentResponse._meta_.fields["requestSuccessful"].type = Boolean;
PaymentResponse._meta_.fields["responseErrorMessage"] = {};
PaymentResponse._meta_.fields["responseErrorMessage"].type = String;
PaymentResponse._meta_.fields["payment"] = {};
PaymentResponse._meta_.fields["payment"].type = payments_Payment;
PaymentResponse._meta_.fields["clientData"] = {};
PaymentResponse._meta_.fields["clientData"].type = Object;
PaymentResponse._meta_.fields["syncPaymentObject"] = {};
PaymentResponse._meta_.fields["syncPaymentObject"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PaymentResponse;
}

