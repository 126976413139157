/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotepay
* @enum {string}
*/
var ResponseCode = {
  SUCCESS : "SUCCESS",
  FAIL : "FAIL",
  UNSUPPORTED : "UNSUPPORTED",
  CANCEL : "CANCEL",
  DUPLICATE : "DUPLICATE",
  ERROR : "ERROR"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ResponseCode;
}

