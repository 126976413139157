/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof inventory
*/
var MenuItem = function() {
  this._class_ = MenuItem;
  this.id = undefined;
  this.item = undefined;
  this.merchantRef = undefined;
  this.price = undefined;
  this.name = undefined;
  this.description = undefined;
  this.imageFilename = undefined;
  this.enabled = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
  this.deletedTime = undefined;
};


/**
* Set the field value
* Item id
*
* @memberof inventory.MenuItem
* @param {String} id 
*/
MenuItem.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Item id
* @memberof inventory.MenuItem
* @return {String} 
*/
MenuItem.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Reference to an item
*
* @memberof inventory.MenuItem
* @param {base.Reference} item 
*/
MenuItem.prototype.setItem = function(item) {
  this.item = item;
};

/**
* Get the field value
* Reference to an item
* @memberof inventory.MenuItem
* @return {base.Reference} 
*/
MenuItem.prototype.getItem = function() {
  return this.item;
};

/**
* Set the field value
* Reference to the merchant that created the item
*
* @memberof inventory.MenuItem
* @param {base.Reference} merchantRef 
*/
MenuItem.prototype.setMerchantRef = function(merchantRef) {
  this.merchantRef = merchantRef;
};

/**
* Get the field value
* Reference to the merchant that created the item
* @memberof inventory.MenuItem
* @return {base.Reference} 
*/
MenuItem.prototype.getMerchantRef = function() {
  return this.merchantRef;
};

/**
* Set the field value
* Item price
*
* @memberof inventory.MenuItem
* @param {Number} price must be a long integer
*/
MenuItem.prototype.setPrice = function(price) {
  this.price = price;
};

/**
* Get the field value
* Item price
* @memberof inventory.MenuItem
* @return {Number} must be a long integer
*/
MenuItem.prototype.getPrice = function() {
  return this.price;
};

/**
* Set the field value
* Customer facing item name
*
* @memberof inventory.MenuItem
* @param {String} name 
*/
MenuItem.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Customer facing item name
* @memberof inventory.MenuItem
* @return {String} 
*/
MenuItem.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* Item description
*
* @memberof inventory.MenuItem
* @param {String} description 
*/
MenuItem.prototype.setDescription = function(description) {
  this.description = description;
};

/**
* Get the field value
* Item description
* @memberof inventory.MenuItem
* @return {String} 
*/
MenuItem.prototype.getDescription = function() {
  return this.description;
};

/**
* Set the field value
* Item image file path
*
* @memberof inventory.MenuItem
* @param {String} imageFilename 
*/
MenuItem.prototype.setImageFilename = function(imageFilename) {
  this.imageFilename = imageFilename;
};

/**
* Get the field value
* Item image file path
* @memberof inventory.MenuItem
* @return {String} 
*/
MenuItem.prototype.getImageFilename = function() {
  return this.imageFilename;
};

/**
* Set the field value
* Item enabled for menu
*
* @memberof inventory.MenuItem
* @param {Boolean} enabled 
*/
MenuItem.prototype.setEnabled = function(enabled) {
  this.enabled = enabled;
};

/**
* Get the field value
* Item enabled for menu
* @memberof inventory.MenuItem
* @return {Boolean} 
*/
MenuItem.prototype.getEnabled = function() {
  return this.enabled;
};

/**
* Set the field value
* Timestamp when the menu item was created
*
* @memberof inventory.MenuItem
* @param {Number} createdTime must be a long integer
*/
MenuItem.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Timestamp when the menu item was created
* @memberof inventory.MenuItem
* @return {Number} must be a long integer
*/
MenuItem.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* Timestamp when the menu item was last modified
*
* @memberof inventory.MenuItem
* @param {Number} modifiedTime must be a long integer
*/
MenuItem.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* Timestamp when the menu item was last modified
* @memberof inventory.MenuItem
* @return {Number} must be a long integer
*/
MenuItem.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* Timestamp when menu item was last deleted
*
* @memberof inventory.MenuItem
* @param {Number} deletedTime must be a long integer
*/
MenuItem.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* Timestamp when menu item was last deleted
* @memberof inventory.MenuItem
* @return {Number} must be a long integer
*/
MenuItem.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* @memberof inventory.MenuItem
* @private
*/
MenuItem.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

MenuItem.prototype.toString = function() {
  return JSON.stringify(this);
};

MenuItem._meta_ =  {fields:  {}};
MenuItem._meta_._class_ =  MenuItem;
MenuItem._meta_.fields["id"] = {};
MenuItem._meta_.fields["id"].type = String;
MenuItem._meta_.fields["item"] = {};
MenuItem._meta_.fields["item"].type = base_Reference;
MenuItem._meta_.fields["merchantRef"] = {};
MenuItem._meta_.fields["merchantRef"].type = base_Reference;
MenuItem._meta_.fields["price"] = {};
MenuItem._meta_.fields["price"].type = Number;
MenuItem._meta_.fields["name"] = {};
MenuItem._meta_.fields["name"].type = String;
MenuItem._meta_.fields["description"] = {};
MenuItem._meta_.fields["description"].type = String;
MenuItem._meta_.fields["imageFilename"] = {};
MenuItem._meta_.fields["imageFilename"].type = String;
MenuItem._meta_.fields["enabled"] = {};
MenuItem._meta_.fields["enabled"].type = Boolean;
MenuItem._meta_.fields["createdTime"] = {};
MenuItem._meta_.fields["createdTime"].type = Number;
MenuItem._meta_.fields["modifiedTime"] = {};
MenuItem._meta_.fields["modifiedTime"].type = Number;
MenuItem._meta_.fields["deletedTime"] = {};
MenuItem._meta_.fields["deletedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = MenuItem;
}

