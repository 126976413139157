/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var ActivityMessageFromActivity = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = ActivityMessageFromActivity;
  this.setMethod(remotemessage_Method["ACTIVITY_MESSAGE_FROM_ACTIVITY"]);
  this.action = undefined;
  this.payload = undefined;
};

ActivityMessageFromActivity.prototype = Object.create(remotemessage_Message.prototype);
ActivityMessageFromActivity.prototype.constructor = ActivityMessageFromActivity;

/**
* Set the field value
* The name of the activity/action being sent from a running custom activity
*
* @memberof remotemessage.ActivityMessageFromActivity
* @param {String} action 
*/
ActivityMessageFromActivity.prototype.setAction = function(action) {
  this.action = action;
};

/**
* Get the field value
* The name of the activity/action being sent from a running custom activity
* @memberof remotemessage.ActivityMessageFromActivity
* @return {String} 
*/
ActivityMessageFromActivity.prototype.getAction = function() {
  return this.action;
};

/**
* Set the field value
* Data for this activity
*
* @memberof remotemessage.ActivityMessageFromActivity
* @param {String} payload 
*/
ActivityMessageFromActivity.prototype.setPayload = function(payload) {
  this.payload = payload;
};

/**
* Get the field value
* Data for this activity
* @memberof remotemessage.ActivityMessageFromActivity
* @return {String} 
*/
ActivityMessageFromActivity.prototype.getPayload = function() {
  return this.payload;
};

ActivityMessageFromActivity._meta_ =  {fields:  {}};
ActivityMessageFromActivity._meta_._class_ =  ActivityMessageFromActivity;
ActivityMessageFromActivity._meta_._superMeta_ = remotemessage_Message._meta_;
ActivityMessageFromActivity._meta_.fields["action"] = {};
ActivityMessageFromActivity._meta_.fields["action"].type = String;
ActivityMessageFromActivity._meta_.fields["payload"] = {};
ActivityMessageFromActivity._meta_.fields["payload"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ActivityMessageFromActivity;
}

