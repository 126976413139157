/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var CardlessPaymentNetwork = {
  ALIPAY : "ALIPAY",
  WECHAT : "WECHAT"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardlessPaymentNetwork;
}

