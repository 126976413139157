/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var remotemessage_RemoveLineItemAction = require("../remotemessage/RemoveLineItemAction");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var OrderActionRemoveLineItemMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = OrderActionRemoveLineItemMessage;
  this.setMethod(remotemessage_Method["ORDER_ACTION_REMOVE_LINE_ITEM"]);
  this.removeLineItemAction = undefined;
};

OrderActionRemoveLineItemMessage.prototype = Object.create(remotemessage_Message.prototype);
OrderActionRemoveLineItemMessage.prototype.constructor = OrderActionRemoveLineItemMessage;

/**
* Set the field value
* @memberof remotemessage.OrderActionRemoveLineItemMessage
* @param {remotemessage.RemoveLineItemAction} removeLineItemAction 
*/
OrderActionRemoveLineItemMessage.prototype.setRemoveLineItemAction = function(removeLineItemAction) {
  this.removeLineItemAction = removeLineItemAction;
};

/**
* Get the field value
* @memberof remotemessage.OrderActionRemoveLineItemMessage
* @return {remotemessage.RemoveLineItemAction} 
*/
OrderActionRemoveLineItemMessage.prototype.getRemoveLineItemAction = function() {
  return this.removeLineItemAction;
};

OrderActionRemoveLineItemMessage._meta_ =  {fields:  {}};
OrderActionRemoveLineItemMessage._meta_._class_ =  OrderActionRemoveLineItemMessage;
OrderActionRemoveLineItemMessage._meta_._superMeta_ = remotemessage_Message._meta_;
OrderActionRemoveLineItemMessage._meta_.fields["removeLineItemAction"] = {};
OrderActionRemoveLineItemMessage._meta_.fields["removeLineItemAction"].type = remotemessage_RemoveLineItemAction;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OrderActionRemoveLineItemMessage;
}

