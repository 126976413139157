/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var ReadCardDataRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = ReadCardDataRequest;
  this.isForceSwipePinEntry = undefined;
  this.cardEntryMethods = undefined;
};

ReadCardDataRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
ReadCardDataRequest.prototype.constructor = ReadCardDataRequest;

/**
* Set the field value
* If true, then if the card is swiped, a pin entry must be done.
*
* @memberof remotepay.ReadCardDataRequest
* @param {Boolean} isForceSwipePinEntry 
*/
ReadCardDataRequest.prototype.setIsForceSwipePinEntry = function(isForceSwipePinEntry) {
  this.isForceSwipePinEntry = isForceSwipePinEntry;
};

/**
* Get the field value
* If true, then if the card is swiped, a pin entry must be done.
* @memberof remotepay.ReadCardDataRequest
* @return {Boolean} 
*/
ReadCardDataRequest.prototype.getIsForceSwipePinEntry = function() {
  return this.isForceSwipePinEntry;
};

/**
* Set the field value
* The bit mask of allowable card read types.
*
* @memberof remotepay.ReadCardDataRequest
* @param {Number} cardEntryMethods must be an integer
*/
ReadCardDataRequest.prototype.setCardEntryMethods = function(cardEntryMethods) {
  this.cardEntryMethods = cardEntryMethods;
};

/**
* Get the field value
* The bit mask of allowable card read types.
* @memberof remotepay.ReadCardDataRequest
* @return {Number} must be an integer
*/
ReadCardDataRequest.prototype.getCardEntryMethods = function() {
  return this.cardEntryMethods;
};

ReadCardDataRequest._meta_ =  {fields:  {}};
ReadCardDataRequest._meta_._class_ =  ReadCardDataRequest;
ReadCardDataRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
ReadCardDataRequest._meta_.fields["isForceSwipePinEntry"] = {};
ReadCardDataRequest._meta_.fields["isForceSwipePinEntry"].type = Boolean;
ReadCardDataRequest._meta_.fields["cardEntryMethods"] = {};
ReadCardDataRequest._meta_.fields["cardEntryMethods"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ReadCardDataRequest;
}

