/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* `CREATED`: the online order is created
* `CONFIRMED`: the online order is accepted by merchant
* `REJECTED`: the online order is rejected by merchant
* `CANCELLED`: The online order is cancelled
* `IN_PREPARATION`: The online order is being prepared by the merchant.
* `READY_FOR_PICKUP`: Order is ready for pickup
* `IN_TRANSIT`: Order is on the way for delivery
* `FULFILLED`: Order is delivered or picked-up
* @memberof onlineorder
* @enum {string}
*/
var OrderState = {
  CREATED : "CREATED",
  CONFIRMED : "CONFIRMED",
  REJECTED : "REJECTED",
  CANCELLED : "CANCELLED",
  IN_PREPARATION : "IN_PREPARATION",
  READY_FOR_PICKUP : "READY_FOR_PICKUP",
  IN_TRANSIT : "IN_TRANSIT",
  FULFILLED : "FULFILLED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OrderState;
}

