import React from "react";
import {
  FlatList,
  ListRenderItemInfo,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import { Avatar } from "@rneui/themed";
import moment from "moment";
import { EvilIcons } from "@expo/vector-icons";
import FitImage from "react-native-fit-image";
import TwitterFeedProps, {
  TwitterPost,
} from "../types/components/TwitterFeedProps";
import useMediumScreen from "../hooks/useMediumScreen";
import { styles } from "../styles/components/twitterFeed";

export default ({ data, limit, withImages }: TwitterFeedProps): JSX.Element => {
  const isMediumScreen = useMediumScreen();
  const { height } = useWindowDimensions();

  const filterData = React.useCallback(() => {
    // Slice if limit greater than 0
    if (Number.isInteger(limit) && limit > 0) return data.slice(0, limit);
    return data;
  }, [data, limit]);

  const renderItem = ({
    item,
  }: ListRenderItemInfo<TwitterPost>): JSX.Element => {
    const media = item.media?.length ? item.media[0] : null;
    return (
      <View style={styles.container}>
        <View style={styles.profileImageContainer}>
          <Avatar
            size={isMediumScreen ? 72 : 48}
            rounded
            source={{ uri: item.author.profileImageUrl }}
          />
        </View>
        <View style={styles.mainContentContainer}>
          <View style={styles.contentContainer}>
            <Text style={[styles.titleNameStyles, styles.authorName]}>
              {item.author.name}
            </Text>
          </View>
          <View style={styles.contentContainer}>
            <Text style={styles.secondaryInfo}>
              {`@${item.author.username} . ${moment(item.createdAt).format(
                "DD/MM/YY"
              )}`}
            </Text>
          </View>
          <View style={styles.contentContainer}>
            <Text style={styles.contentText}>{item.text}</Text>
          </View>
          {withImages && media && media.type === "photo" && media.url.length ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                width: "100%",
                marginTop: 15
              }}
            >
              <FitImage
                indicatorSize="small"
                source={{ uri: media.url }}
                style={styles.image}
              />
            </View>
          ) : null}
          <View style={styles.contentContainer}>
            <View style={styles.publicMetricContainer}>
              <EvilIcons name="comment" size={20} color="#0000004D" />
              <Text style={styles.secondaryInfo}>
                {item.publicMetrics.replyCount}
              </Text> 
            </View>
            <View style={styles.publicMetricContainer}>
              <EvilIcons
                style={styles.separateContent}
                name="retweet"
                size={20}
                color="#0000004D"
              />
              <Text style={styles.secondaryInfo}>
                {item.publicMetrics.quoteCount + item.publicMetrics.retweetCount}
              </Text>
            </View>
            <View style={styles.publicMetricContainer}>
              <EvilIcons
                style={styles.separateContent}
                name="heart"
                size={20}
                color="#0000004D"
              />
              <Text style={styles.secondaryInfo}>
                {item.publicMetrics.likeCount}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <FlatList
      data={filterData()}
      renderItem={renderItem}
      keyExtractor={(item) => item.id.toString()}
    />
  );
};
