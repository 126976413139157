/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof order
* @enum {string}
*/
var PayType = {
  SPLIT_GUEST : "SPLIT_GUEST",
  SPLIT_ITEM : "SPLIT_ITEM",
  SPLIT_CUSTOM : "SPLIT_CUSTOM",
  FULL : "FULL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PayType;
}

