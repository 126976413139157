import React from "react";
import { View, Text, Image } from "react-native";
import { Card, Button } from "@rneui/themed";
import { AntDesign } from "@expo/vector-icons";
import SuccessModalProps from "../types/components/SuccessModalProps";
import { styles } from "../styles/components/successModal";

export default ({
  isSuccess,
  title,
  message,
  buttonText,
  onConfirm,
  mainColor,
  isBenefit,
  benefitImage
}: SuccessModalProps): JSX.Element => {
  return (
    <>
      <Card.Title style={styles.legendText}>{title}</Card.Title>
      <Card.Divider />
      <View style={styles.iconsContainer}>
        {isSuccess && !isBenefit ? (
          <AntDesign name="checkcircle" size={48} color={mainColor} />
        ) : isSuccess && isBenefit ? (
          <AntDesign name="gift" size={48} color={mainColor} />
        ) : (
          <AntDesign name="exclamationcircle" size={48} color={mainColor} />
        )}
      </View>
      {isBenefit && benefitImage && (
        <View style={styles.benefitImageContainer}>
          <Image source={{ uri: benefitImage }} style={styles.benefitImage}/>
        </View>
      )}
      <View>
        <Text style={styles.message}>{message}</Text>
      </View>
      <Card.Divider />
      <Button
        title={buttonText}
        titleStyle={[styles.onConfirmText, { color: mainColor }]}
        containerStyle={styles.onConfirmContainer}
        type="clear"
        onPress={() => onConfirm()}
      ></Button>
    </>
  );
};
