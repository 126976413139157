/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof payments
*/
var DCCInfo = function() {
  this._class_ = DCCInfo;
  this.inquiryRateId = undefined;
  this.dccApplied = undefined;
  this.foreignCurrencyCode = undefined;
  this.foreignAmount = undefined;
  this.exchangeRate = undefined;
  this.marginRatePercentage = undefined;
  this.exchangeRateSourceName = undefined;
  this.exchangeRateSourceTimeStamp = undefined;
};


/**
* Set the field value
* Inquiry Rate ID (IPG)
*
* @memberof payments.DCCInfo
* @param {Number} inquiryRateId must be a long integer
*/
DCCInfo.prototype.setInquiryRateId = function(inquiryRateId) {
  this.inquiryRateId = inquiryRateId;
};

/**
* Get the field value
* Inquiry Rate ID (IPG)
* @memberof payments.DCCInfo
* @return {Number} must be a long integer
*/
DCCInfo.prototype.getInquiryRateId = function() {
  return this.inquiryRateId;
};

/**
* Set the field value
* Flag indicating whether DCC was applied on this txn
*
* @memberof payments.DCCInfo
* @param {Boolean} dccApplied 
*/
DCCInfo.prototype.setDccApplied = function(dccApplied) {
  this.dccApplied = dccApplied;
};

/**
* Get the field value
* Flag indicating whether DCC was applied on this txn
* @memberof payments.DCCInfo
* @return {Boolean} 
*/
DCCInfo.prototype.getDccApplied = function() {
  return this.dccApplied;
};

/**
* Set the field value
* Foreign currency code
*
* @memberof payments.DCCInfo
* @param {String} foreignCurrencyCode 
*/
DCCInfo.prototype.setForeignCurrencyCode = function(foreignCurrencyCode) {
  this.foreignCurrencyCode = foreignCurrencyCode;
};

/**
* Get the field value
* Foreign currency code
* @memberof payments.DCCInfo
* @return {String} 
*/
DCCInfo.prototype.getForeignCurrencyCode = function() {
  return this.foreignCurrencyCode;
};

/**
* Set the field value
* Foreign (transaction) amount
*
* @memberof payments.DCCInfo
* @param {Number} foreignAmount must be a long integer
*/
DCCInfo.prototype.setForeignAmount = function(foreignAmount) {
  this.foreignAmount = foreignAmount;
};

/**
* Get the field value
* Foreign (transaction) amount
* @memberof payments.DCCInfo
* @return {Number} must be a long integer
*/
DCCInfo.prototype.getForeignAmount = function() {
  return this.foreignAmount;
};

/**
* Set the field value
* Exchange Rate
*
* @memberof payments.DCCInfo
* @param {Number} exchangeRate must be a double
*/
DCCInfo.prototype.setExchangeRate = function(exchangeRate) {
  this.exchangeRate = exchangeRate;
};

/**
* Get the field value
* Exchange Rate
* @memberof payments.DCCInfo
* @return {Number} must be a double
*/
DCCInfo.prototype.getExchangeRate = function() {
  return this.exchangeRate;
};

/**
* Set the field value
* Margin Rate Percentage
*
* @memberof payments.DCCInfo
* @param {String} marginRatePercentage 
*/
DCCInfo.prototype.setMarginRatePercentage = function(marginRatePercentage) {
  this.marginRatePercentage = marginRatePercentage;
};

/**
* Get the field value
* Margin Rate Percentage
* @memberof payments.DCCInfo
* @return {String} 
*/
DCCInfo.prototype.getMarginRatePercentage = function() {
  return this.marginRatePercentage;
};

/**
* Set the field value
* Exchange Rate Source Name
*
* @memberof payments.DCCInfo
* @param {String} exchangeRateSourceName 
*/
DCCInfo.prototype.setExchangeRateSourceName = function(exchangeRateSourceName) {
  this.exchangeRateSourceName = exchangeRateSourceName;
};

/**
* Get the field value
* Exchange Rate Source Name
* @memberof payments.DCCInfo
* @return {String} 
*/
DCCInfo.prototype.getExchangeRateSourceName = function() {
  return this.exchangeRateSourceName;
};

/**
* Set the field value
* Exchange Rate Source Timestamp
*
* @memberof payments.DCCInfo
* @param {String} exchangeRateSourceTimeStamp 
*/
DCCInfo.prototype.setExchangeRateSourceTimeStamp = function(exchangeRateSourceTimeStamp) {
  this.exchangeRateSourceTimeStamp = exchangeRateSourceTimeStamp;
};

/**
* Get the field value
* Exchange Rate Source Timestamp
* @memberof payments.DCCInfo
* @return {String} 
*/
DCCInfo.prototype.getExchangeRateSourceTimeStamp = function() {
  return this.exchangeRateSourceTimeStamp;
};

/**
* @memberof payments.DCCInfo
* @private
*/
DCCInfo.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DCCInfo.prototype.toString = function() {
  return JSON.stringify(this);
};

DCCInfo._meta_ =  {fields:  {}};
DCCInfo._meta_._class_ =  DCCInfo;
DCCInfo._meta_.fields["inquiryRateId"] = {};
DCCInfo._meta_.fields["inquiryRateId"].type = Number;
DCCInfo._meta_.fields["dccApplied"] = {};
DCCInfo._meta_.fields["dccApplied"].type = Boolean;
DCCInfo._meta_.fields["foreignCurrencyCode"] = {};
DCCInfo._meta_.fields["foreignCurrencyCode"].type = String;
DCCInfo._meta_.fields["foreignAmount"] = {};
DCCInfo._meta_.fields["foreignAmount"].type = Number;
DCCInfo._meta_.fields["exchangeRate"] = {};
DCCInfo._meta_.fields["exchangeRate"].type = Number;
DCCInfo._meta_.fields["marginRatePercentage"] = {};
DCCInfo._meta_.fields["marginRatePercentage"].type = String;
DCCInfo._meta_.fields["exchangeRateSourceName"] = {};
DCCInfo._meta_.fields["exchangeRateSourceName"].type = String;
DCCInfo._meta_.fields["exchangeRateSourceTimeStamp"] = {};
DCCInfo._meta_.fields["exchangeRateSourceTimeStamp"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DCCInfo;
}

