/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/** Snapshot of a line item modifier at the time that the order was placed. */
/**
* @constructor
* @memberof order
*/
var DisplayModification = function() {
  this._class_ = DisplayModification;
  this.id = undefined;
  this.name = undefined;
  this.amount = undefined;
};


/**
* Set the field value
* @memberof order.DisplayModification
* @param {String} id 
*/
DisplayModification.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* @memberof order.DisplayModification
* @return {String} 
*/
DisplayModification.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof order.DisplayModification
* @param {String} name 
*/
DisplayModification.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* @memberof order.DisplayModification
* @return {String} 
*/
DisplayModification.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* @memberof order.DisplayModification
* @param {String} amount 
*/
DisplayModification.prototype.setAmount = function(amount) {
  this.amount = amount;
};

/**
* Get the field value
* @memberof order.DisplayModification
* @return {String} 
*/
DisplayModification.prototype.getAmount = function() {
  return this.amount;
};

/**
* @memberof order.DisplayModification
* @private
*/
DisplayModification.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DisplayModification.prototype.toString = function() {
  return JSON.stringify(this);
};

DisplayModification._meta_ =  {fields:  {}};
DisplayModification._meta_._class_ =  DisplayModification;
DisplayModification._meta_.fields["id"] = {};
DisplayModification._meta_.fields["id"].type = String;
DisplayModification._meta_.fields["name"] = {};
DisplayModification._meta_.fields["name"].type = String;
DisplayModification._meta_.fields["amount"] = {};
DisplayModification._meta_.fields["amount"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DisplayModification;
}

