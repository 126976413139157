import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: { 
    flexDirection: "row"
  },
  leftExternalSpace: {
    width: "10%",  
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start" 
  },
  contentContainer: {
    width: "100%", 
    flexDirection: "column"
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
  },
  title: {
    fontSize: 12,
    color: '#7C5BFF',
    letterSpacing: 1.25,
  },
  titleSeeMoreResults: {
    fontSize: 12,
    color: '#89909B',
    letterSpacing: 1.25,
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 15,
    marginRight: 30,
    marginTop: 20,
    marginBottom: 20,
  },
});
