/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var ExternalDeviceState = {
  IDLE : "IDLE",
  BUSY : "BUSY",
  WAITING_FOR_POS : "WAITING_FOR_POS",
  WAITING_FOR_CUSTOMER : "WAITING_FOR_CUSTOMER",
  UNKNOWN : "UNKNOWN"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ExternalDeviceState;
}

