/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var CardType = {
  VISA : "VISA",
  MC : "MC",
  AMEX : "AMEX",
  DISCOVER : "DISCOVER",
  DINERS_CLUB : "DINERS_CLUB",
  JCB : "JCB",
  MAESTRO : "MAESTRO",
  SOLO : "SOLO",
  LASER : "LASER",
  CHINA_UNION_PAY : "CHINA_UNION_PAY",
  CARTE_BLANCHE : "CARTE_BLANCHE",
  UNKNOWN : "UNKNOWN",
  GIFT_CARD : "GIFT_CARD",
  EBT : "EBT",
  GIROCARD : "GIROCARD",
  INTERAC : "INTERAC",
  OTHER : "OTHER"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CardType;
}

