/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_CardData = require("../base/CardData");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var ReadCardDataResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = ReadCardDataResponse;
  this.cardData = undefined;
};

ReadCardDataResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
ReadCardDataResponse.prototype.constructor = ReadCardDataResponse;

/**
* Set the field value
* The data for the card
*
* @memberof remotepay.ReadCardDataResponse
* @param {base.CardData} cardData 
*/
ReadCardDataResponse.prototype.setCardData = function(cardData) {
  this.cardData = cardData;
};

/**
* Get the field value
* The data for the card
* @memberof remotepay.ReadCardDataResponse
* @return {base.CardData} 
*/
ReadCardDataResponse.prototype.getCardData = function() {
  return this.cardData;
};

ReadCardDataResponse._meta_ =  {fields:  {}};
ReadCardDataResponse._meta_._class_ =  ReadCardDataResponse;
ReadCardDataResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
ReadCardDataResponse._meta_.fields["cardData"] = {};
ReadCardDataResponse._meta_.fields["cardData"].type = base_CardData;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ReadCardDataResponse;
}

