/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var PartialAuthMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = PartialAuthMessage;
  this.setMethod(remotemessage_Method["PARTIAL_AUTH"]);
  this.partialAuthAmount = undefined;
};

PartialAuthMessage.prototype = Object.create(remotemessage_Message.prototype);
PartialAuthMessage.prototype.constructor = PartialAuthMessage;

/**
* Set the field value
* Amount authorized
*
* @memberof remotemessage.PartialAuthMessage
* @param {Number} partialAuthAmount must be a long integer
*/
PartialAuthMessage.prototype.setPartialAuthAmount = function(partialAuthAmount) {
  this.partialAuthAmount = partialAuthAmount;
};

/**
* Get the field value
* Amount authorized
* @memberof remotemessage.PartialAuthMessage
* @return {Number} must be a long integer
*/
PartialAuthMessage.prototype.getPartialAuthAmount = function() {
  return this.partialAuthAmount;
};

PartialAuthMessage._meta_ =  {fields:  {}};
PartialAuthMessage._meta_._class_ =  PartialAuthMessage;
PartialAuthMessage._meta_._superMeta_ = remotemessage_Message._meta_;
PartialAuthMessage._meta_.fields["partialAuthAmount"] = {};
PartialAuthMessage._meta_.fields["partialAuthAmount"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PartialAuthMessage;
}

