/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof device
* @enum {string}
*/
var DeviceEventType = {
  REGISTERED : "REGISTERED",
  PROVISIONED : "PROVISIONED",
  ACTIVATED : "ACTIVATED",
  REACTIVATED : "REACTIVATED",
  DEACTIVATED : "DEACTIVATED",
  DISASSOCIATED : "DISASSOCIATED",
  POS_MODE : "POS_MODE",
  CFD_MODE : "CFD_MODE"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeviceEventType;
}

