/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var payments_Authorization = require("../payments/Authorization");
var remotemessage_ResultStatus = require("../remotemessage/ResultStatus");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var IncrementPreAuthResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = IncrementPreAuthResponseMessage;
  this.setMethod(remotemessage_Method["INCREMENT_PREAUTH_RESPONSE"]);
  this.status = undefined;
  this.reason = undefined;
  this.authorization = undefined;
};

IncrementPreAuthResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
IncrementPreAuthResponseMessage.prototype.constructor = IncrementPreAuthResponseMessage;

/**
* Set the field value
* The status of the request.
*
* @memberof remotemessage.IncrementPreAuthResponseMessage
* @param {remotemessage.ResultStatus} status 
*/
IncrementPreAuthResponseMessage.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* The status of the request.
* @memberof remotemessage.IncrementPreAuthResponseMessage
* @return {remotemessage.ResultStatus} 
*/
IncrementPreAuthResponseMessage.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* Detail message from the server.
*
* @memberof remotemessage.IncrementPreAuthResponseMessage
* @param {String} reason 
*/
IncrementPreAuthResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* Detail message from the server.
* @memberof remotemessage.IncrementPreAuthResponseMessage
* @return {String} 
*/
IncrementPreAuthResponseMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* The resulting Authorization
*
* @memberof remotemessage.IncrementPreAuthResponseMessage
* @param {payments.Authorization} authorization 
*/
IncrementPreAuthResponseMessage.prototype.setAuthorization = function(authorization) {
  this.authorization = authorization;
};

/**
* Get the field value
* The resulting Authorization
* @memberof remotemessage.IncrementPreAuthResponseMessage
* @return {payments.Authorization} 
*/
IncrementPreAuthResponseMessage.prototype.getAuthorization = function() {
  return this.authorization;
};

IncrementPreAuthResponseMessage._meta_ =  {fields:  {}};
IncrementPreAuthResponseMessage._meta_._class_ =  IncrementPreAuthResponseMessage;
IncrementPreAuthResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
IncrementPreAuthResponseMessage._meta_.fields["status"] = {};
IncrementPreAuthResponseMessage._meta_.fields["status"].type = remotemessage_ResultStatus;
IncrementPreAuthResponseMessage._meta_.fields["reason"] = {};
IncrementPreAuthResponseMessage._meta_.fields["reason"].type = String;
IncrementPreAuthResponseMessage._meta_.fields["authorization"] = {};
IncrementPreAuthResponseMessage._meta_.fields["authorization"].type = payments_Authorization;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = IncrementPreAuthResponseMessage;
}

