/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof pay
* @enum {string}
*/
var ServiceCode1 = {
  INTERNATIONAL_INTERCHANGE_OK : "INTERNATIONAL_INTERCHANGE_OK",
  INTERNATIONAL_INTERCHANGE_USE_IC_CHIP_WHERE_FEASIBLE : "INTERNATIONAL_INTERCHANGE_USE_IC_CHIP_WHERE_FEASIBLE",
  NATIONAL_INTERCHANGE_ONLY_EXCEPT_UNDER_BILATERAL_AGREEMENT : "NATIONAL_INTERCHANGE_ONLY_EXCEPT_UNDER_BILATERAL_AGREEMENT",
  NATIONAL_INTERCHANGE_ONLY_EXCEPT_UNDER_BILATERAL_AGREEMENT_USE_IC_CHIP_WHERE_FEASIBLE : "NATIONAL_INTERCHANGE_ONLY_EXCEPT_UNDER_BILATERAL_AGREEMENT_USE_IC_CHIP_WHERE_FEASIBLE",
  NO_INTERCHANGE_EXCEPT_UNDER_BILATERAL_AGREEMENT : "NO_INTERCHANGE_EXCEPT_UNDER_BILATERAL_AGREEMENT",
  TEST : "TEST",
  UNDEFINED : "UNDEFINED"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ServiceCode1;
}

