/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof base
*/
var CountryInfo = function() {
  this._class_ = CountryInfo;
  this.countryCode = undefined;
  this.displayName = undefined;
  this.localDisplayName = undefined;
  this.defaultCurrency = undefined;
  this.defaultTimezone = undefined;
  this.stateProvinceRequired = undefined;
  this.zipPostalRequired = undefined;
  this.countyRequired = undefined;
  this.defaultLocale = undefined;
  this.appMarketBillingEnabled = undefined;
};


/**
* Set the field value
* ISO 3166-1-alpha-2 code
*
* @memberof base.CountryInfo
* @param {String} countryCode 
*/
CountryInfo.prototype.setCountryCode = function(countryCode) {
  this.countryCode = countryCode;
};

/**
* Get the field value
* ISO 3166-1-alpha-2 code
* @memberof base.CountryInfo
* @return {String} 
*/
CountryInfo.prototype.getCountryCode = function() {
  return this.countryCode;
};

/**
* Set the field value
* @memberof base.CountryInfo
* @param {String} displayName 
*/
CountryInfo.prototype.setDisplayName = function(displayName) {
  this.displayName = displayName;
};

/**
* Get the field value
* @memberof base.CountryInfo
* @return {String} 
*/
CountryInfo.prototype.getDisplayName = function() {
  return this.displayName;
};

/**
* Set the field value
* @memberof base.CountryInfo
* @param {Null|String} localDisplayName 
*/
CountryInfo.prototype.setLocalDisplayName = function(localDisplayName) {
  this.localDisplayName = localDisplayName;
};

/**
* Get the field value
* @memberof base.CountryInfo
* @return {Null|String} 
*/
CountryInfo.prototype.getLocalDisplayName = function() {
  return this.localDisplayName;
};

/**
* Set the field value
* @memberof base.CountryInfo
* @param {String} defaultCurrency 
*/
CountryInfo.prototype.setDefaultCurrency = function(defaultCurrency) {
  this.defaultCurrency = defaultCurrency;
};

/**
* Get the field value
* @memberof base.CountryInfo
* @return {String} 
*/
CountryInfo.prototype.getDefaultCurrency = function() {
  return this.defaultCurrency;
};

/**
* Set the field value
* @memberof base.CountryInfo
* @param {String} defaultTimezone 
*/
CountryInfo.prototype.setDefaultTimezone = function(defaultTimezone) {
  this.defaultTimezone = defaultTimezone;
};

/**
* Get the field value
* @memberof base.CountryInfo
* @return {String} 
*/
CountryInfo.prototype.getDefaultTimezone = function() {
  return this.defaultTimezone;
};

/**
* Set the field value
* Indicates whether the state/province field is required when creating the address
*
* @memberof base.CountryInfo
* @param {Boolean} stateProvinceRequired 
*/
CountryInfo.prototype.setStateProvinceRequired = function(stateProvinceRequired) {
  this.stateProvinceRequired = stateProvinceRequired;
};

/**
* Get the field value
* Indicates whether the state/province field is required when creating the address
* @memberof base.CountryInfo
* @return {Boolean} 
*/
CountryInfo.prototype.getStateProvinceRequired = function() {
  return this.stateProvinceRequired;
};

/**
* Set the field value
* Indicates whether the ZIP/Postal code field is required when creating the address
*
* @memberof base.CountryInfo
* @param {Boolean} zipPostalRequired 
*/
CountryInfo.prototype.setZipPostalRequired = function(zipPostalRequired) {
  this.zipPostalRequired = zipPostalRequired;
};

/**
* Get the field value
* Indicates whether the ZIP/Postal code field is required when creating the address
* @memberof base.CountryInfo
* @return {Boolean} 
*/
CountryInfo.prototype.getZipPostalRequired = function() {
  return this.zipPostalRequired;
};

/**
* Set the field value
* Indicates whether the county field is required when creating the address
*
* @memberof base.CountryInfo
* @param {Boolean} countyRequired 
*/
CountryInfo.prototype.setCountyRequired = function(countyRequired) {
  this.countyRequired = countyRequired;
};

/**
* Get the field value
* Indicates whether the county field is required when creating the address
* @memberof base.CountryInfo
* @return {Boolean} 
*/
CountryInfo.prototype.getCountyRequired = function() {
  return this.countyRequired;
};

/**
* Set the field value
* @memberof base.CountryInfo
* @param {String} defaultLocale 
*/
CountryInfo.prototype.setDefaultLocale = function(defaultLocale) {
  this.defaultLocale = defaultLocale;
};

/**
* Get the field value
* @memberof base.CountryInfo
* @return {String} 
*/
CountryInfo.prototype.getDefaultLocale = function() {
  return this.defaultLocale;
};

/**
* Set the field value
* Indicates whether the country is enabled for app market billing
*
* @memberof base.CountryInfo
* @param {Boolean} appMarketBillingEnabled 
*/
CountryInfo.prototype.setAppMarketBillingEnabled = function(appMarketBillingEnabled) {
  this.appMarketBillingEnabled = appMarketBillingEnabled;
};

/**
* Get the field value
* Indicates whether the country is enabled for app market billing
* @memberof base.CountryInfo
* @return {Boolean} 
*/
CountryInfo.prototype.getAppMarketBillingEnabled = function() {
  return this.appMarketBillingEnabled;
};

/**
* @memberof base.CountryInfo
* @private
*/
CountryInfo.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

CountryInfo.prototype.toString = function() {
  return JSON.stringify(this);
};

CountryInfo._meta_ =  {fields:  {}};
CountryInfo._meta_._class_ =  CountryInfo;
CountryInfo._meta_.fields["countryCode"] = {};
CountryInfo._meta_.fields["countryCode"].type = String;
CountryInfo._meta_.fields["displayName"] = {};
CountryInfo._meta_.fields["displayName"].type = String;
CountryInfo._meta_.fields["localDisplayName"] = {};
CountryInfo._meta_.fields["localDisplayName"].type = String;
CountryInfo._meta_.fields["defaultCurrency"] = {};
CountryInfo._meta_.fields["defaultCurrency"].type = String;
CountryInfo._meta_.fields["defaultTimezone"] = {};
CountryInfo._meta_.fields["defaultTimezone"].type = String;
CountryInfo._meta_.fields["stateProvinceRequired"] = {};
CountryInfo._meta_.fields["stateProvinceRequired"].type = Boolean;
CountryInfo._meta_.fields["zipPostalRequired"] = {};
CountryInfo._meta_.fields["zipPostalRequired"].type = Boolean;
CountryInfo._meta_.fields["countyRequired"] = {};
CountryInfo._meta_.fields["countyRequired"].type = Boolean;
CountryInfo._meta_.fields["defaultLocale"] = {};
CountryInfo._meta_.fields["defaultLocale"].type = String;
CountryInfo._meta_.fields["appMarketBillingEnabled"] = {};
CountryInfo._meta_.fields["appMarketBillingEnabled"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CountryInfo;
}

