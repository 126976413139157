/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var employees_Permission = require("../employees/Permission");

/**
* @constructor
* @memberof employees
*/
var Permissions = function() {
  this._class_ = Permissions;
  this.id = undefined;
  this.bits = undefined;
  this.readablePermissions = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof employees.Permissions
* @param {String} id 
*/
Permissions.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof employees.Permissions
* @return {String} 
*/
Permissions.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* A bitmap representing the permissions
*
* @memberof employees.Permissions
* @param {Number} bits must be a long integer
*/
Permissions.prototype.setBits = function(bits) {
  this.bits = bits;
};

/**
* Get the field value
* A bitmap representing the permissions
* @memberof employees.Permissions
* @return {Number} must be a long integer
*/
Permissions.prototype.getBits = function() {
  return this.bits;
};

/**
* Set the field value
* A readable list of permissions
*
* @memberof employees.Permissions
* @param {Array.<employees.Permission>} readablePermissions An array of 
*/
Permissions.prototype.setReadablePermissions = function(readablePermissions) {
  this.readablePermissions = readablePermissions;
};

/**
* Get the field value
* A readable list of permissions
* @memberof employees.Permissions
* @return {Array.<employees.Permission>} An array of 
*/
Permissions.prototype.getReadablePermissions = function() {
  return this.readablePermissions;
};

/**
* @memberof employees.Permissions
* @private
*/
Permissions.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Permissions.prototype.toString = function() {
  return JSON.stringify(this);
};

Permissions._meta_ =  {fields:  {}};
Permissions._meta_._class_ =  Permissions;
Permissions._meta_.fields["id"] = {};
Permissions._meta_.fields["id"].type = String;
Permissions._meta_.fields["bits"] = {};
Permissions._meta_.fields["bits"].type = Number;
Permissions._meta_.fields["readablePermissions"] = {};
Permissions._meta_.fields["readablePermissions"].type = Array;
Permissions._meta_.fields["readablePermissions"].elementType = employees_Permission;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Permissions;
}

