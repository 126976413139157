/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var CVVResult = {
  SUCCESS : "SUCCESS",
  FAILURE : "FAILURE",
  NOT_PROCESSED : "NOT_PROCESSED",
  NOT_PRESENT : "NOT_PRESENT"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = CVVResult;
}

