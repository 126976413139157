/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var printer_Printer = require("../printer/Printer");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var GetPrintersResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = GetPrintersResponseMessage;
  this.setMethod(remotemessage_Method["GET_PRINTERS_RESPONSE"]);
  this.printers = undefined;
};

GetPrintersResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
GetPrintersResponseMessage.prototype.constructor = GetPrintersResponseMessage;

/**
* Set the field value
* List of printers of the requested type.
*
* @memberof remotemessage.GetPrintersResponseMessage
* @param {Array.<printer.Printer>} printers An array of 
*/
GetPrintersResponseMessage.prototype.setPrinters = function(printers) {
  this.printers = printers;
};

/**
* Get the field value
* List of printers of the requested type.
* @memberof remotemessage.GetPrintersResponseMessage
* @return {Array.<printer.Printer>} An array of 
*/
GetPrintersResponseMessage.prototype.getPrinters = function() {
  return this.printers;
};

GetPrintersResponseMessage._meta_ =  {fields:  {}};
GetPrintersResponseMessage._meta_._class_ =  GetPrintersResponseMessage;
GetPrintersResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
GetPrintersResponseMessage._meta_.fields["printers"] = {};
GetPrintersResponseMessage._meta_.fields["printers"].type = Array;
GetPrintersResponseMessage._meta_.fields["printers"].elementType = printer_Printer;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = GetPrintersResponseMessage;
}

