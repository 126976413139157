/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_ResultStatus = require("../remotemessage/ResultStatus");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Refund = require("../payments/Refund");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var VoidPaymentRefundResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = VoidPaymentRefundResponseMessage;
  this.setMethod(remotemessage_Method["VOID_PAYMENT_REFUND_RESPONSE"]);
  this.refund = undefined;
  this.status = undefined;
  this.success = false;
  this.reason = undefined;
  this.message = undefined;
};

VoidPaymentRefundResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
VoidPaymentRefundResponseMessage.prototype.constructor = VoidPaymentRefundResponseMessage;

/**
* Set the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @param {payments.Refund} refund 
*/
VoidPaymentRefundResponseMessage.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @return {payments.Refund} 
*/
VoidPaymentRefundResponseMessage.prototype.getRefund = function() {
  return this.refund;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @param {remotemessage.ResultStatus} status 
*/
VoidPaymentRefundResponseMessage.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @return {remotemessage.ResultStatus} 
*/
VoidPaymentRefundResponseMessage.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @param {Boolean} success 
*/
VoidPaymentRefundResponseMessage.prototype.setSuccess = function(success) {
  this.success = success;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @return {Boolean} 
*/
VoidPaymentRefundResponseMessage.prototype.getSuccess = function() {
  return this.success;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @param {String} reason 
*/
VoidPaymentRefundResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @return {String} 
*/
VoidPaymentRefundResponseMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @param {String} message 
*/
VoidPaymentRefundResponseMessage.prototype.setMessage = function(message) {
  this.message = message;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentRefundResponseMessage
* @return {String} 
*/
VoidPaymentRefundResponseMessage.prototype.getMessage = function() {
  return this.message;
};

VoidPaymentRefundResponseMessage._meta_ =  {fields:  {}};
VoidPaymentRefundResponseMessage._meta_._class_ =  VoidPaymentRefundResponseMessage;
VoidPaymentRefundResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
VoidPaymentRefundResponseMessage._meta_.fields["refund"] = {};
VoidPaymentRefundResponseMessage._meta_.fields["refund"].type = payments_Refund;
VoidPaymentRefundResponseMessage._meta_.fields["status"] = {};
VoidPaymentRefundResponseMessage._meta_.fields["status"].type = remotemessage_ResultStatus;
VoidPaymentRefundResponseMessage._meta_.fields["success"] = {};
VoidPaymentRefundResponseMessage._meta_.fields["success"].type = Boolean;
VoidPaymentRefundResponseMessage._meta_.fields["reason"] = {};
VoidPaymentRefundResponseMessage._meta_.fields["reason"].type = String;
VoidPaymentRefundResponseMessage._meta_.fields["message"] = {};
VoidPaymentRefundResponseMessage._meta_.fields["message"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VoidPaymentRefundResponseMessage;
}

