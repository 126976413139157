/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");
var remotemessage_AddDiscountAction = require("../remotemessage/AddDiscountAction");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var OrderActionAddDiscountMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = OrderActionAddDiscountMessage;
  this.setMethod(remotemessage_Method["ORDER_ACTION_ADD_DISCOUNT"]);
  this.addDiscountAction = undefined;
};

OrderActionAddDiscountMessage.prototype = Object.create(remotemessage_Message.prototype);
OrderActionAddDiscountMessage.prototype.constructor = OrderActionAddDiscountMessage;

/**
* Set the field value
* @memberof remotemessage.OrderActionAddDiscountMessage
* @param {remotemessage.AddDiscountAction} addDiscountAction 
*/
OrderActionAddDiscountMessage.prototype.setAddDiscountAction = function(addDiscountAction) {
  this.addDiscountAction = addDiscountAction;
};

/**
* Get the field value
* @memberof remotemessage.OrderActionAddDiscountMessage
* @return {remotemessage.AddDiscountAction} 
*/
OrderActionAddDiscountMessage.prototype.getAddDiscountAction = function() {
  return this.addDiscountAction;
};

OrderActionAddDiscountMessage._meta_ =  {fields:  {}};
OrderActionAddDiscountMessage._meta_._class_ =  OrderActionAddDiscountMessage;
OrderActionAddDiscountMessage._meta_._superMeta_ = remotemessage_Message._meta_;
OrderActionAddDiscountMessage._meta_.fields["addDiscountAction"] = {};
OrderActionAddDiscountMessage._meta_.fields["addDiscountAction"].type = remotemessage_AddDiscountAction;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = OrderActionAddDiscountMessage;
}

