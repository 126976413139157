/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof employees
* @enum {string}
*/
var Permission = {
  ORDERS_R : "ORDERS_R",
  ORDERS_W : "ORDERS_W",
  INVENTORY_R : "INVENTORY_R",
  INVENTORY_W : "INVENTORY_W",
  PAYMENTS_R : "PAYMENTS_R",
  PAYMENTS_W : "PAYMENTS_W",
  CUSTOMERS_R : "CUSTOMERS_R",
  CUSTOMERS_W : "CUSTOMERS_W",
  EMPLOYEES_R : "EMPLOYEES_R",
  EMPLOYEES_W : "EMPLOYEES_W",
  MERCHANT_R : "MERCHANT_R",
  MERCHANT_W : "MERCHANT_W"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Permission;
}

