/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");
var inventory_Option = require("../inventory/Option");

/**
* @constructor
* @memberof inventory
*/
var Attribute = function() {
  this._class_ = Attribute;
  this.id = undefined;
  this.name = undefined;
  this.itemGroup = undefined;
  this.options = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof inventory.Attribute
* @param {String} id 
*/
Attribute.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof inventory.Attribute
* @return {String} 
*/
Attribute.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* Name of this attribute
*
* @memberof inventory.Attribute
* @param {String} name 
*/
Attribute.prototype.setName = function(name) {
  this.name = name;
};

/**
* Get the field value
* Name of this attribute
* @memberof inventory.Attribute
* @return {String} 
*/
Attribute.prototype.getName = function() {
  return this.name;
};

/**
* Set the field value
* The item group this attribute belongs to
*
* @memberof inventory.Attribute
* @param {base.Reference} itemGroup 
*/
Attribute.prototype.setItemGroup = function(itemGroup) {
  this.itemGroup = itemGroup;
};

/**
* Get the field value
* The item group this attribute belongs to
* @memberof inventory.Attribute
* @return {base.Reference} 
*/
Attribute.prototype.getItemGroup = function() {
  return this.itemGroup;
};

/**
* Set the field value
* Options associated with this attribute
*
* @memberof inventory.Attribute
* @param {Array.<inventory.Option>} options An array of 
*/
Attribute.prototype.setOptions = function(options) {
  this.options = options;
};

/**
* Get the field value
* Options associated with this attribute
* @memberof inventory.Attribute
* @return {Array.<inventory.Option>} An array of 
*/
Attribute.prototype.getOptions = function() {
  return this.options;
};

/**
* @memberof inventory.Attribute
* @private
*/
Attribute.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Attribute.prototype.toString = function() {
  return JSON.stringify(this);
};

Attribute._meta_ =  {fields:  {}};
Attribute._meta_._class_ =  Attribute;
Attribute._meta_.fields["id"] = {};
Attribute._meta_.fields["id"].type = String;
Attribute._meta_.fields["name"] = {};
Attribute._meta_.fields["name"].type = String;
Attribute._meta_.fields["itemGroup"] = {};
Attribute._meta_.fields["itemGroup"].type = base_Reference;
Attribute._meta_.fields["options"] = {};
Attribute._meta_.fields["options"].type = Array;
Attribute._meta_.fields["options"].elementType = inventory_Option;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Attribute;
}

