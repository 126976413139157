/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var device_DeviceProvisionState = require("../device/DeviceProvisionState");
var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof device
*/
var DeviceProvision = function() {
  this._class_ = DeviceProvision;
  this.id = undefined;
  this.deviceRef = undefined;
  this.state = undefined;
  this.lastActivationCode = undefined;
  this.activationCode = undefined;
  this.emailSent = undefined;
  this.hasShipment = undefined;
  this.shipment = undefined;
  this.serialNumber = undefined;
  this.chip_uid = undefined;
  this.imei = undefined;
  this.merchant = undefined;
  this.deviceType = undefined;
  this.provisionedTime = undefined;
  this.activatedTime = undefined;
  this.activationCodeCreatedTime = undefined;
  this.terminalId = undefined;
  this.reseller = undefined;
  this.currentRom = undefined;
  this.currentSecureBoardRom = undefined;
  this.bundleIndicator = undefined;
  this.modifiedTime = undefined;
  this.deletedTime = undefined;
  this.deviceWarrantyRef = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof device.DeviceProvision
* @param {String} id 
*/
DeviceProvision.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* A 128-bit UUID, not a normal base-13 Clover ID.
*
* @memberof device.DeviceProvision
* @param {base.Reference} deviceRef 
*/
DeviceProvision.prototype.setDeviceRef = function(deviceRef) {
  this.deviceRef = deviceRef;
};

/**
* Get the field value
* A 128-bit UUID, not a normal base-13 Clover ID.
* @memberof device.DeviceProvision
* @return {base.Reference} 
*/
DeviceProvision.prototype.getDeviceRef = function() {
  return this.deviceRef;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {device.DeviceProvisionState} state 
*/
DeviceProvision.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {device.DeviceProvisionState} 
*/
DeviceProvision.prototype.getState = function() {
  return this.state;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {String} lastActivationCode 
*/
DeviceProvision.prototype.setLastActivationCode = function(lastActivationCode) {
  this.lastActivationCode = lastActivationCode;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getLastActivationCode = function() {
  return this.lastActivationCode;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {String} activationCode 
*/
DeviceProvision.prototype.setActivationCode = function(activationCode) {
  this.activationCode = activationCode;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getActivationCode = function() {
  return this.activationCode;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {Boolean} emailSent 
*/
DeviceProvision.prototype.setEmailSent = function(emailSent) {
  this.emailSent = emailSent;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {Boolean} 
*/
DeviceProvision.prototype.getEmailSent = function() {
  return this.emailSent;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {Boolean} hasShipment 
*/
DeviceProvision.prototype.setHasShipment = function(hasShipment) {
  this.hasShipment = hasShipment;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {Boolean} 
*/
DeviceProvision.prototype.getHasShipment = function() {
  return this.hasShipment;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {base.Reference} shipment 
*/
DeviceProvision.prototype.setShipment = function(shipment) {
  this.shipment = shipment;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {base.Reference} 
*/
DeviceProvision.prototype.getShipment = function() {
  return this.shipment;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {String} serialNumber 
*/
DeviceProvision.prototype.setSerialNumber = function(serialNumber) {
  this.serialNumber = serialNumber;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getSerialNumber = function() {
  return this.serialNumber;
};

/**
* Set the field value
* chip uid associated with the device provisioned.
*
* @memberof device.DeviceProvision
* @param {String} chip_uid 
*/
DeviceProvision.prototype.setChipUid = function(chip_uid) {
  this.chip_uid = chip_uid;
};

/**
* Get the field value
* chip uid associated with the device provisioned.
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getChipUid = function() {
  return this.chip_uid;
};

/**
* Set the field value
* imei associated with the device provisioned.
*
* @memberof device.DeviceProvision
* @param {String} imei 
*/
DeviceProvision.prototype.setImei = function(imei) {
  this.imei = imei;
};

/**
* Get the field value
* imei associated with the device provisioned.
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getImei = function() {
  return this.imei;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {base.Reference} merchant 
*/
DeviceProvision.prototype.setMerchant = function(merchant) {
  this.merchant = merchant;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {base.Reference} 
*/
DeviceProvision.prototype.getMerchant = function() {
  return this.merchant;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {String} deviceType 
*/
DeviceProvision.prototype.setDeviceType = function(deviceType) {
  this.deviceType = deviceType;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getDeviceType = function() {
  return this.deviceType;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {Number} provisionedTime must be a long integer
*/
DeviceProvision.prototype.setProvisionedTime = function(provisionedTime) {
  this.provisionedTime = provisionedTime;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {Number} must be a long integer
*/
DeviceProvision.prototype.getProvisionedTime = function() {
  return this.provisionedTime;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {Number} activatedTime must be a long integer
*/
DeviceProvision.prototype.setActivatedTime = function(activatedTime) {
  this.activatedTime = activatedTime;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {Number} must be a long integer
*/
DeviceProvision.prototype.getActivatedTime = function() {
  return this.activatedTime;
};

/**
* Set the field value
* Date/time the activation code was created.
*
* @memberof device.DeviceProvision
* @param {Number} activationCodeCreatedTime must be a long integer
*/
DeviceProvision.prototype.setActivationCodeCreatedTime = function(activationCodeCreatedTime) {
  this.activationCodeCreatedTime = activationCodeCreatedTime;
};

/**
* Get the field value
* Date/time the activation code was created.
* @memberof device.DeviceProvision
* @return {Number} must be a long integer
*/
DeviceProvision.prototype.getActivationCodeCreatedTime = function() {
  return this.activationCodeCreatedTime;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {String} terminalId 
*/
DeviceProvision.prototype.setTerminalId = function(terminalId) {
  this.terminalId = terminalId;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getTerminalId = function() {
  return this.terminalId;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {base.Reference} reseller 
*/
DeviceProvision.prototype.setReseller = function(reseller) {
  this.reseller = reseller;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {base.Reference} 
*/
DeviceProvision.prototype.getReseller = function() {
  return this.reseller;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {base.Reference} currentRom 
*/
DeviceProvision.prototype.setCurrentRom = function(currentRom) {
  this.currentRom = currentRom;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {base.Reference} 
*/
DeviceProvision.prototype.getCurrentRom = function() {
  return this.currentRom;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {base.Reference} currentSecureBoardRom 
*/
DeviceProvision.prototype.setCurrentSecureBoardRom = function(currentSecureBoardRom) {
  this.currentSecureBoardRom = currentSecureBoardRom;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {base.Reference} 
*/
DeviceProvision.prototype.getCurrentSecureBoardRom = function() {
  return this.currentSecureBoardRom;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {String} bundleIndicator 
*/
DeviceProvision.prototype.setBundleIndicator = function(bundleIndicator) {
  this.bundleIndicator = bundleIndicator;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {String} 
*/
DeviceProvision.prototype.getBundleIndicator = function() {
  return this.bundleIndicator;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {Number} modifiedTime must be a long integer
*/
DeviceProvision.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {Number} must be a long integer
*/
DeviceProvision.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* Date/time this Device Provision was deleted.
*
* @memberof device.DeviceProvision
* @param {Number} deletedTime must be a long integer
*/
DeviceProvision.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* Date/time this Device Provision was deleted.
* @memberof device.DeviceProvision
* @return {Number} must be a long integer
*/
DeviceProvision.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* Set the field value
* @memberof device.DeviceProvision
* @param {base.Reference} deviceWarrantyRef 
*/
DeviceProvision.prototype.setDeviceWarrantyRef = function(deviceWarrantyRef) {
  this.deviceWarrantyRef = deviceWarrantyRef;
};

/**
* Get the field value
* @memberof device.DeviceProvision
* @return {base.Reference} 
*/
DeviceProvision.prototype.getDeviceWarrantyRef = function() {
  return this.deviceWarrantyRef;
};

/**
* @memberof device.DeviceProvision
* @private
*/
DeviceProvision.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

DeviceProvision.prototype.toString = function() {
  return JSON.stringify(this);
};

DeviceProvision._meta_ =  {fields:  {}};
DeviceProvision._meta_._class_ =  DeviceProvision;
DeviceProvision._meta_.fields["id"] = {};
DeviceProvision._meta_.fields["id"].type = String;
DeviceProvision._meta_.fields["deviceRef"] = {};
DeviceProvision._meta_.fields["deviceRef"].type = base_Reference;
DeviceProvision._meta_.fields["state"] = {};
DeviceProvision._meta_.fields["state"].type = device_DeviceProvisionState;
DeviceProvision._meta_.fields["lastActivationCode"] = {};
DeviceProvision._meta_.fields["lastActivationCode"].type = String;
DeviceProvision._meta_.fields["activationCode"] = {};
DeviceProvision._meta_.fields["activationCode"].type = String;
DeviceProvision._meta_.fields["emailSent"] = {};
DeviceProvision._meta_.fields["emailSent"].type = Boolean;
DeviceProvision._meta_.fields["hasShipment"] = {};
DeviceProvision._meta_.fields["hasShipment"].type = Boolean;
DeviceProvision._meta_.fields["shipment"] = {};
DeviceProvision._meta_.fields["shipment"].type = base_Reference;
DeviceProvision._meta_.fields["serialNumber"] = {};
DeviceProvision._meta_.fields["serialNumber"].type = String;
DeviceProvision._meta_.fields["chip_uid"] = {};
DeviceProvision._meta_.fields["chip_uid"].type = String;
DeviceProvision._meta_.fields["imei"] = {};
DeviceProvision._meta_.fields["imei"].type = String;
DeviceProvision._meta_.fields["merchant"] = {};
DeviceProvision._meta_.fields["merchant"].type = base_Reference;
DeviceProvision._meta_.fields["deviceType"] = {};
DeviceProvision._meta_.fields["deviceType"].type = String;
DeviceProvision._meta_.fields["provisionedTime"] = {};
DeviceProvision._meta_.fields["provisionedTime"].type = Number;
DeviceProvision._meta_.fields["activatedTime"] = {};
DeviceProvision._meta_.fields["activatedTime"].type = Number;
DeviceProvision._meta_.fields["activationCodeCreatedTime"] = {};
DeviceProvision._meta_.fields["activationCodeCreatedTime"].type = Number;
DeviceProvision._meta_.fields["terminalId"] = {};
DeviceProvision._meta_.fields["terminalId"].type = String;
DeviceProvision._meta_.fields["reseller"] = {};
DeviceProvision._meta_.fields["reseller"].type = base_Reference;
DeviceProvision._meta_.fields["currentRom"] = {};
DeviceProvision._meta_.fields["currentRom"].type = base_Reference;
DeviceProvision._meta_.fields["currentSecureBoardRom"] = {};
DeviceProvision._meta_.fields["currentSecureBoardRom"].type = base_Reference;
DeviceProvision._meta_.fields["bundleIndicator"] = {};
DeviceProvision._meta_.fields["bundleIndicator"].type = String;
DeviceProvision._meta_.fields["modifiedTime"] = {};
DeviceProvision._meta_.fields["modifiedTime"].type = Number;
DeviceProvision._meta_.fields["deletedTime"] = {};
DeviceProvision._meta_.fields["deletedTime"].type = Number;
DeviceProvision._meta_.fields["deviceWarrantyRef"] = {};
DeviceProvision._meta_.fields["deviceWarrantyRef"].type = base_Reference;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = DeviceProvision;
}

