/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof remotemessage
*/
var ExternalDeviceStateData = function() {
  this._class_ = ExternalDeviceStateData;
  this.externalPaymentId = undefined;
  this.customActivityId = undefined;
};


/**
* Set the field value
* External Payment Id, if applicable
*
* @memberof remotemessage.ExternalDeviceStateData
* @param {String} externalPaymentId 
*/
ExternalDeviceStateData.prototype.setExternalPaymentId = function(externalPaymentId) {
  this.externalPaymentId = externalPaymentId;
};

/**
* Get the field value
* External Payment Id, if applicable
* @memberof remotemessage.ExternalDeviceStateData
* @return {String} 
*/
ExternalDeviceStateData.prototype.getExternalPaymentId = function() {
  return this.externalPaymentId;
};

/**
* Set the field value
* Custom Activity Id, if applicable
*
* @memberof remotemessage.ExternalDeviceStateData
* @param {String} customActivityId 
*/
ExternalDeviceStateData.prototype.setCustomActivityId = function(customActivityId) {
  this.customActivityId = customActivityId;
};

/**
* Get the field value
* Custom Activity Id, if applicable
* @memberof remotemessage.ExternalDeviceStateData
* @return {String} 
*/
ExternalDeviceStateData.prototype.getCustomActivityId = function() {
  return this.customActivityId;
};

/**
* @memberof remotemessage.ExternalDeviceStateData
* @private
*/
ExternalDeviceStateData.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

ExternalDeviceStateData.prototype.toString = function() {
  return JSON.stringify(this);
};

ExternalDeviceStateData._meta_ =  {fields:  {}};
ExternalDeviceStateData._meta_._class_ =  ExternalDeviceStateData;
ExternalDeviceStateData._meta_.fields["externalPaymentId"] = {};
ExternalDeviceStateData._meta_.fields["externalPaymentId"].type = String;
ExternalDeviceStateData._meta_.fields["customActivityId"] = {};
ExternalDeviceStateData._meta_.fields["customActivityId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ExternalDeviceStateData;
}

