/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof device
*/
var AssociatedDeviceBundle = function() {
  this._class_ = AssociatedDeviceBundle;
  this.id = undefined;
  this.bundleIndicator = undefined;
  this.createdTime = undefined;
  this.deletedTime = undefined;
  this.associatedTime = undefined;
  this.disassociatedTime = undefined;
};


/**
* Set the field value
* Unique identifier
*
* @memberof device.AssociatedDeviceBundle
* @param {String} id 
*/
AssociatedDeviceBundle.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Unique identifier
* @memberof device.AssociatedDeviceBundle
* @return {String} 
*/
AssociatedDeviceBundle.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof device.AssociatedDeviceBundle
* @param {String} bundleIndicator 
*/
AssociatedDeviceBundle.prototype.setBundleIndicator = function(bundleIndicator) {
  this.bundleIndicator = bundleIndicator;
};

/**
* Get the field value
* @memberof device.AssociatedDeviceBundle
* @return {String} 
*/
AssociatedDeviceBundle.prototype.getBundleIndicator = function() {
  return this.bundleIndicator;
};

/**
* Set the field value
* The time that the device bundle was created
*
* @memberof device.AssociatedDeviceBundle
* @param {Number} createdTime must be a long integer
*/
AssociatedDeviceBundle.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* The time that the device bundle was created
* @memberof device.AssociatedDeviceBundle
* @return {Number} must be a long integer
*/
AssociatedDeviceBundle.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* The time that the device bundle was deleted
*
* @memberof device.AssociatedDeviceBundle
* @param {Number} deletedTime must be a long integer
*/
AssociatedDeviceBundle.prototype.setDeletedTime = function(deletedTime) {
  this.deletedTime = deletedTime;
};

/**
* Get the field value
* The time that the device bundle was deleted
* @memberof device.AssociatedDeviceBundle
* @return {Number} must be a long integer
*/
AssociatedDeviceBundle.prototype.getDeletedTime = function() {
  return this.deletedTime;
};

/**
* Set the field value
* The time that the device bundle was associated to a device
*
* @memberof device.AssociatedDeviceBundle
* @param {Number} associatedTime must be a long integer
*/
AssociatedDeviceBundle.prototype.setAssociatedTime = function(associatedTime) {
  this.associatedTime = associatedTime;
};

/**
* Get the field value
* The time that the device bundle was associated to a device
* @memberof device.AssociatedDeviceBundle
* @return {Number} must be a long integer
*/
AssociatedDeviceBundle.prototype.getAssociatedTime = function() {
  return this.associatedTime;
};

/**
* Set the field value
* The time that the device bundle was disassociated from a device
*
* @memberof device.AssociatedDeviceBundle
* @param {Number} disassociatedTime must be a long integer
*/
AssociatedDeviceBundle.prototype.setDisassociatedTime = function(disassociatedTime) {
  this.disassociatedTime = disassociatedTime;
};

/**
* Get the field value
* The time that the device bundle was disassociated from a device
* @memberof device.AssociatedDeviceBundle
* @return {Number} must be a long integer
*/
AssociatedDeviceBundle.prototype.getDisassociatedTime = function() {
  return this.disassociatedTime;
};

/**
* @memberof device.AssociatedDeviceBundle
* @private
*/
AssociatedDeviceBundle.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

AssociatedDeviceBundle.prototype.toString = function() {
  return JSON.stringify(this);
};

AssociatedDeviceBundle._meta_ =  {fields:  {}};
AssociatedDeviceBundle._meta_._class_ =  AssociatedDeviceBundle;
AssociatedDeviceBundle._meta_.fields["id"] = {};
AssociatedDeviceBundle._meta_.fields["id"].type = String;
AssociatedDeviceBundle._meta_.fields["bundleIndicator"] = {};
AssociatedDeviceBundle._meta_.fields["bundleIndicator"].type = String;
AssociatedDeviceBundle._meta_.fields["createdTime"] = {};
AssociatedDeviceBundle._meta_.fields["createdTime"].type = Number;
AssociatedDeviceBundle._meta_.fields["deletedTime"] = {};
AssociatedDeviceBundle._meta_.fields["deletedTime"].type = Number;
AssociatedDeviceBundle._meta_.fields["associatedTime"] = {};
AssociatedDeviceBundle._meta_.fields["associatedTime"].type = Number;
AssociatedDeviceBundle._meta_.fields["disassociatedTime"] = {};
AssociatedDeviceBundle._meta_.fields["disassociatedTime"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AssociatedDeviceBundle;
}

