/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var base_Reference = require("../base/Reference");

/**
* @constructor
* @memberof order
*/
var SendReceiptRequest = function() {
  this._class_ = SendReceiptRequest;
  this.payment = undefined;
  this.refund = undefined;
  this.credit = undefined;
  this.emailAddresses = undefined;
  this.phoneNumbers = undefined;
};


/**
* Set the field value
* The payment of the requested receipt.
*
* @memberof order.SendReceiptRequest
* @param {Null|base.Reference} payment 
*/
SendReceiptRequest.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* The payment of the requested receipt.
* @memberof order.SendReceiptRequest
* @return {Null|base.Reference} 
*/
SendReceiptRequest.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* The refund of the requested receipt.
*
* @memberof order.SendReceiptRequest
* @param {Null|base.Reference} refund 
*/
SendReceiptRequest.prototype.setRefund = function(refund) {
  this.refund = refund;
};

/**
* Get the field value
* The refund of the requested receipt.
* @memberof order.SendReceiptRequest
* @return {Null|base.Reference} 
*/
SendReceiptRequest.prototype.getRefund = function() {
  return this.refund;
};

/**
* Set the field value
* The credit of the requested receipt.
*
* @memberof order.SendReceiptRequest
* @param {Null|base.Reference} credit 
*/
SendReceiptRequest.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* The credit of the requested receipt.
* @memberof order.SendReceiptRequest
* @return {Null|base.Reference} 
*/
SendReceiptRequest.prototype.getCredit = function() {
  return this.credit;
};

/**
* Set the field value
* List of email addresses to send the receipt to.
*
* @memberof order.SendReceiptRequest
* @param {Array.<String>} emailAddresses An array of 
*/
SendReceiptRequest.prototype.setEmailAddresses = function(emailAddresses) {
  this.emailAddresses = emailAddresses;
};

/**
* Get the field value
* List of email addresses to send the receipt to.
* @memberof order.SendReceiptRequest
* @return {Array.<String>} An array of 
*/
SendReceiptRequest.prototype.getEmailAddresses = function() {
  return this.emailAddresses;
};

/**
* Set the field value
* List of phone numbers to send the receipt to.
*
* @memberof order.SendReceiptRequest
* @param {Array.<String>} phoneNumbers An array of 
*/
SendReceiptRequest.prototype.setPhoneNumbers = function(phoneNumbers) {
  this.phoneNumbers = phoneNumbers;
};

/**
* Get the field value
* List of phone numbers to send the receipt to.
* @memberof order.SendReceiptRequest
* @return {Array.<String>} An array of 
*/
SendReceiptRequest.prototype.getPhoneNumbers = function() {
  return this.phoneNumbers;
};

/**
* @memberof order.SendReceiptRequest
* @private
*/
SendReceiptRequest.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

SendReceiptRequest.prototype.toString = function() {
  return JSON.stringify(this);
};

SendReceiptRequest._meta_ =  {fields:  {}};
SendReceiptRequest._meta_._class_ =  SendReceiptRequest;
SendReceiptRequest._meta_.fields["payment"] = {};
SendReceiptRequest._meta_.fields["payment"].type = base_Reference;
SendReceiptRequest._meta_.fields["refund"] = {};
SendReceiptRequest._meta_.fields["refund"].type = base_Reference;
SendReceiptRequest._meta_.fields["credit"] = {};
SendReceiptRequest._meta_.fields["credit"].type = base_Reference;
SendReceiptRequest._meta_.fields["emailAddresses"] = {};
SendReceiptRequest._meta_.fields["emailAddresses"].type = Array;
SendReceiptRequest._meta_.fields["phoneNumbers"] = {};
SendReceiptRequest._meta_.fields["phoneNumbers"].type = Array;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = SendReceiptRequest;
}

