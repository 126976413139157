/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotepay
* @enum {string}
*/
var ErrorType = {
  COMMUNICATION : "COMMUNICATION",
  VALIDATION : "VALIDATION",
  EXCEPTION : "EXCEPTION"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ErrorType;
}

