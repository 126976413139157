/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_TxState = require("../remotemessage/TxState");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var TxStateMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = TxStateMessage;
  this.setMethod(remotemessage_Method["TX_STATE"]);
  this.txState = undefined;
};

TxStateMessage.prototype = Object.create(remotemessage_Message.prototype);
TxStateMessage.prototype.constructor = TxStateMessage;

/**
* Set the field value
* Transaction state
*
* @memberof remotemessage.TxStateMessage
* @param {remotemessage.TxState} txState 
*/
TxStateMessage.prototype.setTxState = function(txState) {
  this.txState = txState;
};

/**
* Get the field value
* Transaction state
* @memberof remotemessage.TxStateMessage
* @return {remotemessage.TxState} 
*/
TxStateMessage.prototype.getTxState = function() {
  return this.txState;
};

TxStateMessage._meta_ =  {fields:  {}};
TxStateMessage._meta_._class_ =  TxStateMessage;
TxStateMessage._meta_._superMeta_ = remotemessage_Message._meta_;
TxStateMessage._meta_.fields["txState"] = {};
TxStateMessage._meta_.fields["txState"].type = remotemessage_TxState;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TxStateMessage;
}

