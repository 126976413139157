/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_RemoteMessageType = require("../remotemessage/RemoteMessageType");

/**
* @constructor
* @memberof remotemessage
*/
var RemoteMessage = function() {
  this._class_ = RemoteMessage;
  this.id = undefined;
  this.type = undefined;
  this.packageName = undefined;
  this.method = undefined;
  this.payload = undefined;
  this.remoteSourceSDK = undefined;
  this.remoteApplicationID = undefined;
  this.version = undefined;
  this.fragmentIndex = undefined;
  this.lastFragment = undefined;
  this.attachmentEncoding = undefined;
  this.attachment = undefined;
  this.attachmentUri = undefined;
  this.remotePayCompatibilityVersion = undefined;
};


/**
* Set the field value
* Identifier for the message
*
* @memberof remotemessage.RemoteMessage
* @param {String} id 
*/
RemoteMessage.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* Identifier for the message
* @memberof remotemessage.RemoteMessage
* @return {String} 
*/
RemoteMessage.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof remotemessage.RemoteMessage
* @param {remotemessage.RemoteMessageType} type 
*/
RemoteMessage.prototype.setType = function(type) {
  this.type = type;
};

/**
* Get the field value
* @memberof remotemessage.RemoteMessage
* @return {remotemessage.RemoteMessageType} 
*/
RemoteMessage.prototype.getType = function() {
  return this.type;
};

/**
* Set the field value
* The package name of the sending Clover api
*
* @memberof remotemessage.RemoteMessage
* @param {String} packageName 
*/
RemoteMessage.prototype.setPackageName = function(packageName) {
  this.packageName = packageName;
};

/**
* Get the field value
* The package name of the sending Clover api
* @memberof remotemessage.RemoteMessage
* @return {String} 
*/
RemoteMessage.prototype.getPackageName = function() {
  return this.packageName;
};

/**
* Set the field value
* The name of the business message being sent
*
* @memberof remotemessage.RemoteMessage
* @param {remotemessage.Method} method 
*/
RemoteMessage.prototype.setMethod = function(method) {
  this.method = method;
};

/**
* Get the field value
* The name of the business message being sent
* @memberof remotemessage.RemoteMessage
* @return {remotemessage.Method} 
*/
RemoteMessage.prototype.getMethod = function() {
  return this.method;
};

/**
* Set the field value
* The payload message being sent.  Interpreted based on the method.
*
* @memberof remotemessage.RemoteMessage
* @param {String} payload 
*/
RemoteMessage.prototype.setPayload = function(payload) {
  this.payload = payload;
};

/**
* Get the field value
* The payload message being sent.  Interpreted based on the method.
* @memberof remotemessage.RemoteMessage
* @return {String} 
*/
RemoteMessage.prototype.getPayload = function() {
  return this.payload;
};

/**
* Set the field value
* The identifier for the source SDK.  This identifies the connector.
*
* @memberof remotemessage.RemoteMessage
* @param {String} remoteSourceSDK 
*/
RemoteMessage.prototype.setRemoteSourceSDK = function(remoteSourceSDK) {
  this.remoteSourceSDK = remoteSourceSDK;
};

/**
* Get the field value
* The identifier for the source SDK.  This identifies the connector.
* @memberof remotemessage.RemoteMessage
* @return {String} 
*/
RemoteMessage.prototype.getRemoteSourceSDK = function() {
  return this.remoteSourceSDK;
};

/**
* Set the field value
* The identifier for the sending application.  This identifies the app.
*
* @memberof remotemessage.RemoteMessage
* @param {String} remoteApplicationID 
*/
RemoteMessage.prototype.setRemoteApplicationID = function(remoteApplicationID) {
  this.remoteApplicationID = remoteApplicationID;
};

/**
* Get the field value
* The identifier for the sending application.  This identifies the app.
* @memberof remotemessage.RemoteMessage
* @return {String} 
*/
RemoteMessage.prototype.getRemoteApplicationID = function() {
  return this.remoteApplicationID;
};

/**
* Set the field value
* The Remote Message version.
*
* @memberof remotemessage.RemoteMessage
* @param {Number} version must be an integer
*/
RemoteMessage.prototype.setVersion = function(version) {
  this.version = version;
};

/**
* Get the field value
* The Remote Message version.
* @memberof remotemessage.RemoteMessage
* @return {Number} must be an integer
*/
RemoteMessage.prototype.getVersion = function() {
  return this.version;
};

/**
* Set the field value
* The order of fragments when message is split for large attachments.
*
* @memberof remotemessage.RemoteMessage
* @param {Number} fragmentIndex must be an integer
*/
RemoteMessage.prototype.setFragmentIndex = function(fragmentIndex) {
  this.fragmentIndex = fragmentIndex;
};

/**
* Get the field value
* The order of fragments when message is split for large attachments.
* @memberof remotemessage.RemoteMessage
* @return {Number} must be an integer
*/
RemoteMessage.prototype.getFragmentIndex = function() {
  return this.fragmentIndex;
};

/**
* Set the field value
* If this is the final fragment in a series.
*
* @memberof remotemessage.RemoteMessage
* @param {Boolean} lastFragment 
*/
RemoteMessage.prototype.setLastFragment = function(lastFragment) {
  this.lastFragment = lastFragment;
};

/**
* Get the field value
* If this is the final fragment in a series.
* @memberof remotemessage.RemoteMessage
* @return {Boolean} 
*/
RemoteMessage.prototype.getLastFragment = function() {
  return this.lastFragment;
};

/**
* Set the field value
* The type of encoding used for the attachment, typically BASE64.
*
* @memberof remotemessage.RemoteMessage
* @param {String} attachmentEncoding 
*/
RemoteMessage.prototype.setAttachmentEncoding = function(attachmentEncoding) {
  this.attachmentEncoding = attachmentEncoding;
};

/**
* Get the field value
* The type of encoding used for the attachment, typically BASE64.
* @memberof remotemessage.RemoteMessage
* @return {String} 
*/
RemoteMessage.prototype.getAttachmentEncoding = function() {
  return this.attachmentEncoding;
};

/**
* Set the field value
* The encoded attachment.
*
* @memberof remotemessage.RemoteMessage
* @param {String} attachment 
*/
RemoteMessage.prototype.setAttachment = function(attachment) {
  this.attachment = attachment;
};

/**
* Get the field value
* The encoded attachment.
* @memberof remotemessage.RemoteMessage
* @return {String} 
*/
RemoteMessage.prototype.getAttachment = function() {
  return this.attachment;
};

/**
* Set the field value
* The attachment URI.
*
* @memberof remotemessage.RemoteMessage
* @param {String} attachmentUri 
*/
RemoteMessage.prototype.setAttachmentUri = function(attachmentUri) {
  this.attachmentUri = attachmentUri;
};

/**
* Get the field value
* The attachment URI.
* @memberof remotemessage.RemoteMessage
* @return {String} 
*/
RemoteMessage.prototype.getAttachmentUri = function() {
  return this.attachmentUri;
};

/**
* Set the field value
* This indicates the version of the SDK compatibility to the version of the Pay Display.  This is set to > 1 for 'nextGen' messages
*
* @memberof remotemessage.RemoteMessage
* @param {Number} remotePayCompatibilityVersion must be an integer
*/
RemoteMessage.prototype.setRemotePayCompatibilityVersion = function(remotePayCompatibilityVersion) {
  this.remotePayCompatibilityVersion = remotePayCompatibilityVersion;
};

/**
* Get the field value
* This indicates the version of the SDK compatibility to the version of the Pay Display.  This is set to > 1 for 'nextGen' messages
* @memberof remotemessage.RemoteMessage
* @return {Number} must be an integer
*/
RemoteMessage.prototype.getRemotePayCompatibilityVersion = function() {
  return this.remotePayCompatibilityVersion;
};

/**
* @memberof remotemessage.RemoteMessage
* @private
*/
RemoteMessage.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

RemoteMessage.prototype.toString = function() {
  return JSON.stringify(this);
};

RemoteMessage._meta_ =  {fields:  {}};
RemoteMessage._meta_._class_ =  RemoteMessage;
RemoteMessage._meta_.fields["id"] = {};
RemoteMessage._meta_.fields["id"].type = String;
RemoteMessage._meta_.fields["type"] = {};
RemoteMessage._meta_.fields["type"].type = remotemessage_RemoteMessageType;
RemoteMessage._meta_.fields["packageName"] = {};
RemoteMessage._meta_.fields["packageName"].type = String;
RemoteMessage._meta_.fields["method"] = {};
RemoteMessage._meta_.fields["method"].type = remotemessage_Method;
RemoteMessage._meta_.fields["payload"] = {};
RemoteMessage._meta_.fields["payload"].type = String;
RemoteMessage._meta_.fields["remoteSourceSDK"] = {};
RemoteMessage._meta_.fields["remoteSourceSDK"].type = String;
RemoteMessage._meta_.fields["remoteApplicationID"] = {};
RemoteMessage._meta_.fields["remoteApplicationID"].type = String;
RemoteMessage._meta_.fields["version"] = {};
RemoteMessage._meta_.fields["version"].type = Number;
RemoteMessage._meta_.fields["fragmentIndex"] = {};
RemoteMessage._meta_.fields["fragmentIndex"].type = Number;
RemoteMessage._meta_.fields["lastFragment"] = {};
RemoteMessage._meta_.fields["lastFragment"].type = Boolean;
RemoteMessage._meta_.fields["attachmentEncoding"] = {};
RemoteMessage._meta_.fields["attachmentEncoding"].type = String;
RemoteMessage._meta_.fields["attachment"] = {};
RemoteMessage._meta_.fields["attachment"].type = String;
RemoteMessage._meta_.fields["attachmentUri"] = {};
RemoteMessage._meta_.fields["attachmentUri"].type = String;
RemoteMessage._meta_.fields["remotePayCompatibilityVersion"] = {};
RemoteMessage._meta_.fields["remotePayCompatibilityVersion"].type = Number;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RemoteMessage;
}

