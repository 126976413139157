/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var VoidPaymentRefundRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = VoidPaymentRefundRequest;
  this.refundId = undefined;
  this.orderId = undefined;
  this.employeeId = undefined;
  this.disablePrinting = undefined;
  this.disableReceiptSelection = undefined;
  this.extras = undefined;
};

VoidPaymentRefundRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
VoidPaymentRefundRequest.prototype.constructor = VoidPaymentRefundRequest;

/**
* Set the field value
* Unique identifier
*
* @memberof remotepay.VoidPaymentRefundRequest
* @param {String} refundId 
*/
VoidPaymentRefundRequest.prototype.setRefundId = function(refundId) {
  this.refundId = refundId;
};

/**
* Get the field value
* Unique identifier
* @memberof remotepay.VoidPaymentRefundRequest
* @return {String} 
*/
VoidPaymentRefundRequest.prototype.getRefundId = function() {
  return this.refundId;
};

/**
* Set the field value
* Unique identifier
*
* @memberof remotepay.VoidPaymentRefundRequest
* @param {String} orderId 
*/
VoidPaymentRefundRequest.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier
* @memberof remotepay.VoidPaymentRefundRequest
* @return {String} 
*/
VoidPaymentRefundRequest.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* Unique identifier
*
* @memberof remotepay.VoidPaymentRefundRequest
* @param {String} employeeId 
*/
VoidPaymentRefundRequest.prototype.setEmployeeId = function(employeeId) {
  this.employeeId = employeeId;
};

/**
* Get the field value
* Unique identifier
* @memberof remotepay.VoidPaymentRefundRequest
* @return {String} 
*/
VoidPaymentRefundRequest.prototype.getEmployeeId = function() {
  return this.employeeId;
};

/**
* Set the field value
* If true, then do not print using the clover printer.  Return print information.
*
* @memberof remotepay.VoidPaymentRefundRequest
* @param {Boolean|Null} disablePrinting 
*/
VoidPaymentRefundRequest.prototype.setDisablePrinting = function(disablePrinting) {
  this.disablePrinting = disablePrinting;
};

/**
* Get the field value
* If true, then do not print using the clover printer.  Return print information.
* @memberof remotepay.VoidPaymentRefundRequest
* @return {Boolean|Null} 
*/
VoidPaymentRefundRequest.prototype.getDisablePrinting = function() {
  return this.disablePrinting;
};

/**
* Set the field value
* Do not show the receipt options screen
*
* @memberof remotepay.VoidPaymentRefundRequest
* @param {Boolean|Null} disableReceiptSelection 
*/
VoidPaymentRefundRequest.prototype.setDisableReceiptSelection = function(disableReceiptSelection) {
  this.disableReceiptSelection = disableReceiptSelection;
};

/**
* Get the field value
* Do not show the receipt options screen
* @memberof remotepay.VoidPaymentRefundRequest
* @return {Boolean|Null} 
*/
VoidPaymentRefundRequest.prototype.getDisableReceiptSelection = function() {
  return this.disableReceiptSelection;
};

/**
* Set the field value
* Extra pass-through data used by external systems.
*
* @memberof remotepay.VoidPaymentRefundRequest
* @param {Object.<String,String>} extras A map of <String> to <>
*/
VoidPaymentRefundRequest.prototype.setExtras = function(extras) {
  this.extras = extras;
};

/**
* Get the field value
* Extra pass-through data used by external systems.
* @memberof remotepay.VoidPaymentRefundRequest
* @return {Object.<String,String>} A map of <String> to <>
*/
VoidPaymentRefundRequest.prototype.getExtras = function() {
  return this.extras;
};

VoidPaymentRefundRequest._meta_ =  {fields:  {}};
VoidPaymentRefundRequest._meta_._class_ =  VoidPaymentRefundRequest;
VoidPaymentRefundRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
VoidPaymentRefundRequest._meta_.fields["refundId"] = {};
VoidPaymentRefundRequest._meta_.fields["refundId"].type = String;
VoidPaymentRefundRequest._meta_.fields["orderId"] = {};
VoidPaymentRefundRequest._meta_.fields["orderId"].type = String;
VoidPaymentRefundRequest._meta_.fields["employeeId"] = {};
VoidPaymentRefundRequest._meta_.fields["employeeId"].type = String;
VoidPaymentRefundRequest._meta_.fields["disablePrinting"] = {};
VoidPaymentRefundRequest._meta_.fields["disablePrinting"].type = Boolean;
VoidPaymentRefundRequest._meta_.fields["disableReceiptSelection"] = {};
VoidPaymentRefundRequest._meta_.fields["disableReceiptSelection"].type = Boolean;
VoidPaymentRefundRequest._meta_.fields["extras"] = {};
VoidPaymentRefundRequest._meta_.fields["extras"].type = Object;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VoidPaymentRefundRequest;
}

