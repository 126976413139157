/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var ReversalReason = {
  CHIP_DECLINE : "CHIP_DECLINE",
  CARDHOLDER_CANCELLATION : "CARDHOLDER_CANCELLATION",
  COMMUNICATION_ERROR : "COMMUNICATION_ERROR",
  OTHER_REASON : "OTHER_REASON"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ReversalReason;
}

