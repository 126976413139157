/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof customers
*/
var PhoneNumber = function() {
  this._class_ = PhoneNumber;
  this.id = undefined;
  this.phoneNumber = undefined;
};


/**
* Set the field value
* @memberof customers.PhoneNumber
* @param {String} id 
*/
PhoneNumber.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* @memberof customers.PhoneNumber
* @return {String} 
*/
PhoneNumber.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* @memberof customers.PhoneNumber
* @param {String} phoneNumber 
*/
PhoneNumber.prototype.setPhoneNumber = function(phoneNumber) {
  this.phoneNumber = phoneNumber;
};

/**
* Get the field value
* @memberof customers.PhoneNumber
* @return {String} 
*/
PhoneNumber.prototype.getPhoneNumber = function() {
  return this.phoneNumber;
};

/**
* @memberof customers.PhoneNumber
* @private
*/
PhoneNumber.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

PhoneNumber.prototype.toString = function() {
  return JSON.stringify(this);
};

PhoneNumber._meta_ =  {fields:  {}};
PhoneNumber._meta_._class_ =  PhoneNumber;
PhoneNumber._meta_.fields["id"] = {};
PhoneNumber._meta_.fields["id"].type = String;
PhoneNumber._meta_.fields["phoneNumber"] = {};
PhoneNumber._meta_.fields["phoneNumber"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = PhoneNumber;
}

