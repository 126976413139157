/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_KeyPress = require("../remotemessage/KeyPress");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var KeyPressMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = KeyPressMessage;
  this.setMethod(remotemessage_Method["KEY_PRESS"]);
  this.keyPress = undefined;
};

KeyPressMessage.prototype = Object.create(remotemessage_Message.prototype);
KeyPressMessage.prototype.constructor = KeyPressMessage;

/**
* Set the field value
* @memberof remotemessage.KeyPressMessage
* @param {remotemessage.KeyPress} keyPress 
*/
KeyPressMessage.prototype.setKeyPress = function(keyPress) {
  this.keyPress = keyPress;
};

/**
* Get the field value
* @memberof remotemessage.KeyPressMessage
* @return {remotemessage.KeyPress} 
*/
KeyPressMessage.prototype.getKeyPress = function() {
  return this.keyPress;
};

KeyPressMessage._meta_ =  {fields:  {}};
KeyPressMessage._meta_._class_ =  KeyPressMessage;
KeyPressMessage._meta_._superMeta_ = remotemessage_Message._meta_;
KeyPressMessage._meta_.fields["keyPress"] = {};
KeyPressMessage._meta_.fields["keyPress"].type = remotemessage_KeyPress;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = KeyPressMessage;
}

