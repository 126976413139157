/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var TxState = {
  START : "START",
  SUCCESS : "SUCCESS",
  FAIL : "FAIL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TxState;
}

