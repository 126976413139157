/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var ForceConnectMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = ForceConnectMessage;
  this.setMethod(remotemessage_Method["FORCECONNECT"]);
  this.friendlyId = undefined;
};

ForceConnectMessage.prototype = Object.create(remotemessage_Message.prototype);
ForceConnectMessage.prototype.constructor = ForceConnectMessage;

/**
* Set the field value
* The friendly id of the system that overtook the connection.
*
* @memberof remotemessage.ForceConnectMessage
* @param {String} friendlyId 
*/
ForceConnectMessage.prototype.setFriendlyId = function(friendlyId) {
  this.friendlyId = friendlyId;
};

/**
* Get the field value
* The friendly id of the system that overtook the connection.
* @memberof remotemessage.ForceConnectMessage
* @return {String} 
*/
ForceConnectMessage.prototype.getFriendlyId = function() {
  return this.friendlyId;
};

ForceConnectMessage._meta_ =  {fields:  {}};
ForceConnectMessage._meta_._class_ =  ForceConnectMessage;
ForceConnectMessage._meta_._superMeta_ = remotemessage_Message._meta_;
ForceConnectMessage._meta_.fields["friendlyId"] = {};
ForceConnectMessage._meta_.fields["friendlyId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ForceConnectMessage;
}

