/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");
var remotepay_DataProviderConfig = require("../remotepay/DataProviderConfig");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var RegisterForCustomerProvidedDataRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = RegisterForCustomerProvidedDataRequest;
  this.configurations = undefined;
};

RegisterForCustomerProvidedDataRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
RegisterForCustomerProvidedDataRequest.prototype.constructor = RegisterForCustomerProvidedDataRequest;

/**
* Set the field value
* Configurations for the data we want to receive.
*
* @memberof remotepay.RegisterForCustomerProvidedDataRequest
* @param {Array.<remotepay.DataProviderConfig>} configurations An array of 
*/
RegisterForCustomerProvidedDataRequest.prototype.setConfigurations = function(configurations) {
  this.configurations = configurations;
};

/**
* Get the field value
* Configurations for the data we want to receive.
* @memberof remotepay.RegisterForCustomerProvidedDataRequest
* @return {Array.<remotepay.DataProviderConfig>} An array of 
*/
RegisterForCustomerProvidedDataRequest.prototype.getConfigurations = function() {
  return this.configurations;
};

RegisterForCustomerProvidedDataRequest._meta_ =  {fields:  {}};
RegisterForCustomerProvidedDataRequest._meta_._class_ =  RegisterForCustomerProvidedDataRequest;
RegisterForCustomerProvidedDataRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
RegisterForCustomerProvidedDataRequest._meta_.fields["configurations"] = {};
RegisterForCustomerProvidedDataRequest._meta_.fields["configurations"].type = Array;
RegisterForCustomerProvidedDataRequest._meta_.fields["configurations"].elementType = remotepay_DataProviderConfig;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RegisterForCustomerProvidedDataRequest;
}

