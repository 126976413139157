/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_BatchType = require("../payments/BatchType");
var payments_BatchTransactions = require("../payments/BatchTransactions");
var payments_BatchDetail = require("../payments/BatchDetail");
var payments_BatchState = require("../payments/BatchState");

/**
* @constructor
* @memberof payments
*/
var Batch = function() {
  this._class_ = Batch;
  this.id = undefined;
  this.txCount = 0;
  this.totalBatchAmount = 0;
  this.devices = undefined;
  this.state = payments_BatchState["OPEN"];
  this.batchType = undefined;
  this.createdTime = undefined;
  this.modifiedTime = undefined;
  this.closeoutTimeDifference = undefined;
  this.batchDetails = undefined;
  this.batchTransactions = undefined;
};


/**
* Set the field value
* @memberof payments.Batch
* @param {String} id 
*/
Batch.prototype.setId = function(id) {
  this.id = id;
};

/**
* Get the field value
* @memberof payments.Batch
* @return {String} 
*/
Batch.prototype.getId = function() {
  return this.id;
};

/**
* Set the field value
* The number of transactions being batched
*
* @memberof payments.Batch
* @param {Number} txCount must be a long integer
*/
Batch.prototype.setTxCount = function(txCount) {
  this.txCount = txCount;
};

/**
* Get the field value
* The number of transactions being batched
* @memberof payments.Batch
* @return {Number} must be a long integer
*/
Batch.prototype.getTxCount = function() {
  return this.txCount;
};

/**
* Set the field value
* Total amount closed
*
* @memberof payments.Batch
* @param {Number} totalBatchAmount must be a long integer
*/
Batch.prototype.setTotalBatchAmount = function(totalBatchAmount) {
  this.totalBatchAmount = totalBatchAmount;
};

/**
* Get the field value
* Total amount closed
* @memberof payments.Batch
* @return {Number} must be a long integer
*/
Batch.prototype.getTotalBatchAmount = function() {
  return this.totalBatchAmount;
};

/**
* Set the field value
* List of devices in batch
*
* @memberof payments.Batch
* @param {String} devices 
*/
Batch.prototype.setDevices = function(devices) {
  this.devices = devices;
};

/**
* Get the field value
* List of devices in batch
* @memberof payments.Batch
* @return {String} 
*/
Batch.prototype.getDevices = function() {
  return this.devices;
};

/**
* Set the field value
* @memberof payments.Batch
* @param {payments.BatchState} state 
*/
Batch.prototype.setState = function(state) {
  this.state = state;
};

/**
* Get the field value
* @memberof payments.Batch
* @return {payments.BatchState} 
*/
Batch.prototype.getState = function() {
  return this.state;
};

/**
* Set the field value
* @memberof payments.Batch
* @param {payments.BatchType} batchType 
*/
Batch.prototype.setBatchType = function(batchType) {
  this.batchType = batchType;
};

/**
* Get the field value
* @memberof payments.Batch
* @return {payments.BatchType} 
*/
Batch.prototype.getBatchType = function() {
  return this.batchType;
};

/**
* Set the field value
* Created time of batch
*
* @memberof payments.Batch
* @param {Number} createdTime must be a long integer
*/
Batch.prototype.setCreatedTime = function(createdTime) {
  this.createdTime = createdTime;
};

/**
* Get the field value
* Created time of batch
* @memberof payments.Batch
* @return {Number} must be a long integer
*/
Batch.prototype.getCreatedTime = function() {
  return this.createdTime;
};

/**
* Set the field value
* Modified time of batch
*
* @memberof payments.Batch
* @param {Number} modifiedTime must be a long integer
*/
Batch.prototype.setModifiedTime = function(modifiedTime) {
  this.modifiedTime = modifiedTime;
};

/**
* Get the field value
* Modified time of batch
* @memberof payments.Batch
* @return {Number} must be a long integer
*/
Batch.prototype.getModifiedTime = function() {
  return this.modifiedTime;
};

/**
* Set the field value
* Time difference in minutes between expected and actual closeout
*
* @memberof payments.Batch
* @param {Number} closeoutTimeDifference must be a long integer
*/
Batch.prototype.setCloseoutTimeDifference = function(closeoutTimeDifference) {
  this.closeoutTimeDifference = closeoutTimeDifference;
};

/**
* Get the field value
* Time difference in minutes between expected and actual closeout
* @memberof payments.Batch
* @return {Number} must be a long integer
*/
Batch.prototype.getCloseoutTimeDifference = function() {
  return this.closeoutTimeDifference;
};

/**
* Set the field value
* Details split based on card / employees
*
* @memberof payments.Batch
* @param {payments.BatchDetail} batchDetails 
*/
Batch.prototype.setBatchDetails = function(batchDetails) {
  this.batchDetails = batchDetails;
};

/**
* Get the field value
* Details split based on card / employees
* @memberof payments.Batch
* @return {payments.BatchDetail} 
*/
Batch.prototype.getBatchDetails = function() {
  return this.batchDetails;
};

/**
* Set the field value
* List of payments, refunds, and gift card transaction reference objects in the batch
*
* @memberof payments.Batch
* @param {payments.BatchTransactions} batchTransactions 
*/
Batch.prototype.setBatchTransactions = function(batchTransactions) {
  this.batchTransactions = batchTransactions;
};

/**
* Get the field value
* List of payments, refunds, and gift card transaction reference objects in the batch
* @memberof payments.Batch
* @return {payments.BatchTransactions} 
*/
Batch.prototype.getBatchTransactions = function() {
  return this.batchTransactions;
};

/**
* @memberof payments.Batch
* @private
*/
Batch.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

Batch.prototype.toString = function() {
  return JSON.stringify(this);
};

Batch._meta_ =  {fields:  {}};
Batch._meta_._class_ =  Batch;
Batch._meta_.fields["id"] = {};
Batch._meta_.fields["id"].type = String;
Batch._meta_.fields["txCount"] = {};
Batch._meta_.fields["txCount"].type = Number;
Batch._meta_.fields["totalBatchAmount"] = {};
Batch._meta_.fields["totalBatchAmount"].type = Number;
Batch._meta_.fields["devices"] = {};
Batch._meta_.fields["devices"].type = String;
Batch._meta_.fields["state"] = {};
Batch._meta_.fields["state"].type = payments_BatchState;
Batch._meta_.fields["batchType"] = {};
Batch._meta_.fields["batchType"].type = payments_BatchType;
Batch._meta_.fields["createdTime"] = {};
Batch._meta_.fields["createdTime"].type = Number;
Batch._meta_.fields["modifiedTime"] = {};
Batch._meta_.fields["modifiedTime"].type = Number;
Batch._meta_.fields["closeoutTimeDifference"] = {};
Batch._meta_.fields["closeoutTimeDifference"].type = Number;
Batch._meta_.fields["batchDetails"] = {};
Batch._meta_.fields["batchDetails"].type = payments_BatchDetail;
Batch._meta_.fields["batchTransactions"] = {};
Batch._meta_.fields["batchTransactions"].type = payments_BatchTransactions;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = Batch;
}

