/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var order_DisplayDiscount = require("../order/DisplayDiscount");

/**
* @constructor
* @memberof remotemessage
*/
var AddDiscountAction = function() {
  this._class_ = AddDiscountAction;
  this.discount = undefined;
};


/**
* Set the field value
* @memberof remotemessage.AddDiscountAction
* @param {order.DisplayDiscount} discount 
*/
AddDiscountAction.prototype.setDiscount = function(discount) {
  this.discount = discount;
};

/**
* Get the field value
* @memberof remotemessage.AddDiscountAction
* @return {order.DisplayDiscount} 
*/
AddDiscountAction.prototype.getDiscount = function() {
  return this.discount;
};

/**
* @memberof remotemessage.AddDiscountAction
* @private
*/
AddDiscountAction.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

AddDiscountAction.prototype.toString = function() {
  return JSON.stringify(this);
};

AddDiscountAction._meta_ =  {fields:  {}};
AddDiscountAction._meta_._class_ =  AddDiscountAction;
AddDiscountAction._meta_.fields["discount"] = {};
AddDiscountAction._meta_.fields["discount"].type = order_DisplayDiscount;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = AddDiscountAction;
}

