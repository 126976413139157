/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof payments
* @enum {string}
*/
var TxFormat = {
  DEFAULT : "DEFAULT",
  NEXO : "NEXO"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TxFormat;
}

