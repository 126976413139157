/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_ResultStatus = require("../remotemessage/ResultStatus");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Credit = require("../payments/Credit");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var VoidCreditResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = VoidCreditResponseMessage;
  this.setMethod(remotemessage_Method["VOID_CREDIT_RESPONSE"]);
  this.credit = undefined;
  this.status = undefined;
  this.success = false;
  this.reason = undefined;
  this.message = undefined;
};

VoidCreditResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
VoidCreditResponseMessage.prototype.constructor = VoidCreditResponseMessage;

/**
* Set the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @param {payments.Credit} credit 
*/
VoidCreditResponseMessage.prototype.setCredit = function(credit) {
  this.credit = credit;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @return {payments.Credit} 
*/
VoidCreditResponseMessage.prototype.getCredit = function() {
  return this.credit;
};

/**
* Set the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @param {remotemessage.ResultStatus} status 
*/
VoidCreditResponseMessage.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @return {remotemessage.ResultStatus} 
*/
VoidCreditResponseMessage.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @param {Boolean} success 
*/
VoidCreditResponseMessage.prototype.setSuccess = function(success) {
  this.success = success;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @return {Boolean} 
*/
VoidCreditResponseMessage.prototype.getSuccess = function() {
  return this.success;
};

/**
* Set the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @param {String} reason 
*/
VoidCreditResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @return {String} 
*/
VoidCreditResponseMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @param {String} message 
*/
VoidCreditResponseMessage.prototype.setMessage = function(message) {
  this.message = message;
};

/**
* Get the field value
* @memberof remotemessage.VoidCreditResponseMessage
* @return {String} 
*/
VoidCreditResponseMessage.prototype.getMessage = function() {
  return this.message;
};

VoidCreditResponseMessage._meta_ =  {fields:  {}};
VoidCreditResponseMessage._meta_._class_ =  VoidCreditResponseMessage;
VoidCreditResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
VoidCreditResponseMessage._meta_.fields["credit"] = {};
VoidCreditResponseMessage._meta_.fields["credit"].type = payments_Credit;
VoidCreditResponseMessage._meta_.fields["status"] = {};
VoidCreditResponseMessage._meta_.fields["status"].type = remotemessage_ResultStatus;
VoidCreditResponseMessage._meta_.fields["success"] = {};
VoidCreditResponseMessage._meta_.fields["success"].type = Boolean;
VoidCreditResponseMessage._meta_.fields["reason"] = {};
VoidCreditResponseMessage._meta_.fields["reason"].type = String;
VoidCreditResponseMessage._meta_.fields["message"] = {};
VoidCreditResponseMessage._meta_.fields["message"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VoidCreditResponseMessage;
}

