/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var RetrieveDeviceStatusRequestMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = RetrieveDeviceStatusRequestMessage;
  this.setMethod(remotemessage_Method["RETRIEVE_DEVICE_STATUS_REQUEST"]);
  this.sendLastMessage = false;
};

RetrieveDeviceStatusRequestMessage.prototype = Object.create(remotemessage_Message.prototype);
RetrieveDeviceStatusRequestMessage.prototype.constructor = RetrieveDeviceStatusRequestMessage;

/**
* Set the field value
* Send last message, if true
*
* @memberof remotemessage.RetrieveDeviceStatusRequestMessage
* @param {Boolean} sendLastMessage 
*/
RetrieveDeviceStatusRequestMessage.prototype.setSendLastMessage = function(sendLastMessage) {
  this.sendLastMessage = sendLastMessage;
};

/**
* Get the field value
* Send last message, if true
* @memberof remotemessage.RetrieveDeviceStatusRequestMessage
* @return {Boolean} 
*/
RetrieveDeviceStatusRequestMessage.prototype.getSendLastMessage = function() {
  return this.sendLastMessage;
};

RetrieveDeviceStatusRequestMessage._meta_ =  {fields:  {}};
RetrieveDeviceStatusRequestMessage._meta_._class_ =  RetrieveDeviceStatusRequestMessage;
RetrieveDeviceStatusRequestMessage._meta_._superMeta_ = remotemessage_Message._meta_;
RetrieveDeviceStatusRequestMessage._meta_.fields["sendLastMessage"] = {};
RetrieveDeviceStatusRequestMessage._meta_.fields["sendLastMessage"].type = Boolean;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = RetrieveDeviceStatusRequestMessage;
}

