/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var order_VoidReason = require("../order/VoidReason");
var remotemessage_ResultStatus = require("../remotemessage/ResultStatus");
var remotemessage_Message = require("../remotemessage/Message");
var payments_Payment = require("../payments/Payment");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var VoidPaymentResponseMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = VoidPaymentResponseMessage;
  this.setMethod(remotemessage_Method["VOID_PAYMENT_RESPONSE"]);
  this.payment = undefined;
  this.voidReason = undefined;
  this.status = undefined;
  this.success = false;
  this.reason = undefined;
  this.message = undefined;
};

VoidPaymentResponseMessage.prototype = Object.create(remotemessage_Message.prototype);
VoidPaymentResponseMessage.prototype.constructor = VoidPaymentResponseMessage;

/**
* Set the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @param {payments.Payment} payment 
*/
VoidPaymentResponseMessage.prototype.setPayment = function(payment) {
  this.payment = payment;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @return {payments.Payment} 
*/
VoidPaymentResponseMessage.prototype.getPayment = function() {
  return this.payment;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @param {order.VoidReason} voidReason 
*/
VoidPaymentResponseMessage.prototype.setVoidReason = function(voidReason) {
  this.voidReason = voidReason;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @return {order.VoidReason} 
*/
VoidPaymentResponseMessage.prototype.getVoidReason = function() {
  return this.voidReason;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @param {remotemessage.ResultStatus} status 
*/
VoidPaymentResponseMessage.prototype.setStatus = function(status) {
  this.status = status;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @return {remotemessage.ResultStatus} 
*/
VoidPaymentResponseMessage.prototype.getStatus = function() {
  return this.status;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @param {Boolean} success 
*/
VoidPaymentResponseMessage.prototype.setSuccess = function(success) {
  this.success = success;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @return {Boolean} 
*/
VoidPaymentResponseMessage.prototype.getSuccess = function() {
  return this.success;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @param {String} reason 
*/
VoidPaymentResponseMessage.prototype.setReason = function(reason) {
  this.reason = reason;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @return {String} 
*/
VoidPaymentResponseMessage.prototype.getReason = function() {
  return this.reason;
};

/**
* Set the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @param {String} message 
*/
VoidPaymentResponseMessage.prototype.setMessage = function(message) {
  this.message = message;
};

/**
* Get the field value
* @memberof remotemessage.VoidPaymentResponseMessage
* @return {String} 
*/
VoidPaymentResponseMessage.prototype.getMessage = function() {
  return this.message;
};

VoidPaymentResponseMessage._meta_ =  {fields:  {}};
VoidPaymentResponseMessage._meta_._class_ =  VoidPaymentResponseMessage;
VoidPaymentResponseMessage._meta_._superMeta_ = remotemessage_Message._meta_;
VoidPaymentResponseMessage._meta_.fields["payment"] = {};
VoidPaymentResponseMessage._meta_.fields["payment"].type = payments_Payment;
VoidPaymentResponseMessage._meta_.fields["voidReason"] = {};
VoidPaymentResponseMessage._meta_.fields["voidReason"].type = order_VoidReason;
VoidPaymentResponseMessage._meta_.fields["status"] = {};
VoidPaymentResponseMessage._meta_.fields["status"].type = remotemessage_ResultStatus;
VoidPaymentResponseMessage._meta_.fields["success"] = {};
VoidPaymentResponseMessage._meta_.fields["success"].type = Boolean;
VoidPaymentResponseMessage._meta_.fields["reason"] = {};
VoidPaymentResponseMessage._meta_.fields["reason"].type = String;
VoidPaymentResponseMessage._meta_.fields["message"] = {};
VoidPaymentResponseMessage._meta_.fields["message"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VoidPaymentResponseMessage;
}

