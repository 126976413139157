/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_BaseRequest = require("../remotepay/BaseRequest");

/**
* @constructor
* @augments remotepay.BaseRequest
* @memberof remotepay
*/
var TipAdjustAuthRequest = function() {
  remotepay_BaseRequest.call(this);
  this._superClass_ = remotepay_BaseRequest;
  this._class_ = TipAdjustAuthRequest;
  this.tipAmount = undefined;
  this.orderId = undefined;
  this.paymentId = undefined;
};

TipAdjustAuthRequest.prototype = Object.create(remotepay_BaseRequest.prototype);
TipAdjustAuthRequest.prototype.constructor = TipAdjustAuthRequest;

/**
* Set the field value
* Amount paid in tips
*
* @memberof remotepay.TipAdjustAuthRequest
* @param {Number} tipAmount must be a long integer
*/
TipAdjustAuthRequest.prototype.setTipAmount = function(tipAmount) {
  this.tipAmount = tipAmount;
};

/**
* Get the field value
* Amount paid in tips
* @memberof remotepay.TipAdjustAuthRequest
* @return {Number} must be a long integer
*/
TipAdjustAuthRequest.prototype.getTipAmount = function() {
  return this.tipAmount;
};

/**
* Set the field value
* Unique identifier
*
* @memberof remotepay.TipAdjustAuthRequest
* @param {String} orderId 
*/
TipAdjustAuthRequest.prototype.setOrderId = function(orderId) {
  this.orderId = orderId;
};

/**
* Get the field value
* Unique identifier
* @memberof remotepay.TipAdjustAuthRequest
* @return {String} 
*/
TipAdjustAuthRequest.prototype.getOrderId = function() {
  return this.orderId;
};

/**
* Set the field value
* Unique identifier
*
* @memberof remotepay.TipAdjustAuthRequest
* @param {String} paymentId 
*/
TipAdjustAuthRequest.prototype.setPaymentId = function(paymentId) {
  this.paymentId = paymentId;
};

/**
* Get the field value
* Unique identifier
* @memberof remotepay.TipAdjustAuthRequest
* @return {String} 
*/
TipAdjustAuthRequest.prototype.getPaymentId = function() {
  return this.paymentId;
};

TipAdjustAuthRequest._meta_ =  {fields:  {}};
TipAdjustAuthRequest._meta_._class_ =  TipAdjustAuthRequest;
TipAdjustAuthRequest._meta_._superMeta_ = remotepay_BaseRequest._meta_;
TipAdjustAuthRequest._meta_.fields["tipAmount"] = {};
TipAdjustAuthRequest._meta_.fields["tipAmount"].type = Number;
TipAdjustAuthRequest._meta_.fields["orderId"] = {};
TipAdjustAuthRequest._meta_.fields["orderId"].type = String;
TipAdjustAuthRequest._meta_.fields["paymentId"] = {};
TipAdjustAuthRequest._meta_.fields["paymentId"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TipAdjustAuthRequest;
}

