/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var payments_Authorization = require("../payments/Authorization");
var remotepay_BaseResponse = require("../remotepay/BaseResponse");

/** The result of an attempt to increment a pre auth */
/**
* @constructor
* @augments remotepay.BaseResponse
* @memberof remotepay
*/
var IncrementPreAuthResponse = function() {
  remotepay_BaseResponse.call(this);
  this._superClass_ = remotepay_BaseResponse;
  this._class_ = IncrementPreAuthResponse;
  this.authorization = undefined;
};

IncrementPreAuthResponse.prototype = Object.create(remotepay_BaseResponse.prototype);
IncrementPreAuthResponse.prototype.constructor = IncrementPreAuthResponse;

/**
* Set the field value
* The resulting Authorization
*
* @memberof remotepay.IncrementPreAuthResponse
* @param {payments.Authorization} authorization 
*/
IncrementPreAuthResponse.prototype.setAuthorization = function(authorization) {
  this.authorization = authorization;
};

/**
* Get the field value
* The resulting Authorization
* @memberof remotepay.IncrementPreAuthResponse
* @return {payments.Authorization} 
*/
IncrementPreAuthResponse.prototype.getAuthorization = function() {
  return this.authorization;
};

IncrementPreAuthResponse._meta_ =  {fields:  {}};
IncrementPreAuthResponse._meta_._class_ =  IncrementPreAuthResponse;
IncrementPreAuthResponse._meta_._superMeta_ = remotepay_BaseResponse._meta_;
IncrementPreAuthResponse._meta_.fields["authorization"] = {};
IncrementPreAuthResponse._meta_.fields["authorization"].type = payments_Authorization;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = IncrementPreAuthResponse;
}

