/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @constructor
* @memberof remotemessage
*/
var TxTypeRequestInfo = function() {
  this._class_ = TxTypeRequestInfo;
  this.SALE = "SALE";
  this.AUTH = "AUTH";
  this.PREAUTH = "PREAUTH";
  this.CREDIT = "CREDIT";
  this.REFUND = "REFUND";
  this.VOID_PAYMENT = "VOID_PAYMENT";
};


/**
* Get the field value
* @memberof remotemessage.TxTypeRequestInfo
* @return {String} 
*/
TxTypeRequestInfo.prototype.getSALE = function() {
  return this.SALE;
};

/**
* Get the field value
* @memberof remotemessage.TxTypeRequestInfo
* @return {String} 
*/
TxTypeRequestInfo.prototype.getAUTH = function() {
  return this.AUTH;
};

/**
* Get the field value
* @memberof remotemessage.TxTypeRequestInfo
* @return {String} 
*/
TxTypeRequestInfo.prototype.getPREAUTH = function() {
  return this.PREAUTH;
};

/**
* Get the field value
* @memberof remotemessage.TxTypeRequestInfo
* @return {String} 
*/
TxTypeRequestInfo.prototype.getCREDIT = function() {
  return this.CREDIT;
};

/**
* Get the field value
* @memberof remotemessage.TxTypeRequestInfo
* @return {String} 
*/
TxTypeRequestInfo.prototype.getREFUND = function() {
  return this.REFUND;
};

/**
* Get the field value
* @memberof remotemessage.TxTypeRequestInfo
* @return {String} 
*/
TxTypeRequestInfo.prototype.getVOIDPAYMENT = function() {
  return this.VOID_PAYMENT;
};

/**
* @memberof remotemessage.TxTypeRequestInfo
* @private
*/
TxTypeRequestInfo.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

TxTypeRequestInfo.prototype.toString = function() {
  return JSON.stringify(this);
};

TxTypeRequestInfo._meta_ =  {fields:  {}};
TxTypeRequestInfo._meta_._class_ =  TxTypeRequestInfo;
TxTypeRequestInfo._meta_.fields["SALE"] = {};
TxTypeRequestInfo._meta_.fields["SALE"].type = String;
TxTypeRequestInfo._meta_.fields["AUTH"] = {};
TxTypeRequestInfo._meta_.fields["AUTH"].type = String;
TxTypeRequestInfo._meta_.fields["PREAUTH"] = {};
TxTypeRequestInfo._meta_.fields["PREAUTH"].type = String;
TxTypeRequestInfo._meta_.fields["CREDIT"] = {};
TxTypeRequestInfo._meta_.fields["CREDIT"].type = String;
TxTypeRequestInfo._meta_.fields["REFUND"] = {};
TxTypeRequestInfo._meta_.fields["REFUND"].type = String;
TxTypeRequestInfo._meta_.fields["VOID_PAYMENT"] = {};
TxTypeRequestInfo._meta_.fields["VOID_PAYMENT"].type = String;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TxTypeRequestInfo;
}

