import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: "#0000004D",
    marginTop: 10
  },
  profileImageContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "20%",
  },
  mainContentContainer: {
    flexDirection: "column",
    width: "80%",
  },
  contentContainer: {
    flexDirection: "row",
    marginTop: 4,
  },
  authorName: {
    fontWeight: "bold",
  },
  separateContent: {
    marginLeft: 4,
  },
  titleNameStyles: {
    fontSize: 14,
  },
  contentText: {
    fontSize: 12,
  },
  secondaryInfo: {
    color: "#687684",
    fontSize: 12,
    paddingTop: 2,
  },
  image: {
    borderRadius: 20,
  },
  publicMetricContainer: {
    flexDirection: "row",
    marginRight: 20
  }
});
