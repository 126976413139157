/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */


/**
* @memberof remotemessage
* @enum {string}
*/
var TxStartResponseResult = {
  SUCCESS : "SUCCESS",
  ORDER_MODIFIED : "ORDER_MODIFIED",
  ORDER_LOAD : "ORDER_LOAD",
  DUPLICATE : "DUPLICATE",
  FAIL : "FAIL"
};


//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = TxStartResponseResult;
}

