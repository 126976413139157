/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var ShutDownMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = ShutDownMessage;
  this.setMethod(remotemessage_Method["SHUTDOWN"]);
};

ShutDownMessage.prototype = Object.create(remotemessage_Message.prototype);
ShutDownMessage.prototype.constructor = ShutDownMessage;

ShutDownMessage._meta_ =  {fields:  {}};
ShutDownMessage._meta_._class_ =  ShutDownMessage;
ShutDownMessage._meta_._superMeta_ = remotemessage_Message._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = ShutDownMessage;
}

