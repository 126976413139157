/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_Method = require("../remotemessage/Method");
var payments_VasConfig = require("../payments/VasConfig");
var remotemessage_Message = require("../remotemessage/Message");

/**
* @constructor
* @augments remotemessage.Message
* @memberof remotemessage
*/
var StartVasMessage = function() {
  remotemessage_Message.call(this);
  this._superClass_ = remotemessage_Message;
  this._class_ = StartVasMessage;
  this.setMethod(remotemessage_Method["START_VAS"]);
  this.config = undefined;
};

StartVasMessage.prototype = Object.create(remotemessage_Message.prototype);
StartVasMessage.prototype.constructor = StartVasMessage;

/**
* Set the field value
* The configuration to use when starting VAS, or null
*
* @memberof remotemessage.StartVasMessage
* @param {payments.VasConfig} config 
*/
StartVasMessage.prototype.setConfig = function(config) {
  this.config = config;
};

/**
* Get the field value
* The configuration to use when starting VAS, or null
* @memberof remotemessage.StartVasMessage
* @return {payments.VasConfig} 
*/
StartVasMessage.prototype.getConfig = function() {
  return this.config;
};

StartVasMessage._meta_ =  {fields:  {}};
StartVasMessage._meta_._class_ =  StartVasMessage;
StartVasMessage._meta_._superMeta_ = remotemessage_Message._meta_;
StartVasMessage._meta_.fields["config"] = {};
StartVasMessage._meta_.fields["config"].type = payments_VasConfig;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = StartVasMessage;
}

