/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotemessage_VasState = require("../remotemessage/VasState");

/**
* @constructor
* @memberof remotemessage
*/
var VasStateEnum = function() {
  this._class_ = VasStateEnum;
  this.vasState = undefined;
};


/**
* Set the field value
* @memberof remotemessage.VasStateEnum
* @param {remotemessage.VasState} vasState 
*/
VasStateEnum.prototype.setVasState = function(vasState) {
  this.vasState = vasState;
};

/**
* Get the field value
* @memberof remotemessage.VasStateEnum
* @return {remotemessage.VasState} 
*/
VasStateEnum.prototype.getVasState = function() {
  return this.vasState;
};

/**
* @memberof remotemessage.VasStateEnum
* @private
*/
VasStateEnum.prototype.getMetaInfo = function(fieldName) {
  var curclass = this._class_;
  do {
    var fieldMetaInfo = curclass._meta_.fields[fieldName];
    if(fieldMetaInfo) {
      return fieldMetaInfo;
    }
    if(curclass._meta_._superMeta_) {
      curclass = curclass._meta_._superMeta_._class_;
    } else {
      curclass = null;
    }
  } while(curclass);
  return null;
};

VasStateEnum.prototype.toString = function() {
  return JSON.stringify(this);
};

VasStateEnum._meta_ =  {fields:  {}};
VasStateEnum._meta_._class_ =  VasStateEnum;
VasStateEnum._meta_.fields["vasState"] = {};
VasStateEnum._meta_.fields["vasState"].type = remotemessage_VasState;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = VasStateEnum;
}

