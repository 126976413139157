import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10,
    marginBottom: 10,
  },
  header__title: {
    fontSize: 12,
    color: "#89909B",
    letterSpacing: 1.25,
  },
  header__seeFull: {
    fontSize: 12,
    color: "#0F6657",
    letterSpacing: 1.25,
  },
  itemSeparator: {
    marginLeft: 20,
  },
});
