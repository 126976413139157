/**
 * Autogenerated by Avro
 * 
 * DO NOT EDIT DIRECTLY
 */

var remotepay_ActivityMessage = require("../remotepay/ActivityMessage");

/** A message sent to custom activities */
/**
* @constructor
* @augments remotepay.ActivityMessage
* @memberof remotepay
*/
var MessageToActivity = function() {
  remotepay_ActivityMessage.call(this);
  this._superClass_ = remotepay_ActivityMessage;
  this._class_ = MessageToActivity;
};

MessageToActivity.prototype = Object.create(remotepay_ActivityMessage.prototype);
MessageToActivity.prototype.constructor = MessageToActivity;

MessageToActivity._meta_ =  {fields:  {}};
MessageToActivity._meta_._class_ =  MessageToActivity;
MessageToActivity._meta_._superMeta_ = remotepay_ActivityMessage._meta_;

//
// Expose the module.
//
if ('undefined' !== typeof module) {
module.exports = MessageToActivity;
}

